import React, { FC } from 'react';
import { AppTitles } from 'src/constants/titles.enum';
import { GridWrapper, GridItem } from '@jsluna/react';
import { BECOME_A_SUPPLIER } from 'src/constants/icons';

export const BecomeASupplier: FC = () => {
  const BECOME_A_SUPPLIER_LINK =
    'https://about.sainsburys.co.uk/suppliers/becoming-a-supplier';

  const becomingSupplierLeftBoxSizes = {
    xs: '1/1',
    sm: '1/1',
    md: '4/10',
    lg: '3/10',
    xl: '3/10',
  };

  const becomingSupplierRightBoxSizes = {
    xs: '1/1',
    sm: '1/1',
    md: '6/10',
    lg: '7/10',
    xl: '7/10',
  };

  return (
    <GridWrapper
      className='background--v-light-grey landing-page__become-supplier'
      verticalAlign='middle'
    >
      <GridItem
        size={becomingSupplierLeftBoxSizes}
        className='landing-page__become-supplier__left-box'
      >
        <img
          className='landing-page__become-supplier__image'
          src={BECOME_A_SUPPLIER}
          alt='Becoming a Supplier'
        />
      </GridItem>
      <GridItem
        size={becomingSupplierRightBoxSizes}
        className='landing-page__become-supplier__right-box'
      >
        <h2 className='ln-u-display-4 landing-page__section-title'>
          Becoming a Supplier
        </h2>
        <p className='landing-page__become-supplier__description'>
          We&#39;re always looking for new producers and suppliers. It&#39;s
          easy to apply - all you need to do is complete the application form.
        </p>
        <p>
          <a
            href={BECOME_A_SUPPLIER_LINK}
            data-cy='becoming-supplier-link'
            className='navigation__button ln-c-button ln-c-button--filled'
          >
            New supplier application
          </a>
        </p>
      </GridItem>
    </GridWrapper>
  );
};
