import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 128px);
  background-size: cover;
  background-repeat: no-repeat;

  .login-section {
    margin-right: 200px;
    margin-left: auto;
    width: 400px;
    height: 350px;
    background-color: #fff;
    border-radius: 20px;
    transition: all var(--ds-modifier-transition-duration);

    box-shadow: 2px 2px 10px 1px rgba(0, 0, 0, 0.24);
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    padding: 20px;

    .login-btn {
      border: 1px solid #9b99af;
      border-radius: 6px;
      min-width: 80%;
    }

    h1 {
      color: ${ThemeColors.primary};
      font-size: 50px !important;
    }

    .pps-desc {
    }

    &.select-option {
      height: 400px;
    }

    .account-section {
      width: 100%;
      .accounts {
        max-height: 300px;
        overflow: auto;

        .account-option {
          border: 1px solid #d8d8d8;
          padding: 16px;
          text-align: left;
          display: flex;
          cursor: pointer;
          transition: all var(--ds-modifier-transition-duration);
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .ln-c-progress-spinner {
            height: 25px;
          }

          &:hover {
            border: 1px solid #f06c00;

            svg {
              color: #f06c00;
            }
          }
        }
      }
    }
  }
`;
