import styled from 'styled-components';
import { Card } from '@jsluna/react';
import {
  QuickLinkColors,
  ThemeColors,
} from '../../../../shared/styles/variables';

export const QuickLinkStyledCard = styled((props: any) => <Card {...props} />)`
  padding: 16px 24px;
  background: ${props =>
    props.color ? QuickLinkColors[props.color as string] : '#fff'};
  border-radius: 5px;
  margin-bottom: 22px;
  position: relative;
  cursor: pointer;
  text-decoration: none;

  .link-button {
    min-height: 20px;
    min-width: 20px;
    line-height: 20px;
    svg {
      padding: 0;
      height: 20px;
      width: 20px;
      color: #fff;
    }
  }

  h2.heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${props =>
      props.color === 'yellow'
        ? ThemeColors.monochromeDarker
        : ThemeColors.monochromeZero};
  }

  .description {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${ThemeColors.monochromeZero};
  }

  &::after {
    content: '';
    display: block;
    width: 17px;
    height: 14px;
    position: absolute;
    bottom: 16px;
    right: 24px;
    background-image: url(${props =>
      props.color === 'yellow'
        ? 'img/RightArrowBlack.svg'
        : 'img/RightArrow.svg'});
  }
`;
