import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';
import { envConfiguration } from 'src/utils/loadEnvConfig';

const options = {
  init: {
    distributed_tracing: { enabled: true },
    privacy: { cookies_enabled: true },
    ajax: { deny_list: ['bam.nr-data.net'] },
  },
  info: {
    beacon: 'bam.nr-data.net',
    errorBeacon: 'bam.nr-data.net',
    licenseKey: envConfiguration.newrelicLicenseKey,
    applicationID: envConfiguration.newrelicApplicationId,
    sa: 1,
  },
  loader_config: {
    accountID: envConfiguration.newrelicAccountId,
    trustKey: envConfiguration.newrelicTrustKey,
    agentID: envConfiguration.newrelicAgentId,
    licenseKey: envConfiguration.newrelicLicenseKey,
    applicationID: envConfiguration.newrelicApplicationId,
  },
};

new BrowserAgent(options);
