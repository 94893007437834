import React, { FC, useEffect, useState } from 'react';
import { UpdateProfileContainer } from './updateProfileScreen.styles';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextInputField } from '@jsluna/react';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { IProfileDetails } from '@interfaces/profile.interface';
import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { ProfileCard_ProfileInfoFragment } from 'src/operations/generated/graphql';

interface IUpdateProfileEmailScreen {
  handleClose: () => void;
  handleConfirmation: (status: any) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  heading: string;
  profile: ProfileCard_ProfileInfoFragment;
}

export const UpdateProfileEmailScreen: FC<IUpdateProfileEmailScreen> = ({
  handleConfirmation,
  handleClose,
  error,
  heading,
  loading,
  target,
  profile,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const updateEmailSchema = Yup.object().shape({
    emailAddress: Yup.string()
      .required(InviteProfileMessages.emailAddress.required)
      .email(InviteProfileMessages.emailAddress.valid),
  });

  const emailFormObj = useForm({
    resolver: yupResolver(updateEmailSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = emailFormObj;

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setFeedBackMessage(null);
  };

  const handleFormSubmit = (values: any) => {
    handleConfirmation(values);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <UpdateProfileContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${profile.firstName}'s email has been updated.`
                  : `Failed to update the email for ${profile.firstName}'s profile.`
              }
              saveButtonText={'Save and close'}
              onCloseHandler={handleClose}
              resetButtonText={'Further changes'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `Profile successfully updated`
                  : 'Failed to update profile'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              <h3>{heading}</h3>
            </div>
            <div className='panel-body'>
              <form
                id='update-profile-email'
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <Controller
                  name='emailAddress'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      label='Email address'
                      placeholder={profile.emailAddress}
                      error={errors?.emailAddress?.message}
                    />
                  )}
                />
              </form>
            </div>
          </>
        )}
      </UpdateProfileContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            form='update-profile-email'
            type='submit'
            variant='filled'
            disabled={!isValid}
          >
            Update email
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
