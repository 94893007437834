import { LocalStorageNames } from 'src/constants/localStorageNames';
import { createSlice } from '@reduxjs/toolkit';
import { IAccount } from 'src/interfaces/account.interface';
import { IProfile } from 'src/interfaces/profile.interface';
import { INotification } from '../interfaces/notification.interface';
import { NotificationType } from 'src/enums/notificationTypes.enum';

export interface IAppState {
  profile: IProfile | null;
  profileAccounts: IAccount[] | [];
  selectedAccount: IAccount | null;
  notifications: INotification[];
  isLoggedIn: boolean;
  feedback: {
    message: string;
    type: NotificationType;
    targetId?: string;
  } | null;
}

const selectedAccount =
  localStorage.getItem(LocalStorageNames.PPS_ACCOUNT) || null;

let selectAccountObj;

try {
  selectAccountObj = selectedAccount ? JSON.parse(selectedAccount) : null;
} catch (error) {
  selectAccountObj = null;
}

const initialState: IAppState = {
  profile: null,
  profileAccounts: [],
  selectedAccount: selectAccountObj,
  notifications: [],
  isLoggedIn: false,
  feedback: null,
};

const appSlice = createSlice({
  name: 'appState',
  initialState: initialState,
  reducers: {
    setInitialState(state, action) {
      state.profile = action.payload.profileDetails;
      state.profileAccounts = action.payload.accounts;
      state.isLoggedIn = action.payload.isLoggedIn;
      if (state.selectedAccount?.id !== action.payload.selectedAccount?.id) {
        localStorage.setItem(
          LocalStorageNames.PPS_ACCOUNT,
          JSON.stringify(action.payload.selectedAccount),
        );
        state.selectedAccount = action.payload.selectedAccount;
      }
    },
    updateState(state, action) {
      state = action.payload;
    },
    setProfileAccounts(state, action) {
      state.profileAccounts = action.payload;
    },
    setProfileDetails(state, action) {
      state.profile = action.payload;
    },
    setSelectedAccount(state, action) {
      state.selectedAccount = action.payload;
    },
    setInitialNotifications(state, action) {
      state.notifications = action.payload;
    },
    addNotification(state, action) {
      state.notifications = [
        action.payload as INotification,
        ...state.notifications,
      ];
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload;
    },
    setShowFeedback(state, action) {
      state.feedback = action.payload;
    },
    setHideFeedback(state) {
      state.feedback = null;
    },
  },
});

export const {
  setInitialState,
  updateState,
  setSelectedAccount,
  setProfileAccounts,
  setProfileDetails,
  setInitialNotifications,
  addNotification,
  setLoggedIn,
  setShowFeedback,
  setHideFeedback,
} = appSlice.actions;
export default appSlice.reducer;
