import React, { FC } from 'react';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { NotificationType } from 'src/enums/notificationTypes.enum';

interface ITagFeedback {
  type: NotificationType;
  onClose: () => void;
}

export const TagFeedback: FC<ITagFeedback> = ({ type, onClose }) => {
  return (
    <NotificationBanner
      loading={false}
      messageType={type}
      message={
        type === NotificationType.FAILURE
          ? 'Error in deleting tag'
          : 'Tag deleted'
      }
      onClose={onClose}
    />
  );
};
