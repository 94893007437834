import React from 'react';
import { MovedPageWrapper } from './movedPage.styles';
import { GridWrapper, GridItem, Link } from '@jsluna/react';

export const MovedPage = () => {
  return (
    <MovedPageWrapper>
      <GridWrapper verticalAlign='middle'>
        <GridItem
          size={{
            sm: '1/1',
            md: '1/2',
          }}
        >
          <div className='img-container'>
            <img
              className='img-error'
              src={`${process.env.PUBLIC_URL}/img/301.svg`}
              alt='Service Error'
            />
          </div>
        </GridItem>
        <GridItem
          size={{
            sm: '1/1',
            md: '1/2',
          }}
        >
          <h2 className=''>Website you are looking for has moved</h2>
          <p>
            The website you are looking has moved it's address. Please visit{' '}
            <Link href='https://togetherwith.co.uk/' bare color='alpha'>
              Together with
            </Link>
          </p>
        </GridItem>
      </GridWrapper>
    </MovedPageWrapper>
  );
};
