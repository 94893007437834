import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const EditRolesContainer = styled('div')`
  width: 448px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  height: 100vh;

  h4 {
    margin-bottom: 0;
    font-family: var(--ds-font-stack-display-2-font-family)!important;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    color: ${ThemeColors.black};
    margin-bottom: 28px;
  }

  .tab-heading {
    font-family: var(--ds-font-stack-display-2-font-family)!important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${ThemeColors.black};
    margin-bottom: 18px;
    margin-top: 24px;
  }

  .panel-body {
    overflow: auto;
  }

    .ln-c-progress-spinner {
      height: 25px;
      width: 25px;
      margin-right: 10px;
      color: ${ThemeColors.white};
    }
  }

  .feedback-container {
    position: relative;
    padding-top: 72px;

    .feedback-message {
      font-family: var(--ds-font-stack-display-2-font-family)!important;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 72px;
    }
  }
`;
