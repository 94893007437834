import { NotificationType } from '@enums/notificationTypes.enum';
import React, { FC } from 'react';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { ScreenFeedbackContainer } from './screenFeedback.styles';
import { Button } from '@jsluna/react';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

interface IScreenFeedback {
  isLoading: boolean;
  notificationType: NotificationType;
  saveButtonText: string;
  onCloseHandler: () => void;
  feedBackMessage: string;
  resetButtonText: string;
  resetHandler: () => void;
  notificationMessage?: string;
  feedBackInfo?: string;
}

export const ScreenFeedback: FC<IScreenFeedback> = ({
  isLoading,
  notificationType,
  feedBackMessage,
  saveButtonText,
  onCloseHandler,
  resetButtonText,
  resetHandler,
  notificationMessage,
  feedBackInfo,
}) => {
  return (
    <>
      <ScreenFeedbackContainer>
        <NotificationBanner
          loading={isLoading}
          message={notificationMessage || 'Added Successfully'}
          messageType={notificationType}
          onClose={() => {
            onCloseHandler();
          }}
        />
        <div className='feedback-message'>{feedBackMessage}</div>
        <p className='feedback-info'>{feedBackInfo}</p>
      </ScreenFeedbackContainer>
      <PanelActions className='no-gap'>
        <Button onClick={onCloseHandler} variant='filled'>
          {saveButtonText}
        </Button>
        <Button onClick={resetHandler} variant='outlined'>
          {resetButtonText}
        </Button>
      </PanelActions>
    </>
  );
};
