import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

export const AddTagsContainer = styled('div')`
  width: 448px;
  padding: 24px;
  padding-bottom: 0;

  .panel-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${ThemeColors.black};
    margin-bottom: 18px;
    margin-top: 0;
  }

  .no-tags-to-select {
  }
`;

export const TagLoader = styled((props: any) => <ContentLoader {...props} />)`
  margin: 20px;
`;
