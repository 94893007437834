import React, { FC, ReactNode } from 'react';
import { GridItem, GridWrapper } from '@jsluna/react';

interface IContentWrapper {
  children: ReactNode;
  noMargin?: boolean;
}

export const ContentWrapper: FC<IContentWrapper> = ({ children, noMargin }) => (
  <div
    className={`ln-u-text-align-center ${!noMargin ? 'ln-u-soft-top-xl' : ''} `}
  >
    <GridWrapper matrix>
      <GridItem
        size={noMargin ? '1' : { md: '5/6', lg: '3/4' }}
        className={` ${noMargin ? `ln-u-flush-sides` : ''}`}
      >
        {children}
      </GridItem>
    </GridWrapper>
  </div>
);
