import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';
import * as Yup from 'yup';

export default Yup.object().shape(
  {
    name: Yup.string().required(InviteProfileMessages.name.required),
    jobTitle: Yup.string().required(InviteProfileMessages.jobTitle.required),
    accountTypeId: Yup.string().required(
      InviteProfileMessages.accountTypeId.required,
    ),
    siteId: Yup.string(), //.required(InviteProfileMessages.siteId.required),
    isPartner: Yup.string().required(InviteProfileMessages.isPartner.required),
    roles: Yup.array().of(Yup.string()),
    tags: Yup.array().of(Yup.string()),
    partnerName: Yup.string().when('isPartner', {
      is: 'yes',
      then: Yup.string().required(InviteProfileMessages.partnerName.required),
    }),
    partnerContact: Yup.string().when('isPartner', {
      is: 'yes',
      then: Yup.string().required(
        InviteProfileMessages.partnerContact.required,
      ),
    }),
  },
  [['isPartner', 'isPartner']],
);
