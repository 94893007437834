import React, { FC } from 'react';
import { AppCHB } from './applicationDeleteRow.styles';
import { createLogo } from 'src/utils/createLogo';
import { capitalize } from 'src/utils/capitalize';
import { Button } from '@jsluna/react';
import { Delete } from '@jsluna/icons';
import { AddNewAppGroupScreen_ApplicationFragment } from 'src/operations/generated/graphql';

const DeleteIcon = Delete as unknown as React.FC;

interface IApplicationDeleteRow extends React.ComponentPropsWithoutRef<'div'> {
  application: AddNewAppGroupScreen_ApplicationFragment;
  deleteHandler: () => void;
}

export const ApplicationDeleteRow: FC<IApplicationDeleteRow> = ({
  application,
  deleteHandler,
  ...props
}) => {
  const { name, shortDescription } = application;
  return (
    <AppCHB key={props.key}>
      <div className='app-img'>
        <div className='app-text-img'>{createLogo(name || '')}</div>
      </div>
      <div className='app-info'>
        <div className='app-name'>{capitalize(name || '')}</div>
        <div className='app-desc'>{shortDescription}</div>
      </div>
      <div className='deleteBox'>
        <Button variant='text' onClick={deleteHandler} color='dark'>
          <DeleteIcon />
        </Button>
      </div>
    </AppCHB>
  );
};
