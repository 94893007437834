import React, { FC, useEffect, useState } from 'react';
import { INotification } from '@interfaces/notification.interface';
import { gql } from '@apollo/client';
import {
  Switch,
  TextInputField,
  GridWrapper,
  GridItem,
  ButtonGroupWrapper,
  ButtonGroupSecondary,
  RadioButtonField,
  FilledButton,
  ProgressIndicator,
  ProgressSpinner,
} from '@jsluna/react';
import { Tick, ErrorCircle } from '@jsluna/icons';
import { InfoPanelTypes } from 'src/enums/infoPanelTypes.enum';
import { InfoPanel } from 'src/shared/components/infoPanel';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { getSelectedAccount } from 'src/redux/selectors';
import { SiteRequestWrapper } from './SiteRequest.styles';
import { setShowFeedback } from 'src/redux/reducer';
import { NotificationType } from 'src/enums/notificationTypes.enum';
const TickIcon = Tick as unknown as React.FC<{ className?: string }>;
const WarningIcon = ErrorCircle as unknown as React.FC<{ className: string }>;

import { useForm, Controller } from 'react-hook-form';

import {
  useTakeOverMutation,
  useUnassignNotificationMutation,
  useAddApprovedDomainToSiteMutation,
  useAddRejectedDomainToSiteMutation,
  useSiteAddApprovedAccountsMutation,
  useSiteRejectAccountRequestMutation,
  useAssociateAccountToSitesMutation,
  RejectReasonOptions,
  useNotification_SiteRequestSiteInfoQuery,
  Notifications_AccountInfoFragment,
} from 'src/operations/generated/graphql';

gql`
  query Notification_SiteRequestSiteInfo($id: String!, $accountId: String!) {
    site(id: $id) {
      id
      name
      supplierCode
      supplierNumber
      status
      activeStatus
      groupId
      parentGroup {
        value {
          id
          name
        }
      }
      account(accountId: $accountId) {
        requestedId
      }
    }
  }
`;

interface ISiteRequest {
  notification: INotification;
  accountDetails: Notifications_AccountInfoFragment;
  refetchNotification: () => void;
}

export const SiteRequest: FC<ISiteRequest> = ({
  notification,
  accountDetails,
  refetchNotification,
}) => {
  const selectedAccount = useAppSelector(getSelectedAccount);
  const dispatch = useAppDispatch();

  const [approveReject, setApproveReject] = useState<boolean | null>(null);
  const [automaticApprove, setAutomaticApprove] = useState<boolean | null>(
    null,
  );
  const [reason, setReason] = useState<RejectReasonOptions | null>(null);
  const [reasonComment, setReasonComment] = useState<string>('');

  if (!selectedAccount?.id) return <p>No Accounts selected.</p>;

  const [takenOver, setTakenOver] = useState(false);

  useEffect(() => {
    if (selectedAccount?.id)
      setTakenOver(selectedAccount?.id === notification.assignedTo?.id);
  }, [selectedAccount]);

  const {
    handleSubmit,
    control,
    formState: { isDirty },
    watch,
  } = useForm();

  const [takeOver, { loading: takeOverLoading }] = useTakeOverMutation();
  const [unAssign, { loading: unAssignLoading }] =
    useUnassignNotificationMutation();

  const toggleAssignment = async () => {
    let result = null;

    if (takenOver) {
      const { data } = await unAssign({
        variables: {
          entityId: notification.entityId,
        },
      });
      if (data?.unassignNotification) {
        result = true;
      }
    } else {
      const { data } = await takeOver({
        variables: {
          entityId: notification.entityId,
        },
      });
      if (data?.takeOver) {
        result = true;
      }
    }

    if (result) {
      refetchNotification();
      setTakenOver(!takenOver);
    }
  };

  const { data: { site: siteData = null } = {} } =
    useNotification_SiteRequestSiteInfoQuery({
      variables: {
        id: notification.sourceId,
        accountId: accountDetails.id,
      },
    });

  //   @ts-ignore
  const domain = accountDetails.profile?.value.emailAddress.split('@')[1];

  const verifiedStatusIcon = (status: string) => {
    switch (status) {
      case 'VERIFIED':
        return <TickIcon className={'green-circle icon tick'} />;
      case 'TEMP':
      case 'PERSPECTIVE':
        return <WarningIcon className={'green-circle icon warning'} />;
    }
  };

  const [approveMutate, { loading: approveLoading }] =
    useSiteAddApprovedAccountsMutation();
  const [rejectMutate, { loading: rejectLoading }] =
    useSiteRejectAccountRequestMutation();
  const [approvedDomainToSiteMutate, { loading: approvedDomainToSiteLoading }] =
    useAddApprovedDomainToSiteMutation();
  const [rejectDomainToSiteMutate, { loading: rejectDomainToSiteLoading }] =
    useAddRejectedDomainToSiteMutation();

  const [
    associateMutate,
    { error: associateError, loading: associateLoading },
  ] = useAssociateAccountToSitesMutation();

  const confirmSiteRequest = async () => {
    if (!siteData?.id) return;
    try {
      // need to change this to allow reason not to be null
      if (!approveReject && reason) {
        const rejected = await rejectMutate({
          variables: {
            reject: {
              siteId: siteData?.id,
              accountId: accountDetails.id,
              reason,
              reasonComment,
            },
          },
        });
        if (automaticApprove && domain) {
          rejectDomainToSiteMutate({
            variables: {
              id: siteData?.id,
              domain: domain,
            },
          });
        }

        if (rejected?.data?.siteRejectAccountRequest?.id) {
          dispatch(
            setShowFeedback({
              message: 'Site rejected successfully',
              type: NotificationType.SUCCESS,
              targetId: notification.sourceId,
            }),
          );
        }
      } else {
        const approved = await approveMutate({
          variables: {
            siteId: siteData?.id,
            accountIds: [accountDetails.id],
          },
        });

        if (automaticApprove && domain) {
          approvedDomainToSiteMutate({
            variables: {
              id: siteData?.id,
              domain: domain,
            },
          });
        }

        if (approved?.data?.siteAddApprovedAccounts?.id) {
          dispatch(
            setShowFeedback({
              message: 'Site approved successfully',
              type: NotificationType.SUCCESS,
              targetId: notification.sourceId,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(
        setShowFeedback({
          message: 'Something wrong',
          type: NotificationType.FAILURE,
        }),
      );
    }
  };

  const submitHandler = async (data: any) => {
    if (!siteData?.id) return;
    try {
      const result = await associateMutate({
        variables: {
          id: accountDetails.id,
          siteIds: [data.sID],
        },
      });
      if (result?.data?.associateAccountToSites?.id) {
        const rejected = await rejectMutate({
          variables: {
            reject: {
              siteId: siteData?.id,
              accountId: accountDetails.id,
              reason: RejectReasonOptions.SupplierCodeNotRecognised,
              reasonComment,
            },
          },
        });

        if (rejected?.data?.siteRejectAccountRequest?.id) {
          dispatch(
            setShowFeedback({
              message: 'SID is changed and this notification is dealt with',
              type: NotificationType.SUCCESS,
              targetId: notification.sourceId,
            }),
          );
        }
      }
    } catch (error) {
      dispatch(
        setShowFeedback({
          message: 'Could not complete the request',
          type: NotificationType.FAILURE,
        }),
      );
    }
  };

  const watchSID = watch('sID');

  const assignee = notification.assignedTo
    ? notification.assignedTo?.profile.value.firstName +
      ' ' +
      notification.assignedTo?.profile.value.lastName
    : takenOver
    ? // @ts-ignore
      selectedAccount?.profile?.value.firstName +
      ' ' +
      // @ts-ignore
      selectedAccount?.profile?.value.lastName
    : null;

  return (
    <SiteRequestWrapper>
      <div className='assign-to-me'>
        <div className='label-1 ln-u-margin-bottom*3'>Company details</div>
        <div>
          {takeOverLoading || unAssignLoading ? (
            <>
              <div className='loading-container ln-u-display-flex ln-u-margin-ends'>
                <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
                Loading
              </div>
            </>
          ) : (
            <>
              {assignee && (
                <div className='assigned-info ln-u-margin-ends'>{assignee}</div>
              )}
            </>
          )}
          <Switch
            name={`assign-task-${notification.entityId}`}
            label={!takenOver ? `Assign task to me` : `Assigned to me`}
            outlined
            className='ln-u-margin-bottom*3'
            disabled={takeOverLoading || unAssignLoading}
            onClick={toggleAssignment}
            checked={takenOver}
          />
        </div>
      </div>
      <GridWrapper>
        <GridItem size={{ md: '1/2', xs: '1/1' }}>
          <TextInputField
            name='text-input-group-name-smd'
            label='Group name in SMD'
            value={siteData?.parentGroup?.value.name}
            disabled={true}
          />

          <TextInputField
            name='text-input-group-name-smd'
            label='name in SMD'
            value={siteData?.name}
            disabled={true}
          />
          <TextInputField
            name='text-input-supplier-code-smd'
            label='Supplier code name in SMD'
            value={siteData?.supplierCode}
            disabled={true}
          />
          <TextInputField
            name='text-input-supplier-code-smd'
            label='Supplier number'
            value={siteData?.supplierNumber}
            disabled={true}
          />

          <form onSubmit={handleSubmit(submitHandler)}>
            <Controller
              name='sID'
              control={control}
              render={({ field }) => (
                <TextInputField
                  {...field}
                  label='SID code in SMD '
                  placeholder={siteData?.id}
                  onChange={field.onChange}
                />
              )}
            />
          </form>
        </GridItem>
      </GridWrapper>

      <GridWrapper>
        <GridItem size={{ md: '1/1', xs: '1/1' }}>
          <div className='label-1 ln-u-margin-bottom'>Supplier code status</div>

          <div className='requested-roles ln-u-margin-bottom'>
            {siteData?.activeStatus ? (
              <>
                <TickIcon className='green-circle' /> Active
              </>
            ) : (
              <>
                <WarningIcon className='yellow-circle' /> Not Active
              </>
            )}
          </div>

          <div className='label-1 ln-u-margin-bottom'>
            Supplier verification status
          </div>
          <div className='requested-roles ln-u-margin-bottom'>
            {verifiedStatusIcon(siteData?.status || '')} {siteData?.status}
          </div>

          <TextInputField
            name='text-input-supplier-ref'
            label='Supplier Reference number provided'
            value={siteData?.account?.requestedId}
            readOnly={true}
            disabled={true}
          />

          {!watchSID && (
            <>
              <div className='label-1 ln-u-margin-top*3'>
                Accept or reject access to Together With
              </div>

              <RadioButtonField
                name='approve-reject-site'
                hideLabel
                fullWidth
                options={[
                  { value: true, label: 'Accept' },
                  { value: false, label: 'Reject' },
                ]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setApproveReject(e.target.value === 'true' ? true : false);
                }}
              />
            </>
          )}

          {approveReject !== null && (
            <>
              {approveReject === false && (
                <>
                  <div className='label-1 ln-u-margin-bottom'>
                    Please select a reason for rejecting
                  </div>
                  <div>
                    If you reject this application, {selectedAccount.name} will
                    receive a notification via email about the decision.
                  </div>

                  <RadioButtonField
                    name='reject-options'
                    hideLabel
                    fullWidth
                    options={[
                      {
                        value: RejectReasonOptions.SupplierCodeNotRecognised,
                        label: `The supplier code provided is not recognised on our system`,
                      },
                      {
                        value: RejectReasonOptions.SupplierNameNotMatch,
                        label: `The supplier name provided does not match our system`,
                      },
                      {
                        value: RejectReasonOptions.GenericEmailAddress,
                        label: `The email address provided is a generic email address`,
                      },
                      {
                        value: RejectReasonOptions.Other,
                        label: `Other`,
                      },
                    ]}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setReason(e.target.value as RejectReasonOptions);
                      setReasonComment('');
                    }}
                  />
                  {reason === RejectReasonOptions.Other && (
                    <>
                      <TextInputField
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setReasonComment(e.target.value);
                        }}
                        label='Message to include in email/notification.'
                        info='Include a reason for the rejection so they can try to resolve the issue.'
                        placeholder='Please enter'
                      />
                    </>
                  )}
                </>
              )}
              <div className='label-1 ln-u-margin-top*3'>
                Do you want @{domain} email domains to be automatically{' '}
                {approveReject ? 'approved ' : 'rejected '}
                in the future for SID: {siteData?.supplierCode}?
              </div>

              <InfoPanel
                infoType={InfoPanelTypes.INFORMATION}
                infoText={`If you add @${domain} to the automatically ${
                  approveReject ? 'approved' : 'rejected'
                } list, users requesting access to Together With using an @${domain} email domain linked to SID: ${
                  siteData?.supplierCode
                } will be automatically ${
                  approveReject ? 'approved' : 'rejected'
                }, subject to meeting approval/reject criteria.`}
                customClass={'roleInfo'}
              />

              <RadioButtonField
                name='auto-approve-reject'
                hideLabel
                fullWidth
                options={[
                  {
                    value: true,
                    label: `Yes - automatically ${
                      approveReject ? 'approve' : 'reject'
                    } in future`,
                  },
                  {
                    value: false,
                    label: `No - don’t automatically ${
                      approveReject ? 'approve' : 'reject'
                    } in future`,
                  },
                ]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAutomaticApprove(e.target.value === 'true' ? true : false);
                }}
              />
            </>
          )}
        </GridItem>
      </GridWrapper>
      <ButtonGroupWrapper>
        <ButtonGroupSecondary>
          {isDirty && watchSID ? (
            <>
              <FilledButton
                className='ln-u-margin-right'
                onClick={handleSubmit(d => submitHandler(d))}
              >
                Change supplier ID
              </FilledButton>
            </>
          ) : (
            <>
              <FilledButton
                disabled={
                  approveReject === null ||
                  (approveReject === false && reason === null) ||
                  (approveReject === true && automaticApprove === null) ||
                  (approveReject === false &&
                    reason === 'OTHER' &&
                    !reasonComment.length)
                }
                className='ln-u-margin-right'
                onClick={confirmSiteRequest}
              >
                {approveLoading ||
                rejectLoading ||
                approvedDomainToSiteLoading ||
                rejectDomainToSiteLoading ? (
                  <div className='loader-content white'>
                    <ProgressSpinner /> Loading
                  </div>
                ) : (
                  <>Confirm</>
                )}
              </FilledButton>
            </>
          )}
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </SiteRequestWrapper>
  );
};
