import React, { FC, useContext, useEffect, useState } from 'react';
import { AccountDetailStatus } from 'src/pages/viewProfile/components/accountDetailStatus';
import { AccountTypeDetailSection } from '../accountTypeDetails/accountTypeDetails.styles';
import { gql } from '@apollo/client';
import { IconButton } from '@jsluna/react';
import { Plus, Minus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { createRoleRoute, manageRoleRoute } from 'src/constants/routes';
import { DeleteRoleScreen } from '../screens/deleteRoleScreen';
import { AuthContext } from '../../../../providers/AuthProvider';
import {
  AccountTypeDetails_AccountTypeInfoFragment,
  SectionRoles_RolesByAccountTypeFragment,
  useDeleteRolesMutation,
  useSectionRoles_RolesByAccountTypeQuery,
} from 'src/operations/generated/graphql';

gql`
  fragment SectionRoles_RolesByAccountType on RoleProjection {
    id
    name
  }
`;

gql`
  query SectionRoles_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      ...SectionRoles_RolesByAccountType
    }
  }
`;

enum PanelAction {
  ADD_ROLE = 'ADD_ROLE',
  DELETE_ROLE = 'DELETE_ROLE',
}

interface IRoles {
  accountType: AccountTypeDetails_AccountTypeInfoFragment;
  disabled: boolean;
}

const PlusIcon = Plus as unknown as React.FC;
const MinusIcon = Minus as unknown as React.FC;
export const SectionRoles: FC<IRoles> = ({ accountType }) => {
  // @ts-ignore
  const { isAccountTypeAdminOf } = useContext(AuthContext);

  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const handleCloseEditDetails = () => {
    setShowSidePanel(null);
  };

  const { data: { rolesByAccountType = null } = {} } =
    useSectionRoles_RolesByAccountTypeQuery({
      variables: {
        accountTypeId: accountType.id,
      },
    });

  const [roles, setRoles] = useState<SectionRoles_RolesByAccountTypeFragment[]>(
    [],
  );

  useEffect(() => {
    if (rolesByAccountType) {
      setRoles(rolesByAccountType);
    }
  }, [rolesByAccountType]);

  const [
    deleteRolesMutation,
    { loading: deleteRolesLoading, error: deleteRolesError },
  ] = useDeleteRolesMutation();

  const handleDeleteRoles = async (selectedRoles: string[]) => {
    if (!rolesByAccountType) return;
    const filteredRoles = rolesByAccountType.filter(
      role => !selectedRoles.includes(role.id),
    );

    try {
      await deleteRolesMutation({
        variables: { roleIds: selectedRoles },
      });
      setRoles(filteredRoles);
    } catch (e) {}
  };

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.DELETE_ROLE:
        return (
          <DeleteRoleScreen
            handleClose={handlePanelClose}
            roles={roles}
            loading={deleteRolesLoading}
            error={deleteRolesError}
            handleConfirmation={handleDeleteRoles}
            target={{ id: accountType.id, displayName: accountType.name }}
          />
        );
    }
  };

  return (
    <AccountTypeDetailSection>
      <h4 className='display-1 ln-u-margin-bottom*3'>
        Roles linked to account type
      </h4>
      {roles &&
        roles.map(role => {
          return (
            <AccountDetailStatus
              label={role.name || ''}
              link={manageRoleRoute({ roleId: role.id })}
            />
          );
        })}

      <div className='action-panel'>
        <a href={createRoleRoute({ accountTypeId: accountType.id })}>
          <IconButton
            variant='text'
            circle={false}
            disabled={!isAccountTypeAdminOf(accountType.id)}
            hard={false}
            element='button'
            label='Add role'
            color='dark'
          >
            <PlusIcon />
          </IconButton>
        </a>
        <IconButton
          variant='text'
          circle={false}
          disabled={!isAccountTypeAdminOf(accountType.id)}
          hard={false}
          element='button'
          label='Remove role'
          color='dark'
          onClick={() => {
            setShowSidePanel(PanelAction.DELETE_ROLE);
          }}
        >
          <MinusIcon />
        </IconButton>
      </div>

      <Drawer
        open={showSidePanel !== null}
        onClose={handleCloseEditDetails}
        anchor='right'
        className='edit-app-group'
      >
        {renderPanel()}
      </Drawer>
    </AccountTypeDetailSection>
  );
};
