import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const TagContainer = styled('div')`
font-family: 'Arial';
font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 22px;
text-align: center;
color: #f2f2f2;
padding: 9px 16px;
display: flex;
background: ${ThemeColors.primary};
border-radius: 1000px;
align-items: center;
margin-right: 15px;
margin-bottom: 15px;
min-height: 40px;
text-transform: capitalize;


.ln-c-button{
    margin-left: 15px;
    padding: 0;
    line-height: 1;
    min-width: 15px;
    min-height: 15px;
    &:hover{
        background-color: ${ThemeColors.primary} !important;
        svg{
            color: ${ThemeColors.white}!important;
        }
    }
    svg{
        width: 20px;
        height: 20px;
        padding: 0;
        color: ${ThemeColors.white}
    }
}
.
`;
