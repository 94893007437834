import React, { useEffect, useState } from 'react';
import { FeedbackContainer } from './feedback.styles';
import Container from 'src/components/Container/Container';
import {
  Card,
  Modal,
  ModalHeading,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  FilledButton,
} from '@jsluna/react';
import { PageHeader } from 'src/components/PageHeader';
import { FeedbackForm } from './components/feedbackForm';
import { SuccessCheck } from 'src/components/SuccessCheck';
import { ErrorCross } from 'src/components/ErrorCross';
import { useNavigate } from 'react-router';
import { routes } from 'src/constants/routes';

export const FeedbackPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const sendFeedback = (formData: any) => {
    try {
      fetch(`/profile-projection/feedback`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(formData),
      })
        .then(res => {
          if (res.status === 201) {
            setShowModal(true);
            setLoading(false);
          } else {
            setError(true);
          }
        })
        .catch(err => {
          setError(true);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  return (
    <FeedbackContainer>
      <PageHeader
        heading={`Feedback form`}
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'Feedback',
            link: '',
          },
        ]}
      />
      <Container>
        <Card>
          <h3>Feedback Form</h3>
          <p>
            We are committed to providing the best experience for our users.
            Your feedback helps us improve our services and make your experience
            even better. Please take a moment to share your thoughts with us.
          </p>

          {!loading && error && (
            <div className='message-wrapper'>
              <ErrorCross />
              <p>Something went wrong, try again later.</p>
            </div>
          )}

          <FeedbackForm submit={sendFeedback} />

          <Modal
            restrictClose
            open={showModal}
            headingId='feedback-confirmation'
          >
            <ModalHeading
              element='h3'
              className='name-modal'
            >{`Feedback sent successfully`}</ModalHeading>
            <SuccessCheck />
            <p>Thank you for your feedback! We'll review it shortly.</p>
            <ButtonGroupWrapper className='ln-u-margin-left'>
              <ButtonGroupPrimary>
                <FilledButton
                  className='ln-u-margin-right'
                  onClick={() => navigate(routes.root)}
                >
                  Home
                </FilledButton>
              </ButtonGroupPrimary>
            </ButtonGroupWrapper>
          </Modal>
        </Card>
      </Container>
    </FeedbackContainer>
  );
};
