import { FC } from 'react';
import { Modal, Button, ProgressSpinner } from '@jsluna/react';
import { StatusModalWrapper } from './StatusModal.styles';
import { SuccessCheck } from 'src/components/SuccessCheck';
import { ErrorCross } from 'src/components/ErrorCross';
import { NavLink } from 'react-router-dom';

interface IStatusModal {
  showModal: boolean;
  handleClose: () => void;
  isLoading?: boolean;
  error?: any;
  accountTypeID?: string;
}

export const CreatedStatusModal: FC<IStatusModal> = ({
  showModal,
  handleClose,
  isLoading,
  accountTypeID,
  error,
}) => {
  return (
    <div>
      <Modal
        handleClose={handleClose}
        open={showModal}
        headingId='status-modal'
        fullScreen
      >
        <StatusModalWrapper>
          {isLoading ? (
            <div className='loading-container'>
              <ProgressSpinner />
              <p>The account type is being created</p>
            </div>
          ) : (
            <div className='created-result'>
              {accountTypeID && (
                <div className='success-modal'>
                  <SuccessCheck />
                  <p>The account type has been created successfully.</p>
                  <NavLink to={`/manage-account-type`}>
                    <Button
                      circle={false}
                      disabled={false}
                      hard={false}
                      element='button'
                      variant='filled'
                    >
                      View account type
                    </Button>
                  </NavLink>
                </div>
              )}
              {error && (
                <div className='error-modal'>
                  <ErrorCross />
                  <h5>The account type couldn't be created.</h5>
                  {error.graphQLErrors.map((data: any, i: number) => (
                    <p key={i}>{data.message}</p>
                  ))}
                  <Button
                    circle={false}
                    disabled={false}
                    hard={false}
                    element='button'
                    variant='filled'
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </div>
              )}
            </div>
          )}
        </StatusModalWrapper>
      </Modal>
    </div>
  );
};
