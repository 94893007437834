import React, { FC, useEffect, useState } from 'react';
import { AddNewAppGroupContainer } from './addNewAppGroup.styles';
import { ProgressSpinner } from '@jsluna/react';

import { Button, AutocompleteField, TextInputField } from '@jsluna/react';
import { ApplicationDeleteRow } from 'src/shared/components/applicationDeleteRow';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ScreenFeedback } from '../components/screenFeedback';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { gql } from '@apollo/client';

import {
  AddNewAppGroupScreen_ApplicationFragment,
  useAddApplicationsToMyGroupMutation,
  useAddNewAppGroupScreen_MyApplicationsQuery,
} from 'src/operations/generated/graphql';

interface IOption {
  label: string;
  value: AddNewAppGroupScreen_ApplicationFragment;
}
interface IAddNewAppGroupScreen {
  closeHandler: () => void;
}

gql`
  fragment AddNewAppGroupScreen_Application on ApplicationProjection {
    id
    name
    acronym
    shortDescription
  }
`;

gql`
  query AddNewAppGroupScreen_MyApplications {
    myApplications {
      ...AddNewAppGroupScreen_Application
    }
  }
`;

export const AddNewAppGroupScreen: FC<IAddNewAppGroupScreen> = ({
  closeHandler,
}) => {
  const [showFeedBack, setShowFeedback] = useState<string>('');
  const [feedBackMessage, setFeedBackMessage] = useState<string>('');
  const [selectedApps, setSelectedApps] = useState<string[]>([]);
  const [appSearchOptions, setAppsTSearchOptions] = useState<IOption[]>([]);
  const [previewSelectedApps, setPreviewSelectedApps] = useState<
    AddNewAppGroupScreen_ApplicationFragment[]
  >([]);
  const [appGroupName, setAppGroupName] = useState('');

  const {
    data: { myApplications: applications } = {},
    error: applicationsError,
    loading: applicationsLoading,
  } = useAddNewAppGroupScreen_MyApplicationsQuery();

  const [mutate, { loading: addingLoading }] =
    useAddApplicationsToMyGroupMutation();

  const closeAppGroupHandler = () => {
    closeHandler();
  };

  const handleAddAppGroup = async () => {
    const addedId = await mutate({
      variables: {
        input: {
          groupName: appGroupName,
          applicationIds: [...selectedApps],
        },
      },
    });
    if (addedId?.data?.addApplicationsToMyGroup) {
      setShowFeedback('added');
      setFeedBackMessage(`App group successfully added`);
    }
  };

  const handleReset = () => {
    setShowFeedback('');
    setSelectedApps([]);
    setPreviewSelectedApps([]);
    buildOptions();
  };

  const handleSelectSearch = (selected: IOption) => {
    if (selected) {
      const { value } = selected;
      setSelectedApps([...selectedApps, value.id]);
      setPreviewSelectedApps([...previewSelectedApps, value]);
      setAppsTSearchOptions(
        appSearchOptions.filter((toRemove: IOption) => toRemove !== selected),
      );
    }
  };
  const handleRemoveSelection = (appID: string) => {
    const appsRemoved = previewSelectedApps.reduce((acc: IOption[], app) => {
      if (app.id === appID) {
        acc.push({
          label: app.name || '',
          value: app,
        });
      }
      return acc;
    }, []);

    setPreviewSelectedApps(
      previewSelectedApps.filter(toRemove => toRemove.id !== appID),
    );
    setSelectedApps(
      selectedApps.filter((toRemove: string) => toRemove !== appID),
    );
    setAppsTSearchOptions([...appSearchOptions, ...appsRemoved]);
  };

  useEffect(() => {
    buildOptions();
  }, [applications]);

  const buildOptions = () => {
    const options: IOption[] = [];
    applications?.map(application => {
      options.push({
        label: application.name || '',
        value: application,
      });
    });

    setAppsTSearchOptions(options);
  };

  return (
    <>
      <AddNewAppGroupContainer>
        {showFeedBack !== '' ? (
          <ScreenFeedback
            isLoading={addingLoading}
            notificationMessage={'Account updated'}
            notificationType={NotificationType.SUCCESS}
            feedBackMessage={feedBackMessage}
            saveButtonText={'Close'}
            onCloseHandler={closeAppGroupHandler}
            resetButtonText={'Add another group'}
            resetHandler={handleReset}
          />
        ) : (
          <>
            <h4>Create your app group</h4>

            <TextInputField
              name='app-group-name'
              info='e.g. Favourites'
              label='1. Name your app group'
              placeholder='Enter your group name'
              onChange={(e: any) => {
                setAppGroupName(e.target.value);
              }}
            />

            {applications && (
              <AutocompleteField
                name='search-apps-to-add'
                placeholder='e.g. PMA'
                label='2. Search and add apps'
                options={appSearchOptions}
                role='search'
                onSelect={(selected: any) => {
                  handleSelectSearch(selected);
                }}
              />
            )}

            <div className='panel-body'>
              {previewSelectedApps.map(application => (
                <ApplicationDeleteRow
                  key={application.id}
                  application={application}
                  deleteHandler={() => {
                    handleRemoveSelection(application.id);
                  }}
                />
              ))}
            </div>
          </>
        )}
      </AddNewAppGroupContainer>
      {showFeedBack === '' && (
        <PanelActions>
          <Button
            onClick={() => {
              handleAddAppGroup();
            }}
            variant='filled'
            disabled={
              selectedApps.length === 0 || addingLoading || appGroupName === ''
            }
          >
            {addingLoading && <ProgressSpinner />}
            {addingLoading ? 'Adding' : 'Create app group'}
          </Button>
          <Button onClick={closeAppGroupHandler} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
