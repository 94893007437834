import styled from 'styled-components';
import { ThemeColors } from 'src/shared/styles/variables';

export const EditTagContainer = styled('div')`
display: flex;
flex-direction: column;
justify-content: space-between;
height: 100vh;
width: 448px;
  padding: 80px 24px 0;

  h4 {
    margin-bottom: 0;
    font-family: var(--ds-font-stack-display-2-font-family)!important;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    color: ${ThemeColors.black};
    margin-bottom: 28px;
  }

  .tab-heading {
    font-family: var(--ds-font-stack-display-2-font-family)!important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${ThemeColors.black};
    margin-bottom: 18px;
    margin-top: 24px;
  }
  }

  .feedback-container{

    position: relative;
    padding-top: 72px;

    .feedback-message{
      font-family: var(--ds-font-stack-display-2-font-family)!important;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 72px;
    }
  }
`;
