import { INotification } from 'src/interfaces/notification.interface';
import React, { FC, useEffect, useState } from 'react';
import {
  Switch,
  ButtonGroupWrapper,
  ButtonGroupSecondary,
  OutlinedButton,
  FilledButton,
  ProgressSpinner,
} from '@jsluna/react';
import { Tick } from '@jsluna/icons';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getSelectedAccount } from '../../../../../redux/selectors';
import { gql } from '@apollo/client';
import { RoleRequestWrapper } from './RoleREquest.styles';
import { IAccount } from 'src/interfaces/account.interface';
import { setShowFeedback } from 'src/redux/reducer';
import { NotificationType } from 'src/enums/notificationTypes.enum';

import {
  useTakeOverMutation,
  useUnassignNotificationMutation,
  useApproveRolesRequestMutation,
  useDeclineRolesRequestMutation,
  useRoleRequestNotification_RoleQuery,
} from 'src/operations/generated/graphql';

const TickIcon = Tick as unknown as React.FC<{ className?: string }>;

interface IRoleRequest {
  notification: INotification;
  accountDetails: IAccount;
  refetchNotification: () => void;
}
gql`
  fragment RoleRequestNotification_RoleInfo on RoleProjection {
    id
    name
    accountType {
      value {
        name
      }
    }
  }
`;

gql`
  query RoleRequestNotification_Role($id: String!) {
    role(id: $id) {
      ...RoleRequestNotification_RoleInfo
    }
  }
`;

export const RoleRequest: FC<IRoleRequest> = ({
  notification,
  accountDetails,
  refetchNotification,
}) => {
  const selectedAccount = useAppSelector(getSelectedAccount);
  const dispatch = useAppDispatch();

  if (!selectedAccount?.id) return <p>No Accounts selected.</p>;

  const [takenOver, setTakenOver] = useState(false);

  useEffect(() => {
    if (selectedAccount?.id)
      setTakenOver(selectedAccount?.id === notification.assignedTo?.id);
  }, [selectedAccount]);

  const { data: { role = null } = {} } = useRoleRequestNotification_RoleQuery({
    variables: { id: notification.sourceId },
  });

  const [takeOver, { loading: takeOverLoading }] = useTakeOverMutation();
  const [unAssign, { loading: unAssignLoading }] =
    useUnassignNotificationMutation();

  const toggleAssignment = async () => {
    let result = null;

    if (takenOver) {
      const { data } = await unAssign({
        variables: {
          entityId: notification.entityId,
        },
      });
      if (data?.unassignNotification) {
        result = true;
      }
    } else {
      const { data } = await takeOver({
        variables: {
          entityId: notification.entityId,
        },
      });
      if (data?.takeOver) {
        result = true;
      }
    }

    if (result) {
      refetchNotification();
      setTakenOver(!takenOver);
    }
  };

  const [approveRole, { loading: approveLoading }] =
    useApproveRolesRequestMutation();
  const [declineRole, { loading: declineLoading }] =
    useDeclineRolesRequestMutation();

  const approveRoleFunction = async () => {
    try {
      const approvedRoleId = await approveRole({
        variables: {
          input: {
            roleIds: [notification.sourceId],
            accountId: accountDetails.id,
          },
        },
      });

      if (approvedRoleId?.data?.approveRolesRequest?.id) {
        dispatch(
          setShowFeedback({
            message: 'Role approved successfully',
            type: NotificationType.SUCCESS,
            targetId: notification.sourceId,
          }),
        );
      }
    } catch (error) {
      dispatch(
        setShowFeedback({
          message: 'Could not approve role',
          type: NotificationType.FAILURE,
          targetId: notification.sourceId,
        }),
      );
    }
  };
  const RejectRoleFunction = async () => {
    try {
      const declinedRoleId = await declineRole({
        variables: {
          input: {
            roleIds: [notification.sourceId],
            accountId: accountDetails.id,
          },
        },
      });

      if (declinedRoleId?.data?.declineRolesRequest?.id) {
        dispatch(
          setShowFeedback({
            message: 'Role declined successfully',
            type: NotificationType.SUCCESS,
            targetId: notification.sourceId,
          }),
        );
      }
    } catch (error) {
      dispatch(
        setShowFeedback({
          message: 'Could not decline role',
          type: NotificationType.FAILURE,
          targetId: notification.sourceId,
        }),
      );
    }
  };

  const assignee = notification.assignedTo
    ? notification.assignedTo?.profile.value.firstName +
      ' ' +
      notification.assignedTo?.profile.value.lastName
    : takenOver
    ? selectedAccount?.profile?.firstName +
      ' ' +
      selectedAccount?.profile?.lastName
    : null;

  return (
    <RoleRequestWrapper>
      <div className='assign-to-me'>
        <div>
          {takeOverLoading || unAssignLoading ? (
            <>
              <div className='loading-container ln-u-display-flex ln-u-margin-ends'>
                <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
                Loading
              </div>
            </>
          ) : (
            <>
              {assignee && (
                <div className='assigned-info ln-u-margin-ends'>{assignee}</div>
              )}
            </>
          )}
          <Switch
            name={`assign-task-${notification.entityId}`}
            label={!takenOver ? `Assign task to me` : `Assigned to me`}
            outlined
            className='ln-u-margin-bottom*3'
            disabled={takeOverLoading || unAssignLoading}
            onClick={toggleAssignment}
            checked={takenOver}
          />
        </div>
      </div>

      <div className='account-type-row'>
        <div className='label'>Account type:</div>
        <div className='value'>{role?.accountType?.value.name}</div>
      </div>

      <div className='requested-roles ln-u-margin-bottom'>
        <TickIcon className='green-circle' /> Role:<span>{role?.name}</span>
      </div>

      <div className='label-1 ln-u-margin-top*3'>
        Do you approve or reject the "{role?.name}" role request?
      </div>

      <ButtonGroupWrapper>
        <ButtonGroupSecondary>
          <FilledButton
            className='ln-u-margin-right'
            onClick={approveRoleFunction}
            disabled={approveLoading}
          >
            {approveLoading ? (
              <div className='loader-content white'>
                <ProgressSpinner /> Loading
              </div>
            ) : (
              <>Approve</>
            )}
          </FilledButton>
          <OutlinedButton
            onClick={RejectRoleFunction}
            disabled={declineLoading}
          >
            {declineLoading ? (
              <div className='loader-content'>
                <ProgressSpinner /> Loading
              </div>
            ) : (
              <>Reject</>
            )}
          </OutlinedButton>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </RoleRequestWrapper>
  );
};
