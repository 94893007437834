import styled from 'styled-components';
import { ThemeColors } from 'src/shared/styles/variables';
import { Card } from '@jsluna/react';

export const RoleSectionWrapper = styled('div')`
  padding-bottom: 48px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${ThemeColors.sectionSeparator};
  }
  &:not(:first-of-type) {
    padding-top: 48px;
  }

  .action-panel {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 12px 8px;
      line-height: 27px;
    }
  }
`;

export const PermissionAppTile = styled('div')`
  position: relative;
  padding-bottom: 48px;
  margin-bottom: 24px;
  .manage-permission {
    position: absolute;
    right: 34px;
  }
`;
export const PopOverCard = styled((props: any) => <Card {...props} />)`
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  padding: 16px;

  h5 {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 27px;
    margin-bottom: 8px;
    cursor: pointer;
    &:hover {
      color: var(--ds-modifier-button-color-base);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;
