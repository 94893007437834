import { reducerType } from '@redux/types';
import { IApplicationsState } from '../context/applications.context';
import { actions } from './applications.actions';

export const initialState: (
  initialData: IApplicationsState,
) => IApplicationsState = (initialData: IApplicationsState) => ({
  mappedApplications: initialData?.mappedApplications || [],
  filteredApplications: initialData?.filteredApplications || [],
  showAllApps: initialData?.showAllApps || false,
  showRequestPermissionsModal:
    initialData?.showRequestPermissionsModal || false,
  showAssignGroupModal: initialData?.showRequestPermissionsModal || false,
  tagsArray: initialData?.tagsArray || new Set(),
  selectedApplication: initialData?.selectedApplication || null,
  selectedApplicationGroup: initialData?.selectedApplicationGroup || null,
  refetchMyApps: initialData?.refetchMyApps || false,
  showAddNewAppGroup: initialData?.showAddNewAppGroup || false,
});

export const reducer: reducerType<IApplicationsState> = (state, action) => {
  switch (action.type) {
    case actions.SET_INITIAL_DATA:
      return {
        ...state,
        mappedApplications: action.payload,
        filteredApplications: action.payload,
      };
    case actions.SET_MAPPED_APPLICATIONS:
      return {
        ...state,
        mappedApplications: action.payload,
      };
    case actions.SET_FILTERED_APPLICATIONS:
      return {
        ...state,
        filteredApplications: action.payload,
      };
    case actions.SET_SHOW_ALL_APPS:
      return {
        ...state,
        showAllApps: action.payload,
      };
    case actions.SHOW_ASSIGN_GROUP_MODAL:
      return {
        ...state,
        showAssignGroupModal: true,
      };
    case actions.OPEN_REQUEST_PERMISSION_MODAL:
      return {
        ...state,
        showRequestPermissionsModal: true,
        selectedApplication: action.payload,
      };
    case actions.OPEN_ASSIGN_GROUP_MODAL:
      return {
        ...state,
        showAssignGroupModal: true,
        selectedApplication: action.payload,
      };
    case actions.HIDE_ASSIGN_GROUP_MODAL:
      return {
        ...state,
        showAssignGroupModal: false,
      };
    case actions.SHOW_REQUEST_PERMISSION_MODAL:
      return {
        ...state,
        showRequestPermissionsModal: true,
      };
    case actions.HIDE_REQUEST_PERMISSION_MODAL:
      return {
        ...state,
        showRequestPermissionsModal: false,
      };
    case actions.SET_SELECTED_APPLICATIONS:
      return {
        ...state,
        selectedApplication: action.payload,
      };
    case actions.SET_TAGS:
      return {
        ...state,
        tagsArray: action.payload,
      };
    case actions.OPEN_EDIT_APP_GROUP_PANEL:
      return {
        ...state,
        showAssignGroupModal: true,
        selectedApplicationGroup: action.payload,
      };
    case actions.TOGGLE_REFETCH_MY_APPS_GROUP:
      return {
        ...state,
        refetchMyApps: !state.refetchMyApps,
      };
    case actions.SHOW_ADD_NEW_APP_GROUP:
      return {
        ...state,
        showAddNewAppGroup: true,
      };
    case actions.HIDE_ADD_NEW_APP_GROUP:
      return {
        ...state,
        showAddNewAppGroup: false,
      };

    default:
      return state;
  }
};
