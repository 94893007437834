import React, { FC } from 'react';
import { Card, TextInputField, GridWrapper, GridItem } from '@jsluna/react';

interface IProfileCardDetails {
  firstName: string;
  lastName: string;
  emailAddress: string;
}

export const ProfileDetailCard: FC<IProfileCardDetails> = ({
  firstName,
  lastName,
  emailAddress,
}) => {
  return (
    <Card>
      <h4>Profile Details</h4>
      <GridWrapper>
        <GridItem size={{ md: '1/3' }}>
          <TextInputField disabled label='First name' value={`${firstName}`} />
        </GridItem>
      </GridWrapper>
      <GridWrapper>
        <GridItem size={{ md: '1/3' }}>
          <TextInputField disabled label='Last name' value={`${lastName}`} />
        </GridItem>
      </GridWrapper>
      <GridWrapper>
        <GridItem size={{ md: '1/3' }}>
          <TextInputField disabled label='Email' value={`${emailAddress}`} />
        </GridItem>
      </GridWrapper>
    </Card>
  );
};
