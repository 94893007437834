import { routes } from 'src/constants/routes';
import { FC } from 'react';
import { wowView } from 'src/utils/getWowView';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BreadcrumbsItem, BreadcrumbsWrapper } from '@jsluna/react';

interface INavigation {
  section: any;
  showContent: boolean;
  wrapperStyle: string;
  activeIndex: number;
}

export const Navigation: FC<INavigation> = ({
  section,
  showContent,
  wrapperStyle,
  activeIndex,
}) => {
  const navigate = useNavigate();
  const links = [
    { title: 'Home', href: routes.root, active: '' },
    { title: 'Handbook', href: routes.waysOfWorkingUrl, active: '' },
  ];

  const backToNavigationView = () => {
    const url = wowView.getUrl({ section: null });
    navigate(url);
  };

  const breadcrumbs = links.map((link, index) => (
    <BreadcrumbsItem
      active={index === activeIndex || link.active}
      key={link.title}
    >
      <Link to={link.href}>{link.title}</Link>
    </BreadcrumbsItem>
  ));

  return (
    <>
      {!showContent && (
        <div
          className={`ln-o-container ln-o-container--lg ln-o-container--soft ln-o-clearfix ln-u-hard-sides`}
        >
          <div
            className={`${
              wrapperStyle
                ? wrapperStyle
                : 'ln-u-soft-top-lg ln-u-padding-left*3'
            }`}
          >
            <BreadcrumbsWrapper narrow>{breadcrumbs}</BreadcrumbsWrapper>
          </div>
        </div>
      )}
      {section && (
        <div className='ln-u-soft-top-lg navigation__back'>
          <span
            onClick={backToNavigationView}
            className='cursor--pointer ln-c-link'
          >
            Back
          </span>
        </div>
      )}
    </>
  );
};
