import styled from 'styled-components';
import { Container as LunaContainer } from '@jsluna/react';
const Container = styled((props: any) => (
  <LunaContainer size='md' {...props} />
)).attrs({
  className: 'ln-u-push-top-md',
})`
  width: 100%;
  padding: 20px;
`;

export default Container;
