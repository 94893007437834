import React, { FC, useState } from 'react';
import { Button, GridWrapper, GridItem } from '@jsluna/react';
import { LinkApplicationScreen } from '../../../screens/linkApplications';
import { Cancel } from '@jsluna/icons';
import { Drawer, Popover } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';
import {
  AppTileWithPermission,
  LinkAppContainer,
  PopOverCard,
} from './linkAppWithPermission.styles';
import {
  PermissionSelection,
  SelectorType,
} from 'src/screens/permissionSelector';
import { IPermissionIdsByAppId } from 'src/pages/createRole';
import { LinkAppsSection_LinkAppInfoFragment } from '../../../operations/generated/graphql';

const CancelIcon = Cancel as unknown as React.FC;

interface ILinkAppsSection {
  loading: boolean;
  error: ApolloError | undefined;
  linkedApps: LinkAppsSection_LinkAppInfoFragment[];
  target: { id: string; displayName: string };
  linkedAppOptions: LinkAppsSection_LinkAppInfoFragment[];
  handleConfirmation: (
    LinkApps: LinkAppsSection_LinkAppInfoFragment[],
  ) => Promise<void>;
  handleRemoval: (appId: string) => void;
  existingPermissions: IPermissionIdsByAppId;
  existingPermissionGroups: IPermissionIdsByAppId;
  handlePermissionOrPermissionGroupUpdate: (
    selected: IPermissionIdsByAppId,
    type: SelectorType,
  ) => void;
}

enum PanelAction {
  LINK_APP_SELECTOR = 'LINK_APP_SELECTOR',
  APP_PERMISSION_SELECTOR = 'APP_PERMISSION_SELECTOR',
  APP_PERMISSION_GROUP_SELECTOR = 'APP_PERMISSION_GROUP_SELECTOR',
}

export const LinkAppsWithPermissionsSection: FC<ILinkAppsSection> = ({
  linkedApps,
  target,
  linkedAppOptions,
  handleConfirmation,
  handleRemoval,
  loading,
  error,
  existingPermissions,
  existingPermissionGroups,
  handlePermissionOrPermissionGroupUpdate,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [selectedApplication, setSelectedApplication] =
    useState<LinkAppsSection_LinkAppInfoFragment | null>();

  const [selectedPermissions, setSelectedPermissions] =
    useState<IPermissionIdsByAppId>(existingPermissions);
  const [selectedPermissionGroups, setSelectedPermissionGroups] =
    useState<IPermissionIdsByAppId>(existingPermissionGroups);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };
  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.LINK_APP_SELECTOR:
        return (
          <LinkApplicationScreen
            target={target}
            loading={loading}
            error={error}
            handleClose={handlePanelClose}
            existingSelectedAppIds={linkedApps.map(app => app.id)}
            fullApplicationOptions={linkedAppOptions}
            handleConfirmation={handleConfirmation}
          />
        );

      case PanelAction.APP_PERMISSION_SELECTOR:
        if (selectedApplication) {
          return (
            <PermissionSelection
              application={selectedApplication}
              handleClose={handlePanelClose}
              existingPermissionsOrGroups={
                selectedPermissions[selectedApplication.id] || []
              }
              loading={false}
              error={error}
              handleConfirmation={(selectedTempPermissions: string[]) => {
                handleConfirmationPermissions(
                  selectedTempPermissions,
                  SelectorType.PERMISSION,
                );
              }}
              target={target}
              type={SelectorType.PERMISSION}
            />
          );
        }
        break;

      case PanelAction.APP_PERMISSION_GROUP_SELECTOR:
        if (selectedApplication) {
          return (
            <PermissionSelection
              application={selectedApplication}
              handleClose={handlePanelClose}
              existingPermissionsOrGroups={
                selectedPermissionGroups[selectedApplication.id] || []
              }
              error={error}
              loading={false}
              handleConfirmation={(selectedTempPermissions: string[]) => {
                handleConfirmationPermissions(
                  selectedTempPermissions,
                  SelectorType.PERMISSION_GROUP,
                );
              }}
              target={target}
              type={SelectorType.PERMISSION_GROUP}
            />
          );
        }
        break;
    }
  };

  const handleConfirmationPermissions = (
    selectedTempPermissions: string[],
    type: SelectorType,
  ) => {
    if (selectedApplication) {
      if (type === SelectorType.PERMISSION) {
        setSelectedPermissions({
          ...selectedPermissions,
          [selectedApplication.id]: selectedTempPermissions,
        });
        handlePermissionOrPermissionGroupUpdate(
          {
            ...selectedPermissions,
            [selectedApplication.id]: selectedTempPermissions,
          },
          type,
        );
      } else {
        setSelectedPermissionGroups({
          ...selectedPermissionGroups,
          [selectedApplication.id]: selectedTempPermissions,
        });
        handlePermissionOrPermissionGroupUpdate(
          {
            ...selectedPermissionGroups,
            [selectedApplication.id]: selectedTempPermissions,
          },
          type,
        );
      }
    }
  };

  const popOverContent = (selectedApp: LinkAppsSection_LinkAppInfoFragment) => {
    return (
      <>
        <PopOverCard>
          <h5
            onClick={() => {
              handleClose();
              setShowSidePanel(PanelAction.APP_PERMISSION_SELECTOR);
            }}
          >
            Manage permissions
          </h5>
          <h5
            onClick={() => {
              handleClose();
              setShowSidePanel(PanelAction.APP_PERMISSION_GROUP_SELECTOR);
            }}
          >
            Manage permission groups
          </h5>
        </PopOverCard>
      </>
    );
  };

  const renderAppTile = (linkedApp: LinkAppsSection_LinkAppInfoFragment) => {
    return (
      <>
        <AppTileWithPermission>
          <AppTile
            application={linkedApp}
            ChevronSection={() => (
              <Button
                variant='text'
                onClick={() => {
                  handleRemoval(linkedApp.id);
                }}
                color='dark'
              >
                <CancelIcon />
              </Button>
            )}
            ActionButtonSection={() => <></>}
            DetailsSection={() => <></>}
          />
          <div className='manage-permission'>
            <Button
              aria-describedby={id}
              variant='text'
              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                setSelectedApplication(linkedApp);
                handleClick(event);
              }}
              color='dark'
              className='btn-manage-permission'
            >
              Manage permissions
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={() => {
                setSelectedApplication(null);
                handleClose();
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              {popOverContent(linkedApp)}
            </Popover>
          </div>
        </AppTileWithPermission>
      </>
    );
  };

  return (
    <LinkAppContainer>
      <h4>Which apps should this role have access to?</h4>

      <div className='body-1 ln-u-margin-bottom*3'>
        Add applications and manage their permissions and permission groups. A
        permission group is a list of predefined permissions associated with an
        app.
      </div>
      <Button
        element='button'
        variant='filled'
        onClick={() => setShowSidePanel(PanelAction.LINK_APP_SELECTOR)}
      >
        Add apps
      </Button>

      {linkedApps.length > 0 && (
        <>
          <h4 className='display-1 assigned-apps-h4'>Assigned apps</h4>
          <div className='ln-u-padding-top'>
            <GridWrapper>
              {linkedApps.map(linkedApp => (
                <GridItem size={{ md: '1/3' }} key={linkedApp.id}>
                  {renderAppTile(linkedApp)}
                </GridItem>
              ))}
            </GridWrapper>
          </div>
        </>
      )}
      <Drawer
        open={showSidePanel !== null}
        onClose={handlePanelClose}
        anchor='right'
        className='add-application'
      >
        {renderPanel()}
      </Drawer>
    </LinkAppContainer>
  );
};
