import styled from 'styled-components';

export const TermsContainer = styled('div')`
  .terms__olist {
    counter-reset: item;
    display: table;
  }

  .terms__olist__item {
    display: table-row;
  }

  .terms__olist__item:before {
    content: counter(item) '. ';
    counter-increment: item;
    display: table-cell;
    font-weight: 700;
    padding-right: 1rem;
    width: 2rem;
  }

  .terms__olist__item--heading {
    font-size: 1.25rem;
    display: block;
    font-weight: 700;
    left: -8px;
    padding: 1rem 0;
    position: relative;
  }

  @media (min-width: 720px) {
    .terms__olist__item--heading {
      font-size: 1.375rem;
    }
  }

  .terms__sub-olist--alpha {
    counter-reset: alphaitem;
    display: table-row;
    list-style-type: lower-alpha;
    margin: 0;
  }

  .terms__sub-olist--alpha__item {
    display: table-row;
  }

  .terms__sub-olist--alpha__item:before {
    content: counter(alphaitem, lower-alpha) '.';
    counter-increment: alphaitem;
    display: table-cell;
    padding-right: 1rem;
  }

  .terms__sub-olist {
    counter-reset: subitem;
    left: -3rem;
    margin: 0;
    position: relative;
  }

  .terms__sub-olist__item {
    display: table-row;
  }

  .terms__sub-olist__item:before {
    content: counter(item) '.' counters(subitem, '.') ' ';
    counter-increment: subitem;
    display: table-cell;
    padding-right: 1rem;
    width: 2rem;
  }

  .terms__sub-ulist {
    list-style: disc;
    margin: 0;
  }

  .terms__sub-ulist__item {
    display: table-row;
  }

  .terms__sub-ulist__item:before {
    font-size: 1.25rem;
    content: 'â€¢';
    display: table-cell;
    padding-right: 1rem;
  }
`;
