import React, { FC, useEffect, useState } from 'react';
import { UpdateAccountContainer } from './updateAccountScreen.styles';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { TextInputField } from '@jsluna/react';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError, gql } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { UpdateAccountDetailsScreen_AccountInfoFragment } from 'src/operations/generated/graphql';

interface IUpdateDetails {
  name?: string | null;
  jobTitle?: string | null;
}
interface IUpdateAccountDetailsScreen {
  handleClose: () => void;
  handleConfirmation: (status: any) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  heading: string;
  account: UpdateAccountDetailsScreen_AccountInfoFragment;
  details: IUpdateDetails;
}

gql`
  fragment UpdateAccountDetailsScreen_AccountInfo on AccountProjection {
    id
    name
    jobTitle
  }
`;

export const UpdateAccountDetailsScreen: FC<IUpdateAccountDetailsScreen> = ({
  handleConfirmation,
  handleClose,
  error,
  heading,
  loading,
  account,
  details,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const updateAccountDetailsSchema = Yup.object().shape(
    {
      accountName: Yup.string().when('accountName', (value, schema) => {
        if (value?.length > 0) {
          return Yup.string()
            .min(2, `${InviteProfileMessages.name.min} 2`)
            .max(35, `${InviteProfileMessages.name.max} 35`);
        } else {
          return Yup.string().notRequired();
        }
      }),
      accountJobTitle: Yup.string().when('accountJobTitle', (value, schema) => {
        if (value?.length > 0) {
          return Yup.string()
            .min(2, `${InviteProfileMessages.accountJobTitle.min} 2`)
            .max(35, `${InviteProfileMessages.accountJobTitle.max} 35`);
        } else {
          return Yup.string().notRequired();
        }
      }),
    },
    [
      ['accountJobTitle', 'accountJobTitle'],
      ['accountName', 'accountName'],
    ],
  );
  const accountNameFormObj = useForm({
    resolver: yupResolver(updateAccountDetailsSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
  } = accountNameFormObj;

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setFeedBackMessage(null);
  };

  const handleFormSubmit = (values: any) => {
    handleConfirmation(values);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <UpdateAccountContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${account.name}'s details has been updated.`
                  : `Failed to update the details for ${account.name}.`
              }
              saveButtonText={'Save and close'}
              onCloseHandler={handleClose}
              resetButtonText={'Further changes'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `Account details successfully updated`
                  : 'Failed to update account details'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              <h3>{heading}</h3>
            </div>
            <div className='panel-body'>
              <form
                id='update-account-details'
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <Controller
                  name='accountName'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      label='Account name'
                      placeholder={details.name}
                      error={errors?.name?.message}
                    />
                  )}
                />
                <Controller
                  name='accountJobTitle'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      label='Job title'
                      placeholder={details.jobTitle}
                      error={errors?.jobTitle?.message}
                    />
                  )}
                />
              </form>
            </div>
          </>
        )}
      </UpdateAccountContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            type='submit'
            variant='filled'
            disabled={!isValid}
            form='update-account-details'
          >
            Update account details
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
