export enum APP_ACCESS {
  CREATE_ACCOUNT_TYPE = 'PER_system_create-account-type',
  CREATE_APPLICATION = 'PER_system_create-application',
  TAG_MANAGEMENT = 'PER_system_tag-management',
  UPDATE_USER_DETAILS = 'PER_system_update-user-details',
  PROFILE_INVITATION_MANAGEMENT = 'PER_system_profile-invitation-management',
  REMOVE_PROFILE = 'PER_system_remove-profile-access',
  VIEW_USER_DETAILS = 'PER_system_view-user-details',
  DOWNLOAD_REGISTRATIONS_REPORT = 'PER_system_download-registration-report',
  DOWNLOAD_RAINBOW_REPORT = 'PER_system_download-rainbow-extract',
  SERVICE_DASHBOARD = 'PER_system_access-service-dashboard',
  GLOBAL_APPLICATION_ADMIN = 'PER_system_global-application-admin',
  GLOBAL_APPLICATION_APPROVER = 'PER_system_global-application-approver',
  GLOBAL_ACCOUNT_TYPE_ADMIN = 'PER_system_global-account-type-admin',
  GLOBAL_ACCOUNT_TYPE_APPROVER = 'PER_system_global-account-type-approver',
}
