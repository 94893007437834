import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_SUPPLIER_NEWS_QUERY = gql`
  query ($page: Int!, $size: Int!) {
    news(page: $page, size: $size)
      @rest(
        type: "news"
        path: "content-projection/contentStack/supplierNews?pageSize=:size&page=:page"
      ) {
      list
      total_results
    }
  }
`;

export const useGetSupplierNews = (
  page: number = 1,
  size: number = 10,
  skip: boolean = false,
) => {
  return useQuery(GET_SUPPLIER_NEWS_QUERY, {
    variables: { page, size },
    context: { clientName: EndPoint.PPS_REST_ENDPOINT },
    skip: skip,
  });
};
