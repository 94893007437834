import React, { FC } from 'react';
import { StatusDiv } from './supplierCodeStatus.styles';
import { Tick, Minus } from '@jsluna/icons';

interface IAccountDetailStatus {
  label: string;
  status: 'AVAILABLE' | 'UNAVAILABLE';
}

const TickIcon = Tick as unknown as React.FC;
const MinusIcon = Minus as unknown as React.FC;

export const SupplierCodeStatus: FC<IAccountDetailStatus> = ({
  label,
  status,
}) => {
  return (
    <StatusDiv>
      <div className='chb-display'>
        {status === 'UNAVAILABLE' ? <MinusIcon /> : <TickIcon />}
      </div>
      <div className='label'>{label}</div>
    </StatusDiv>
  );
};
