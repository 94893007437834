import styled from 'styled-components';

export const FeedbackCardWrapper = styled('div')`
  .feedback-title {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: bold;
    font-size: 50px;
    line-height: 62px;
    max-width: 800px;
  }

  h3 {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    margin-top: 48px;
  }

  .feedback-message {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    margin-bottom: 48px;
    max-width: 800px;
  }
`;
