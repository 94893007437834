import styled from 'styled-components';

export const MovedPageWrapper = styled('div')`
  display: flex;
  align-items: center;
  height: calc(100vh - 48px);
  width: 100%;


  .img-container {
    text-align: center;
    padding-right: 0;

    @media (max-width: 720px) {
      text-align: right;
      padding-right: 40px;
       
    
      }
    .img-error {
      object-fit: contain;
      height: 50vh;
      width: auto; 
    }
  }

  h2{
    text-align: left;
    margin-top: 30px;

    max-width: 80%;
    margin: 0;

    @media (max-width: 720px) {
      text-align: right;
      margin: 0 auto;
      max-width: 400px;
    }
  }


 

  p{
    max-width: 70%;
    font-size: 18px;

    @media (max-width: 720px) {
        text-align: center;
        max-width: 80%;
        margin: 0 auto;
      }


     
`;
