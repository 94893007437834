import React, { FC, useEffect, useState } from 'react';
import { AddAccountByProfile } from './addAccountByProfile.styles';
import { Button, AsyncAutocompleteField } from '@jsluna/react';
import { ApolloError, gql } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ProfileAccountSelector } from 'src/shared/components/profileAccountSelector';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

import {
  AccountSelectorSection_AccountsFragment,
  AddAccountByProfileScreen_ProfilesFragment,
  useAddAccountByProfileScreen_ProfileSearchLazyQuery,
} from 'src/operations/generated/graphql';

gql`
  fragment AddAccountByProfileScreen_Profiles on ProfileProjection {
    id
    firstName
    lastName
    emailAddress
    accounts {
      value {
        id
        name
      }
    }
  }
`;

gql`
  query AddAccountByProfileScreen_ProfileSearch(
    $searchTerms: String
    $page: Float
    $pageSize: Float
  ) {
    paginatedProfiles(
      searchTerms: $searchTerms
      page: $page
      pageSize: $pageSize
    ) {
      page
      count
      perPage
      profiles {
        ...AddAccountByProfileScreen_Profiles
      }
    }
  }
`;

interface IAddAccountByProfileScreen {
  handleClose: () => void;
  existingSelectedAccounts: AccountSelectorSection_AccountsFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (
    accounts: AccountSelectorSection_AccountsFragment[],
  ) => Promise<void>;
  target: { id: string; displayName: string; type?: string; origin: string };
}

export const AddAccountByProfileScreen: FC<IAddAccountByProfileScreen> = ({
  handleClose,
  existingSelectedAccounts,
  loading,
  error,
  handleConfirmation,
  target,
}) => {
  const [searchProfiles] =
    useAddAccountByProfileScreen_ProfileSearchLazyQuery();

  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [selectedProfiles, setSelectedProfiles] = useState<
    AddAccountByProfileScreen_ProfilesFragment[]
  >([]);

  const [newSelectedAccounts, setNewSelectedAccounts] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);

  const filterProfileOptions = (
    fullProfileOptions: AddAccountByProfileScreen_ProfilesFragment[],
    selectedProfiles: AddAccountByProfileScreen_ProfilesFragment[],
  ) => {
    const selectedProfileIds = selectedProfiles.map(profile => profile.id);
    const filteredProfileOptions = fullProfileOptions.reduce((acc, profile) => {
      if (selectedProfileIds.includes(profile.id) || !profile?.accounts?.length)
        return acc;
      return [
        ...acc,
        {
          ...profile,
          label: `${profile.firstName} ${profile.lastName}`,
          value: profile.id,
        },
      ];
    }, [] as (AddAccountByProfileScreen_ProfilesFragment & { label: string; value: string })[]);
    return filteredProfileOptions;
  };

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setFeedBackMessage(null);
    setNewSelectedAccounts([]);
  };

  const onCloseHandler = () => {
    handleClose();
    setNewSelectedAccounts([]);
  };

  const onProfileAdded = (
    selected: AddAccountByProfileScreen_ProfilesFragment,
  ) => {
    if (selected) {
      setSelectedProfiles([...selectedProfiles, selected]);
    }
  };

  const onProfileRemoved = (id: string) => {
    function partition<T>(array: T[], isValid: (profile: T) => {}) {
      return array.reduce<[T[], T[]]>(
        ([pass, fail], elem) => {
          return isValid(elem)
            ? [[...pass, elem], fail]
            : [pass, [...fail, elem]];
        },
        [[], []],
      );
    }

    const [filteredProfiles, removedProfiles] =
      partition<AddAccountByProfileScreen_ProfilesFragment>(
        selectedProfiles,
        profile => profile.id !== id,
      );

    if (removedProfiles.length) {
      const accountIdsToRemove = removedProfiles[0].accounts.map(
        account => account.value.id,
      );
      const filteredAccountSelection = newSelectedAccounts.filter(
        item => !accountIdsToRemove.includes(item.id),
      );
      setNewSelectedAccounts(filteredAccountSelection);
    }

    setSelectedProfiles(filteredProfiles);
  };

  const handleAccountChecked = (
    account: AccountSelectorSection_AccountsFragment,
    checked: boolean,
  ) => {
    if (checked) {
      const updatedAccounts = [...newSelectedAccounts, account];
      setNewSelectedAccounts(updatedAccounts);
    } else {
      const updatedAccounts = newSelectedAccounts.filter(
        item => item.id !== account.id,
      );
      setNewSelectedAccounts(updatedAccounts);
    }
  };

  const searchHandler = async (searchKey: string) => {
    const {
      data: {
        paginatedProfiles: { profiles: profileResults = [], count = 1 } = {},
      } = {},
    } = await searchProfiles({
      variables: {
        searchTerms: searchKey,
        pageSize: 25,
        page: 1,
      },
    });

    return filterProfileOptions(profileResults, selectedProfiles);
  };

  return (
    <>
      <AddAccountByProfile>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={false}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${
                      target.type === 'Admin' || target.type === 'Approver'
                        ? `${
                            target.type === 'Admin'
                              ? 'Administrator'
                              : 'Approver'
                          } successfully added`
                        : `A ${target.type} has been added to ${target.displayName}.`
                    }`
                  : `Unable to add ${target.type}. Please try again.`
              }
              saveButtonText={'Close'}
              onCloseHandler={onCloseHandler}
              resetButtonText={`Add another ${target.type}`}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${
                      target.origin === 'account type'
                        ? `Account type updated`
                        : `${target.displayName} details updated`
                    } `
                  : `Failed to added ${target.type}`
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              {target.origin === 'account type'
                ? `Add ${target.type}s to ${target.origin}: ${target.displayName}`
                : `Add ${target.type} to ${target.origin}`}
            </div>
            {target.origin === 'account type'
              ? `${
                  target.type === 'Admin' &&
                  'Admins for this account type can; amend/delete it, add/remove email domains, administrators and approvers.'
                }`
              : `An account given access to ${target.displayName} can see data
                related to ${target.displayName} based on job role.
            ${
              target.type === 'Admin' &&
              'Application administrators (app admins) view, manage and create user permissions.'
            }`}
            {target.type === 'Approver' &&
              'Application approvers can either approve or reject access requests.'}

            <AsyncAutocompleteField
              name='search-profiles-to-add'
              label='Search users'
              info='e.g. John Smith'
              loadOptions={searchHandler}
              role='search'
              onSelect={onProfileAdded}
              debounceWait={500}
            />

            {selectedProfiles.length > 0 && (
              <>
                <h4>
                  Select which user accounts require{' '}
                  {target.id.includes('AT_')
                    ? `${
                        target.type === 'Admin' ? 'admin' : 'approver'
                      } permissions`
                    : `access to ${target.displayName}`}
                </h4>
                {selectedProfiles?.map(profile => (
                  <ProfileAccountSelector
                    key={profile.id}
                    profile={profile}
                    deleteHandler={onProfileRemoved}
                    onAccountSelected={handleAccountChecked}
                    selectedAccounts={newSelectedAccounts.map(
                      account => account.id,
                    )}
                    existingAccountIds={existingSelectedAccounts.map(
                      account => account.id,
                    )}
                  />
                ))}
              </>
            )}
          </>
        )}
      </AddAccountByProfile>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            variant='filled'
            disabled={loading || newSelectedAccounts?.length === 0}
            onClick={() => {
              handleConfirmation(newSelectedAccounts);
              setFeedBackMessage(NotificationType.SUCCESS);
            }}
          >
            Add users
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
