import { PropsWithChildren } from 'react';
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  from,
  ApolloLink,
  Operation,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { useAppSelector } from 'src/redux/hooks';
import { getSelectedAccount } from 'src/redux/selectors';

import { RestLink } from 'apollo-link-rest';
import { EndPoint } from 'src/enums/endPoint.enum';
import { useCookies } from 'react-cookie';
import { CookieNames } from 'src/enums/cookieNames';
import { destructureCookie } from 'src/utils/destructureCookie';
import { envConfiguration } from 'src/utils/loadEnvConfig';

// const PPS_GATEWAY_URL = process.env.REACT_APP_PPS_GATEWAY ?? ``;

const PPS_GATEWAY_URL = '/graphql';

const PPS_REST_URL = '/';

const ApolloClientProvider = ({ children, token }: PropsWithChildren<any>) => {
  const httpLink = createHttpLink({
    uri: PPS_GATEWAY_URL,
    credentials: 'same-origin',
  });

  const restLink = new RestLink({
    uri: envConfiguration.twShortEndpoint,
    credentials: 'same-origin',
  });

  const ppsRestLink = new RestLink({
    uri: PPS_REST_URL,
    credentials: 'same-origin',
  });

  const TW_IMG_Link = new RestLink({
    uri: envConfiguration.twImagesEndpoint,
    credentials: 'same-origin',
    typePatcher: {
      healthCheckFields: (data, outerType, patchDeeper) => {
        if (data != null) {
          data.items = Object.keys(data).map(field => ({
            __typename: 'healthCheck',
            ...data[field],
          }));
        }
        return data;
      },
    },
  });

  // const client = new ApolloClient({
  //   link: ApolloLink.split(
  //     operation => operation.getContext().clientName === undefined,
  //     from([httpLink]),
  //     ApolloLink.split(
  //       operation => operation.getContext().clientName === EndPoint.TW_ENDPOINT,
  //       from([restLink]),
  //       ApolloLink.split(
  //         operation =>
  //           operation.getContext().clientName ===
  //           EndPoint.TW_AUTHENTICATED_ENDPOINT,
  //         from([restLink]),
  //         from([TW_IMG_Link]),
  //       ),
  //     ),
  //   ),
  //   cache: new InMemoryCache(),
  // });

  const getLinkBasedOnContext = (context: any) => {
    switch (context) {
      case EndPoint.TW_ENDPOINT:
        return restLink;
      case EndPoint.TW_AUTHENTICATED_ENDPOINT:
        return restLink;
      case EndPoint.PPS_REST_ENDPOINT:
        return ppsRestLink;
      default:
        return httpLink;
    }
  };

  const client = new ApolloClient({
    link: ApolloLink.from([
      (operation, forward) => {
        const clientName = operation.getContext().clientName;
        const link = getLinkBasedOnContext(clientName);
        return link ? link.request(operation, forward) : null;
      },
      from([TW_IMG_Link]),
    ]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloClientProvider;
