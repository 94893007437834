import React, { FC, useEffect, useState } from 'react';
import { EditApplicationsContainer } from './viewApplicationEditScreen.styles';
import { RadioButtonField } from '@jsluna/react';
import { IApplicationDetails } from 'src/interfaces/application.interface';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

interface IHeading {
  heading: string;
  subHeading: string;
  feedBackStatus: string;
}

interface IEditApplicationRadioScreen {
  handleClose: () => void;
  handleConfirmation: (status: boolean) => void;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  radioHeading: IHeading;
  defaultValue: boolean;
}

export const EditApplicationRadioScreen: FC<IEditApplicationRadioScreen> = ({
  handleConfirmation,
  handleClose,
  error,
  radioHeading,
  target,
  defaultValue,
}) => {
  const [selectedValue, setSelectedValue] = useState<boolean | null>(null);
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);
  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setSelectedValue(null);
    setFeedBackMessage(null);
  };

  return (
    <>
      <EditApplicationsContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={false}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `The ${radioHeading.feedBackStatus} status has been updated for this app`
                  : `Failed to update the ${radioHeading.feedBackStatus} for this app.`
              }
              saveButtonText={'Save and close'}
              onCloseHandler={handleClose}
              resetButtonText={'Further changes'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `Application successfully updated`
                  : 'Failed to update application'
              }
            />
          </>
        ) : (
          <>
            <div className='tab-heading'>
              <h3>{radioHeading.heading}</h3>
            </div>
            <div className='panel-body'>
              <RadioButtonField
                label={radioHeading.subHeading}
                name={`${radioHeading.feedBackStatus}-radio-button`}
                fullWidth
                options={[
                  {
                    value: 'true',
                    label: 'Yes',
                    defaultChecked: defaultValue === true ? true : false,
                  },
                  {
                    value: 'false',
                    label: 'No',
                    defaultChecked: defaultValue === false ? true : false,
                  },
                ]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedValue(e.target.value === 'true' ? true : false);
                }}
              />
            </div>
          </>
        )}
      </EditApplicationsContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={() => {
              if (selectedValue !== null) {
                handleConfirmation(selectedValue);
                setFeedBackMessage(NotificationType.SUCCESS);
              }
            }}
            variant='filled'
            disabled={selectedValue === null}
          >
            Set status
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
