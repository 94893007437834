type quickLinkColorType = {};

interface IQuickLinkColor {
  [id: string]: string;
}

interface IInfoPanelColor {
  [id: string]: {
    panelColor: string;
    textColor: string;
  };
}

export const ThemeColors = {
  primary: '#F06C00',
  white: '#FFFFFF',
  black: '#000000',
  monoChromeBlack: '#0C0C0C',
  monochromeZero: '#FFFFFF',
  monochromeDark: '#404040',
  monochromeDarker: '#262626',
  monochromeLighter: '#404040',
  descriptionText: '#4C4C4C',
  linkColor: '#1669BA',
  sectionSeparator: '#D7D7D7',
  chbBackground: '#D8D8D8',
  primaryFocus: '#1976D2',
  red: '#de350b',
};

export const InfoPanel: IInfoPanelColor = {
  information: {
    panelColor: '#114E8B',
    textColor: '#FFFFFF',
  },
  warning: {
    panelColor: '#114E8B',
    textColor: '#FFFFFF',
  },
};

export const QuickLinkColors: IQuickLinkColor = {
  blue: '#1976d3',
  orange: '#f06c00',
  maroon: '#800442',
  yellow: '#ffd600',
  green: '#008543',
  grey: '#737373',
};
