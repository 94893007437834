import React, { FC, useEffect, useState } from 'react';
import { Button, CheckboxField, ProgressSpinner } from '@jsluna/react';
import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ApolloError } from '@apollo/client';
import { RemoveEmailDomainContainer } from '../accountTypeManagement/removeEmailDomains/removeEmailDomains.styles';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

interface IDomainOptions {
  label: string;
  value: string;
}

interface IRemoveDomainScreen {
  handleClose: () => void;
  domains: string[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (domains: string[]) => Promise<void>;
  target: { id: string; displayName: string };
  isApprovalList: boolean;
}

export const RemoveDomainScreen: FC<IRemoveDomainScreen> = ({
  handleClose,
  domains,
  loading,
  error,
  handleConfirmation,
  target,
  isApprovalList,
}) => {
  const listName = isApprovalList ? 'approval' : 'rejection';

  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [selectedDomains, setSelectedDomains] = useState<string[]>([]);

  const [domainOptions, setDomainOptions] = useState<IDomainOptions[]>([]);

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  useEffect(() => {
    const options = domains.map<IDomainOptions>((domain: string) => ({
      label: domain,
      value: domain,
    }));
    setDomainOptions(options);
  }, [domains]);

  const handleReset = () => {
    setFeedBackMessage(null);
  };

  const onDomainsSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedDomains([...selectedDomains, value]);
    } else {
      const filteredDomains = selectedDomains.filter(
        domain => domain !== e.target.value,
      );
      setSelectedDomains(filteredDomains);
    }
  };
  const handleSubmission = async () => {
    await handleConfirmation(selectedDomains);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <RemoveEmailDomainContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${
                      target.id.includes('AT_')
                        ? `Email domains successfully removed from the automatic ${listName} list`
                        : 'Email domains successfully removed.'
                    }`
                  : 'Unable to remove domain. Please try again.'
              }
              saveButtonText={'Close'}
              onCloseHandler={handleClose}
              resetButtonText={`${
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Select more domains'
                  : 'Try again'
              }`}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Email domain list updated'
                  : 'Failed to remove email domain'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              {`Select email domains to remove from the automatic ${listName} list`}
            </div>
            {domainOptions.length > 0 && (
              <CheckboxField
                info='Please note, more than one domain can be selected.'
                name='approved-domains-checkbox'
                options={domainOptions}
                onChange={onDomainsSelected}
              />
            )}
          </>
        )}
      </RemoveEmailDomainContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={handleSubmission}
            variant='filled'
            disabled={!selectedDomains.length}
          >
            {loading && <ProgressSpinner />}
            {'Remove domains'}
          </Button>
          <Button
            onClick={() => {
              setSelectedDomains([]);
              handleClose();
            }}
            variant='outlined'
          >
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
