import React, { FC, useEffect, useContext, useState } from 'react';
import Container from 'src/components/Container/Container';
import { PageHeader } from 'src/components/PageHeader';
import { DetailsCard } from './manageRole.styles';
import { gql, useQuery } from '@apollo/client';
import { NotificationType } from '@enums/notificationTypes.enum';
import { useParams } from 'react-router-dom';
import { RoleDetailsSection } from './sections/roleDetailsSection';
import { RoleApplicationsSection } from './sections/roleApplicationsSection';
import { AuthContext } from 'src/providers/AuthProvider';
import { TagsSection } from 'src/components/Sections/TagsSection';

import {
  useUpdateRoleMutation,
  useRoleAddApplicationsAudienceMutation,
  useRoleRemoveApplicationsAudienceMutation,
  useRemove_Tags_From_RoleMutation,
  useAdd_Tags_To_RoleMutation,
  useManageRole_RoleQuery,
  TagSelection_TagsFragment,
  RoleApplicationsSection_ApplicationInfoFragment,
  useManageRole_TagsQuery,
  useRoleUpdateHiddenForRequestsMutation,
} from 'src/operations/generated/graphql';
import { RoleHiddenSection } from './sections/roleHiddenSection';

gql`
  fragment ManageRole_RoleInfo on RoleProjection {
    id
    name
    description
    ...RoleDetailsSection_RoleDetails
    accountType {
      value {
        id
        name
      }
    }
    roleApplications {
      value {
        ...RoleApplicationsSection_ApplicationInfo
      }
    }
    rolePermissions {
      value {
        ...RoleDetailsSection_PermissionDetails
      }
    }
    rolePermissionGroups {
      value {
        ...RoleDetailsSection_PermissionGroupDetails
      }
    }
    tags {
      value {
        ...TagSelection_Tags
      }
    }
  }
`;

gql`
  query ManageRole_Role($id: String!) {
    role(id: $id) {
      ...RoleHiddenSection_RoleHidden
      ...ManageRole_RoleInfo
    }
  }
`;
gql`
  query ManageRole_Tags {
    tags {
      ...TagSelection_Tags
    }
  }
`;

interface IDetailsToChange {
  description?: string;
}

export const ManageRole: FC = () => {
  const { roleId } = useParams();
  // @ts-ignore
  const { isAccountTypeAdminOf, isAccountTypeApproverOf } =
    useContext(AuthContext);

  const { data: { role = null } = {} } = useManageRole_RoleQuery({
    variables: {
      id: roleId || '',
    },
  });

  const { data: { tags: tagOptions = [] } = {} } = useManageRole_TagsQuery();
  const [tags, setTags] = useState<TagSelection_TagsFragment[]>([]);
  const [hiddenFromRequests, setHiddenFromRequests] = useState(false);

  const [details, setDetails] = useState({
    name: 'N/A',
    description: 'N/A',
  });
  const [roleApplications, setRoleApplications] = useState<
    RoleApplicationsSection_ApplicationInfoFragment[]
  >([]);

  const [feedback, setFeedback] = useState<{
    notificationFor: 'ADMIN' | 'APPROVER';
    type: NotificationType;
  } | null>(null);

  useEffect(() => {
    if (!role) return;
    const { name, description, roleApplications, tags } = role;
    setDetails({ name: name || '', description: description || '' });
    setRoleApplications(roleApplications.map(app => app.value));
    if (tags) {
      setTags(tags.map(tag => tag.value));
    }
    setHiddenFromRequests(!!role.hiddenForRequests);
  }, [role]);

  const [
    editDetailsMutation,
    { loading: editDetailsLoading, error: editDetailsError },
  ] = useUpdateRoleMutation();

  const [
    roleUpdateHiddenForRequestsMutation,
    {
      loading: roleUpdateHiddenForRequestsLoading,
      error: roleUpdateHiddenForRequestsError,
    },
  ] = useRoleUpdateHiddenForRequestsMutation();

  const [
    removeTagsMutation,
    { loading: removeTagsLoading, error: removeTagsError },
  ] = useRemove_Tags_From_RoleMutation();

  const [addTagsMutation, { loading: addTagsLoading, error: addTagsError }] =
    useAdd_Tags_To_RoleMutation();

  const handleUpdateConfirmation = async (value: IDetailsToChange) => {
    if (!role) return;
    try {
      const valuesToSubmit = Object.fromEntries(
        Object.entries(value).filter(([_, entry]) => entry != ''),
      );
      await editDetailsMutation({
        variables: {
          id: role.id,
          role: valuesToSubmit,
        },
      });
      setDetails({ ...details, ...value });
    } catch (e) {}
  };

  const handleRoleUpdateHiddenForRequestsConfirmation = async (
    value: boolean,
  ) => {
    if (!role) return;
    try {
      await roleUpdateHiddenForRequestsMutation({
        variables: {
          id: role.id,
          hiddenForRequests: value,
        },
      });
      setHiddenFromRequests(value);
    } catch (e) {}
  };

  const [roleAppsMutation, { loading: roleAppsLoading, error: roleAppsError }] =
    useRoleAddApplicationsAudienceMutation();

  const handleAddAppConfirmation = async (
    appsSelected: RoleApplicationsSection_ApplicationInfoFragment[],
  ) => {
    const selectedApplications = appsSelected.map(app => app.id);
    if (!role) return;
    try {
      await roleAppsMutation({
        variables: {
          id: role.id,
          applicationIds: selectedApplications,
        },
      });
      setRoleApplications([...roleApplications, ...appsSelected]);
    } catch (e) {}
  };

  const [
    removeAppsMutation,
    { loading: removeAppsLoading, error: removeAppsError },
  ] = useRoleRemoveApplicationsAudienceMutation();

  const handleApplicationRemoval = async (applicationId: string) => {
    if (!role) return;
    const filteredAccounts = roleApplications.filter(
      application => application.id !== applicationId,
    );
    try {
      await removeAppsMutation({
        variables: {
          id: role.id,
          applicationIds: [applicationId],
        },
      });
      setRoleApplications(filteredAccounts);
    } catch (e) {}
  };

  const handleTagConfirmation = async (
    tagsSelected: TagSelection_TagsFragment[],
  ) => {
    const tagsToBeAdded = [...tags, ...tagsSelected];
    try {
      const result = await addTagsMutation({
        variables: {
          id: role?.id || '',
          tagIds: tagsToBeAdded.map(obj => obj.id),
        },
      });
      if (result?.data?.roleAddTags?.id) {
        setTags(tagsToBeAdded);
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  };

  const handleTagRemoval = async (tagId: string) => {
    //mutation for tag adding

    const filteredTags = tags.filter(tag => tag.id !== tagId);

    try {
      await removeTagsMutation({
        variables: {
          id: role?.id || '',
          tagIds: filteredTags.map(obj => obj.id),
        },
      });
      setTags(filteredTags);
    } catch (e) {}
  };

  return (
    <>
      <PageHeader
        heading='Manage role'
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'Manage account types',
            link: '/manage-account-type',
          },
          {
            name: 'Manage role',
            link: '',
          },
        ]}
        subHeading='Edit the role’s name and description and the associated apps, permissions and permission groups.'
      />
      <Container size='sm'>
        {isAccountTypeApproverOf(role?.accountType.value.id) ||
        isAccountTypeAdminOf(role?.accountType.value.id) ? (
          <DetailsCard>
            {role && (
              <>
                <RoleDetailsSection
                  loading={editDetailsLoading}
                  error={editDetailsError}
                  target={{
                    id: role.id,
                    displayName: details.name,
                  }}
                  handleConfirmation={handleUpdateConfirmation}
                  role={{
                    id: role.id,
                    name: details.name,
                    description: details.description,
                  }}
                ></RoleDetailsSection>
                <RoleHiddenSection
                  loading={roleUpdateHiddenForRequestsLoading}
                  error={roleUpdateHiddenForRequestsError}
                  target={{
                    id: role.id,
                    displayName: details.name,
                  }}
                  handleConfirmation={
                    handleRoleUpdateHiddenForRequestsConfirmation
                  }
                  role={{
                    id: role.id,
                    name: role.name,
                    hiddenForRequests: hiddenFromRequests,
                    accountType: role.accountType,
                  }}
                ></RoleHiddenSection>
                <RoleApplicationsSection
                  role={{
                    id: role.id,
                    name: role?.name || '',
                    roleApplications,
                    rolePermissions:
                      role.rolePermissions.map(
                        permission => permission.value,
                      ) || [],
                    rolePermissionGroups:
                      role.rolePermissionGroups.map(
                        permissionGroup => permissionGroup.value,
                      ) || [],
                  }}
                  loading={roleAppsLoading || removeAppsLoading}
                  error={roleAppsError || removeAppsError}
                  handleAddAppConfirmation={handleAddAppConfirmation}
                  handleRemoval={handleApplicationRemoval}
                ></RoleApplicationsSection>

                <TagsSection
                  loading={false}
                  error={undefined}
                  tags={tags}
                  target={{ id: '', displayName: 'role' }}
                  tagOptions={tagOptions}
                  handleConfirmation={handleTagConfirmation}
                  handleRemoval={handleTagRemoval}
                  disabled={false}
                />
              </>
            )}
          </DetailsCard>
        ) : (
          <div>you do not have access</div>
        )}
      </Container>
    </>
  );
};
