import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const AccountTypeDetailsWrapper = styled('div')``;
export const AccountTypeDetailSection = styled('div')`
  padding-bottom: 48px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${ThemeColors.sectionSeparator};
  }
  &:not(:first-of-type) {
    padding-top: 48px;
  }

  .action-panel {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }
`;

export const DrawerPanel = styled('div')`
  width: 448px;
  padding: 24px;
`;
