import React, { FC } from 'react';
import { LoaderContainer } from './globalLoader.styles';
import ContentLoader from 'react-content-loader';

export const GlobalLoader: FC = () => {
  return (
    <LoaderContainer>
      <ContentLoader
        viewBox='0 0 100 100'
        style={{ width: '100%' }}
        backgroundColor='#f06c00'
      >
        <rect x='0' y='0' rx='0' ry='0' width='2000' height='10' />
      </ContentLoader>
    </LoaderContainer>
  );
};
