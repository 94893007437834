import React, { FC, useContext, useState } from 'react';
import { RoleSectionWrapper } from './roleSections.styles';
import { RadioButtonField, IconButton } from '@jsluna/react';
import { Plus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError, gql } from '@apollo/client';
import { IOptionWithDefault } from '@interfaces/option.interface';
import { AuthContext } from 'src/providers/AuthProvider';
import { RoleUpdateHiddenForRequestsScreen } from '../screens/roleUpdateHiddenForRequestsScreen';
import { NotificationType } from '@enums/notificationTypes.enum';
import { RoleHiddenSection_RoleHiddenFragment } from 'src/operations/generated/graphql';

interface ISectionDetails {
  handleConfirmation: (hiddenForRequests: boolean) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  role: RoleHiddenSection_RoleHiddenFragment;
}
enum PanelAction {
  HIDDEN_FOR_REQUESTS = 'HIDDEN_FOR_REQUESTS',
}

gql`
  fragment RoleHiddenSection_RoleHidden on RoleProjection {
    id
    name
    hiddenForRequests
    accountType {
      value {
        id
      }
    }
  }
`;

export const RoleHiddenSection: FC<ISectionDetails> = ({
  role,
  handleConfirmation,
  loading,
  error,
  target,
}) => {
  //@ts-ignore
  const { isAccountTypeAdminOf } = useContext(AuthContext);
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);
  const handlePanelClose = () => {
    setShowSidePanel(null);
    setFeedBackMessage(null);
  };
  const PlusIcon = Plus as unknown as React.FC;
  const defaultOption = (value: boolean): IOptionWithDefault[] => {
    const options = [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ];
    const opt = value ? 'yes' : 'no';
    return options.map(option => {
      return {
        ...option,
        defaultChecked: option.value === opt,
      };
    });
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.HIDDEN_FOR_REQUESTS:
        return (
          <RoleUpdateHiddenForRequestsScreen
            target={{
              id: role.id,
              displayName: role?.name || '',
            }}
            loading={loading}
            error={error}
            handleClose={handlePanelClose}
            handleConfirmation={handleConfirmation}
            radioHeading={{
              heading: 'Hide from requests?',
              subHeading: 'Do you want to hide this role from non admin users?',
              feedBackStatus: 'hide-role',
            }}
            defaultValue={role?.hiddenForRequests || false}
          />
        );
    }
  };

  return (
    <RoleSectionWrapper>
      {role && (
        <>
          <RadioButtonField
            label={<h4>Hide from requests?</h4>}
            info={
              'Do you want to prevent users from requesting this role? If you do, users would only be allowed access if if an admin of an account type requests it on their behalf'
            }
            name='radio-button-field-2'
            fullWidth
            options={defaultOption(role?.hiddenForRequests || false)}
            value={role?.hiddenForRequests ? 'yes' : 'no'}
          />
          <div className='action-panel'>
            <IconButton
              variant='text'
              circle={false}
              disabled={!isAccountTypeAdminOf(role.accountType.value.id)}
              hard={false}
              element='button'
              label='Edit access'
              color='dark'
              onClick={() => {
                setShowSidePanel(PanelAction.HIDDEN_FOR_REQUESTS);
              }}
            >
              <PlusIcon />
            </IconButton>
          </div>
          <Drawer
            open={showSidePanel !== null}
            onClose={handlePanelClose}
            anchor='right'
            className='view-application'
          >
            {renderPanel()}
          </Drawer>
        </>
      )}
    </RoleSectionWrapper>
  );
};
