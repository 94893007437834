import React, { FC, useEffect, useState } from 'react';
import { Button, CheckboxField, ProgressSpinner } from '@jsluna/react';
import { ApolloError, gql } from '@apollo/client';

import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { RequestRoleContainer } from './requestRoles.styles';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { RemoveRoleScreen_RoleInfoFragment } from 'src/operations/generated/graphql';

interface IRoleOption {
  label: string;
  value: string;
}

interface IRemoveRoleScreen {
  handleClose: () => void;
  existingRoleIds: string[];
  fullRoleOptions: RemoveRoleScreen_RoleInfoFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (roles: string[]) => Promise<void>;
}

gql`
  fragment RemoveRoleScreen_RoleInfo on RoleProjection {
    id
    name
  }
`;

export const RequestRoleScreen: FC<IRemoveRoleScreen> = ({
  handleClose,
  existingRoleIds,
  fullRoleOptions,
  loading,
  error,
  handleConfirmation,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [roleOptions, setRoleOptions] = useState<IRoleOption[]>([]);

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  useEffect(() => {
    const options = fullRoleOptions.reduce((acc, role) => {
      if (existingRoleIds.includes(role.id)) return acc;
      return [...acc, { label: role.name || '', value: role.id }];
    }, [] as IRoleOption[]);

    setRoleOptions(options);
  }, [existingRoleIds, fullRoleOptions]);

  const handleReset = () => {
    setFeedBackMessage(null);
    setSelectedRoles([]);
  };

  const onRolesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedRoles([...selectedRoles, value]);
    } else {
      const filteredRoles = selectedRoles.filter(
        role => role !== e.target.value,
      );
      setSelectedRoles(filteredRoles);
    }
  };
  const handleSubmission = async () => {
    await handleConfirmation(selectedRoles);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <RequestRoleContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Your request for roles has been submitted'
                  : 'Unable to request role. Please try again.'
              }
              feedBackInfo={
                'Once your request has been reviewed, you’ll get a notification of the outcome in Together With.'
              }
              saveButtonText={'Close'}
              onCloseHandler={handleClose}
              resetButtonText={`${
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Request another'
                  : 'Try again'
              }`}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Request submitted'
                  : 'Failed to request role'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              {'Add new roles to your account'}
            </div>
            {roleOptions.length > 0 ? (
              <>
                <CheckboxField
                  label='What’s your role in the company?'
                  info='You can select more than one role.'
                  name='roles-to-request-checkbox'
                  options={roleOptions}
                  onChange={onRolesSelected}
                />
              </>
            ) : (
              <p>There are no roles available to request.</p>
            )}
          </>
        )}
      </RequestRoleContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={handleSubmission}
            variant='filled'
            disabled={!selectedRoles.length}
          >
            {loading && <ProgressSpinner />}
            Add roles
          </Button>
          <Button
            onClick={() => {
              setSelectedRoles([]);
              handleClose();
            }}
            variant='outlined'
          >
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
