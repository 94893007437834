import React, { FC, useState } from 'react';
import { IconButton } from '@jsluna/react';
import { SupplierCodesSectionContainer } from './supplierCodesSection.styles';
import { Drawer } from '@mui/material';
import { Plus, Minus } from '@jsluna/icons';

import { AccountDetailStatus } from '../accountDetailStatus';
import {
  AddSupplierCode,
  RemoveSupplierCode,
} from 'src/screens/editSupplierCode';
import { gql } from '@apollo/client';
import {
  SupplierCodesSection_AccountToSiteRelationshipFragment,
  SupplierCodesSection_SiteInfoFragment,
  AssociationStatus,
} from 'src/operations/generated/graphql';

const PlusIcon = Plus as unknown as React.FC;
const MinusIcon = Minus as unknown as React.FC;

export enum DrawerViews {
  ADD_SUBSIDIARY = 'ADD_SUBSIDIARY',
  REMOVE_SUBSIDIARY = 'REMOVE_SUBSIDIARY',
}

interface ISupplierCodeSection {
  sites: SupplierCodesSection_AccountToSiteRelationshipFragment[];
  disabled: boolean;
  selectedAccountId: string;
}

gql`
  fragment SupplierCodesSection_SiteInfo on SiteProjection {
    ...AddSupplierCode_SiteInfo
    ...RemoveSupplierCode_SiteInfo
    parentGroup {
      value {
        id
      }
    }
  }
`;

gql`
  fragment SupplierCodesSection_AccountToSiteRelationship on AccountToSiteRelationship {
    status
    value {
      ...SupplierCodesSection_SiteInfo
    }
  }
`;

export const SupplierCodesSection: FC<ISupplierCodeSection> = ({
  sites = [],
  disabled,
  selectedAccountId,
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [drawerView, setDrawerView] = useState('');
  const [accountSites, setAccountSites] = useState(sites);

  const addSupplierCodeHandler = (
    requestedSites: SupplierCodesSection_SiteInfoFragment[],
  ) => {
    const newRequestedSites = requestedSites.map(site => ({
      value: site,
      status: AssociationStatus.Requested,
    }));
    setAccountSites([...accountSites, ...newRequestedSites]);
  };

  const removeSupplierCodeHandler = (removedSites: String[]) => {
    const filteredSites = accountSites.filter(
      site => !removedSites.includes(site.value.id),
    );
    setAccountSites(filteredSites);
  };
  return (
    <SupplierCodesSectionContainer>
      <div className='supplier-codes-container'>
        <div className='supplier-codes'>
          {accountSites.map(site => (
            <AccountDetailStatus
              label={site.value.name || site.value.id}
              status={site.status || undefined}
            />
          ))}
        </div>
      </div>
      <div className='section-actions'>
        <IconButton
          variant='text'
          circle={false}
          disabled={!sites.length || disabled}
          hard={false}
          element='button'
          label='Add subsidiary'
          color='dark'
          onClick={() => {
            setIsDrawerOpen(true);
            setDrawerView(DrawerViews.ADD_SUBSIDIARY);
          }}
        >
          <PlusIcon />
        </IconButton>
        <IconButton
          variant='text'
          circle={false}
          disabled={sites.length < 2 || disabled}
          hard={false}
          element='button'
          label='Remove subsidiary'
          color='dark'
          onClick={() => {
            setIsDrawerOpen(true);
            setDrawerView(DrawerViews.REMOVE_SUBSIDIARY);
          }}
        >
          <MinusIcon />
        </IconButton>
      </div>
      {isDrawerOpen && (
        <Drawer
          open={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setDrawerView('');
          }}
          anchor='right'
          className='edit-app-group'
        >
          {drawerView === DrawerViews.ADD_SUBSIDIARY ? (
            <AddSupplierCode
              sites={sites}
              selectedAccountId={selectedAccountId}
              groupId={sites?.[0]?.value.parentGroup?.value.id}
              addHandler={addSupplierCodeHandler}
              setDrawerView={setDrawerView}
              drawerView={drawerView}
              setIsDrawerOpen={setIsDrawerOpen}
            />
          ) : (
            drawerView === DrawerViews.REMOVE_SUBSIDIARY && (
              <RemoveSupplierCode
                selectedAccountId={selectedAccountId}
                sites={sites}
                removeHandler={removeSupplierCodeHandler}
                setDrawerView={setDrawerView}
                drawerView={drawerView}
                setIsDrawerOpen={setIsDrawerOpen}
              />
            )
          )}
        </Drawer>
      )}
    </SupplierCodesSectionContainer>
  );
};
