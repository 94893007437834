import React, { FC, useContext, useEffect, useState } from 'react';
import Container from 'src/components/Container/Container';
import { PageHeader } from 'src/components/PageHeader';
import { Accordion, AccordionItem, Button } from '@jsluna/react';
import {
  AccountTypeManagementWrapper,
  RelativeDiv,
} from './accountTypeManagement.styles';
import { gql } from '@apollo/client';
import { AccountTypeDetails } from './components/accountTypeDetails/accountTypeDetails';
import { AccountTypeFeedback } from './removeAccountTypeFeedback';
import { NotificationType } from '@enums/notificationTypes.enum';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constants/routes';
import { AuthContext } from '../../providers/AuthProvider';
import { APP_ACCESS } from 'src/enums/permissions.enum';
import { NoRecordCard } from 'src/components/NoRecordCard';
import {
  AccountTypeManagement_AccountTypesFragment,
  useAccountTypeManagement_AccountTypesQuery,
} from 'src/operations/generated/graphql';

gql`
  fragment AccountTypeManagement_AccountTypes on AccountTypeProjection {
    id
    name
  }
`;

gql`
  query AccountTypeManagement_AccountTypes {
    accountTypes {
      ...AccountTypeManagement_AccountTypes
    }
  }
`;

export const AccountTypeManagement: FC = () => {
  // @ts-ignore
  const { isAccountTypeAdminOf, isAccountTypeApproverOf, hasPermission } =
    useContext(AuthContext);

  const { data: { accountTypes = [] } = {} } =
    useAccountTypeManagement_AccountTypesQuery();

  const [filteredAccountTypes, setFilteredAccountTypes] = useState<
    AccountTypeManagement_AccountTypesFragment[]
  >([]);

  useEffect(() => {
    if (!accountTypes.length) return;

    const filteredAts = accountTypes.filter(
      accountType =>
        isAccountTypeAdminOf(accountType.id) ||
        isAccountTypeApproverOf(accountType.id),
    );
    setFilteredAccountTypes(filteredAts);
  }, [accountTypes, isAccountTypeApproverOf, isAccountTypeAdminOf]);

  const [feedback, setFeedback] = useState<{
    notificationFor: 'ADMIN' | 'APPROVER';
    type: NotificationType;
  } | null>(null);
  const navigate = useNavigate();

  return (
    <AccountTypeManagementWrapper>
      <PageHeader
        heading='Manage account types'
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'Manage account types',
            link: '',
          },
        ]}
        subHeading='Update admins, approvers, email domains and linked accounts.'
      />
      <RelativeDiv>
        {feedback && (
          <>
            <AccountTypeFeedback
              notificationFor={feedback.notificationFor}
              type={feedback.type}
              onClose={() => {
                setFeedback(null);
              }}
            />
          </>
        )}
      </RelativeDiv>
      <Container>
        <div className='heading-section'>
          <h4>Account types </h4>
          {hasPermission(APP_ACCESS.CREATE_ACCOUNT_TYPE) && (
            <Button
              circle={false}
              disabled={false}
              hard={false}
              element='button'
              variant='filled'
              onClick={() => navigate(routes.createAccountType)}
            >
              Add account type
            </Button>
          )}
        </div>
        {filteredAccountTypes?.length > 0 ? (
          <Accordion titleElement='h3'>
            {filteredAccountTypes.map(accountType => (
              <AccordionItem
                id={`accordion-${accountType.id}`}
                title={`${accountType.name}`}
                className='account-type-accordion'
                key={accountType.id}
              >
                <AccountTypeDetails accountTypeId={accountType.id} />
              </AccordionItem>
            ))}
          </Accordion>
        ) : (
          <>
            <NoRecordCard recordType='Account types' />
          </>
        )}
      </Container>
    </AccountTypeManagementWrapper>
  );
};
