import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const EditAppGroupContainer = styled('div')`
  width: 448px;
  padding: 24px;
  padding-bottom: 0;

  .tab-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${ThemeColors.black};
    margin-bottom: 18px;
    margin-top: 24px;
  }

  .panel-body {
    height: calc(100vh - 290px);
    overflow: auto;
  }

  .feedback-container {
    position: relative;
    padding-top: 72px;

    .feedback-message {
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-weight: bold;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 72px;
    }
  }
`;
