import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const SectionHeaderWrapper = styled('div')`
  margin-bottom: 25px;

  h4 {
    margin-bottom: 0;
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
    color: ${ThemeColors.monochromeDarker};
  }

  .sub-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: ${ThemeColors.monochromeLighter};
  }

  &.flex-layout {
    display: flex;
    align-items: flex-start;

    button {
      margin-left: auto;
    }
  }
`;
