import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const DetailsRowContainer = styled('div')`
  display: flex;
  .name-field {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: ${ThemeColors.monochromeLighter};
    width: 200px;
  }
  .value-field {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${ThemeColors.monochromeLighter};
    flex: 1;
  }
`;
