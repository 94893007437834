//@ts-nocheck
import React, { FC, useEffect, useState } from 'react';
import Container from 'src/components/Container/Container';
import { PageHeader } from 'src/components/PageHeader';
import { StatCard } from './components/StatCard';
import { GridWrapper, GridItem, Card } from '@jsluna/react';
import { StatusSummary } from './serviceDashboard.styles';
import { AppHosts } from 'src/enums/appHosts.enum';

interface ServiceInfo {
  status: string;
  message: string;
}
export interface IServiceHealth {
  status: string;
  info?: {
    [key: string]: ServiceInfo;
  };
  error?: {};
  details?: {
    [key: string]: ServiceInfo;
  };
  isLoading?: boolean;
}

interface Host {
  name: string;
  link: AppHosts;
}

export const ServiceDashboard: FC = () => {
  const appHostsArray: Host[] = [
    { name: 'Account Service', link: AppHosts.AccountService },
    { name: 'Account Projection', link: AppHosts.AccountProjection },
    { name: 'Application Service', link: AppHosts.ApplicationService },
    { name: 'Application Projection', link: AppHosts.ApplicationProjection },
    { name: 'Hierarchy Projection', link: AppHosts.HierarchyProjection },
    { name: 'Notification Projection', link: AppHosts.NotificationProjection },
    { name: 'Organisation Service', link: AppHosts.OrganisationService },
    { name: 'Organisation Projection', link: AppHosts.OrganisationProjection },
    { name: 'Permission Service', link: AppHosts.PermissionService },
    { name: 'Permission Projection', link: AppHosts.PermissionProjection },
    { name: 'Profile Service', link: AppHosts.ProfileService },
    { name: 'Profile Projection', link: AppHosts.ProfileProjection },
    { name: 'Tag Service', link: AppHosts.TagService },
    { name: 'Tag Projection', link: AppHosts.TagProjection },
    { name: 'Routing Projection', link: AppHosts.RoutingProjection },
  ];

  const [healthResults, setHealthResults] = useState<{
    [key: string]: IServiceHealth;
  }>({});

  const [overAllStatus, setOverAllStatus] = useState({
    activeCount: 0,
    totalCount: 0,
    percentage: 0,
  });

  useEffect(() => {
    setInitialResults();
    fetchHealthCheckResults();
  }, []);

  useEffect(() => {
    calculatePercentage();
  }, [healthResults]);

  const fetchHealthCheckResults = async () => {
    const results: { [key: string]: any } = {};

    for (const { name, link } of appHostsArray) {
      try {
        const response = await fetch(`${link}/healthcheck`);
        const data = await response.json();
        results[name] = data;
      } catch (error) {
        console.error(`Error fetching data from ${link}:`, error);
        const errorObj = {
          'status': 'down',
          error,
        };
        results[name] = errorObj;
      }
    }
    setHealthResults(results);
  };

  const setInitialResults = async () => {
    const results: { [key: string]: any } = {};
    for (const { name, link } of appHostsArray) {
      const initialObj = {
        status: '',
        isLoading: true,
      };
      results[name] = initialObj;
    }
    setHealthResults(results);
  };

  const calculatePercentage = () => {
    let activeCount = 0;
    let totalCount = 0;

    for (const serviceName in healthResults) {
      const service = healthResults[serviceName];
      if (!service.isLoading && service.status === 'ok') {
        activeCount++;
      }
      if (!service.isLoading) {
        totalCount++;
      }
    }
    const operationalPercentage = (activeCount / totalCount) * 100;

    setOverAllStatus({
      activeCount,
      totalCount,
      percentage: isNaN(operationalPercentage)
        ? 0
        : Math.round(operationalPercentage),
    });
  };

  const getClassName = percentage => {
    if (percentage >= 75) {
      return 'all-green';
    } else if (percentage >= 50) {
      return 'all-yellow';
    } else {
      return 'all-red';
    }
  };

  return (
    <>
      <PageHeader
        heading={'Service Dashboard'}
        topHeading='Together With'
        subHeading='Service dashboard to check the health of your services.'
      />

      <Container className='ln-u-hard-top'>
        {Object.values(healthResults).every(service => !service.isLoading) ? (
          <StatusSummary className={getClassName(overAllStatus.percentage)}>
            {overAllStatus.percentage === 0
              ? 'All systems are currently down.'
              : overAllStatus.percentage === 100
              ? 'All systems are operational'
              : `${overAllStatus.percentage}% of systems are operational`}
          </StatusSummary>
        ) : (
          <StatusSummary className='loading'>
            <div className='loader'>Loading...</div>
          </StatusSummary>
        )}

        <GridWrapper>
          {healthResults &&
            Object.entries(healthResults).map(([serviceName, serviceInfo]) => (
              <GridItem size={{ md: '1/2', xs: '1/1' }} key={`serviceName`}>
                <StatCard healthInfo={serviceInfo} serviceName={serviceName} />
              </GridItem>
            ))}
        </GridWrapper>
      </Container>
    </>
  );
};
