import React, { FC, useEffect, useState } from 'react';
import { CreateTagWrapper } from './createTag.styles';
import { TextInputField, Button, TextAreaField } from '@jsluna/react';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ScreenFeedback } from '../components/screenFeedback';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import createTagSchema from './createTag.schema';
import { ApolloError } from '@apollo/client';

interface ICreateTag {
  handleClose: () => void;
  handleConfirmation: (values: any) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
}

export const CreateTag: FC<ICreateTag> = ({
  handleClose,
  handleConfirmation,
  loading,
  error,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(createTagSchema),
    mode: 'onChange',
  });
  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);
  return (
    <CreateTagWrapper>
      {feedBackMessage ? (
        <>
          <ScreenFeedback
            isLoading={loading}
            notificationType={feedBackMessage}
            feedBackMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? `A new tag has been successfully been created`
                : 'Unable to create tag. Please try again.'
            }
            saveButtonText={'Close'}
            onCloseHandler={() => {
              handleClose();
            }}
            resetButtonText={'Create another tag'}
            resetHandler={() => {
              setFeedBackMessage(null);
              reset();
            }}
            notificationMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? 'Tags updated'
                : 'Failed to create tag'
            }
          />
        </>
      ) : (
        <form onSubmit={handleSubmit(handleConfirmation)}>
          <h4>Create a new tag</h4>
          <Controller
            name='name'
            control={control}
            render={({ field }) => (
              <TextInputField
                {...field}
                label='Tag name'
                error={errors?.name?.message}
                placeholder='Please enter'
              />
            )}
          />

          <div className='textarea-wrapper'>
            <Controller
              name='description'
              control={control}
              render={({ field }) => (
                <TextAreaField
                  {...field}
                  label='Tag description'
                  error={errors?.description?.message}
                  placeholder='Please enter'
                />
              )}
            />
          </div>
        </form>
      )}
      {!feedBackMessage && (
        <PanelActions>
          <>
            <Button
              onClick={handleSubmit(d => {
                handleConfirmation(d);
                setFeedBackMessage(NotificationType.SUCCESS);
              })}
              variant='filled'
              disabled={!isValid}
              type='submit'
            >
              Create tag
            </Button>
            <Button
              hard={false}
              variant='outlined'
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </Button>
          </>
        </PanelActions>
      )}
    </CreateTagWrapper>
  );
};
