import { FC, useEffect, useState } from 'react';
import { generateBreadcrumbs } from 'src/utils/getBreadcrumbs';
import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TileGroup } from '@sainsburys-tech/suppex-tile-group';
import { TileData } from '@sainsburys-tech/suppex-tile';
import { TagGroup } from '@sainsburys-tech/suppex-tag-group';
import { ArticleNavigation } from '@sainsburys-tech/suppex-article-navigation';
import { ArticleFooter } from '@sainsburys-tech/article-footer';
import {
  generateJournalArticleTags,
  generateJournalTags,
} from 'src/utils/generateJournalTags';
import { journalClickHandler } from 'src/utils/journalClickHandler';
import { useGetJournalArticlePage } from 'src/operations/queries/getJournalArticle';
import { GridWrapper, GridItem, Container } from '@jsluna/react';
import { routes } from 'src/constants/routes';
import { JournalLoading } from 'src/components/JournalLoading';
import { JournalError } from 'src/components/JournalError';
import DOMPurify from 'dompurify';

export const JournalArticlePage: FC = () => {
  const [currentArticleIndex, setCurrentArticleIndex] = useState(0);
  const [filteredChildren, setFilteredChildren] = useState<any[]>([]);
  const [moreJournalArticles, setMoreJournalArticles] = useState<any[]>([]);
  const [latestJournal, setLatestJournal] = useState<any>(null);
  const navigate = useNavigate();
  const { journalId, articleId } = useParams();
  const {
    data: { article = null, journal = null, supplierJournals = null } = {},
    loading,
    error,
  } = useGetJournalArticlePage(articleId || '', journalId || '');

  useEffect(() => {
    if (journal) {
      setFilteredChildren(
        journal.children.filter((child: any) => child.child_type === 'ARTICLE'),
      );
      setMoreJournalArticles(
        journal.children
          .filter((child: any) => child.uid !== article.uid)
          .slice(0, 3),
      );
    }
  }, [journal]);

  useEffect(() => {
    if (filteredChildren?.length) {
      setCurrentArticleIndex(
        filteredChildren.findIndex((child: any) => child.uid === article.uid),
      );
    }
  }, [filteredChildren]);

  useEffect(() => {
    if (supplierJournals?.length) {
      setLatestJournal(supplierJournals[0]);
    }
  }, [supplierJournals]);

  const getArticleTitle = (type: string) => {
    if (currentArticleIndex === 0 && type === 'PREV') return null;
    if (currentArticleIndex === filteredChildren.length - 1 && type === 'NEXT')
      return null;
    const nextIndex =
      type === 'NEXT' ? currentArticleIndex + 1 : currentArticleIndex - 1;
    return filteredChildren[nextIndex]?.title;
  };

  const articleNavigationHandler = (type: string) => {
    if (currentArticleIndex === 0 && type === 'PREV') return null;
    if (currentArticleIndex === filteredChildren.length - 1 && type === 'NEXT')
      return null;
    const nextIndex =
      type === 'NEXT' ? currentArticleIndex + 1 : currentArticleIndex - 1;
    const nextArticle = filteredChildren[nextIndex];
    journalClickHandler(nextArticle, navigate);
  };

  return (
    <>
      {loading ? (
        <JournalLoading />
      ) : error ? (
        <JournalError />
      ) : (
        <>
          <TopOfPage
            title={article.title}
            subtitle={article.summary}
            breadcrumbs={generateBreadcrumbs({
              journal,
              article,
            })}
            LinkType={Link}
          />

          <GridWrapper className='ln-u-bg-color-white'>
            <Container
              soft
              className='ln-u-soft-top ln-u-soft-bottom news-and-articles__article-page'
            >
              <GridItem size={{ xs: '1/1', md: '8/12' }}>
                <TagGroup tags={generateJournalArticleTags(article, journal)} />
                {article.intro && (
                  <div className='ln-u-soft-top'>
                    <b>{article.intro}</b>
                  </div>
                )}
                {article.image && (
                  <div className='ln-u-soft-top'>
                    <img
                      style={{ borderRadius: '0.5rem' }}
                      src={article.image}
                      alt={article.image_caption}
                    />
                  </div>
                )}
                <article
                  className='ln-u-soft-top'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(article.body, {
                      ADD_TAGS: ['iframe'],
                      ADD_ATTR: [
                        'allow',
                        'allowfullscreen',
                        'frameborder',
                        'scrolling',
                      ],
                    }),
                  }}
                />
                <div className='ln-u-soft-top'>
                  <ArticleNavigation
                    nextArticleClickHandler={() =>
                      articleNavigationHandler('NEXT')
                    }
                    prevArticleClickHandler={() =>
                      articleNavigationHandler('PREV')
                    }
                    nextArticleTitle={getArticleTitle('NEXT')}
                    prevArticleTitle={getArticleTitle('PREV')}
                  />
                </div>
                <div className='ln-u-soft-top'>
                  <ArticleFooter text='' />
                </div>
              </GridItem>
              <GridItem size={{ xs: '1/1', md: '4/12' }} role='complementary'>
                <TileGroup
                  tileClickHandler={(tile: TileData) => {
                    journalClickHandler(tile, navigate);
                  }}
                  title={`More from ${journal.title}`}
                  variant='card'
                  style='light'
                  tiles={moreJournalArticles.map(child => ({
                    ...child,
                    summary: null,
                    image: { url: child.thumbnail_image || child.teaser_image },
                    tags: [],
                    // tags: generateJournalArticleTags(child, journal),
                  }))}
                  seeMoreHandler={() => journalClickHandler(journal, navigate)}
                  seeMoreButtonPosition='bottom'
                />
                {journal.type === 'Agriculture' && latestJournal && (
                  <TileGroup
                    tileClickHandler={(tile: TileData) => {
                      journalClickHandler(tile, navigate);
                    }}
                    title='Supplier Journal'
                    variant='card'
                    style='light'
                    tiles={[
                      {
                        ...latestJournal,
                        image: { url: latestJournal.teaser_image },
                        tags: generateJournalTags(latestJournal),
                      },
                    ]}
                    seeMoreHandler={() => navigate(routes.supplierJournals)}
                    seeMoreButtonPosition='bottom'
                    seeMoreText='Find out more'
                  />
                )}
              </GridItem>
            </Container>
          </GridWrapper>
        </>
      )}
    </>
  );
};
