import React from 'react';
import { GridWrapper, GridItem, Card } from '@jsluna/react';
import ContentLoader from 'react-content-loader';
import { StyledApplicationsLoader } from './applicationsLoader.styles';

export const ApplicationsLoader = () => {
  return (
    <div>
      <GridWrapper>
        <GridItem size={{ md: '1/3' }} className='ln-u-margin-bottom*3'>
          <StyledApplicationsLoader>
            <ContentLoader
              speed={2}
              width={270}
              height={20}
              viewBox='0 0 270 20'
              backgroundColor='#737373'
              foregroundColor='#ecebeb'
            >
              <rect y='0' x='0' rx='3' ry='3' width='200' height='10' />
            </ContentLoader>
            <ContentLoader
              speed={2}
              width={200}
              height={20}
              viewBox='0 0 200 10'
              backgroundColor='#737373'
              foregroundColor='#ecebeb'
            >
              <rect y='0' x='0' rx='3' ry='3' width='100' height='10' />
            </ContentLoader>
          </StyledApplicationsLoader>
        </GridItem>
        <GridItem size={{ md: '1/3' }} className='ln-u-margin-bottom*3'>
          <StyledApplicationsLoader>
            <ContentLoader
              speed={2}
              width={270}
              height={20}
              viewBox='0 0 270 20'
              backgroundColor='#737373'
              foregroundColor='#ecebeb'
            >
              <rect y='0' x='0' rx='3' ry='3' width='200' height='10' />
            </ContentLoader>
            <ContentLoader
              speed={2}
              width={200}
              height={20}
              viewBox='0 0 200 10'
              backgroundColor='#737373'
              foregroundColor='#ecebeb'
            >
              <rect y='0' x='0' rx='3' ry='3' width='100' height='10' />
            </ContentLoader>
          </StyledApplicationsLoader>
        </GridItem>
        <GridItem size={{ md: '1/3' }} className='ln-u-margin-bottom*3'>
          <StyledApplicationsLoader>
            <ContentLoader
              speed={2}
              width={270}
              height={20}
              viewBox='0 0 270 20'
              backgroundColor='#737373'
              foregroundColor='#ecebeb'
            >
              <rect y='0' x='0' rx='3' ry='3' width='200' height='10' />
            </ContentLoader>
            <ContentLoader
              speed={2}
              width={200}
              height={20}
              viewBox='0 0 200 10'
              backgroundColor='#737373'
              foregroundColor='#ecebeb'
            >
              <rect y='0' x='0' rx='3' ry='3' width='100' height='10' />
            </ContentLoader>
          </StyledApplicationsLoader>
        </GridItem>
      </GridWrapper>
    </div>
  );
};
