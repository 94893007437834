export const DEFAULT_ICON =
  'https://images.togetherwith.co.uk/Default-icon.png';
export const SAINSBURYS_LOGO_ICON =
  'https://images.togetherwith.co.uk/logo-sainsburys.svg';
export const FACEBOOK_ICON = 'https://images.togetherwith.co.uk/facebook.svg';
export const TWITTER_ICON = 'https://images.togetherwith.co.uk/twitter.svg';
export const YOUTUBE_ICON = 'https://images.togetherwith.co.uk/youtube.svg';
export const INSTAGRAM_ICON = 'https://images.togetherwith.co.uk/instagram.svg';
export const PINTEREST_ICON = 'https://images.togetherwith.co.uk/pinterest.svg';
export const FLICKR_ICON = 'https://images.togetherwith.co.uk/flickr.svg';
export const APP_STORE_ICON = 'https://images.togetherwith.co.uk/appstore.svg';
export const GOOGLE_PLAY_ICON =
  'https://images.togetherwith.co.uk/google-play-badge.png';
export const BECOME_A_SUPPLIER =
  'https://images.togetherwith.co.uk/apprentice-box-image@3x-v2.png';
export const QUERY_APP_ICON =
  'https://images.togetherwith.co.uk/queryapp-icon-v2.svg';
export const QUERY_APP_DEVICES_DESKTOP =
  'https://images.togetherwith.co.uk/queryapp-devices-desktops-v2.svg';
export const QUERY_APP_DEVICES_TABLET =
  'https://images.togetherwith.co.uk/queryapp-devices-tablet-v2.svg';
export const QUERY_APP_DEVICES_MOBILE =
  'https://images.togetherwith.co.uk/queryapp-devices-mobile-v2.svg';
export const REGISTER_ILLUSTRATION_ICON =
  'https://images.togetherwith.co.uk/register-illustration-v2.svg';
export const ALL_APPS_ILLUSTRATION_ICON =
  'https://images.togetherwith.co.uk/allapps-illustration-v2.svg';
export const FAVOURITES_ILLUSTRATION_ICON =
  'https://images.togetherwith.co.uk/favourites-illustration-v2.svg';
export const DEVICE_COMBINATION =
  'https://images.togetherwith.co.uk/device-combination.png';
export const ACCOUNTS_ICON =
  'https://images.togetherwith.co.uk/account-icon.svg';
export const QUESTION_MARK_ICON =
  'https://images.togetherwith.co.uk/question-mark-icon.svg';
