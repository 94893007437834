import React, { FC } from 'react';
import { QuickLinkStyledCard } from './quickLinkCard.styles';

interface IQuickLink {
  color: string;
  title: string;
  description?: string;
  link: string;
}

export const QuickLinkCard: FC<IQuickLink> = ({
  color,
  title,
  description,
  link,
}) => {
  return (
    <QuickLinkStyledCard color={color} element={'a'} href={link}>
      <h2 className='heading'>{title}</h2>
      {description && <div className='description'>{description}</div>}
    </QuickLinkStyledCard>
  );
};
