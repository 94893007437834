import React, { FC } from 'react';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import {
  NotificationLoading,
  NotificationWrapper,
} from './notificationBanner.styles';
import { Cancel, Tick } from '@jsluna/icons';
import { IconButton } from '@jsluna/react';

interface INotificationBanner {
  loading: boolean;
  messageType: NotificationType;
  message: string;
  onClose?: () => void;
}

const TickIcon = Tick as unknown as React.FC;
const CancelIcon = Cancel as unknown as React.FC;

export const NotificationBanner: FC<INotificationBanner> = ({
  loading,
  messageType,
  message,
  onClose,
}) => {
  const className = !message
    ? ''
    : messageType === NotificationType.SUCCESS
    ? 'success'
    : 'failure';
  return (
    <NotificationWrapper className={`notification-wrapper ${className}`}>
      <div className={`feedback-zone ${loading && 'loading'} ${className}`}>
        {messageType === NotificationType.SUCCESS ? (
          <TickIcon />
        ) : (
          <CancelIcon />
        )}
        {message}
        {loading && (
          <NotificationLoading
            speed={2}
            width={1000}
            height={5}
            viewBox='0 0 1000 5'
            backgroundColor='#F06C00'
            foregroundColor='#994601'
          >
            <rect y='0' cx='0' cy='0' rx='0' ry='0' width='1000' height='5' />
          </NotificationLoading>
        )}
        <IconButton
          variant='text'
          circle={false}
          disabled={false}
          hard={false}
          element='button'
          label='close banner'
          hideLabel
          onClick={onClose}
        >
          <CancelIcon />
        </IconButton>
        <div></div>
      </div>
    </NotificationWrapper>
  );
};
