import React, { FC, useEffect, useState } from 'react';
import {
  PermissionAppTile,
  PopOverCard,
  RoleSectionWrapper,
} from './roleSections.styles';
import {
  Button,
  GridWrapper,
  GridItem,
  Modal,
  ModalHeading,
  TextInputField,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from '@jsluna/react';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';
import { Delete } from '@jsluna/icons';
import { Drawer, Popover } from '@mui/material';
import { ApolloError, gql, useQuery } from '@apollo/client';
import { LinkApplicationScreen } from 'src/screens/linkApplications';
import {
  PermissionSelection,
  SelectorType,
} from 'src/screens/permissionSelector';
import {
  useAssociatePermissionsToRoleMutation,
  useDissociatePermissionsFromRoleMutation,
  useAssociateGroupsToRoleMutation,
  useDissociateGroupsFromRoleMutation,
  RoleApplicationsSection_ApplicationInfoFragment,
  RoleDetailsSection_PermissionDetailsFragment,
  RoleDetailsSection_PermissionGroupDetailsFragment,
  useRoleApplicationsSection_ApplicationsListQuery,
} from 'src/operations/generated/graphql';

const DeleteIcon = Delete as unknown as React.FC;

interface IRoleDetails {
  id: string;
  name: string;
  roleApplications: RoleApplicationsSection_ApplicationInfoFragment[];
  rolePermissions: RoleDetailsSection_PermissionDetailsFragment[];
  rolePermissionGroups: RoleDetailsSection_PermissionGroupDetailsFragment[];
}

interface ISectionDetails {
  handleAddAppConfirmation: (
    appIds: RoleApplicationsSection_ApplicationInfoFragment[],
  ) => Promise<void>;
  handleRemoval: (appId: string) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  role: IRoleDetails;
}

enum PanelAction {
  ASSIGN_APPLICATION = 'ASSIGN_APPLICATION',
  MANAGE_PERMISSION = 'MANAGE_PERMISSION',
  MANAGE_PERMISSION_GROUP = 'MANAGE_PERMISSION_GROUP',
}

gql`
  fragment RoleApplicationsSection_ApplicationInfo on ApplicationProjection {
    id
    acronym
    name
    shortDescription
    slug
    isLive
    proxyLocation
  }
`;

gql`
  fragment RoleDetailsSection_PermissionDetails on PermissionProjection {
    id
    name
  }
`;

gql`
  fragment RoleDetailsSection_PermissionGroupDetails on PermissionGroupProjection {
    id
    name
  }
`;

gql`
  query RoleApplicationsSection_ApplicationsList {
    applications {
      ...LinkAppsSection_linkAppInfo
    }
  }
`;

export const RoleApplicationsSection: FC<ISectionDetails> = ({
  role,
  handleAddAppConfirmation,
  handleRemoval,
  // handlePermissionsConfirmation,
  loading,
  error,
  // target,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const [permissions, setPermissions] = useState<string[]>([]);
  const [permissionGroups, setPermissionGroups] = useState<string[]>([]);
  const [selectedApplication, setSelectedApplication] =
    useState<RoleApplicationsSection_ApplicationInfoFragment | null>();

  const [showModal, setShowModal] = useState(false);
  const [appName, setAppName] = useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const handleClose = () => {
    setShowSidePanel(null);
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    if (role.rolePermissions.length === 0) return;
    setPermissions(role.rolePermissions.map(permission => permission.id));
    if (role.rolePermissionGroups.length === 0) return;
    setPermissionGroups(
      role.rolePermissionGroups.map(permissionGroup => permissionGroup.id),
    );
  }, [role]);

  const [
    associatePermissionsToRoleMutation,
    {
      loading: associatePermissionsToRoleLoading,
      error: associatePermissionsToRoleError,
    },
  ] = useAssociatePermissionsToRoleMutation();

  const [
    associateGroupsToRoleMutation,
    {
      loading: associateGroupsToRoleLoading,
      error: associateGroupsToRoleError,
    },
  ] = useAssociateGroupsToRoleMutation();

  const [
    dissociateGroupsFromRoleMutation,
    {
      loading: dissociateGroupsFromRoleLoading,
      error: dissociateGroupsFromRoleError,
    },
  ] = useDissociateGroupsFromRoleMutation();

  const [
    dissociatePermissionsFromRoleMutation,
    {
      loading: dissociatePermissionsFromRoleLoading,
      error: dissociatePermissionsFromRoleError,
    },
  ] = useDissociatePermissionsFromRoleMutation();

  const handlePermissionsConfirmation = async (
    selectedPermissions: string[],
  ) => {
    if (selectedApplication) {
      const removed = permissions.filter((existingPermission: any) => {
        if (!selectedPermissions.includes(existingPermission)) {
          return existingPermission;
        }
      });
      try {
        if (selectedPermissions.length > 0) {
          await associatePermissionsToRoleMutation({
            variables: {
              input: {
                roleId: role.id,
                permissionIds: selectedPermissions,
              },
            },
          });
        }
        if (removed.length > 0) {
          await dissociatePermissionsFromRoleMutation({
            variables: {
              input: {
                roleId: role.id,
                permissionIds: removed,
              },
            },
          });
        }

        setPermissions(selectedPermissions);
      } catch (e) {}
    }
  };

  const handlePermissionGroupsConfirmation = async (
    selectedPermissionGroups: string[],
  ) => {
    if (selectedApplication) {
      const removed = permissionGroups.filter(
        (existingPermissionGroup: any) => {
          if (!selectedPermissionGroups.includes(existingPermissionGroup)) {
            return existingPermissionGroup;
          }
        },
      );
      try {
        if (selectedPermissionGroups.length > 0) {
          await associateGroupsToRoleMutation({
            variables: {
              input: {
                roleId: role.id,
                groupIds: selectedPermissionGroups,
              },
            },
          });
        }
        if (removed.length > 0) {
          await dissociateGroupsFromRoleMutation({
            variables: {
              input: {
                roleId: role.id,
                groupIds: removed,
              },
            },
          });
        }

        setPermissionGroups(selectedPermissionGroups);
      } catch (e) {}
    }
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.ASSIGN_APPLICATION:
        return (
          <LinkApplicationScreen
            target={{ id: role.id, displayName: role.name }}
            loading={loading}
            error={error}
            handleClose={handleClose}
            existingSelectedAppIds={role.roleApplications.map(app => app.id)}
            fullApplicationOptions={applicationOptions || []}
            handleConfirmation={handleAddAppConfirmation}
          />
        );
      case PanelAction.MANAGE_PERMISSION_GROUP:
        if (selectedApplication) {
          return (
            <PermissionSelection
              application={selectedApplication}
              handleClose={handleClose}
              existingPermissionsOrGroups={permissionGroups}
              loading={
                associateGroupsToRoleLoading || dissociateGroupsFromRoleLoading
              }
              error={
                associateGroupsToRoleError || dissociateGroupsFromRoleError
              }
              handleConfirmation={(selectedPermissionGroups: string[]) => {
                handlePermissionGroupsConfirmation(selectedPermissionGroups);
              }}
              target={{
                id: selectedApplication.id,
                displayName: selectedApplication?.name || '',
              }}
              type={SelectorType.PERMISSION_GROUP}
            />
          );
        }
        break;
      case PanelAction.MANAGE_PERMISSION:
        if (selectedApplication) {
          return (
            <PermissionSelection
              application={selectedApplication}
              handleClose={handleClose}
              existingPermissionsOrGroups={permissions}
              loading={
                associatePermissionsToRoleLoading ||
                dissociatePermissionsFromRoleLoading
              }
              error={
                associatePermissionsToRoleError ||
                dissociatePermissionsFromRoleError
              }
              handleConfirmation={(selectedPermissions: string[]) => {
                handlePermissionsConfirmation(selectedPermissions);
              }}
              target={{
                id: selectedApplication.id,
                displayName: selectedApplication?.name || '',
              }}
              type={SelectorType.PERMISSION}
            />
          );
        }
        break;
    }
  };

  const { data: { applications: applicationOptions = [] } = {} } =
    useRoleApplicationsSection_ApplicationsListQuery();

  const handleReset = () => {
    setShowModal(false);
    setSelectedApplication(null);
  };

  const popOverContent = (
    selectedApp: RoleApplicationsSection_ApplicationInfoFragment,
  ) => {
    return (
      <>
        <PopOverCard>
          <h5
            onClick={() => {
              handleClose();
              setShowSidePanel(PanelAction.MANAGE_PERMISSION);
            }}
          >
            Manage permissions
          </h5>
          <h5
            onClick={() => {
              handleClose();
              setShowSidePanel(PanelAction.MANAGE_PERMISSION_GROUP);
            }}
          >
            Manage permission groups
          </h5>
        </PopOverCard>
      </>
    );
  };

  return (
    <RoleSectionWrapper>
      {role && (
        <>
          <div className='heading-section'>
            <div>
              <h4 className='display-1 ln-u-margin-bottom*3'>
                Manage currently assigned apps
              </h4>
              <p>
                Add and remove apps assigned to the role, and manage the
                permissions and permission groups associated with them.
              </p>
            </div>
            <Button
              circle={false}
              disabled={false}
              hard={false}
              element='button'
              variant='filled'
              onClick={() => setShowSidePanel(PanelAction.ASSIGN_APPLICATION)}
            >
              Assign new app
            </Button>
          </div>
          <GridWrapper>
            {role.roleApplications.map(application => (
              <>
                <GridItem
                  size={{ md: '1/2', sm: '1/2', xs: '1/1' }}
                  className='app-grid-item'
                  key={application.id}
                >
                  <PermissionAppTile>
                    <AppTile
                      application={application}
                      ChevronSection={() => (
                        <Button
                          variant='text'
                          onClick={() => {
                            handleRemoval(application.id);
                          }}
                          color='dark'
                        >
                          <DeleteIcon />
                        </Button>
                      )}
                      ActionButtonSection={() => <></>}
                      DetailsSection={() => <></>}
                    />
                    <div className='manage-permission'>
                      <Button
                        aria-describedby={id}
                        variant='text'
                        onClick={(
                          event: React.MouseEvent<HTMLButtonElement>,
                        ) => {
                          setSelectedApplication(application);
                          handleClick(event);
                        }}
                        color='dark'
                        className='btn-manage-permission'
                      >
                        Manage permissions
                      </Button>
                      <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={() => {
                          setSelectedApplication(null);
                          handleClose();
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                      >
                        {popOverContent(application)}
                      </Popover>
                    </div>
                  </PermissionAppTile>
                </GridItem>
              </>
            ))}
          </GridWrapper>
          <Drawer
            open={showSidePanel !== null}
            onClose={handleClose}
            anchor='right'
            className='drawer-on-top edit-account-type'
          >
            {renderPanel()}
          </Drawer>
        </>
      )}

      <Modal
        restrictClose
        open={showModal}
        headingId='permission-delete-confirmation'
      >
        <ModalHeading
          element='h3'
          className='name-modal'
        >{`You're deleting the app "${selectedApplication?.name}". Please note this action can't be undone.`}</ModalHeading>
        You will lose permissions associated with this app.
        <TextInputField
          name='confirm-app-name-field'
          required='*'
          label='Please confirm app name'
          onChange={(e: any) => {
            setAppName(e.target.value);
          }}
        />
        <ButtonGroupWrapper className='ln-u-margin-left'>
          <ButtonGroupPrimary>
            <FilledButton
              className='ln-u-margin-right'
              disabled={appName !== selectedApplication?.name}
              onClick={async () => {
                if (selectedApplication) {
                  await handleRemoval(selectedApplication.id);
                  handleReset();
                }
              }}
            >
              Confirm
            </FilledButton>
            <OutlinedButton
              onClick={() => {
                handleReset();
              }}
            >
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </RoleSectionWrapper>
  );
};
