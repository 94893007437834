import React, { FC } from 'react';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { PopularHelpTopicsDiv } from './popularHelpTopics.styles';
import { PopularHelpTopics } from '@sainsburys-tech/suppex-popular-help-topics';
import { routes } from 'src/constants/routes';

export const PopularHelpTopicsSection: FC = () => {
  const helpList = [
    {
      title: 'Product',
      url: `${routes.supplierSupport}/product/`,
    },
    {
      title: 'Product Management App (PMA)',
      url: `${routes.supplierSupport}/product/product-management/`,
    },
    {
      title: 'Product withdrawals',
      url: `${routes.supplierSupport}/product/product-withdrawals/`,
    },
    {
      title: 'Navigating Together With',
      url: `${routes.supplierSupport}/together-with-apps-systems-or-websites/`,
    },
    {
      title: 'Supply Chain',
      url: `${routes.supplierSupport}/supply-chain/`,
    },
    {
      title: 'Finance',
      url: `${routes.supplierSupport}/finance/`,
    },
  ];
  return (
    <PopularHelpTopicsDiv>
      <SectionHeader
        heading='Popular help topics'
        subHeading='Get help on Together With, apps, T&C’s, GSCOP and more...'
      />

      <PopularHelpTopics
        title='title'
        subtitle='subtitle'
        seeAllHelpSupport='seeAllHelpSupport'
        list={helpList}
      />
    </PopularHelpTopicsDiv>
  );
};
