import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { List, ListItem } from '@jsluna/react';
import PropTypes from 'prop-types';
import { checkObjectValues } from 'src/utils/checkObjectValues';

interface ISubNavigationMenu {
  navigationSubMenuList: any;
  itemIndex: number;
  currentIndex: number;
  setCurrentIndex: (index: any, subSection: any) => void;
  setSubSection: (index: number) => void;
}

export const SubNavigationMenu: FC<ISubNavigationMenu> = ({
  navigationSubMenuList,
  itemIndex,
  setCurrentIndex,
  setSubSection,
  currentIndex,
}) => {
  const { subSection = '' } = useParams();

  const navigateToSubSection = (index: number) => {
    if (currentIndex !== itemIndex || parseInt(subSection, 10) !== index) {
      setCurrentIndex(itemIndex, index);
    }
    setSubSection(index);
  };

  return (
    <>
      {navigationSubMenuList[itemIndex] &&
        checkObjectValues(navigationSubMenuList[itemIndex].length >= 1) && (
          <List
            type='bare'
            className='t-side-menu-sub-nav-list ln-u-align-items-center ln-u-push-left-lg ln-u-font-weight-medium'
          >
            {checkObjectValues(navigationSubMenuList[itemIndex]).map(
              (item, index) => (
                <ListItem
                  className='t-side-menu-sub-nav-list__item cursor--pointer'
                  data-testid='sub-nav-list'
                  key={item}
                  onClick={() => navigateToSubSection(index + 1)}
                >
                  {item}
                </ListItem>
              ),
            )}
          </List>
        )}
    </>
  );
};
