import React, { FC, useEffect, useState } from 'react';
import { AddNewPermissionContainer } from './addNewPermissionToGroup.styles';
import { ProgressSpinner } from '@jsluna/react';

import { Button, MultiAutocompleteField } from '@jsluna/react';

import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { gql } from '@apollo/client';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

import {
  useAddNewPermissionToGroupScreen_PermissionByAppQuery,
  PermissionDeleteRow_PermissionInfoFragment,
  PermissionGroupComp_PermissionGroupFragment,
  useAssociatePermissionsToGroupMutation,
  AddNewPermissionToGroupScreen_PermissionInfoFragment,
} from 'src/operations/generated/graphql';

interface IAddNewPermissionToGroupScreen {
  permissionGroup: PermissionGroupComp_PermissionGroupFragment;
  applicationId: string;
  closeHandler: (
    permissions: PermissionDeleteRow_PermissionInfoFragment[],
    stayOpen?: boolean,
  ) => void;
}

interface IPermissionOption {
  value: AddNewPermissionToGroupScreen_PermissionInfoFragment;
  label: string;
}
gql`
  fragment AddNewPermissionToGroupScreen_PermissionInfo on PermissionProjection {
    id
    name
  }
`;
gql`
  query AddNewPermissionToGroupScreen_PermissionByApp($applicationId: String!) {
    applicationPermissions(applicationId: $applicationId) {
      ...AddNewPermissionToGroupScreen_PermissionInfo
    }
  }
`;

export const AddNewPermissionToGroupScreen: FC<
  IAddNewPermissionToGroupScreen
> = ({ permissionGroup, closeHandler, applicationId }) => {
  const [showFeedBack, setShowFeedback] = useState<string>('');
  const [feedBackMessage, setFeedBackMessage] = useState<string>('');
  const [selectedPermissions, setSelectedPermissions] = useState<
    IPermissionOption[]
  >([]);
  const [permissionOption, setPermissionOption] =
    useState<IPermissionOption[]>();

  const { data: { applicationPermissions = null } = {} } =
    useAddNewPermissionToGroupScreen_PermissionByAppQuery({
      variables: { applicationId },
    });

  const [mutate, { loading: addingLoading }] =
    useAssociatePermissionsToGroupMutation();

  const handleSelect = (selected: IPermissionOption[]) => {
    setSelectedPermissions(selected);
  };

  useEffect(() => {
    const existingPermissionIds = permissionGroup.permissions.map(
      permission => permission.value.id,
    );
    const options = applicationPermissions?.reduce(
      (acc: IPermissionOption[], permission) => {
        if (existingPermissionIds.includes(permission.id)) return acc;
        return [...acc, { label: permission.name || '', value: permission }];
      },
      [],
    );

    setPermissionOption(options);
  }, [applicationPermissions]);

  const handleAddPermission = async () => {
    let ids = selectedPermissions.map(({ value }) => value.id);
    try {
      const addedId = await mutate({
        variables: {
          input: {
            permissionIds: ids,
            groupId: permissionGroup.id,
            applicationId: permissionGroup.applicationId || '',
          },
        },
      });
      if (addedId?.data?.associatePermissionsToGroup) {
        setShowFeedback('added');
        setFeedBackMessage(
          `You’ve added new permission to ${permissionGroup.name}`,
        );
        const optimisticOptions = permissionOption?.filter(
          (option: IPermissionOption) => !ids.includes(option.value.id),
        );
        setPermissionOption(optimisticOptions);
      }
    } catch (error) {
      setShowFeedback('failed');
      setFeedBackMessage(`Permission couldn't be added`);
    }
  };

  const handleReset = () => {
    const permissions = selectedPermissions.map(
      (option: IPermissionOption) => option.value,
    );
    closeHandler(permissions, true);
    setSelectedPermissions([]);
    setShowFeedback('');
  };

  return (
    <>
      <AddNewPermissionContainer>
        {showFeedBack !== '' ? (
          <ScreenFeedback
            isLoading={addingLoading}
            notificationType={
              showFeedBack === 'added'
                ? NotificationType.SUCCESS
                : NotificationType.FAILURE
            }
            feedBackMessage={feedBackMessage}
            notificationMessage={
              showFeedBack === 'added'
                ? 'Permission added'
                : 'Something went wrong'
            }
            saveButtonText={'Save and close'}
            onCloseHandler={() => {
              const permissions = selectedPermissions.map(
                (option: IPermissionOption) => option.value,
              );
              closeHandler(permissions);
            }}
            resetButtonText={
              showFeedBack === 'added' ? 'Add more permission' : 'Try again'
            }
            resetHandler={handleReset}
          />
        ) : (
          <>
            <h4>Add permission to group "{permissionGroup.name}"</h4>

            <div className='panel-body'>
              {permissionOption?.length && (
                <MultiAutocompleteField
                  name='multi-autocomplete-field-1'
                  label={`Add a permissions to your ${permissionGroup.name}`}
                  options={permissionOption}
                  placeholder='Search for a permission'
                  onSelect={handleSelect}
                />
              )}
            </div>
          </>
        )}
      </AddNewPermissionContainer>
      {showFeedBack === '' && (
        <PanelActions>
          <Button
            type='submit'
            variant='filled'
            disabled={addingLoading || selectedPermissions?.length === 0}
            onClick={handleAddPermission}
          >
            {addingLoading && <ProgressSpinner />}
            {addingLoading ? 'Adding' : 'Add permission'}
            Add permission
          </Button>
          <Button
            onClick={() => {
              closeHandler([]);
            }}
            variant='outlined'
          >
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
