import React, { FC } from 'react';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { NotificationType } from 'src/enums/notificationTypes.enum';

interface IAccountFeedback {
  type: NotificationType;
  onClose: () => void;
}

export const AccountFeedback: FC<IAccountFeedback> = ({ type, onClose }) => {
  return (
    <NotificationBanner
      loading={false}
      messageType={type}
      message={
        type === NotificationType.FAILURE
          ? 'Error in deleting account'
          : 'Account deleted successfully'
      }
      onClose={onClose}
    />
  );
};
