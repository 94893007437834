import React, { FC } from 'react';
import { StatDiv } from './statCard.styles';
import { Card } from '@jsluna/react';
import { IServiceHealth } from '../..';
import { StatCardLoader } from '../StatCardLoader';

interface IStatCard {
  healthInfo: IServiceHealth;
  serviceName: string;
}

export const StatCard: FC<IStatCard> = ({ healthInfo, serviceName }) => {
  return (
    <StatDiv
      className={`${healthInfo.isLoading ? 'loading' : healthInfo?.status}`}
    >
      {healthInfo.isLoading ? (
        <StatCardLoader serviceName={serviceName} />
      ) : (
        <Card
          className={`ln-u-margin-bottom*2 ${
            healthInfo.status === 'up' ? 'active' : 'inactive'
          }`}
        >
          <h5>{serviceName}</h5>
          <div
            className={`badge ${healthInfo.status === 'ok' ? 'ok' : 'not-ok'}`}
          >
            <span>
              {healthInfo.status === 'ok' ? 'Operational' : 'Non-operational'}
            </span>
          </div>

          <div className='service-list'>
            {healthInfo.info &&
              Object.entries(healthInfo.info).map(
                ([serviceName, serviceInfo]) => (
                  <div
                    key={serviceName}
                    className={`service ${
                      serviceInfo.status === 'up' ? 'ok' : 'not-ok'
                    }`}
                  >
                    {serviceName}
                  </div>
                ),
              )}
          </div>
        </Card>
      )}
    </StatDiv>
  );
};
