import styled from 'styled-components';
import { Card } from '@jsluna/react';
import { ThemeColors } from 'src/shared/styles/variables';

export const FavAppsCard = styled(Card)`
  padding 0;
  padding-bottom: var(--ds-space-spacing-x1);
  padding-top: var(--ds-space-spacing-x1);
  margin-bottom: 32px;

  .edit-app-group-btn {
    text-decoration: underline;
    &:hover {
      color: ${ThemeColors.primary} !important;
      background-color: transparent !important;
    }
    &:focus {
      color: ${ThemeColors.primary} !important;
      background-color: transparent !important;
    }
  }
`;

export const FavAppsSection = styled('div')`
  .heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 12px 8px;
      line-height: 27px;
    }

    h4 {
      margin-bottom: 0;
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      color: ${ThemeColors.monochromeDarker};
    }

    margin-bottom: 48px;
  }
`;

export const CreateAppSection = styled('div')`
  display: flex;
  justify-content: space-between;
`;
