import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';
import { phoneRegExp } from 'src/constants/regExValidations';
import { SELECT_OPTION_FROM_DROPDOWN } from 'src/constants/genericValidationMessages';
import * as Yup from 'yup';
import { countries } from 'countries-list';

export default Yup.object().shape(
  {
    title: Yup.string().required(InviteProfileMessages.title.required),
    firstName: Yup.string()
      .required(InviteProfileMessages.firstName.required)
      .min(2, `${InviteProfileMessages.firstName.min} 2`)
      .max(35, `${InviteProfileMessages.firstName.max} 35`),
    lastName: Yup.string()
      .required(InviteProfileMessages.lastName.required)
      .min(2, `${InviteProfileMessages.lastName.min} 2`)
      .max(35, `${InviteProfileMessages.lastName.max} 35`),
    preferredName: Yup.string().when('preferredName', (value, schema) => {
      if (value?.length > 0) {
        return Yup.string()
          .min(2, `${InviteProfileMessages.preferredName.min} 2`)
          .max(35, `${InviteProfileMessages.preferredName.max} 35`);
      } else {
        return Yup.string().notRequired();
      }
    }),
    emailAddress: Yup.string()
      .email(InviteProfileMessages.emailAddress.valid)
      .required(InviteProfileMessages.emailAddress.required),
    confirmEmailAddress: Yup.string()
      .oneOf(
        [Yup.ref('emailAddress'), null],
        InviteProfileMessages.confirmEmailAddress.match,
      )
      .required(InviteProfileMessages.confirmEmailAddress.required),
    countryCode: Yup.string()
      .oneOf(Object.keys(countries), SELECT_OPTION_FROM_DROPDOWN)
      .required(InviteProfileMessages.countryCode.required),
    contactNumber: Yup.string()
      .matches(phoneRegExp, InviteProfileMessages.contactNumber.valid)
      .required(InviteProfileMessages.contactNumber.required),
    name: Yup.string().required(InviteProfileMessages.name.required),
    jobTitle: Yup.string().required(InviteProfileMessages.jobTitle.required),
    accountTypeId: Yup.string().required(
      InviteProfileMessages.accountTypeId.required,
    ),
    siteId: Yup.string(), //.required(InviteProfileMessages.siteId.required),
    autoInvite: Yup.boolean(),
    isPartner: Yup.string().required(InviteProfileMessages.isPartner.required),
    roles: Yup.array().of(Yup.string()),
    tags: Yup.array()
      .of(Yup.string())
      .min(1, InviteProfileMessages.tagIds.required)
      .required(InviteProfileMessages.tagIds.required),
    partnerName: Yup.string().when('isPartner', {
      is: 'yes',
      then: Yup.string().required(InviteProfileMessages.partnerName.required),
    }),
    partnerContact: Yup.string().when('isPartner', {
      is: 'yes',
      then: Yup.string().required(
        InviteProfileMessages.partnerContact.required,
      ),
    }),
  },
  [
    ['isPartner', 'isPartner'],
    ['preferredName', 'preferredName'],
  ],
);

export const createInviteProfileSchema = (options: string[]) => {
  return Yup.object().shape(
    {
      title: Yup.string().required(InviteProfileMessages.title.required),
      firstName: Yup.string()
        .required(InviteProfileMessages.firstName.required)
        .min(2, `${InviteProfileMessages.firstName.min} 2`)
        .max(35, `${InviteProfileMessages.firstName.max} 35`),
      lastName: Yup.string()
        .required(InviteProfileMessages.lastName.required)
        .min(2, `${InviteProfileMessages.lastName.min} 2`)
        .max(35, `${InviteProfileMessages.lastName.max} 35`),
      preferredName: Yup.string().when('preferredName', (value, schema) => {
        if (value?.length > 0) {
          return Yup.string()
            .min(2, `${InviteProfileMessages.preferredName.min} 2`)
            .max(35, `${InviteProfileMessages.preferredName.max} 35`);
        } else {
          return Yup.string().notRequired();
        }
      }),
      emailAddress: Yup.string()
        .email(InviteProfileMessages.emailAddress.valid)
        .required(InviteProfileMessages.emailAddress.required),
      confirmEmailAddress: Yup.string()
        .oneOf(
          [Yup.ref('emailAddress'), null],
          InviteProfileMessages.confirmEmailAddress.match,
        )
        .required(InviteProfileMessages.confirmEmailAddress.required),
      countryCode: Yup.string()
        .oneOf(Object.keys(countries), SELECT_OPTION_FROM_DROPDOWN)
        .required(InviteProfileMessages.countryCode.required),
      contactNumber: Yup.string()
        .matches(phoneRegExp, InviteProfileMessages.contactNumber.valid)
        .required(InviteProfileMessages.contactNumber.required),
      name: Yup.string().required(InviteProfileMessages.name.required),
      jobTitle: Yup.string().required(InviteProfileMessages.jobTitle.required),
      accountTypeId: Yup.string().required(InviteProfileMessages.name.required),
      siteId: Yup.string(), //.required(InviteProfileMessages.siteId.required),
      autoInvite: Yup.boolean(),
      isPartner: Yup.string().required(
        InviteProfileMessages.isPartner.required,
      ),
      roles: Yup.array().of(Yup.string()),
      tags: Yup.array()
        .of(Yup.string())
        .when('options', {
          is: (options: string[]) => options.length > 0,
          then: Yup.array()
            .min(1, InviteProfileMessages.tagIds.required)
            .required(InviteProfileMessages.tagIds.required),
        }),
      options: Yup.array().of(Yup.string()).default(options),

      partnerName: Yup.string().when('isPartner', {
        is: 'yes',
        then: Yup.string().required(InviteProfileMessages.partnerName.required),
      }),
      partnerContact: Yup.string().when('isPartner', {
        is: 'yes',
        then: Yup.string().required(
          InviteProfileMessages.partnerContact.required,
        ),
      }),
    },
    [
      ['isPartner', 'isPartner'],
      ['preferredName', 'preferredName'],
    ],
  );
};
