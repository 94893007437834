export const actions = {
  SET_INITIAL_DATA: 'SET_INITIAL_DATA',
  SET_MAPPED_APPLICATIONS: 'SET_MAPPED_APPLICATIONS',
  SET_FILTERED_APPLICATIONS: 'SET_FILTERED_APPLICATIONS',
  SET_SHOW_ALL_APPS: 'SET_SHOW_ALL_APPS',
  SHOW_REQUEST_PERMISSION_MODAL: 'SHOW_REQUEST_PERMISSION_MODAL',
  HIDE_REQUEST_PERMISSION_MODAL: 'HIDE_REQUEST_PERMISSION_MODAL',
  SHOW_ASSIGN_GROUP_MODAL: 'SHOW_ASSIGN_GROUP_MODAL',
  HIDE_ASSIGN_GROUP_MODAL: 'HIDE_ASSIGN_GROUP_MODAL',
  SET_SELECTED_APPLICATIONS: 'SET_SELECTED_APPLICATIONS',
  SET_TAGS: 'SET_TAGS',
  OPEN_REQUEST_PERMISSION_MODAL: 'OPEN_REQUEST_PERMISSION_MODAL',
  OPEN_ASSIGN_GROUP_MODAL: 'OPEN_ASSIGN_GROUP_MODAL',
  OPEN_EDIT_APP_GROUP_PANEL: 'OPEN_EDIT_APP_GROUP_PANEL',
  TOGGLE_REFETCH_MY_APPS_GROUP: 'TOGGLE_REFETCH_MY_APPS_GROUP',
  SHOW_ADD_NEW_APP_GROUP: 'SHOW_ADD_NEW_APP_GROUP',
  HIDE_ADD_NEW_APP_GROUP: 'HIDE_ADD_NEW_APP_GROUP',
};
