import React, { FC } from 'react';
import {
  GridWrapper,
  GridItem,
  Container,
  List,
  ListItem,
  Button,
} from '@jsluna/react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constants/routes';

export const LoginRegisterPanel: FC = () => {
  const navigate = useNavigate();

  return (
    <GridWrapper className='not-logged-in-news ln-u-padding-bottom*4'>
      <Container soft className='ln-u-soft-top'>
        <GridItem className='ln-u-soft-top'>
          <h2>Latest news</h2>
          <div className='ln-u-bg-color-white ln-u-soft-top ln-u-soft-bottom'>
            <GridItem className='ln-u-text-align-center ln-u-soft-top ln-u-soft-bottom ln-u-soft-right'>
              <h3 className='ln-u-margin-bottom ln-u-font-size-display-4'>
                Sign in or register to read the latest supplier news
              </h3>
              <p className='ln-u-margin-bottom*4 ln-u-font-size-body-2'>
                To get unlimited access to news you should be in the process of,
                or already are a registered Sainsbury's food supplier
              </p>
              <List type='inline' className='ln-u-padding-top*3'>
                <ListItem className='logged-out-button-wrapper'>
                  <Button
                    variant='filled'
                    onClick={() => navigate(routes.root)}
                    fullWidth
                  >
                    Sign in
                  </Button>
                </ListItem>
                {/* <ListItem className='logged-out-button-wrapper'>
                  <Button
                    variant='outlined'
                    color='dark'
                    onClick={() => navigate('/')}
                    fullWidth
                  >
                    Register
                  </Button>
                </ListItem> */}
              </List>
            </GridItem>
          </div>
        </GridItem>
      </Container>
    </GridWrapper>
  );
};
