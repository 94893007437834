import React, { FC, useEffect, useState } from 'react';
import { EditAppGroupContainer } from './linkApplications.styles';
import { AutocompleteField } from '@jsluna/react';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';
import { Delete } from '@jsluna/icons';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { LinkAppsSection_LinkAppInfoFragment } from 'src/operations/generated/graphql';
import { A } from 'ts-toolbelt';

const DeleteIcon = Delete as unknown as React.FC;

interface IOption {
  label: string;
  value: string;
  application: LinkAppsSection_LinkAppInfoFragment;
}

interface ILinkApplicationScreen {
  handleClose: () => void;
  existingSelectedAppIds: string[];
  fullApplicationOptions: LinkAppsSection_LinkAppInfoFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (
    apps: LinkAppsSection_LinkAppInfoFragment[],
  ) => Promise<void>;
  target: { id: string; displayName: string };
}

export const LinkApplicationScreen: FC<ILinkApplicationScreen> = ({
  handleConfirmation,
  existingSelectedAppIds,
  handleClose,
  fullApplicationOptions,
  error,
  target,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [appSearchOptions, setAppsSearchOptions] = useState<IOption[]>([]);
  const [selectedApps, setSelectedApps] = useState<
    LinkAppsSection_LinkAppInfoFragment[]
  >([]);

  useEffect(() => {
    const selectedApplicationIds = selectedApps.map(
      application => application.id,
    );
    const filteredApplicationOptions = fullApplicationOptions.reduce<IOption[]>(
      (acc, application) => {
        if (
          existingSelectedAppIds.includes(application.id) ||
          selectedApplicationIds.includes(application.id)
        )
          return acc;
        return [
          ...acc,
          {
            application,
            label: application.name || '',
            value: application.id,
          },
        ];
      },
      [],
    );
    setAppsSearchOptions(filteredApplicationOptions);
  }, [fullApplicationOptions, selectedApps]);

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleSelectSearch = (selected: IOption) => {
    if (selected) {
      const { value, application } = selected;
      setSelectedApps([...selectedApps, application]);
    }
  };
  const handleRemoveSelection = (appId: string) => {
    const filteredSelectedApps = selectedApps.filter(
      application => application.id !== appId,
    );
    setSelectedApps(filteredSelectedApps);
  };

  const handleReset = () => {
    setSelectedApps([]);
    setFeedBackMessage(null);
  };

  return (
    <>
      <EditAppGroupContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={false}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${
                      target.id.includes('AT_')
                        ? `The selected apps have been linked to the account type ${target.displayName}`
                        : 'The selected apps have been successfully linked to your new app'
                    }`
                  : `Failed to link selected apps to your ${target.displayName}.`
              }
              saveButtonText={'Save and close'}
              onCloseHandler={handleClose}
              resetButtonText={'Further changes'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${
                      target.id.includes('AT_')
                        ? 'Account type updated'
                        : 'Apps linked'
                    }`
                  : 'Failed to link application'
              }
            />
          </>
        ) : (
          <>
            <h3>
              {target.id.includes('AT_')
                ? 'Link apps to account type'
                : 'Link an existing app to your new app'}
            </h3>

            <div className='tab-heading'>
              {target.id.includes('AT_')
                ? 'Search apps'
                : 'Select the apps you want to link'}
            </div>
            {appSearchOptions.length > 0 && (
              <AutocompleteField
                name='search-apps-to-add'
                info='e.g. PMA'
                label='search apps'
                options={appSearchOptions}
                role='search'
                hideLabel
                onSelect={(selected: any) => {
                  handleSelectSearch(selected);
                }}
              />
            )}
            <div className='panel-body'>
              {selectedApps.length > 0 && (
                <h3>Apps being linked to new {target.displayName}</h3>
              )}
              {selectedApps.map(application => (
                <div key={application.id}>
                  <AppTile
                    application={application}
                    requestAccessLabel={'delete'}
                    ChevronSection={() => (
                      <Button
                        variant='text'
                        onClick={() => {
                          handleRemoveSelection(application.id);
                        }}
                        color='dark'
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                    ActionButtonSection={() => <></>}
                    DetailsSection={() => <></>}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </EditAppGroupContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={() => {
              handleConfirmation(selectedApps);
              setFeedBackMessage(NotificationType.SUCCESS);
            }}
            variant='filled'
            disabled={selectedApps.length === 0}
          >
            Link apps
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
