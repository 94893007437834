import React, { FC, useEffect } from 'react';
import { Button, Modal, ModalHeading } from '@jsluna/react';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ApolloError, gql } from '@apollo/client';
import { DeleteTagFragment } from 'src/operations/generated/graphql';

gql`
  fragment DeleteTag on TagProjection {
    id
    name
  }
`;

interface IFeedback {
  notificationFor: 'DELETE';
  type: NotificationType;
}
interface IDeleteTag {
  isOpen: boolean;
  handleConfirmation: (selectedTag: DeleteTagFragment) => Promise<void>;
  setFeedBack: (status: IFeedback) => void | null;
  setShowDeleteBox: (status: string | null) => void;
  selectedTag: DeleteTagFragment;
  loading: boolean;
  error: ApolloError | undefined;
}

export const DeleteTag: FC<IDeleteTag> = ({
  isOpen,
  selectedTag,
  setFeedBack,
  setShowDeleteBox,
  handleConfirmation,
  error,
}) => {
  useEffect(() => {
    if (error) {
      setFeedBack({
        type: NotificationType.FAILURE,
        notificationFor: 'DELETE',
      });
    }
  }, [error]);

  const handleSubmission = async () => {
    await handleConfirmation(selectedTag);
    setFeedBack({ type: NotificationType.SUCCESS, notificationFor: 'DELETE' });
  };

  return (
    <Modal
      small
      handleClose={() => setShowDeleteBox(null)}
      open={isOpen}
      headingId='info-modal'
    >
      <ModalHeading element='h3'>
        Are you sure you want to delete ‘{selectedTag.name}’? This action cannot
        be undone.
      </ModalHeading>

      <p>
        If this tag is deleted, no information relating to this tag will be sent
        to users who selected it, and all users will lose the ability to filter
        apps via this tag.
      </p>

      <div className='display-flex'>
        <Button
          variant='filled'
          className='ln-u-margin-right*3'
          onClick={() => handleSubmission()}
        >
          Confirm
        </Button>
        <Button
          variant='outlined'
          onClick={() => {
            setShowDeleteBox(null);
          }}
        >
          Cancel
        </Button>
      </div>
    </Modal>
  );
};
