import { RootState } from './store';

export const getProfileDetails = (state: RootState) => {
  return state.globalState.profile;
};

export const getProfileAccounts = (state: RootState) =>
  state.globalState.profileAccounts;

export const getSelectedAccount = (state: RootState) =>
  state.globalState.selectedAccount;

export const getNotifications = (state: RootState) =>
  state.globalState.notifications;

export const getNotificationCount = (state: RootState) =>
  state.globalState.notifications.length;

export const getIsLoggedIn = (state: RootState) => state.globalState.isLoggedIn;

export const getFeedBack = (state: RootState) => state.globalState.feedback;
