import React, { FC, useState } from 'react';
import Container from 'src/components/Container/Container';
import { NavLink } from 'react-router-dom';
import {
  Card,
  BreadcrumbsWrapper,
  BreadcrumbsItem,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  OutlinedButton,
  FilledButton,
} from '@jsluna/react';
import { InviteProfileForm } from './components/inviteProfileForm/inviteProfileForm';
import { IRequestProfile } from '@interfaces/requestProfile.interface';
import { useLocation } from 'react-router-dom';
import { routes } from 'src/constants/routes';
import { useNavigate } from 'react-router';

import {
  useRequestProfileMutation,
  useRequestAccountMutation,
} from 'src/operations/generated/graphql';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { PageHeader } from 'src/components/PageHeader';
import { ErrorPage } from 'src/shared/components/errorPage';

const getHeading = (pathname: string) => {
  if (pathname === routes.inviteProfile) return 'Invitation form';
  if (pathname === routes.createProfile) return 'Create new Profile';
  return 'Register For Access';
};

const getSubHeading = (pathname: string) => {
  if (pathname === routes.inviteProfile)
    return 'Invite other people into Together With';
  if (pathname === routes.createProfile) return '';
  return '';
};

const getButtonText = (isLoggedIn: boolean) => {
  return isLoggedIn ? 'Request Profile' : 'Request Access';
};

const isAutoInvite = (pathname: string) => {
  return (
    pathname === routes.inviteProfile || pathname === routes.registerForAccess
  );
};

export const InviteProfile: FC = () => {
  let location = useLocation();
  let navigate = useNavigate();
  const [showCompletion, setCompletion] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [requestProfileMutation, { error, loading }] =
    useRequestProfileMutation();
  const [
    requestAccountMutation,
    { error: accountError, loading: accountLoading },
  ] = useRequestAccountMutation();

  const handleProfileCreate = async (values: IRequestProfile) => {
    setInitialValues(values);

    try {
      const profileID = await requestProfileMutation({
        variables: {
          profile: {
            firstName: values.firstName,
            lastName: values.lastName,
            preferredName: values.preferredName ? values.preferredName : null,
            emailAddress: values.emailAddress,
            countryCode: values.countryCode,
            contactNumber: values.contactNumber,
            title: values.title,
            autoInvite: values.autoInvite,
          },
        },
      });

      if (profileID?.data?.requestProfile?.id) {
        const isPartner = values.isPartner === 'yes';
        await requestAccountMutation({
          variables: {
            account: {
              name: values.name,
              jobTitle: values.jobTitle,
              accountTypeId: values.accountTypeId,
              profileId: profileID.data.requestProfile.id,
              siteId: values.siteId,
              email: values.emailAddress,
              isPartner,
              partnerName: isPartner ? values.partnerName : '',
              partnerContact: isPartner ? values.partnerContact : '',
              roleIds: values.roles,
              tagIds: values.tags,
            },
          },
        });
      }
    } catch (error) {}
    setCompletion(true);
  };
  const pathname = location?.pathname;
  const isLoggedIn =
    pathname === routes.inviteProfile || pathname === routes.createProfile;
  const heading = getHeading(pathname);
  const subHeading = getSubHeading(pathname);
  const buttonText = getButtonText(isLoggedIn);
  const autoInvite = isAutoInvite(pathname);

  const showErrorPage = false;

  return (
    <div>
      <PageHeader
        heading={heading}
        subHeading={subHeading}
        cssClass={'no-mb'}
        breadcrumbLinks={[
          {
            name: 'Together with',
            link: '/',
          },
          {
            name: heading,
            link: '',
          },
        ]}
      />
      <Container>
        {showErrorPage ? (
          <ErrorPage>
            <p>
              We are experiencing technical difficulties with new registrations
              and we are working to fix this as soon as possible. If your query
              is urgent, please contact:{' '}
              <a href='mailto:togetherwith@sainsburys.co.uk'>
                togetherwith@sainsburys.co.uk
              </a>
            </p>
          </ErrorPage>
        ) : (
          <>
            {showCompletion === false ? (
              <>
                {pathname === routes.inviteProfile && <h3>Invitee details</h3>}
                <InviteProfileForm
                  initialValue={initialValues}
                  onSubmit={handleProfileCreate}
                  loading={loading || accountLoading}
                  buttonText={buttonText}
                  autoInvite={autoInvite}
                />
              </>
            ) : (
              <>
                {error || accountError ? (
                  <>
                    <NotificationBanner
                      message={'Request failed'}
                      messageType={NotificationType.FAILURE}
                      loading={false}
                    />
                    <Container>
                      <Card>
                        <h4>Your account request has failed.</h4>
                        Something has gone wrong, please try again. If the issue
                        persists, please contact us at
                        together.with@sainsburys.co.uk
                        <ButtonGroupWrapper>
                          <ButtonGroupSecondary>
                            <FilledButton
                              className='ln-u-margin-right'
                              onClick={() => {
                                navigate(routes.root);
                              }}
                            >
                              Go to homepage
                            </FilledButton>
                            <OutlinedButton
                              onClick={() => {
                                setCompletion(false);
                              }}
                            >
                              try again
                            </OutlinedButton>
                          </ButtonGroupSecondary>
                        </ButtonGroupWrapper>
                      </Card>
                    </Container>
                  </>
                ) : (
                  <>
                    <div className='feedback-zone'>
                      <NotificationBanner
                        message={'Request submitted'}
                        messageType={NotificationType.SUCCESS}
                        loading={false}
                      />
                    </div>
                    <Container>
                      <Card>
                        <h4>Your account request has been submitted.</h4>
                        We aim to review all account requests within 48 hours.
                        <ButtonGroupWrapper>
                          <ButtonGroupSecondary>
                            <FilledButton
                              className='ln-u-margin-right'
                              onClick={() => {
                                navigate(routes.root);
                              }}
                            >
                              Go to homepage
                            </FilledButton>
                            {isLoggedIn && (
                              <OutlinedButton
                                onClick={() => {
                                  setInitialValues({});
                                  setCompletion(false);
                                }}
                              >
                                Invite another user
                              </OutlinedButton>
                            )}
                          </ButtonGroupSecondary>
                        </ButtonGroupWrapper>
                      </Card>
                    </Container>
                  </>
                )}
              </>
            )}
          </>
        )}
      </Container>
    </div>
  );
};
