import { FC } from 'react';
import { Plus, Edit, Cancel } from '@jsluna/icons';
import { IconButton } from '@jsluna/react';
import { UpdateEmailButton } from '../profileCard.styles';

const EditIcon = Edit as unknown as React.FC;
const CancelIcon = Cancel as unknown as React.FC;
const PlusIcon = Plus as unknown as React.FC;

interface IChangeEmailLink {
  onClick: () => void;
  showCancel: boolean;
}

export const ChangeEmailLink: FC<IChangeEmailLink> = ({
  onClick,
  showCancel,
}) => {
  return (
    <UpdateEmailButton
      variant='text'
      circle={false}
      disabled={false}
      hard={false}
      element='button'
      label='Update email'
      hideLabel
      onClick={onClick}
    >
      {showCancel ? <CancelIcon /> : <>Edit Email</>}
    </UpdateEmailButton>
  );
};
