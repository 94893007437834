import React, { FC, ReactNode } from 'react';
import { GridItem, GridWrapper, Button } from '@jsluna/react';
import { IconProps } from 'src/interfaces/icon.interface';
import { Cancel } from '@jsluna/icons';
import { NotificationWrapper } from './notifications.style';

interface INotifications {
  children: ReactNode;
  onClose?: () => void;
  styleType: string;
}

const CancelIcon = Cancel as unknown as React.FC<IconProps>;
export const Notifications: FC<INotifications> = ({
  children,
  onClose,
  styleType,
}) => (
  <NotificationWrapper>
    <div className={`notifications ${styleType ? styleType : ''}`}>
      <div
        className={`notifications__item ${
          onClose ? '' : ' ln-u-padding-right*2'
        }`}
      >
        <div className='notifications__item-message'>{children}</div>
        {onClose && (
          <Button
            className='notifications__item-close'
            title='Close'
            role='button'
            onClick={onClose}
            data-cy={'close-modal'}
          >
            <CancelIcon aria-label='close' />
          </Button>
        )}
      </div>
    </div>
  </NotificationWrapper>
);
