import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_PROMOTED_CONTENT_QUERY = gql`
  query {
    supplierCode
      @rest(
        type: "systemAlert"
        path: "content-projection/contentStack/promotedContent"
      ) {
      description
      title
      url
      url_button_name
    }
  }
`;

export const useGetSystemAlerts = () => {
  return useQuery(GET_PROMOTED_CONTENT_QUERY, {
    context: { clientName: EndPoint.PPS_REST_ENDPOINT },
  });
};
