import styled from 'styled-components';

export const ProfileHeaderContainer = styled('div')`
  display: flex;
  vertical-align: middle;
  align-items: center;
  margin-top: 20px;

  .profile-details {
    margin-left: 20px;
  }

  .contact-info {
    display: flex;
    align-items: center;

    .contact-value {
      margin-bottom: 0;
      margin-left: 10px;
      font-size: 18px;
    }
  }
`;

export const ProfileImageContainer = styled('div')`
  width: 200px;
  height: 200px;
  position: relative;
`;
export const ProfilePageWrapper = styled('div')`
  .my-tags-card {
    position: relative;
    margin-top: 20px;
  }

  .tags-container {
    display: flex;

    .tag {
      border-radius: 22px;
      color: #fff;
      background-color: #f06c00;
      margin-right: 10px;
      text-transform: uppercase;
      padding: 10px 15px;
      font-weight: bold;

      &.add-tag {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        svg {
          margin-right: 10px;
        }
      }
    }
  }
`;
export const ProfileImage = styled('div')`
  background-color: #8d8d8d;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;
