import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';
import ContentLoader from 'react-content-loader';

export const AddTagsContainer = styled('div')`
  min-width: 450px;
  min-height: 50vh;

  .panel-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${ThemeColors.black};
    margin-bottom: 18px;
    margin-top: 24px;
  }

  .no-tags-to-select {
  }

  .existing-tags-container {
    h5 {
      font-size: 18px;
    }
    .existing-tags {
      display: flex;
      flex-wrap: wrap;

      .tag {
        padding: 3px 13px;
        border-radius: 25px;
        background-color: #e55100;
        color: #fff;
        margin-bottom: 12px;
      }
    }
  }
`;

export const TagLoader = styled((props: any) => <ContentLoader {...props} />)`
  margin: 20px;
`;

export const PanelActions = styled('div')`
  border-top: 1px solid ${ThemeColors.chbBackground};
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding-top: 24px;

  Button {
    width: calc((100% - 18px) / 2);
    line-height: initial;
    text-align: center;
  }
  .delete-section {
    flex: 0 0 100%;
    text-align: right;
  }
  .ln-c-button--text {
    padding: 0;
    text-decoration: underline;

    &:hover {
      background-color: transparent;
      color: ${ThemeColors.monochromeDark};
    }
  }
  &.no-gap {
    margin: 0 -24px;
  }
`;
