import React, { FC } from 'react';
import { FeedbackCardWrapper } from './feedbackCard.styles';
import Container from 'src/components/Container/Container';
import { Card, Button } from '@jsluna/react';
import { useNavigate } from 'react-router-dom';

interface IFeedbackCard {
  title: string;
  message: string;
  buttonText: string;
  goToLink: string;
  subHeading?: string;
  buttonClickHandler?: () => void;
}

export const FeedbackCard: FC<IFeedbackCard> = ({
  title,
  message,
  buttonText,
  goToLink,
  subHeading,
  buttonClickHandler,
}) => {
  const navigate = useNavigate();
  return (
    <FeedbackCardWrapper>
      <Container>
        <Card>
          <div className='feedback-title'>{title}</div>
          {subHeading && <h3>{subHeading}</h3>}

          <div className='feedback-message'>{message}</div>
          <Button
            onClick={() => {
              buttonClickHandler ? buttonClickHandler() : navigate(goToLink);
            }}
            variant='filled'
          >
            {buttonText}
          </Button>
        </Card>
      </Container>
    </FeedbackCardWrapper>
  );
};
