import React, { FC } from 'react';
import { TagContainer } from './tag.styles';
import { Cancel } from '@jsluna/icons';
import { IconButton, ProgressSpinner } from '@jsluna/react';
import { TagSelection_TagsFragment } from 'src/operations/generated/graphql';
const CancelIcon = Cancel as unknown as React.FC;

interface ITagPill {
  tag: TagSelection_TagsFragment;
  deleteHandler: (tagId: string) => void;
  loading: boolean;
  disabled?: boolean;
}

export const Tag: FC<ITagPill> = ({
  deleteHandler,
  tag,
  loading = false,
  disabled = true,
}) => {
  return (
    <TagContainer>
      {tag.name}

      <IconButton
        variant='text'
        circle={false}
        disabled={disabled || loading}
        hard={false}
        element='button'
        label={`Delete ${tag.name}`}
        hideLabel
        onClick={() => deleteHandler(tag.id)}
      >
        {loading ? <ProgressSpinner /> : <CancelIcon />}
      </IconButton>
    </TagContainer>
  );
};
