import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const DownloadSection = styled('div')`
  display: flex;
  justify-content: space-between;

  .ln-c-progress-spinner {
    height: 25px;
    width: 25px;
    margin-right: 10px;
    color: ${ThemeColors.white};
  }
`;
