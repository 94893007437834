import { FC } from 'react';
import { Controller, UseFormReturn, FieldValues } from 'react-hook-form';
import { GridWrapper, GridItem, RadioButtonField } from '@jsluna/react';
import { TextInputField as LunaTextInput } from '@jsluna/react';
import { Cancel } from '@jsluna/icons';

import { CreateApplicationWrapper } from './CreateApplicationForm.styles';
import { ICreateAccountType } from '@interfaces/createAccountType.interface';
import { IAccountTypeTempData } from 'src/pages/createAccountType';

interface ICreateAccountTypeForm {
  onFormSubmit?: (values: ICreateAccountType) => void;
  formObj: UseFormReturn<FieldValues, any>;
  initialValues?: any;
  setApplicationTempData: any;
}

const CancelIcon = Cancel as unknown as React.FC;
export const CreateAccountTypeForm: FC<ICreateAccountTypeForm> = ({
  onFormSubmit,
  formObj,
  setApplicationTempData,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValidating },
    control,
  } = formObj;

  return (
    <CreateApplicationWrapper>
      <GridWrapper>
        <GridItem size={{ md: '1/3' }}>
          <Controller
            name='name'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <LunaTextInput
                {...field}
                onChange={field.onChange}
                error={errors?.name?.message}
                label='Name'
                info='Who is this account type being created for? For example, Colleagues.'
                placeholder='Please enter'
              />
            )}
          />

          <Controller
            name='description'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <LunaTextInput
                {...field}
                onChange={field.onChange}
                error={errors?.description?.message}
                label='Description'
                info='Please provide a brief overview of the intended use for this account type.'
                placeholder='Please enter'
              />
            )}
          />

          <Controller
            name='checkType'
            control={control}
            render={({ field }) => (
              <RadioButtonField
                label='Check type'
                {...field}
                onChange={(e: any) => {
                  const isDomain = e.target.value === 'DOMAIN';
                  setApplicationTempData((v: IAccountTypeTempData) => ({
                    ...v,
                    isTypeDomain: isDomain,
                    approvedDomains: !isDomain ? [...v.approvedDomains] : [],
                    rejectedDomains: !isDomain ? [...v.rejectedDomains] : [],
                  }));
                  return field.onChange(e);
                }}
                error={errors?.checkType?.message}
                name='radio-button-field-1'
                fullWidth
                options={[
                  { value: 'DOMAIN', label: 'Domain' },
                  { value: 'ORGANISATION', label: 'Organisation' },
                ]}
              />
            )}
          />
        </GridItem>
      </GridWrapper>
    </CreateApplicationWrapper>
  );
};
