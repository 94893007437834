import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const PermissionRowContainer = styled('div')`
  max-width: 50%;
  padding-left: 16px;
  margin-left: -16px;
  border: 1px solid transparent;
  transition: all var(--ds-modifier-transition-duration);
  cursor: pointer;
  border-radius: 4px;
  .permission-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .permission-name {
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #404040;
  }

  .deleteBox {
    button {
      color: #737373;
    }
  }

  &:hover {
    background: rgba(240, 108, 0, 0.05);
    border: 1px solid #f06c00;
  }
`;
