import React, { FC } from 'react';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { NotificationType } from 'src/enums/notificationTypes.enum';

interface IAccountTypeFeedback {
  type: NotificationType;
  notificationFor: String;
  onClose: () => void;
}

export const AccountTypeFeedback: FC<IAccountTypeFeedback> = ({
  type,
  onClose,
  notificationFor,
}) => {
  return (
    <NotificationBanner
      loading={false}
      messageType={type}
      message={
        type === NotificationType.FAILURE
          ? `Error in removing ${
              notificationFor === 'ADMIN' ? 'Admin' : 'Approver'
            }`
          : `${
              notificationFor === 'ADMIN' ? 'Admin' : 'Approver'
            } deleted successfully`
      }
      onClose={onClose}
    />
  );
};
