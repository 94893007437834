import React, { FC, useEffect, useState } from 'react';
import { EditRolesContainer } from './roleScreen.styles';
import { TextInputField } from '@jsluna/react';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { RoleDetailsSection_RoleDetailsFragment } from 'src/operations/generated/graphql';

interface IHeading {
  heading: string;
  subHeading: string;
}

interface IDetailsToChange {
  description?: string;
}

interface IEditApplicationDetailsScreen {
  handleClose: () => void;
  handleConfirmation: (status: IDetailsToChange) => void;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  detailsHeading: IHeading;
  details: RoleDetailsSection_RoleDetailsFragment;
}

export const UpdateRoleDetailsScreen: FC<IEditApplicationDetailsScreen> = ({
  handleConfirmation,
  handleClose,
  error,
  detailsHeading,
  loading,
  target,
  details,
}) => {
  const [detailsToChange, setDetailsToChange] = useState<IDetailsToChange>({});
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);
  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setDetailsToChange({});
    setFeedBackMessage(null);
  };

  return (
    <>
      <EditRolesContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={false}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `The details for ${target.displayName} ${
                      Object.keys(detailsToChange).length === 1 ? 'has' : 'have'
                    } been updated.`
                  : `Failed to update the details for ${target.displayName}.`
              }
              saveButtonText={'Save and close'}
              onCloseHandler={handleClose}
              resetButtonText={'Further changes'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${target.displayName} successfully updated`
                  : `Failed to update ${target.displayName}`
              }
            />
          </>
        ) : (
          <>
            <div className='tab-heading'>
              <h3>{detailsHeading.heading}</h3>
            </div>
            <div className='panel-body'>
              <TextInputField
                name='text-input-field-2'
                label='Description'
                placeholder={details.description}
                onChange={(e: any) =>
                  setDetailsToChange({
                    ...detailsToChange,
                    description: e.target.value,
                  })
                }
              />
            </div>
          </>
        )}
      </EditRolesContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={() => {
              if (Object.keys(detailsToChange).length !== 0) {
                handleConfirmation(detailsToChange);
                setFeedBackMessage(NotificationType.SUCCESS);
              }
            }}
            variant='filled'
            disabled={loading || Object.keys(detailsToChange).length === 0}
          >
            Update details
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
