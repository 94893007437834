import styled from 'styled-components';

export const SideMenuWrapper = styled('div')`
  .side-nav__item {
    box-shadow: 0 0 0 ln-px-to-rem(3px) transparent;
    color: #404040;
    cursor: pointer;
    display: block;
    padding: 1rem 1.5rem;
    position: relative;
    text-decoration: none;
    transition-duration: 0.2s;
    transition-property: box-shadow, color, background-color;
    transition-timing-function: ease-out;
  }
  .side-nav__item:hover {
    background-color: get-rgba(76, 76, 76, 0.08);
    color: #000;
  }
  .side-nav__item::before {
    background-color: #f06c00;
    bottom: ln-px-to-rem(2px);
    content: ' ';
    opacity: 0;
    position: absolute;
    right: 0;
    top: ln-px-to-rem(2px);
    transition-duration: 0.2s;
    transition-property: background-color, opacity;
    width: ln-px-to-rem(2px);
  }
  .side-nav__item.is-active {
    color: #f06c00;
  }
  .side-nav__item.is-active::before {
    opacity: 1;
  }
  .side-nav__item.is-active:hover {
    background-color: #fff;
    color: #f06c00;
  }

  .navigation__arrow-right {
    @include ln-respond-to('max-md') {
      display: flex !important;
    }

    float: right;
    display: none !important;
  }
`;
