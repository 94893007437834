import styled from 'styled-components';

export const ScreenFeedbackContainer = styled('div')`
  position: relative;
  padding-top: 72px;

  .feedback-message {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 72px;
    word-break: break-word;
  }

  .feedback-info {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    margin-top: -60px;
    font-size: 20px;
    word-break: break-word;
    margin-bottom: 72px;
    font-weight: 500;
  }
`;
