import styled from 'styled-components';

export const StatusModalWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  p {
    margin-top: 20px;
    margin-bottom: 30px;
    font-size: 18px;
  }
  @media (min-width: 720px) {
    min-width: 700px;
  }

  min-height: 300px;

  .created-result,
  .loading-container {
    text-align: center;
  }
`;
