import styled from 'styled-components';
import { Card, IconButton } from '@jsluna/react';
import { ThemeColors } from 'src/shared/styles/variables';

export const ProfileCardWrapper = styled((props: any) => <Card {...props} />)`
  padding: 48px;
  position: relative;

  .name-section {
    display: flex;
    justify-content: space-between;
  }

  .profile-name {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 40px;
  }

  .email {
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .profile-details {
    padding-top: 100px;
    position: relative;
    &:before {
      content: '';
      display: block;
      position: absolute;
      top: 50px;
      left: 0;
      right: 0;
      height: 1px;
      background: #d7d7d7;
    }

    .action-button {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .ln-c-button {
        margin-left: 24px;
      }
    }

    .details-heading {
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-style: normal;
      font-weight: 800;
      font-size: 22px;
      line-height: 24px;
      color: #404040;
      margin-bottom: 24px;

      display: flex;
      button {
        margin-left: auto;
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    .ln-c-button {
      margin-left: 24px;
    }
  }

  .profile-email-actions {
    display: flex;
    justify-content: space-between;
  }
`;
export const ProfileEmailEdit = styled((props: any) => <div {...props} />)`
  display: flex;
  align-items: baseline;

  ${({ editEmail }) =>
    editEmail &&
    `
  display: flex;
  justify-content:space-between;
  flex-flow: row wrap;
  justify-content: flex-end;
  .line-break{
    width: 100%;
  }
`}

  .submitEmailUpdate {
    margin-left: 10px;
  }

  .no-padding-btn {
    margin-left: auto;
    line-height: 1;
    min-height: 1px;

    &:hover {
      background-color: transparent !important;
    }
  }
`;

export const UpdateEmailButton = styled((props: any) => (
  <IconButton {...props} />
))`
  svg {
    color: ${ThemeColors.monochromeDarker};
  }

  &:hover {
    svg {
      color: ${ThemeColors.primary};
    }
  }
`;

export const RelativeDiv = styled('div')`
  position: relative;

  .notification-wrapper {
    top: -48px;
  }
`;
