import styled from 'styled-components';

export const JournalLoadingContainer = styled('div')`
  .loader-container {
    min-height: calc(100vh - 342px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
