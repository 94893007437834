import React, { FC, useMemo } from 'react';
import { Modal, GridWrapper, GridItem, List, ListItem } from '@jsluna/react';

interface IInvalidRowModal {
  handleClose: () => void;
  isOpen: boolean;
  publishFailedDescription: string;
  invalidRows?: any[];
}

export const InvalidRowModal: FC<IInvalidRowModal> = ({
  handleClose,
  isOpen,
  invalidRows,
  publishFailedDescription,
}) => {
  const { left, right } = useMemo(() => {
    if (invalidRows && invalidRows.length > 10) {
      return {
        left: invalidRows.slice(0, invalidRows.length / 2 + 1),
        right: invalidRows.slice(
          invalidRows.length / 2 + 1,
          invalidRows.length,
        ),
      };
    }
    return { left: invalidRows };
  }, [invalidRows]);

  return (
    <Modal
      className={`modal-mobile-responsive`}
      handleClose={handleClose}
      open={isOpen}
      data-testid={'invalid-row-modal'}
      aria-label='modal-label'
    >
      <h3>Row Information</h3>
      <GridWrapper horizontalAlign='center'>
        {invalidRows && invalidRows.length > 0 && (
          <GridItem size={publishFailedDescription ? '1/2' : '1'}>
            <h5>Invalid Rows</h5>
            <div className='invalid-row-container'>
              {left && (
                <List data-testid='left-list'>
                  {left.map(row => (
                    <ListItem key={row}>{row}</ListItem>
                  ))}
                </List>
              )}
              {right && (
                <List data-testid='right-list'>
                  {right.map(row => (
                    <ListItem key={row}>{row}</ListItem>
                  ))}
                </List>
              )}
            </div>
          </GridItem>
        )}
        {publishFailedDescription && (
          <GridItem size={invalidRows && invalidRows.length > 0 ? '1/2' : '1'}>
            <h5>Unpublished Rows</h5>
            <span>{publishFailedDescription}</span>
          </GridItem>
        )}
      </GridWrapper>
    </Modal>
  );
};
