import React, { FC } from 'react';
import { ErrorCross } from 'src/components/ErrorCross';
import { SuccessCheck } from 'src/components/SuccessCheck';

interface IAcceptanceStatus {
  status: 'SUCCESS' | 'ERROR';
}

export const AcceptanceStatus: FC<IAcceptanceStatus> = ({ status }) => {
  return (
    <div className='ln-c-card ln-u-soft-lg'>
      {status === 'SUCCESS' ? (
        <>
          <SuccessCheck />
          <h5 className='ln-u-text-align-center'>
            Thank you for accepting our Terms and Conditions
          </h5>
        </>
      ) : (
        <>
          <ErrorCross />
          <h5 className='ln-u-text-align-center'>
            Unable to accept Terms and Conditions at the moment. Please try
            again later
          </h5>
        </>
      )}
    </div>
  );
};
