import React, { FC, useState } from 'react';
import {
  Modal,
  ButtonGroupWrapper,
  TextInputField,
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
  ModalHeading,
} from '@jsluna/react';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { useAppSelector } from 'src/redux/hooks';
import { getSelectedAccount } from 'src/redux/selectors';
import { LocalStorageNames } from 'src/constants/localStorageNames';
import {
  DeleteAccount_AccountFragment,
  useDeleteAccountMutation,
} from 'src/operations/generated/graphql';

interface IDeleteAccountPopup {
  setShowPopup: (status: boolean) => void;
  setRequestedAccount: (status: DeleteAccount_AccountFragment) => void;
  handleClose: (type?: NotificationType) => void;
  requestedAccount: DeleteAccount_AccountFragment;
  showPopup: Boolean;
}

export const DeleteAccountPopup: FC<IDeleteAccountPopup> = ({
  requestedAccount,
  setRequestedAccount,
  handleClose,
  showPopup,
  setShowPopup,
}) => {
  const [accountName, setAccountName] = useState('');
  const { id } = requestedAccount;
  const name = requestedAccount?.name?.replace(/\s{2,}/g, ' ').trim() || '';
  const [deleteAccountMutation] = useDeleteAccountMutation();

  const selectedAccount = useAppSelector(getSelectedAccount);

  const handleReset = () => {
    setShowPopup(false);
    setAccountName('');
    setRequestedAccount({ id: '', name: '' });
  };
  const submitHandler = async () => {
    try {
      const result = await deleteAccountMutation({
        variables: {
          accountId: id,
        },
      });
      if (result?.data?.deleteAccount?.id) {
        if (selectedAccount?.id === result.data.deleteAccount.id) {
          clearAccountCookie();
        }

        handleClose(NotificationType.SUCCESS);
      } else {
        handleClose(NotificationType.FAILURE);
      }
    } catch (error) {
      handleClose(NotificationType.FAILURE);
    }
    handleReset();
  };

  const clearAccountCookie = () => {
    localStorage.removeItem(LocalStorageNames.PPS_ACCOUNT);
    fetch('/clearAccountCookie', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      },
    }).then(response => {
      if (response.status == 200) {
        window.location.href = '/login';
      }
    });
  };

  return (
    <>
      <Modal
        restrictClose
        open={showPopup}
        headingId='select-account-confirmation'
      >
        <ModalHeading
          element='h3'
          className='name-modal'
        >{`You're deleting your account "${name}". Please note this action can't be undone.`}</ModalHeading>
        You will lose access to all data and permissions associated with that
        account.
        <TextInputField
          name='confirm-account-field'
          required='*'
          label='Please confirm your account name'
          onChange={(e: any) => {
            setAccountName(e.target.value);
          }}
        />
        <ButtonGroupWrapper className='ln-u-margin-left'>
          <ButtonGroupPrimary>
            <FilledButton
              className='ln-u-margin-right'
              disabled={accountName !== name}
              onClick={() => submitHandler()}
            >
              Confirm
            </FilledButton>
            <OutlinedButton
              onClick={() => {
                handleReset();
              }}
            >
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </>
  );
};
