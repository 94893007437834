import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const ApplicationTabsWrapper = styled('div')`
  .ln-c-tabs__list {
    text-align: center;
    background: ${ThemeColors.white};
  }

  .application-tag-name {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: 800;
    font-size: 26px;
    line-height: 32px;
  }
`;
