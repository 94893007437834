import { FC, useEffect, useState } from 'react';
import { FavAppsSection, CreateAppSection } from './myAppsGroupTab.styles';
import { Button, Card } from '@jsluna/react';
import { useAppSelector } from 'src/redux/hooks';
import { getSelectedAccount } from 'src/redux/selectors';
import { gql } from '@apollo/client';
import { ApplicationTabs } from './components/applicationTabs/applicationTabs';
import Drawer from '@mui/material/Drawer';
import { AddNewAppGroupScreen } from 'src/screens/addNewAppGroup';

import {
  useMyAppsGroupTab_AccountApplicationGroupsLazyQuery,
  MyAppsGroupTab_AppGroupFragment,
  ApplicationCoreFragment,
} from 'src/operations/generated/graphql';
interface IMyAppsGroup {
  accountId?: string;
}

gql`
  fragment MyAppsGroupTab_AppGroup on AccountApplicationGroupProjection {
    id
    name
    applications {
      value {
        ...ApplicationCore
        tags {
          value {
            ...TagCore
          }
        }
      }
    }
  }
`;

gql`
  fragment MyAppsGroupTab_AccountApplicationGroups on AccountProjection {
    applicationGroups {
      ...MyAppsGroupTab_AppGroup
    }
  }
`;

gql`
  query MyAppsGroupTab_AccountApplicationGroups($id: String!) {
    account(id: $id) {
      ...MyAppsGroupTab_AccountApplicationGroups
    }
  }
`;

export interface IApplicationTabs {
  id: string;
  name: string;
  applications: ApplicationCoreFragment[];
}

export const MyAppsGroupTab: FC<IMyAppsGroup> = ({ accountId }) => {
  const [showCreateApp, setShowCreateApp] = useState(false);
  const selectedAccount = useAppSelector(getSelectedAccount);
  const [
    getApplicationGroups,
    { data: { account = null } = {}, error, loading, refetch },
  ] = useMyAppsGroupTab_AccountApplicationGroupsLazyQuery({
    variables: { id: selectedAccount?.id || '' },
    fetchPolicy: 'network-only',
  });

  const [appByGroups, setAppByGroups] = useState<IApplicationTabs[]>([]);

  useEffect(() => {
    if (!accountId && selectedAccount?.id) {
      getApplicationGroups({
        variables: {
          id: selectedAccount.id,
        },
      });
    }
  }, [selectedAccount]);

  useEffect(() => {
    if (accountId) {
      getApplicationGroups({
        variables: {
          id: accountId,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (account?.applicationGroups) {
      appGroupsMapper(account.applicationGroups);
    }
  }, [account]);

  const appGroupsMapper = (
    applicationGroups: MyAppsGroupTab_AppGroupFragment[],
  ) => {
    const groups = applicationGroups.map(appGroup => ({
      id: appGroup.name,
      name: appGroup.name,
      applications: appGroup.applications.map(app => app.value),
    }));

    setAppByGroups(groups as IApplicationTabs[]);
  };

  const createAppGroupHandler = () => {
    setShowCreateApp(true);
  };
  const closeCreateAppGroupHandler = () => {
    setShowCreateApp(false);
    refetchHandler();
  };

  const refetchHandler = () => {
    getApplicationGroups();
  };

  return (
    <>
      {appByGroups.length > 0 ? (
        <FavAppsSection>
          <ApplicationTabs
            tags={appByGroups}
            refreshHandler={refetchHandler}
            addNewGroup={createAppGroupHandler}
          />
        </FavAppsSection>
      ) : (
        <div className='heading-section'>
          <Card>
            <CreateAppSection>
              <div className=''>
                <div className='display-2'>Create your first app group</div>
                <div className='body-1'>
                  Group your apps to make it easier to find and use the apps you
                  need.
                </div>
              </div>
              <Button
                circle={false}
                disabled={false}
                hard={false}
                element='button'
                variant='filled'
                onClick={createAppGroupHandler}
              >
                Create app group
              </Button>
            </CreateAppSection>
          </Card>
        </div>
      )}
      <Drawer
        open={showCreateApp}
        onClose={closeCreateAppGroupHandler}
        anchor='right'
        className='edit-app-group'
      >
        <AddNewAppGroupScreen closeHandler={closeCreateAppGroupHandler} />
      </Drawer>
    </>
  );
};
