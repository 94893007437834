import { routes } from 'src/constants/routes';

interface ICrumb {
  title: string;
  href: string;
  active?: boolean;
}

export const generateBreadcrumbs = ({
  journal,
  article,
  isJournalsLanding,
  isNewsLanding,
  omitNewsAndArticles,
}: any) => {
  const crumbs: ICrumb[] = [
    { title: 'Home', href: routes.root },
    { title: 'News & articles', href: routes.newsAndArticles },
  ];

  if (!omitNewsAndArticles) {
    crumbs.push({ title: 'News & articles', href: routes.newsAndArticles });
  }

  if (journal?.type === 'Journal' || isJournalsLanding) {
    crumbs.push({ title: 'Supplier Journal', href: routes.supplierJournals });
  }

  if (journal?.type === 'Plan 4 Better') {
    crumbs.push({ title: 'Plan for Better', href: routes.planForBetterWT });
  }

  if (journal?.type === 'Agriculture') {
    crumbs.push({ title: 'Agriculture', href: routes.agriculture });
  }

  if (article?.tags.includes('News') || isNewsLanding) {
    crumbs.push({ title: 'News', href: routes.newsArticles });
  }

  if (journal) {
    crumbs.push({
      title: journal.title,
      href: `${routes.newsAndArticles}/journals/${journal.uid}`,
    });
  }

  crumbs[crumbs.length - 1].active = true;

  return crumbs;
};
