import { gql } from '@apollo/client';
import { useMsal } from '@azure/msal-react';
import { ArrowRight } from '@jsluna/icons';
import { Button, ProgressSpinner } from '@jsluna/react';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { loginRequest } from 'src/constants/msal';
import { routes } from 'src/constants/routes';
import { AccountStatus } from 'src/enums/accessStatus.enum';
import {
  ProfileInvitation,
  useLoginPage_MyProfileLazyQuery,
} from 'src/operations/generated/graphql';
import { useAppDispatch } from 'src/redux/hooks';
import { setLoggedIn } from 'src/redux/reducer';
import { AuthContext, IAuthType } from '../../providers/AuthProvider';
import { LoginWrapper } from './login.styles';
import { setInitialState } from 'src/redux/reducer';

const ArrowRightIcon = ArrowRight as unknown as React.FC;

gql`
  fragment LoginPage_MyProfile on ProfileProjection {
    id
    invitationStatus
    preferredName
    firstName
    lastName
    emailAddress
    title
    contactNumber
    countryCode
    selectedAccountId
    accounts {
      value {
        id
        name
        status
      }
    }
  }
`;

gql`
  query LoginPage_MyProfile {
    myProfile {
      ...LoginPage_MyProfile
    }
  }
`;

export const LoginPage: FC = () => {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(
    null,
  );
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const { setAuthState } = useContext(AuthContext) as {
    authState: IAuthType;
    setAuthState: (state: IAuthType) => void;
  };

  const [
    getLoggedInProfile,
    { data: { myProfile: loggedInProfile = null } = {} },
  ] = useLoginPage_MyProfileLazyQuery();

  const handleLogin = async () => {
    const result = await instance.loginPopup(loginRequest);

    if (result?.accessToken) {
      fetch('/issueCookies', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt: result.accessToken,
        }),
      })
        .then(response => {
          if (response.ok) {
            dispatch(setLoggedIn(true));
            setIsLoggedIn(true);
          } else {
            setError(true);
          }
        })

        .catch(error => {
          console.log(error);
        });
    }
  };

  const doRedirect = () => {
    const { search } = window.location;
    const redirectURL = new URLSearchParams(search).get('redirect_to');
    if (redirectURL) {
      if (redirectURL.startsWith('/apps/')) window.location.assign(redirectURL);
      else navigate(redirectURL);
    } else if (window.location.href === `${window.location.origin}/login`) {
      navigate(routes.root);
    }
  };

  useEffect(() => {
    if (!loggedInProfile) return;
    if (loggedInProfile?.invitationStatus === ProfileInvitation.Pending) {
      navigate(routes.completeInvitation);
    } else if (selectedAccountId != null || loggedInProfile.selectedAccountId) {
      doRedirect();
    }
  }, [loggedInProfile]);

  useEffect(() => {
    if (isLoggedIn) getLoggedInProfile();
  }, [isLoggedIn]);

  useEffect(() => {
    fetch('/validateCookie')
      .then(response => response.json())
      .then(data => {
        setIsLoggedIn(data);
      });
  }, []);

  const bgURL = `${process.env.PUBLIC_URL}/img/login.jpeg`;

  const handleAccountClick = async (accountId: string) => {
    setIsLoading(true);

    try {
      await fetch('/setAccount', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountId,
        }),
      })
        .then(() => setSelectedAccountId(accountId))
        .catch(error => console.error(`[setAccount]`, { error }));

      const claimsResponse = await fetch('/claims');

      const claims = await claimsResponse.json();

      setAuthState(claims);
      setIsLoading(false);

      if (loggedInProfile) {
        const accounts =
          loggedInProfile?.accounts.map(account => account.value) || [];

        const currentAccount =
          accounts.find(
            account => account.id === loggedInProfile?.selectedAccountId,
          ) || null;

        const data = {
          profileDetails: {
            id: loggedInProfile.id,
            title: loggedInProfile.title,
            firstName: loggedInProfile.firstName,
            lastName: loggedInProfile.lastName,
            emailAddress: loggedInProfile.emailAddress,
            contactNumber: loggedInProfile.contactNumber,
            countryCode: loggedInProfile.countryCode,
            selectedAccountId: accountId,
            preferredName: loggedInProfile.preferredName,
          },
          accounts,
          selectedAccount: accountId,
          isLoggedIn: true,
        };

        dispatch(setInitialState(data));
      }

      doRedirect();
    } catch (e) {
      throw new Error(e as any);
    }
  };

  return (
    <LoginWrapper style={{ backgroundImage: `url(${bgURL})` }}>
      <div className={`login-section ${isLoggedIn ? 'select-option' : ''}`}>
        {error ? (
          <>
            <h1 className='ln-u-display-6'>Together With</h1>
            <h6>Access denied</h6>

            <p className='pps-desc'>
              Seems like you don't have access to Together With eco system,
              please register or contact us via{' '}
              <a href=''>together.with@sainsburys.co.uk</a>
            </p>

            <Button
              className='login-btn'
              onClick={() => {
                navigate(routes.registerForAccess);
              }}
            >
              <span>Register for access</span>
              <ArrowRightIcon />
            </Button>
          </>
        ) : (
          <>
            {!isLoggedIn ? (
              <>
                <h1 className='ln-u-display-6'>Together With</h1>
                <p className='pps-desc'>
                  A dedicated platform helping to manage day to day business
                  activities.
                </p>

                <Button className='login-btn' onClick={handleLogin}>
                  Login with microsoft account
                </Button>
              </>
            ) : (
              <>
                {loggedInProfile?.accounts?.length === 0 ? (
                  <>
                    <h1 className='ln-u-display-6'>Together With</h1>
                    <h6>No accounts found</h6>

                    <p className='pps-desc'>
                      It seems like you don't have an account registered in our
                      ecosystem.
                    </p>

                    <Button
                      className='login-btn'
                      onClick={() => {
                        navigate(routes.requestAccount);
                      }}
                    >
                      <span>Request account</span>
                      <ArrowRightIcon />
                    </Button>
                  </>
                ) : (
                  <>
                    {!loggedInProfile?.selectedAccountId && (
                      <>
                        <div className='account-section'>
                          <h6>Select your account</h6>
                          <div className='accounts'>
                            {loggedInProfile?.accounts?.map(account => (
                              <>
                                {account.value.status ===
                                  AccountStatus.APPROVED && (
                                  <Button
                                    key={account.value.id}
                                    disabled={isLoading}
                                    className='account-option'
                                    onClick={async () => {
                                      handleAccountClick(account.value.id);
                                    }}
                                  >
                                    <span>{account.value.name}</span>
                                    {selectedAccountId === account.value.id &&
                                    isLoading ? (
                                      <ProgressSpinner />
                                    ) : (
                                      <ArrowRightIcon />
                                    )}
                                  </Button>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}
      </div>
    </LoginWrapper>
  );
};
