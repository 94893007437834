import { INotification } from 'src/interfaces/notification.interface';
import React, { FC, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { setShowFeedback } from 'src/redux/reducer';
import { getSelectedAccount } from 'src/redux/selectors';
import {
  Switch,
  ButtonGroupWrapper,
  ButtonGroupSecondary,
  RadioButtonField,
  ProgressSpinner,
  FilledButton,
  TextInputField,
} from '@jsluna/react';
import { Tick } from '@jsluna/icons';
import { AccountRequestWrapper } from './AccountRequest.styles';
import { InfoPanelTypes } from 'src/enums/infoPanelTypes.enum';
import { InfoPanel } from 'src/shared/components/infoPanel';
import { gql } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';

import {
  useTakeOverMutation,
  useUnassignNotificationMutation,
  useApproveAccountTypeForAccountMutation,
  useRejectAccountTypeForAccountMutation,
  useAddApprovedDomainToAccountTypeMutation,
  useAddRejectedDomainToAccountTypeMutation,
  RejectReasonOptions,
  Notifications_AccountInfoFragment,
} from 'src/operations/generated/graphql';

gql`
  fragment AccountRequestNotification_AccountTypeDetails on AccountTypeProjection {
    id
    name
  }
`;

const TickIcon = Tick as unknown as React.FC<{ className?: string }>;

interface IAccountRequest {
  notification: INotification;
  accountDetails: Notifications_AccountInfoFragment;
  refetchNotification: () => void;
}

export const AccountRequest: FC<IAccountRequest> = ({
  notification,
  accountDetails,
  refetchNotification,
}) => {
  const selectedAccount = useAppSelector(getSelectedAccount);
  const dispatch = useAppDispatch();

  const [action, setAction] = useState<boolean | null>(null);
  const [automaticApprove, setAutomaticApprove] = useState<boolean | null>(
    null,
  );
  const [reason, setReason] = useState<RejectReasonOptions | null>(null);
  const [reasonComment, setReasonComment] = useState<string>('');

  if (!selectedAccount?.id) return <p>No Accounts selected.</p>;

  const [takenOver, setTakenOver] = useState(false);

  const assignee = notification.assignedTo
    ? notification.assignedTo?.profile.value.firstName +
      ' ' +
      notification.assignedTo?.profile.value.lastName
    : takenOver
    ? // @ts-ignore
      selectedAccount?.profile?.value.firstName +
      ' ' +
      // @ts-ignore
      selectedAccount?.profile?.value.lastName
    : null;

  useEffect(() => {
    if (selectedAccount?.id)
      setTakenOver(selectedAccount?.id === notification.assignedTo?.id);
  }, [selectedAccount]);

  const [takeOver, { loading: takeOverLoading }] = useTakeOverMutation();
  const [unAssign, { loading: unAssignLoading }] =
    useUnassignNotificationMutation();

  const toggleAssignment = async () => {
    let result = null;

    if (takenOver) {
      const { data } = await unAssign({
        variables: {
          entityId: notification.entityId,
        },
      });
      if (data?.unassignNotification) {
        result = true;
      }
    } else {
      const { data } = await takeOver({
        variables: {
          entityId: notification.entityId,
        },
      });
      if (data?.takeOver) {
        result = true;
      }
    }

    if (result) {
      refetchNotification();
      setTakenOver(!takenOver);
    }
  };

  const domain = accountDetails.profile?.value?.emailAddress?.split('@')[1];

  const [approveMutate, { loading: approveLoading }] =
    useApproveAccountTypeForAccountMutation();
  const [rejectMutate, { loading: rejectLoading }] =
    useRejectAccountTypeForAccountMutation();
  const [approvedDomainMutate, { loading: approvedDomainLoading }] =
    useAddApprovedDomainToAccountTypeMutation();
  const [rejectDomainMutate, { loading: rejectDomainLoading }] =
    useAddRejectedDomainToAccountTypeMutation();

  const confirmAccountRequest = async () => {
    //TODO: Change reason to have a default value
    if (!action && reason) {
      const reject = await rejectMutate({
        variables: {
          reject: {
            accountTypeId: notification.audienceId,
            accountId: notification.targetId,
            reason,
            reasonComment,
          },
        },
      });
      if (automaticApprove && domain) {
        rejectDomainMutate({
          variables: {
            id: notification.audienceId,
            domain,
          },
        });
      }

      if (reject?.data?.rejectAccountTypeForAccount?.id) {
        dispatch(
          setShowFeedback({
            message: 'Account rejected successfully',
            type: NotificationType.SUCCESS,
            targetId: notification.sourceId,
          }),
        );
      }
    } else {
      const approve = await approveMutate({
        variables: {
          accountTypeId: notification.audienceId,
          accountId: notification.targetId,
        },
      });

      if (automaticApprove && domain) {
        approvedDomainMutate({
          variables: {
            id: notification.audienceId,
            domain,
          },
        });
      }
      if (approve?.data?.approveAccountTypeForAccount?.id) {
        dispatch(
          setShowFeedback({
            message: 'Account approved successfully',
            type: NotificationType.SUCCESS,
            targetId: notification.sourceId,
          }),
        );
      }
    }
  };

  return (
    <AccountRequestWrapper>
      <div className='assign-to-me'>
        <div className='label-1'>Account request</div>
        <div>
          {takeOverLoading || unAssignLoading ? (
            <>
              <div className='loading-container ln-u-display-flex ln-u-margin-ends'>
                <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
                Loading
              </div>
            </>
          ) : (
            <>
              {assignee && (
                <div className='assigned-info ln-u-margin-ends'>{assignee}</div>
              )}
            </>
          )}
          <Switch
            name={`assign-task-${notification.entityId}`}
            label={!takenOver ? `Assign task to me` : `Assigned to me`}
            outlined
            className='ln-u-margin-bottom*3'
            disabled={takeOverLoading || unAssignLoading}
            onClick={toggleAssignment}
            checked={takenOver}
          />
        </div>
      </div>

      <div className='requested-roles ln-u-margin-bottom'>
        <TickIcon className='green-circle' /> Account:
        <span>{accountDetails.accountType?.name}</span>
      </div>

      <div className='label-1 ln-u-margin-top*3'>
        Accept or reject access to Together With
      </div>

      <RadioButtonField
        name='approve-reject'
        hideLabel
        fullWidth
        options={[
          { value: true, label: 'Accept' },
          { value: false, label: 'Reject' },
        ]}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setAction(e.target.value === 'true' ? true : false);
        }}
      />
      {action !== null && (
        <>
          {action === false && (
            <>
              <div className='label-1 ln-u-margin-bottom'>
                Please select a reason for rejecting
              </div>
              <div>
                If you reject this application, {selectedAccount.name} will
                receive a notification via email about the decision.
              </div>

              <RadioButtonField
                name='reject-options'
                hideLabel
                fullWidth
                options={[
                  {
                    value: RejectReasonOptions.SupplierCodeNotRecognised,
                    label: `The supplier code provided is not recognised on our system`,
                  },
                  {
                    value: RejectReasonOptions.SupplierNameNotMatch,
                    label: `The supplier name provided does not match our system`,
                  },
                  {
                    value: RejectReasonOptions.GenericEmailAddress,
                    label: `The email address provided is a generic email address`,
                  },
                  {
                    value: RejectReasonOptions.Other,
                    label: `Other`,
                  },
                ]}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setReason(e.target.value as RejectReasonOptions);
                  setReasonComment('');
                }}
              />
              {reason === RejectReasonOptions.Other && (
                <>
                  <TextInputField
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setReasonComment(e.target.value);
                    }}
                    label='Message to include in email/notification.'
                    info='Include a reason for the rejection so they can try to resolve the issue.'
                    placeholder='Please enter'
                  />
                </>
              )}
            </>
          )}

          <div className='label-1 ln-u-margin-top*3'>
            Do you want @{domain} email domain to be automatically
            {action ? ' approved' : ' rejected'} list in the future for account
            type: {accountDetails.accountType?.name}?
          </div>
          <InfoPanel
            infoType={InfoPanelTypes.INFORMATION}
            infoText={`If you add @${domain} to the automatically ${
              action ? 'approved' : 'rejected'
            } list, users requesting access to Together With using an @${domain} email domain with account type: ${
              accountDetails.accountType?.name
            } will be automatically ${
              action ? 'approved' : 'rejected'
            }, subject to meeting approval/reject criteria.`}
            customClass={'roleInfo'}
          />

          <RadioButtonField
            name='auto-approve-reject'
            hideLabel
            fullWidth
            options={[
              {
                value: true,
                label: `Yes - automatically ${
                  action ? 'approve' : 'reject'
                } in future`,
              },
              {
                value: false,
                label: `No - don’t automatically ${
                  action ? 'approve' : 'reject'
                } in future`,
              },
            ]}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setAutomaticApprove(e.target.value === 'true' ? true : false);
            }}
          />
        </>
      )}

      <ButtonGroupWrapper>
        <ButtonGroupSecondary>
          <FilledButton
            disabled={
              action === null ||
              (action === false && reason === null) ||
              (action === true && automaticApprove === null) ||
              (action === false && reason === 'OTHER' && !reasonComment.length)
            }
            className='ln-u-margin-right'
            onClick={confirmAccountRequest}
          >
            {approveLoading ||
            rejectLoading ||
            approvedDomainLoading ||
            rejectDomainLoading ? (
              <div className='loader-content white'>
                <ProgressSpinner /> Loading
              </div>
            ) : (
              <>Confirm</>
            )}
          </FilledButton>
        </ButtonGroupSecondary>
      </ButtonGroupWrapper>
    </AccountRequestWrapper>
  );
};
