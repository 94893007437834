import styled from 'styled-components';
import { Card } from '@jsluna/react';

export const StyledStatCardLoader = styled((props: any) => <Card {...props} />)`
  border-radius: 8px;
  position: relative;
  overflow: hidden;

  cursor: pointer;
  transition: all var(--ds-modifier-transition-duration);
`;
