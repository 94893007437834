import { IValidationMessages } from '@interfaces/validationMessages.interface';

export const InviteProfileMessages: { [key: string]: IValidationMessages } = {
  title: {
    required: 'You must select a title',
  },
  firstName: {
    required: 'You must enter a first name',
    min: 'First name must be greater than ',
    max: 'First name must be less than ',
  },
  lastName: {
    required: 'You must enter a last name',
    min: 'Last name must be greater than ',
    max: 'Last name must be less than ',
  },
  preferredName: {
    required: 'You must enter a preferred name',
    min: 'Preferred name must be greater than ',
    max: 'Preferred name must be less than ',
  },
  emailAddress: {
    required: 'You must enter an email address',
    valid: 'You must enter a valid email address',
  },
  confirmEmailAddress: {
    required: 'Please confirm the email address',
    match: 'Email addresses must match',
  },
  contactNumber: {
    valid: 'Phone number is not valid',
    required: 'Phone number is required',
  },
  countryCode: {
    required: 'Country code is required',
  },
  name: {
    required: 'Account name is required',
    min: 'Account name must be greater than ',
    max: 'Account name must be less than ',
  },
  jobTitle: {
    required: 'Job title is required',
  },
  accountTypeId: {
    required: 'Account Type is required',
  },
  profileId: {
    required: 'Profile is required',
  },
  siteId: {
    required: 'Site is required',
  },
  isPartner: {
    required: 'Please confirm if you are a partner',
  },
  partnerName: {
    required: 'Please provide a the name of your partnering organisation',
  },
  partnerContact: {
    required: "Please provide a Sainsbury's contact",
  },
  tagIds: {
    required: 'Select at least one tag',
  },
  roles: {
    required: 'Select at least one role',
  },
  accountJobTitle: {
    required: 'You must enter a job title',
    min: 'Job title must be greater than ',
    max: 'Job title must be less than ',
  },
};
