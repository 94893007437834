import React, { FC, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import {
  TextInputField,
  GridItem,
  GridWrapper,
  TextAreaField,
  Button,
  ProgressSpinner,
} from '@jsluna/react';

import feedbackSchema from './feedback.schema';
import { Rating } from '@mui/material';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';

interface IFeedbackForm {
  submit: (formData: any) => void;
}

export const FeedbackForm: FC<IFeedbackForm> = ({ submit }) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<number | null>(0);
  const [hover, setHover] = React.useState(-1);

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
  } = useForm({
    resolver: yupResolver(feedbackSchema),
    mode: 'onChange',
  });

  const submitHandler = (formData: any) => {
    submit({ ...formData, starRating: parseFloat(formData.starRating) * 2 });
  };

  //labels may possibly change
  const labels: { [index: string]: string } = {
    0.5: 'Very Bad',
    1: 'Very Bad',
    1.5: 'Poor',
    2: 'Poor',
    2.5: 'Ok',
    3: 'Ok',
    3.5: 'Good',
    4: 'Good',
    4.5: 'Excellent',
    5: 'Excellent',
  };

  return (
    <div>
      <form onSubmit={handleSubmit(submitHandler)}>
        <GridWrapper>
          <GridItem size={{ md: '1/3' }}>
            <Controller
              name='subject'
              control={control}
              render={({ field }) => (
                <TextInputField
                  {...field}
                  onChange={field.onChange}
                  error={errors?.subject?.message}
                  label='Subject'
                />
              )}
            />
          </GridItem>
        </GridWrapper>
        <GridWrapper>
          <GridItem size={{ md: '1/3' }}>
            <Controller
              name='starRating'
              control={control}
              render={({ field }) => (
                <>
                  <label className='ln-c-label' htmlFor='starRating'>
                    How would you rate your experience?
                  </label>
                  <br />
                  <Rating
                    name='starRating'
                    value={field.value}
                    precision={0.5}
                    onChange={field.onChange}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    emptyIcon={
                      <StarIcon style={{ opacity: 0.55 }} fontSize='inherit' />
                    }
                  />
                </>
              )}
            />
            {value !== null && (
              <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
            )}
          </GridItem>
        </GridWrapper>
        <GridWrapper>
          <GridItem size={{ md: '1/3' }}>
            <Controller
              name='feedback'
              control={control}
              render={({ field }) => (
                <TextAreaField
                  {...field}
                  onChange={field.onChange}
                  error={errors?.feedback?.message}
                  label='Feedback'
                />
              )}
            />
          </GridItem>
        </GridWrapper>
        <Button
          disabled={!isValid}
          type='submit'
          variant='filled'
          className='add-profile'
        >
          {loading && (
            <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
          )}
          Submit feedback
        </Button>
      </form>
    </div>
  );
};
