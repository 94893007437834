import React, { FC, useEffect, useState } from 'react';
import { CheckboxField, Button, ProgressSpinner } from '@jsluna/react';
import { EditSupplierCodeContainer } from './editSupplierCode.styles';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ScreenFeedback } from '../components/screenFeedback';
import { DrawerViews } from 'src/pages/viewProfile/components/supplierCodesSection';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

import {
  SupplierCodesSection_AccountToSiteRelationshipFragment,
  useDissociateAccountFromSitesMutation,
} from 'src/operations/generated/graphql';
import { gql } from '@apollo/client';

interface IRemoveSupplierCode {
  setDrawerView: (status: string) => void;
  setIsDrawerOpen: (status: boolean) => void;
  removeHandler: (removedSites: string[]) => void;
  drawerView: string;
  selectedAccountId: string;
  sites?: SupplierCodesSection_AccountToSiteRelationshipFragment[];
}

interface IRemoveSupplierCodeOption {
  label: string;
  value: string;
}

gql`
  fragment RemoveSupplierCode_SiteInfo on SiteProjection {
    id
    name
  }
`;

export const RemoveSupplierCode: FC<IRemoveSupplierCode> = ({
  setDrawerView,
  setIsDrawerOpen,
  removeHandler,
  selectedAccountId,
  sites,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);
  const [supplierCodesOptions, setSupplierCodesOptions] = useState<
    IRemoveSupplierCodeOption[]
  >([]);

  const [
    dissociateMutate,
    { error: dissociateError, loading: dissociateLoading },
  ] = useDissociateAccountFromSitesMutation();
  const [removedSupplierCodes, setRemovedSupplierCodes] = useState<string[]>(
    [],
  );

  const supplierCodeSelected = (e: any) => {
    const value = e.target.value;
    if (e.target.checked) {
      setRemovedSupplierCodes([...removedSupplierCodes, value]);
    } else {
      const index = removedSupplierCodes.indexOf(value, 0);
      if (index > -1) {
        setRemovedSupplierCodes(
          removedSupplierCodes.filter(
            removedSupplierCode => removedSupplierCode !== e.target.value,
          ),
        );
      }
    }
  };

  const submitSupplierCodes = async () => {
    const result = await dissociateMutate({
      variables: {
        id: selectedAccountId,
        siteIds: removedSupplierCodes,
      },
    });
    if (result?.data?.dissociateAccountFromSites?.id) {
      setFeedBackMessage(NotificationType.SUCCESS);
      removeHandler(removedSupplierCodes);
    } else {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  };

  useEffect(() => {
    if (sites && sites?.length > 0) {
      // const options: IRemoveSupplierCodeOption[] = [];
      const options = sites.map(site => ({
        // if (site?.status === 'APPROVED') {

        label: `${site.value.name} - ${site.value.id}`,
        value: site.value.id,
        // }
      }));
      setSupplierCodesOptions(options);
    }
  }, []);

  return (
    <EditSupplierCodeContainer>
      {feedBackMessage ? (
        <>
          <ScreenFeedback
            isLoading={dissociateLoading}
            notificationType={feedBackMessage}
            feedBackMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? 'The supplier codes have been removed from your account'
                : 'Unable to remove subsidiaries. Please try again.'
            }
            saveButtonText={'Close'}
            onCloseHandler={() => {
              setIsDrawerOpen(false);
              setDrawerView('');
            }}
            resetButtonText={'Remove more codes'}
            resetHandler={() => {
              setRemovedSupplierCodes([]);
              setFeedBackMessage(null);
              setDrawerView(DrawerViews.REMOVE_SUBSIDIARY);
            }}
            notificationMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? 'Account updated'
                : 'Failed to remove subsidiary'
            }
          />
        </>
      ) : (
        <>
          <h4>Remove supplier codes from your account</h4>
          {supplierCodesOptions?.length > 0 && (
            <CheckboxField
              name='remove-supplier-codes-field'
              info='You can select more than one supplier code.'
              options={supplierCodesOptions}
              onChange={supplierCodeSelected}
            />
          )}
        </>
      )}
      {!feedBackMessage && (
        <PanelActions>
          <>
            <Button
              onClick={() => {
                submitSupplierCodes();
              }}
              variant='filled'
              disabled={dissociateLoading || removedSupplierCodes.length === 0}
              type='submit'
            >
              {dissociateLoading && <ProgressSpinner />}
              Remove codes
            </Button>
            <Button
              hard={false}
              variant='outlined'
              onClick={() => {
                setIsDrawerOpen(false);
                setDrawerView('');
              }}
            >
              Cancel
            </Button>
          </>
        </PanelActions>
      )}
    </EditSupplierCodeContainer>
  );
};
