import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { TileGroup } from '@sainsburys-tech/suppex-tile-group';
import { TileData } from '@sainsburys-tech/suppex-tile';
import { useGetSupplierNews } from 'src/operations/queries/getSupplierNews';
import { journalClickHandler } from 'src/utils/journalClickHandler';
import { routes } from 'src/constants/routes';
import { IArticleChild } from 'src/interfaces/articleChild.interface';
import { generateNewsArticleTags } from 'src/utils/generateJournalTags';
import { LoginRegisterPanel } from 'src/components/LoginRegisterPanel/LoginRegisterPanel';
import CustomError from 'src/components/CustomError/CustomError';

interface ISupplierNews {
  isDashboard?: boolean;
  latestNews: any;
  newsError: any;
  newsLoading: boolean;
}

export const SupplierNews: FC<ISupplierNews> = ({
  isDashboard,
  latestNews,
  newsError,
  newsLoading,
}) => {
  const navigate = useNavigate();

  return (
    <div data-cy='latestNewsTileGroup' className='ln-u-padding-top*4'>
      {latestNews?.list?.length > 0 ? (
        <TileGroup
          title='Latest news'
          variant='promoted'
          style='dark'
          softTop
          seeMoreHandler={() => navigate(routes.newsArticles)}
          tileClickHandler={(tile: TileData) => {
            journalClickHandler(tile, navigate);
          }}
          tiles={latestNews.list.map((article: IArticleChild) => ({
            uid: article.uid,
            title: article.title,
            summary: article.summary,
            tags: generateNewsArticleTags(article),
            child_type: 'ARTICLE',
            image: {
              url: article.thumbnail_image,
            },
          }))}
        />
      ) : (
        newsError && (
          <>
            {isDashboard ? (
              <CustomError error='Could not load news' />
            ) : (
              <LoginRegisterPanel />
            )}
          </>
        )
      )}
    </div>
  );
};
