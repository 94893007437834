import React, { FC, useEffect, useState } from 'react';
import {
  StatusDiv,
  PermissionGroupContainer,
} from './permissionGroupRow.styles';
import { Tick, Plus } from '@jsluna/icons';
import { PermissionDeleteRow } from 'src/shared/components/permissionRow';
import {
  Button,
  Accordion,
  AccordionItem,
  GridWrapper,
  GridItem,
  IconButton,
} from '@jsluna/react';
import { DeleteOption } from 'src/components/Sections/PermissionGroupSection';
import { Drawer } from '@mui/material';
import { AddNewPermissionToGroupScreen } from 'src/screens/addNewPermissionToGroup';
import {
  PermissionDeleteRow_PermissionInfoFragment,
  PermissionGroupRow_PermissionGroupInfoFragment,
} from 'src/operations/generated/graphql';
import { gql } from '@apollo/client';

interface IPermissionGroupRow {
  permissionGroup: PermissionGroupRow_PermissionGroupInfoFragment;
  deleteHandler: (selected: any, deleteOption: DeleteOption) => void;
  setSelectedPermission: (
    permission: PermissionDeleteRow_PermissionInfoFragment,
  ) => void;
  disabled: boolean;
  applicationId: string;
}

const TickIcon = Tick as unknown as React.FC;
const PlusIcon = Plus as unknown as React.FC;

gql`
  fragment PermissionGroupRow_PermissionGroupInfo on PermissionGroupProjection {
    id
    name
    applicationId
    permissions {
      value {
        ...PermissionDeleteRow_PermissionInfo
      }
    }
  }
`;

export const PermissionGroupRow: FC<IPermissionGroupRow> = ({
  permissionGroup,
  deleteHandler,
  disabled,
  applicationId,
  setSelectedPermission,
}) => {
  const [showAddPermission, setShowAddPermission] = useState(false);
  const [permissionGroupObj, setPermissionGroupObj] = useState(permissionGroup);

  const handlePanelClose = () => {
    setShowAddPermission(false);
  };

  const accordionTitle = () => (
    <StatusDiv>
      <div className='chb-display'>
        <TickIcon />
      </div>
      <div className='label accordion-title'>{permissionGroup.name}</div>
    </StatusDiv>
  );

  useEffect(() => {
    setPermissionGroupObj(permissionGroup);
  }, [permissionGroup]);

  return (
    <PermissionGroupContainer>
      <GridWrapper>
        <GridItem size={{ md: '1/2' }}>
          <Accordion titleElement={accordionTitle}>
            <AccordionItem className='permission-group-accordion'>
              <div>
                {permissionGroupObj.permissions.length > 0 ? (
                  <>
                    <h5 className='body-1 permission-msg'>
                      Permissions associated with {permissionGroupObj.name}
                    </h5>
                    {permissionGroupObj.permissions.map(permission => (
                      <PermissionDeleteRow
                        disabled={disabled}
                        permission={permission.value}
                        deleteHandler={() => {
                          deleteHandler(
                            permission,
                            DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP,
                          );
                        }}
                        key={permission.value.id}
                        customClass='less-padding'
                      />
                    ))}
                  </>
                ) : (
                  <>
                    <h5 className='body-1'>
                      No permissions available for this group
                    </h5>
                  </>
                )}
                {permissionGroup.id && (
                  <IconButton
                    variant='text'
                    circle={false}
                    disabled={disabled}
                    hard={false}
                    element='button'
                    label='Add permission'
                    className='ln-u-margin-top*3'
                    color='dark'
                    onClick={() => setShowAddPermission(true)}
                  >
                    <PlusIcon />
                  </IconButton>
                )}
              </div>
              <div className='ln-u-text-align-right'>
                <Button
                  variant='text'
                  disabled={disabled}
                  onClick={() => {
                    deleteHandler(
                      permissionGroupObj,
                      DeleteOption.DELETE_PERMISSION_GROUP,
                    );
                  }}
                  color='dark'
                  className='delete-button'
                >
                  Delete group
                </Button>
              </div>
            </AccordionItem>
          </Accordion>
        </GridItem>
      </GridWrapper>

      <Drawer
        open={showAddPermission}
        onClose={handlePanelClose}
        anchor='right'
        className='add-permission'
      >
        <AddNewPermissionToGroupScreen
          permissionGroup={permissionGroup}
          applicationId={applicationId}
          closeHandler={(
            newPermissions: PermissionDeleteRow_PermissionInfoFragment[],
            stayOpen?: boolean,
          ) => {
            if (newPermissions.length) {
              const optimisticPermissions = [
                ...permissionGroupObj.permissions.map(perm => perm.value),
                ...newPermissions,
              ];
              setPermissionGroupObj({
                ...permissionGroupObj,
                permissions: optimisticPermissions.map(perm => ({
                  value: perm,
                })),
              });
            }
            if (!stayOpen) {
              setShowAddPermission(false);
            }
          }}
        />
      </Drawer>
    </PermissionGroupContainer>
  );
};
