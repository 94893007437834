import React, { FC, useEffect, useState } from 'react';
import { Button, TextAreaField } from '@jsluna/react';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ScreenFeedback } from '../components/screenFeedback';
import { EditTagContainer } from './editTag.styles';
import { ApolloError } from '@apollo/client';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { gql } from '@apollo/client';

gql`
  fragment EditTag_Tag on TagProjection {
    name
  }
`;

interface IEditTag {
  handleConfirmation: (tagDescription: string) => Promise<void>;
  setEditId: (status: string | null) => void;
  setOpenEdit: (status: boolean) => void;
  tagName: string | null;
  description: string | null;
  openEdit: boolean;
  loading: boolean;
  error: ApolloError | undefined;
}

export const EditTag: FC<IEditTag> = ({
  setEditId,
  setOpenEdit,
  error,
  loading,
  handleConfirmation,
  tagName,
  description,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);
  const [tagDescription, setTagDescription] = useState<string>('');

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleSubmission = async () => {
    await handleConfirmation(tagDescription);
    setFeedBackMessage(NotificationType.SUCCESS);
  };
  return (
    <EditTagContainer>
      {feedBackMessage ? (
        <>
          <ScreenFeedback
            isLoading={loading}
            notificationType={feedBackMessage}
            feedBackMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? `The tag’s description has successfully been updated`
                : 'Unable to update tag. Please try again.'
            }
            saveButtonText={'Close'}
            onCloseHandler={() => {
              setOpenEdit(false);
              setEditId(null);
            }}
            resetButtonText={'Update another tag'}
            resetHandler={() => {
              setFeedBackMessage(null);
            }}
            notificationMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? 'Tag updated'
                : 'Failed to update tag'
            }
          />
        </>
      ) : (
        <>
          <h4>Edit tag</h4>
          <TextAreaField
            name='text-area-field-1'
            label='Tag description'
            defaultValue={description}
            onChange={(e: any) => {
              setTagDescription(e.target.value);
            }}
          />
        </>
      )}
      {!feedBackMessage && (
        <PanelActions>
          <>
            <Button
              onClick={() => {
                handleSubmission();
              }}
              variant='filled'
              disabled={!tagDescription}
              type='submit'
            >
              Save
            </Button>

            <Button
              hard={false}
              variant='outlined'
              onClick={() => {
                setOpenEdit(false);
                setEditId(null);
              }}
            >
              Cancel
            </Button>
          </>
        </PanelActions>
      )}
    </EditTagContainer>
  );
};
