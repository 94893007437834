import { FC, useEffect, useState } from 'react';
import { Table } from '@jsluna/react';
import { useGetBrexitUploadUserHistory } from 'src/operations/queries/getBrexitUploadUserHistory';
import { TableWrapper } from './uploadHistoryTableConnected.styles';
import { ValidEntries } from '../validEntriesCell';

interface IUploadHistoryTableConnected {
  token: string;
}

export const UploadHistoryTableConnected: FC<IUploadHistoryTableConnected> = ({
  token,
}) => {
  const {
    data: { uploadHistory } = {},
    loading: uploadHistoryLoading,
    refetch,
  } = useGetBrexitUploadUserHistory(token);

  const intervalTime = 5000;

  const [sortedList, setSortedList] = useState([]);
  const [intervalNumber, setIntervalNumber] = useState<NodeJS.Timer | null>(
    null,
  );

  const CellValueWrapper = ({ value, renderer }: any) => {
    if (!value) return <span>-</span>;
    return renderer ? renderer() : value;
  };

  const formatDate = (date: string) => {
    if (!date) return null;
    return new Date(date).toISOString().replace(/T/, ' ').replace(/\..+/, '');
  };

  const userColumns = [
    {
      name: 'File Name',
      accessor: (rowData: any) => ({ value: rowData.file_name }),
    },
    {
      name: 'Sheet Type',
      accessor: (rowData: any) => ({
        value:
          rowData.sheet_type === 'SUPPLIER_PACK'
            ? 'Supplier Pack'
            : rowData.sheet_type === 'PRODUCTS'
            ? 'Products'
            : '',
      }),
    },
    {
      name: 'Processed At',
      accessor: (rowData: any) => ({
        value: formatDate(rowData.file_processed_at),
      }),
    },
    {
      name: 'Process Status',
      accessor: (rowData: any) => ({ value: rowData.file_process_status }),
      render: (rowData: any) => (
        <span
          className={`publish-status
                  ${rowData.value === 'PROCESSED' ? ' completed' : ' failed'}
                    `}
        >
          {rowData.value}
        </span>
      ),
    },
    {
      name: 'Valid Entries',
      render: (rowData: any) => (
        <>
          <ValidEntries rowData={rowData} />
        </>
      ),
    },
    {
      name: 'Publish Status',
      render: (rowData: any) => (
        <CellValueWrapper
          value={rowData.publish_status}
          renderer={() => (
            <span
              className={`publish-status
                  ${
                    rowData.publish_status === 'COMPLETED'
                      ? ' completed'
                      : rowData.publish_status === 'FAILED'
                      ? ' failed'
                      : rowData.publish_status === 'PARTIALLY_COMPLETED'
                      ? ' warn'
                      : ' in-process'
                  }
                    `}
            >
              {rowData.publish_status}
            </span>
          )}
        />
      ),
    },
    {
      name: 'Published At',
      render: (rowData: any) => (
        <CellValueWrapper value={formatDate(rowData.publish_finished_at)} />
      ),
    },
  ];

  useEffect(() => {
    if (uploadHistory?.length > 0) {
      const list = uploadHistory.slice();
      list.sort((a: any, b: any) =>
        new Date(a.file_processed_at) < new Date(b.file_processed_at) ? 1 : -1,
      );
      setSortedList(list);
    }
  }, [uploadHistory]);

  useEffect(() => {
    if (sortedList.length > 0) {
      const inProcess = sortedList.find(
        (data: any) => data.publish_status && data.publish_status === 'STARTED',
      );
      if (inProcess && !intervalNumber) {
        const interval = setInterval(refetch, intervalTime);
        setIntervalNumber(interval);
      } else if (!inProcess && intervalNumber) {
        setIntervalNumber(null);
      }
    } else if (intervalNumber) {
      setIntervalNumber(null);
    }
  }, [sortedList, intervalNumber, intervalTime]);

  return (
    <TableWrapper>
      {uploadHistoryLoading ? (
        <div>Loading</div>
      ) : (
        sortedList && (
          <Table
            className='history-table'
            responsive={true}
            rowKey='id'
            columns={userColumns}
            data={sortedList}
          />
        )
      )}
    </TableWrapper>
  );
};
