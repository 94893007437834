import { FC, useState, useEffect, useCallback } from 'react';
import { generateBreadcrumbs } from 'src/utils/getBreadcrumbs';
import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import { TileGroup } from '@sainsburys-tech/suppex-tile-group';
import { Link, useNavigate } from 'react-router-dom';
import { useGetSupplierArticleGroupings } from 'src/operations/queries/getSupplierArticleGroupings';
import { ArticleTeaser } from '@sainsburys-tech/suppex-article-teaser';
import { journalClickHandler } from 'src/utils/journalClickHandler';
import { IArticle } from 'src/interfaces/article.interface';
import { generateJournalTags } from 'src/utils/generateJournalTags';
import { IArticleChild } from 'src/interfaces/articleChild.interface';
import { TileData } from '@sainsburys-tech/suppex-tile';
import { JournalLoading } from 'src/components/JournalLoading';
import { JournalError } from 'src/components/JournalError';

export const SupplierJournalsPage: FC = () => {
  const navigate = useNavigate();
  const [latestIssue, setLatestIssue] = useState<IArticle | null>(null);
  const [issues, setIssues] = useState<IArticle[]>([]);
  const [filteredIssues, setFilteredIssues] = useState<IArticle[]>([]);
  const desc = `Every quarter we release a digital magazine exploring the work we do at Sainsbury’s, with our values at the forefront of the decisions we make and projects we undertake.`;

  const {
    data: { articles: journalData } = {},
    error: journalError,
    loading: journalLoading,
  } = useGetSupplierArticleGroupings('Journal');

  useEffect(() => {
    if (journalData?.length) {
      setIssues(journalData.slice(1));
      setLatestIssue(journalData[0]);
    }
  }, [journalData]);

  const handleSeeMore = () => {
    if (filteredIssues.length < issues.length) {
      setFilteredIssues(issues);
    }
  };

  useEffect(() => {
    if (issues.length > 0) {
      const within3Years = new Date();
      within3Years.setFullYear(within3Years.getFullYear() - 3);
      const filtered = issues?.filter(
        issue => new Date(issue.creation_date) > within3Years,
      );

      setFilteredIssues(filtered);
    }
  }, [issues]);

  const renderChildrenList = (children: IArticleChild[]) => {
    const topThree = children.slice(0, 3);
    return (
      <div className={`in-this-issue`}>
        <b>In this issue</b>
        <ul>
          {topThree.map((child, i) => (
            <li key={`article-list-${i}`}>{child.title}</li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
      <TopOfPage
        title='Supplier Journal'
        breadcrumbs={generateBreadcrumbs({
          isJournalsLanding: true,
          omitNewsAndArticles: true,
        })}
        LinkType={Link}
        subtitle={desc}
      />

      {journalLoading || (latestIssue == null && !journalError) ? (
        <JournalLoading />
      ) : journalError ? (
        <JournalError />
      ) : (
        <>
          {latestIssue != null && (
            <div data-cy='latestIssueSection' className='ln-u-padding-top*2'>
              <ArticleTeaser
                title='Latest issue'
                teaserTitle={`What's in the ${latestIssue?.title} issue...`}
                imageTileProps={{
                  onClickHandler: () =>
                    journalClickHandler(latestIssue, navigate),
                  title: latestIssue.title,
                  summary: latestIssue?.summary,
                  image: {
                    url: latestIssue?.teaser_image,
                    alt: '',
                    metaDesc: '',
                  },
                  style: 'grey',
                  tags: generateJournalTags(latestIssue),
                }}
                list={latestIssue?.children?.slice(0, 3).map(child => ({
                  title: child?.title,
                  subtitle: child?.summary,
                  onClickHandler: () =>
                    journalClickHandler(child, navigate, latestIssue),
                }))}
                primaryButtonText={`Read ${latestIssue?.title} issue`}
                primaryButtonHandler={() =>
                  journalClickHandler(latestIssue, navigate)
                }
              />
            </div>
          )}

          <div data-cy='allIssuesSection' className='ln-u-padding-top*4'>
            <TileGroup
              title='All issues'
              tiles={filteredIssues?.map(
                issue =>
                  ({
                    image: { url: issue.teaser_image, alt: '', metaDesc: '' },
                    ...issue,
                    tags: generateJournalTags(issue),
                    summary: renderChildrenList(issue.children),
                  } as unknown as TileData),
              )}
              variant='card-column'
              style={'light'}
              tileClickHandler={(tile: TileData) => {
                journalClickHandler(tile, navigate);
              }}
              seeMoreButtonPosition='bottom'
              seeMoreHandler={
                filteredIssues.length < issues.length
                  ? handleSeeMore
                  : undefined
              }
            />
          </div>
        </>
      )}
    </>
  );
};
