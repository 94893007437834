import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const LatestHeadlinesWrapper = styled('div')`
  .heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    color: ${ThemeColors.monoChromeBlack};
    margin-bottom: 14px;
  }

  ul {
    li {
      a {
        font-family: 'Arial';
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: ${ThemeColors.monochromeDark};
      }
      margin-bottom: 12px;
    }
  }
`;
