import DOMPurify from 'dompurify';
import React, { FC, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import {
  acceptStyle,
  activeStyle,
  baseStyle,
  rejectStyle,
} from './dropzone.tyles';

interface IDropzone {
  onDrop: any;
  accept: any;
  acceptedFiles: any;
  maxFiles?: any;
}

export const Dropzone: FC<IDropzone> = ({
  onDrop,
  maxFiles = 1,
  accept,
  acceptedFiles,
}) => {
  const [style, setStyle] = useState<any>({ ...baseStyle });
  const [acceptTypeNames, setAcceptTypeNames] = useState<any>();

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    accept: accept ? accept.map((f: Accept) => f.type) : undefined,
    maxFiles,
    multiple: false,
  });

  useEffect(
    () =>
      setStyle({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
    [isDragActive, isDragReject, isDragAccept],
  );

  useEffect(() => {
    setAcceptTypeNames(() =>
      accept
        ? accept.filter((t: Accept) => t.name).map((t: Accept) => t.name)
        : null,
    );
  }, [accept]);

  return (
    <section>
      <div {...getRootProps({ style, className: 'cursor--pointer' })}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the files here ...</p>
        ) : (
          <>
            <p>
              Drag 'n' drop {maxFiles > 1 ? 'some files' : 'file'} here, or
              click to select it
            </p>
            {acceptTypeNames && (
              <em data-testid='file-support'>
                <span>Only </span>
                {acceptTypeNames?.map((f: any, i: number) => (
                  <span key={i}>
                    {i > 0 && ', '}
                    {f}
                  </span>
                ))}
                <span>
                  {' '}
                  {acceptTypeNames.length > 1 ? 'are' : 'is'} supported
                </span>
              </em>
            )}
          </>
        )}
      </div>
      {acceptedFiles && acceptedFiles.length > 0 && (
        <aside data-testid='accepted-files'>
          <h4>{acceptedFiles.length > 1 ? 'Files' : 'File'}</h4>

          <ul>
            {acceptedFiles?.map((file: any) => (
              <li key={file.path}>{file.path}</li>
            ))}
          </ul>
        </aside>
      )}
    </section>
  );
};
