import React, { FC } from 'react';
import { SideNavigation } from '../sideMenuNavigation';

interface ISideMenu {
  currentIndex: number;
  setCurrentIndex: (section: any, sub_section?: any) => void;
  setSubSection: (index: number) => void;
  sections: any[];
  sectionsTitles: any[];
}
export const SideMenu: FC<ISideMenu> = ({
  currentIndex,
  setCurrentIndex,
  setSubSection,
  sections,
  sectionsTitles,
}) => {
  const navigationSubMenuListFormatted = sections.reduce(
    (sectionObj, section, sectionIndex) => {
      if (section.sub_sections.length >= 1) {
        section.sub_sections.reduce(
          (sub_sectionArr: any[], sub_section: { title: any }) => {
            sub_sectionArr.push(sub_section.title);
            return (sectionObj[`${sectionIndex + 1}`] = sub_sectionArr);
          },
          [],
        );
      }
      return sectionObj;
    },
    {},
  );

  return (
    <SideNavigation
      showNumberInTitle={false}
      showSubMenuList
      setSubSection={setSubSection}
      navigationSubMenuList={navigationSubMenuListFormatted}
      navigationList={sectionsTitles}
      currentIndex={currentIndex}
      setCurrentIndex={setCurrentIndex}
    />
  );
};
