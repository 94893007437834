import styled from 'styled-components';

export const LoaderContainer = styled('div')`
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 100%;
  z-index: 1500;
  overflow: hidden;
`;
