import styled from 'styled-components';
import { Card } from '@jsluna/react';

export const PageHeaderCard = styled((props: any) => <Card {...props} />)`
  padding-top: 140px;
  padding-bottom: 78px;

  margin-bottom: 48px;

  .header {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 50px;
    line-height: 62px;
    color: #f06c00;
  }

  .sub-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: #404040;
    margin-top: 12px;
  }

  .top-heading {
    font-family: 'Arial';
    font-size: 16px;
    line-height: 24px;
    color: #404040;
  }

  .ln-c-breadcrumbs__link {
    text-decoration: none !important;
  }
`;
