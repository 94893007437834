import React, { FC } from 'react';
import Container from 'src/components/Container/Container';
import { useAppSelector } from 'src/redux/hooks';
import { getProfileDetails } from 'src/redux/selectors';
import { PageHeader } from 'src/components/PageHeader';
import { capitalize } from 'src/utils/capitalize';
import { SupplierNews } from '../newsAndArticles/components/SupplierNews';
import { useGetSupplierArticleGroupings } from '../../operations/queries/getSupplierArticleGroupings';
import { useGetSupplierNews } from '../../operations/queries/getSupplierNews';
import { gql } from '@apollo/client';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { PageSeparator } from '../dashboard/components/pageSeparator';
import { WorkingWithSainsburysSection } from '../dashboard/components/workingWithSainsburys';
import { SupplierJournalSection } from '../dashboard/components/supplierJournalSection';
import { PlanForBetterSection } from '../dashboard/components/planForBetterSection';
import { PopularHelpTopicsSection } from '../dashboard/components/popularHelpTopics';
import { Card, GridWrapper, GridItem } from '@jsluna/react';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';
import { useProspective_MyApplicationsQuery } from 'src/operations/generated/graphql';
import { useNavigate } from 'react-router';

gql`
  fragment Prospective_ApplicationInfo on ApplicationProjection {
    id
    acronym
    name
    shortDescription
    longDescription
    slug
    isLive
    proxyLocation
    isRoot
    type
  }
`;
gql`
  query Prospective_MyApplications {
    myApplications {
      ...Prospective_ApplicationInfo
    }
  }
`;

export const Prospective: FC = () => {
  const profile = useAppSelector(getProfileDetails);
  const navigate = useNavigate();

  const {
    data: { news: latestNews } = {},
    error: newsError,
    loading: newsLoading,
  } = useGetSupplierNews(1, 4);

  const {
    data: { articles: journalData } = {},
    error: journalError,
    loading: journalLoading,
  } = useGetSupplierArticleGroupings('Journal');

  const { data: { myApplications: applications } = {} } =
    useProspective_MyApplicationsQuery();

  return (
    <>
      <PageHeader
        heading={`Hi ${capitalize(
          profile?.preferredName || profile?.firstName || '',
        )},`}
        topHeading='Together With'
        subHeading='Welcome to Together With.'
      />

      <Container className='ln-u-hard-top'>
        {applications && applications?.length > 0 && (
          <>
            <SectionHeader heading=' Please launch the Supplier Onboarding app to continue onboarding or check your progress.' />
            <Card>
              <GridWrapper>
                {applications.map(application => (
                  <GridItem
                    size={{ md: '1/3', xs: '1/1' }}
                    className='app-grid-item'
                    key={application.id}
                  >
                    <AppTile
                      application={application}
                      navigateToDetailsPage={() => {
                        navigate(`/applications/${application.id}`);
                      }}
                    />
                  </GridItem>
                ))}
              </GridWrapper>
            </Card>
          </>
        )}

        <PageSeparator />
        <WorkingWithSainsburysSection />
        <PageSeparator />
        <SupplierNews
          isDashboard={true}
          latestNews={latestNews}
          newsError={newsError}
          newsLoading={newsLoading}
        />
        <PageSeparator />
        <SupplierJournalSection
          journalData={journalData}
          journalError={journalError}
          journalLoading={journalLoading}
        />
        <PageSeparator />
        <PlanForBetterSection />
        <PageSeparator />
        <PopularHelpTopicsSection />
      </Container>
    </>
  );
};
