import React, { FC } from 'react';
import { ProgressSpinner } from '@jsluna/react';
import { JournalErrorContainer } from './JournalError.styles';

interface IJournalError {
  title?: string;
  message?: string;
}

export const JournalError: FC<IJournalError> = ({ title, message }) => {
  const tryAgain = () => {
    window.location.reload();
  };

  return (
    <JournalErrorContainer>
      <div className='error-container'>
        <div className='img-section'>
          <img
            className='img-error'
            src={`${process.env.PUBLIC_URL}/img/404.svg`}
            alt='Service Error'
          />
        </div>
        <div className='text-section'>
          <h3 className='title'>{title || 'Something went wrong!'}</h3>
          <div className='title'>
            {message || 'There was a problem while loading journal article.'}
          </div>
        </div>
      </div>
      <button onClick={tryAgain} className='ln-c-button ln-c-button--filled'>
        Try Again
      </button>
    </JournalErrorContainer>
  );
};
