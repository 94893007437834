import React, { FC, useEffect, useState } from 'react';
import { GridWrapper, GridItem, Container, FilledButton } from '@jsluna/react';
import { selectSize } from 'src/constants/selectSize';
import { TradingTermsAndConditionsText } from '../tradingTermsAndConditionsText/tradingTermsAndConditionsText';
import { TradingTermsAndConditionsSupplierCodes } from '../tradingTermsSupplierCodes';
import { DocumentDownloader } from '../documentDownloader';
import { TermsAndConditions_GroupSitesFragment } from 'src/operations/generated/graphql';
import { PostTermsPayload } from '../../termsAndConditions';

import { useAppSelector } from 'src/redux/hooks';
import { getSelectedAccount } from 'src/redux/selectors';
import { AcceptanceStatus } from '../acceptanceStatus';

const errorMessage = 'Please select at least one';
const authorityErrorMessage = 'Please confirm you have authority';

interface ITermsPage {
  showSupplierCodes: boolean;
  supplierCodesAuthority: boolean;
  setSupplierCodesAuthority: (isChecked: boolean) => void;
  supplierCodes: TermsAndConditions_GroupSitesFragment[];
  tradingTerms: any;
  postAcceptedTerms: (payload: PostTermsPayload) => void;
  acceptedStatus: 'SUCCESS' | 'ERROR' | null;
}

export const TermsPage: FC<ITermsPage> = ({
  showSupplierCodes,
  supplierCodesAuthority,
  setSupplierCodesAuthority,
  supplierCodes,
  tradingTerms,
  postAcceptedTerms,
  acceptedStatus,
}) => {
  const selectedAccount = useAppSelector(getSelectedAccount);

  const tradingTermsRevisionDate = new Date(
    tradingTerms?.revision_date,
  ).toLocaleString('en-GB', {
    month: 'long',
    year: 'numeric',
  });

  const submit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    const code = supplierCodes.find(code => {
      return code.tncStatus !== null;
    });

    if (code?.supplierNumber) {
      postAcceptedTerms({
        body: {
          suppliers: code.supplierNumber,
        },
        isAgent: !selectedAccount?.isPartner,
      });
    }
  };

  return (
    <>
      <Container className='ln-u-soft-top-lg ln-u-soft-bottom'>
        <GridWrapper matrix horizontalAlign='center'>
          <GridItem size={{ default: '5/6', lg: '3/4' }}>
            <div>{tradingTermsRevisionDate}</div>
          </GridItem>
        </GridWrapper>
      </Container>
      <Container className='ln-u-soft-bottom-xl'>
        <GridWrapper matrix horizontalAlign='center'>
          <GridItem size={{ default: '5/6', lg: '3/4' }}>
            <TradingTermsAndConditionsText tradingTerms={tradingTerms} />
          </GridItem>

          {acceptedStatus ? (
            <GridItem size={{ default: '5/6', lg: '3/4' }}>
              <AcceptanceStatus status={acceptedStatus} />
            </GridItem>
          ) : (
            <>
              {showSupplierCodes && supplierCodes.length > 0 && (
                <GridItem size={{ default: '5/6', lg: '3/4' }}>
                  <TradingTermsAndConditionsSupplierCodes
                    supplierCodes={supplierCodes}
                    supplierCodesAuthority={supplierCodesAuthority}
                    updateSupplierCodesAuthority={(value: boolean) => {
                      setSupplierCodesAuthority(value);
                    }}
                  />
                </GridItem>
              )}
            </>
          )}

          <GridItem size={{ default: '5/6', lg: '3/4' }}>
            <div className='ln-u-push-top-xl ln-u-soft-top-sm'>
              <GridWrapper verticalAlign='center' gutterSize='sm' matrix={true}>
                {showSupplierCodes && supplierCodes.length > 0 && (
                  <GridItem size={selectSize}>
                    <FilledButton
                      type='submit'
                      fullWidth
                      onClick={(event: React.SyntheticEvent) => {
                        submit(event);
                      }}
                      data-testid='submit-accept'
                      className='t-button-submit-accept'
                      disabled={!supplierCodesAuthority || acceptedStatus}
                    >
                      <span data-cy='submit-button'>Accept</span>
                    </FilledButton>
                  </GridItem>
                )}
                <GridItem size={selectSize}>
                  <DocumentDownloader />
                </GridItem>
              </GridWrapper>
            </div>
          </GridItem>
        </GridWrapper>
      </Container>
    </>
  );
};
