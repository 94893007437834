import styled from 'styled-components';

export const NotificationWrapper = styled('div')`
  .notifications {
    animation-duration: 0.5s;
    animation-name: fadeIn;
    animation-timing-function: ease;
    left: 0;
    padding: 0;
    right: 0;
  }
  @media (min-width: 960px) {
    .notifications {
      padding: 0 1.5rem;
    }
  }
  @media (min-width: 960px) {
    .notifications {
      padding: 0 2rem;
    }
  }
  .notifications__item {
    background-color: #404040;
    display: flex;
    flex-direction: row;
    padding-left: 1.5rem;
  }
  .notifications__item-message {
    color: #fff;
    display: flex;
    flex: auto;
    flex-direction: column;
    font-weight: 700;
    margin: 0.5rem 0;
  }
  .notifications__item-message:first-child {
    align-self: center;
  }
  .notifications .notifications__item-close {
    color: #fff;
    display: flex;
    flex-direction: column;
    padding: 1rem;

    &:hover {
      background-color: rgba(0, 0, 0, 0.06);
      color: #000;
    }
  }
  .notifications .notifications__item-close:first-child {
    align-self: center;
  }
  .notifications--fixed {
    left: 0;
    padding: 0;
    position: fixed;
  }
  .notifications--top-fixed {
    left: 0;
    padding: 0;
    position: fixed;
    top: 0;
    z-index: 1;
  }
  .notifications--bottom-fixed {
    bottom: 0;
    left: 0;
    padding: 0 0.5rem 0.4rem;
    position: fixed;
    right: 0;
  }
  @media (min-width: 960px) {
    .notifications--bottom-fixed {
      padding: 0 1.5rem 1rem;
    }
  }
  @media (min-width: 960px) {
    .notifications--bottom-fixed {
      padding-right: 0;
      right: 50%;
    }
  }
  .notifications--accepted--top {
    left: 0;
    padding: 0;
    position: relative;
    top: 0;
    z-index: 1;
  }
  .notifications--accepted--top__tick-circle {
    align-items: center;
    color: #fff;
    display: flex;
  }
  .notifications--accepted--top__tick-circle .tick-icon {
    align-items: center;
    background-color: #fff;
    border-radius: 50%;
    display: inline-flex;
    flex-direction: column;
    height: 1.5625rem;
    justify-content: center;
    width: 1.5625rem;
  }
  .notifications--confirm .notifications__item {
    background-color: #2e7d32;
  }
  .notifications--green .notifications__item {
    background-color: #2e7d32;
  }
  .notifications--yellow .notifications__item {
    background-color: #ffd600;
  }
  .notifications--red .notifications__item {
    background-color: #d50000;
  }
  @keyframes slide {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0%);
    }
  }
`;
