import { FC, KeyboardEvent } from 'react';
import { scrollToElement } from 'src/utils/scrollTo';
import { List, ListItem } from '@jsluna/react';
import DOMPurify from 'dompurify';

interface IWaysOfWorkingSubSectionMenu {
  subSections: any[];
}

export const WaysOfWorkingSubSectionMenu: FC<IWaysOfWorkingSubSectionMenu> = ({
  subSections,
}) => {
  return (
    <>
      <h4 className='ln-u-color-grey-dark ln-u-push-top'>
        What&#39;s in this section?
      </h4>
      <div className='light-grey-menu-box'>
        <List type='bare'>
          {subSections.map((link, index) => (
            <ListItem className='ln-u-push-bottom' key={index + 1}>
              <span
                tabIndex={0}
                className='ln-c-standalone-link cursor--pointer'
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(link.title),
                }}
                data-testid='sub-section-link'
                onClick={() => scrollToElement(`#sub-section-${index + 1}`)}
                onKeyDown={(e: KeyboardEvent) => {
                  if (e.key === 'Enter') {
                    scrollToElement(`#sub-section-${index + 1}`);
                  }
                }}
              />
            </ListItem>
          ))}
        </List>
      </div>
    </>
  );
};
