import { ReactNode } from 'react';
import { GlobalFooter } from '@sainsburys-tech/supplier-experience-global-footer';

import Main from './Main/Main';

interface IAppLayoutProps {
  children: ReactNode;
}

const AppLayout: React.FC<IAppLayoutProps> = ({ children }) => {
  return (
    <>
      <Main>{children}</Main>

      <GlobalFooter oneTrustAppId='932215f5-0f9d-4ed6-8ca7-5e5425cf3a18' />
    </>
  );
};

export default AppLayout;
