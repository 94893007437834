import styled from 'styled-components';
import { DetailsCard } from '../../viewApplication.styles';

export const StyledViewApplicationLoader = styled((props: any) => (
  <DetailsCard {...props} />
))`
  height: 146px;
  position: relative;
  overflow: hidden;

  cursor: pointer;
  transition: all var(--ds-modifier-transition-duration);
`;
