import React, { FC } from 'react';
import { GridWrapper, GridItem, Card } from '@jsluna/react';
import { QuickLinkCard } from '../quickLinkCard/quickLinkCard';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { QuickLinkHeadlines } from '../quickLinkHeadlines/quickLinkHeadlines';
import { QuickLinkStyledCard } from '../quickLinkCard/quickLinkCard.styles';
import { routes } from 'src/constants/routes';

interface IQuickLinkSections {
  latestNews: any;
  newsError: any;
  newsLoading: boolean;
  journalData: any;
  journalError: any;
  journalLoading: boolean;
}

export const QuickLinkSections: FC<IQuickLinkSections> = ({
  latestNews,
  newsError,
  newsLoading,
  journalData,
  journalError,
  journalLoading,
}) => {
  return (
    <>
      <SectionHeader
        heading='Quick links'
        subHeading='Read the latest news, our quarterly Supplier Journal or access Help & Support topics '
      />

      <GridWrapper verticalAlign='middle' equalHeight>
        <GridItem size={{ md: '2/6' }}>
          <QuickLinkCard
            title='News'
            description='Read all about the latest supplier news headlines, events and topics. '
            color='blue'
            link='/news-and-articles/news'
          />
        </GridItem>
        <GridItem size={{ md: '2/6' }}>
          <QuickLinkCard
            title='Supplier Journal'
            description='Read the latest issue of our quarterly magazine exploring Sainsbury’s.'
            color='orange'
            link='/news-and-articles/journals'
          />
        </GridItem>
        <GridItem size={{ md: '1/6' }}>
          <QuickLinkCard
            title='Help & Support'
            color='maroon'
            link={routes.supplierSupport}
          />
        </GridItem>
        <GridItem size={{ md: '1/6' }}>
          <QuickLinkCard
            title='Supplier Training'
            color='yellow'
            link='https://togetherwith.co.uk/applications/APP_supplier-academy'
          />
        </GridItem>
        <GridItem size={{ md: '2/6' }}>
          {!newsError && latestNews?.list?.length && (
            <QuickLinkHeadlines
              heading='Latest headlines'
              links={latestNews.list
                .slice(0, 3)
                .map(({ uid, title }: { uid: string; title: string }) => ({
                  name: title,
                  link: `/news-and-articles/news/article/${uid}`,
                }))}
            />
          )}
        </GridItem>
        <GridItem size={{ md: '2/6' }}>
          {!journalError && journalData?.[0]?.children?.length && (
            <QuickLinkHeadlines
              heading={`${journalData[0].title} issue`}
              links={journalData[0].children
                .slice(0, 3)
                .map(({ uid, title }: { uid: string; title: string }) => ({
                  name: title,
                  link: `/news-and-articles/journals/${journalData[0].uid}/article/${uid}`,
                }))}
            />
          )}
        </GridItem>
        <GridItem size={{ md: '1/6' }}>
          <QuickLinkCard
            title='Plan for Better'
            color='grey'
            link='/news-and-articles/plan-for-better/bltc5f10aeea4b5d71b'
          />
        </GridItem>
        <GridItem size={{ md: '1/6' }}>
          <QuickLinkCard
            title='Supplier Handbook'
            color='green'
            link='/ways-of-working'
          />
        </GridItem>
      </GridWrapper>
    </>
  );
};
