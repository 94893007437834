import { IAppByTags } from 'src/interfaces/appByTags.interface';
import { IApplicationGroup } from 'src/interfaces/applicationGroup.interface';
import { createContext, useContext } from 'react';
import { IMappedApplication } from 'src/interfaces/mappedApplication.interface';
import { IApplication } from '@interfaces/application.interface';

export interface IApplicationsState {
  mappedApplications: IApplication[];
  filteredApplications: IApplication[];
  showAllApps: boolean;
  showRequestPermissionsModal: boolean;
  showAssignGroupModal: boolean;
  tagsArray: Set<string>;
  selectedApplication: IMappedApplication | null;
  selectedApplicationGroup: IApplicationGroup | null;
  refetchMyApps: boolean;
  showAddNewAppGroup: boolean;
}

export interface IApplicationsContext {
  state: IApplicationsState;
  dispatch: any;
}

const ApplicationsContext = createContext<IApplicationsContext>({
  state: {} as IApplicationsState,
  dispatch: null,
});

export const useApplicationsContext = () => {
  return useContext(ApplicationsContext);
};

export { ApplicationsContext };
