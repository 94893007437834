import * as Yup from 'yup';

export default Yup.object().shape(
  {
    name: Yup.string(),
    subject: Yup.string().required('Please provide a subject'),
    feedback: Yup.string().required('Please provide feedback'),
  },
  [],
);
