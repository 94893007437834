import React, { FC } from 'react';
import { useParams } from 'react-router';
import Container from 'src/components/Container/Container';
import { PageHeader } from '../../components/PageHeader';
import { DetailsCard } from '../viewSite/viewSite.styles';
import { Section } from '../../components/Sections/section.styles';
import { DetailsRow } from '../../shared/components/detailsRow';
import { AccountDetailStatus } from '../viewProfile/components/accountDetailStatus';
import { gql } from '@apollo/client';
import { viewSiteRoute } from 'src/constants/routes';

import { useOrganisationQuery } from 'src/operations/generated/graphql';

gql`
  fragment ViewOrganisation_GetOrganisation on OrganisationGroupProjection {
    id
    name
    sites {
      value {
        id
        name
      }
    }
  }
`;
gql`
  query Organisation($id: String!) {
    organisation(id: $id) {
      ...ViewOrganisation_GetOrganisation
    }
  }
`;

export const ViewOrganisation: FC = () => {
  const { organisationId } = useParams();

  const { data: { organisation = null } = {} } = useOrganisationQuery({
    variables: {
      id: organisationId || '',
    },
  });

  return (
    <>
      <PageHeader
        heading={`${organisation?.name || 'Organisation name'}`}
        breadcrumbLinks={[
          { name: 'home', link: '/' },
          { name: 'Sites', link: '/sites' },
          { name: organisation?.name || 'Organisation name', link: '' },
        ]}
      />

      {organisation ? (
        <Container size='md'>
          <DetailsCard>
            <Section>
              <h4 className='display-1 ln-u-margin-bottom*3'>Details</h4>
              <DetailsRow name='id' value={organisation.id} />
              <DetailsRow
                name='Name'
                value={organisation.name || 'UNAVALIABLE'}
              />
            </Section>

            <Section>
              <h4 className='display-1 ln-u-margin-bottom*3'>Sites</h4>
              {organisation.sites.map(site => (
                <AccountDetailStatus
                  label={site.value.name || 'UNAVALIABLE'}
                  link={viewSiteRoute({ siteId: site.value.id })}
                />
              ))}
            </Section>
          </DetailsCard>
        </Container>
      ) : (
        <p>No Data</p>
      )}
    </>
  );
};
