import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Container from 'src/components/Container/Container';
import { ProfilePageWrapper } from './viewProfile.styles';
import { useAppSelector } from 'src/redux/hooks';
import { getProfileDetails } from 'src/redux/selectors';
import { PageHeader } from 'src/components/PageHeader';
import { ProfileCard } from './components/profileCard';
import { AccountCard } from './components/accountCard';
import { gql } from '@apollo/client';
import { capitalize } from 'src/utils/capitalize';
import {
  ProfileQuery,
  useProfileLazyQuery,
} from 'src/operations/generated/graphql';

gql`
  fragment ViewProfile_ProfileInfo on ProfileProjection {
    id
    firstName
    selectedAccountId
  }
`;

gql`
  query Profile($id: String!) {
    profile(id: $id) {
      ...ViewProfile_ProfileInfo
      ...ProfileCard_ProfileInfo
    }
  }
`;

export const ViewProfile: FC = () => {
  const baseBreadcrumb = {
    name: 'Together With',
    link: '/',
  };

  const { profileId, accountId } = useParams();
  const [profile, setProfile] = useState<ProfileQuery['profile'] | null>(null);
  const [breadCrumbs, setBreadCrumbs] = useState([baseBreadcrumb]);
  const [getSingleProfile, { data: { profile: singleProfile = null } = {} }] =
    useProfileLazyQuery({
      variables: { id: profileId || '' },
    });

  const currentProfile = useAppSelector(getProfileDetails);

  useEffect(() => {
    if (profileId) {
      setBreadCrumbs([
        baseBreadcrumb,
        {
          name: 'Profiles',
          link: '/profiles',
        },
        {
          name: 'View Profile',
          link: '',
        },
      ]);
    } else {
      setBreadCrumbs([
        baseBreadcrumb,
        {
          name: 'My Profile',
          link: '',
        },
      ]);
    }
  }, [profileId]);

  useEffect(() => {
    if (profileId) {
      getSingleProfile();
      return;
    }
    setProfile(currentProfile as ProfileQuery['profile']);
  }, [currentProfile, profileId]);

  useEffect(() => {
    if (singleProfile) {
      setProfile(singleProfile);
    }
  }, [singleProfile]);

  const isMyProfile = profileId ? false : true;

  return (
    <ProfilePageWrapper>
      {profile && (
        <>
          <PageHeader
            heading={`${
              profileId
                ? capitalize(profile?.firstName || 'UNAVALIABLE') + "'s"
                : 'Your'
            } profile`}
            breadcrumbLinks={breadCrumbs}
          />
          <Container size='xs'>
            <ProfileCard
              profile={profile}
              setProfile={setProfile}
              isViewProfile={profileId ? true : false}
            />
            <AccountCard
              accountId={
                isMyProfile ? profile.selectedAccountId || '' : accountId || ''
              }
              profileId={profile.id}
              isMyProfile={isMyProfile}
            />
          </Container>
        </>
      )}
    </ProfilePageWrapper>
  );
};
