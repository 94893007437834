import React, { FC, useEffect, useState } from 'react';
import {
  GridItem,
  GridWrapper,
  FilledButton,
  ProgressSpinner,
} from '@jsluna/react';
import { ContentWrapper } from '../contentWrapper';
import { NotificationBanner } from '../notificationBanner';
import { fileExtensions, mimeTypes } from 'src/constants/mimeTypes';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { Dropzone } from '../dropzone';
import useUploadBrexitFile from 'src/operations/mutations/uploadBrexitFile';
import { GET_BREXIT_UPLOAD_AUDIT_QUERY } from 'src/operations/queries/getBrexitUploadAudits';
import { useQuery } from '@apollo/client';
import { useGetBrexitUploadUserHistory } from 'src/operations/queries/getBrexitUploadUserHistory';

const acceptedFileTypes = [
  {
    name: 'XLSM',
    type: mimeTypes.XLSM,
  },
  {
    type: fileExtensions.XLSM,
  },
];

const DEFAULT_REASON = 'Unfortunately, no error message was provided.';

interface IBrexitUploadConnected {
  token: string;
}

export const BrexitUploadConnected: FC<IBrexitUploadConnected> = ({
  token,
}) => {
  const [file, setFile] = useState<any>(null);
  const { mutate, success, loading, error, reset, data } =
    useUploadBrexitFile();

  const onUpload = () => {
    mutate(file);
  };

  useEffect(() => {
    if (success) {
      reset();
      setFile(null);
      refetch();
    }
  }, [success]);

  const onNotificationClose = () => {
    reset();
    setFile(null);
  };

  const onDrop = (acceptedFiles: any) => {
    setFile(acceptedFiles[0]);
  };

  useEffect(() => {
    if (error && file) {
      reset();
      setFile(null);
    }
  }, [file]);

  const { refetch } = useGetBrexitUploadUserHistory(token);

  return (
    <ContentWrapper noMargin>
      {!loading && (
        <>
          {success && data ? (
            <NotificationBanner onClose={onNotificationClose}>
              File: {file.name} was uploaded successfully.
              {data?.products && data?.products?.invalid_count > 0 && (
                <>
                  <div>
                    Products sheet result: {data.products.invalid_count} of{' '}
                    {data.products.invalid_count + data.products.valid_count}{' '}
                    rows were invalid and not processed.
                  </div>
                </>
              )}
              {data?.supplier_pack &&
                data?.supplier_pack?.invalid_count > 0 && (
                  <>
                    <div>
                      Supplier Pack sheet result:{' '}
                      {data.supplier_pack.invalid_count} of{' '}
                      {data.supplier_pack.invalid_count +
                        data.supplier_pack.valid_count}{' '}
                      rows were invalid and not processed.
                    </div>
                  </>
                )}
            </NotificationBanner>
          ) : (
            error && (
              <NotificationBanner
                type={NotificationType.FAILURE}
                onClose={onNotificationClose}
              >
                File: {file.name} upload failed. <br />
                Reason: {error ? error : DEFAULT_REASON}
              </NotificationBanner>
            )
          )}
        </>
      )}

      <div className='ln-u-bg-color-grey-v-light'>
        <GridWrapper horizontalAlign='center' className='ln-u-soft'>
          <GridItem size={{ default: '1/1', md: '12/12', xl: '10/12' }}>
            <GridWrapper horizontalAlign='center'>
              <GridItem size='1/1'>
                <h2
                  className='ln-u-push-bottom ln-u-display-2'
                  data-testid='brexit-page-title'
                >
                  Brexit Product Requirements Upload
                </h2>
              </GridItem>
              <GridItem size='1/1'>
                <div className='ln-u-soft ln-u-bg-color-white'>
                  <GridWrapper horizontalAlign='center' className='ln-u-soft'>
                    <GridItem size={{ md: '1/2', sm: '3/4', xs: '1/1' }}>
                      <Dropzone
                        onDrop={onDrop}
                        maxFiles={1}
                        accept={acceptedFileTypes}
                        acceptedFiles={file ? [file] : []}
                      />
                    </GridItem>
                  </GridWrapper>
                  <GridWrapper horizontalAlign='center'>
                    <GridItem size={{ md: '1/3', sm: '1/2', xs: '1/1' }}>
                      <FilledButton
                        fullWidth
                        onClick={onUpload}
                        disabled={!file || loading}
                        data-testid='brexit-upload-button'
                      >
                        {loading ? (
                          <>
                            <ProgressSpinner
                              size='icon'
                              className='ln-u-push-right-sm loading-icon'
                              aria-label='uploading'
                            />
                            Uploading...
                          </>
                        ) : (
                          'Upload'
                        )}
                      </FilledButton>
                    </GridItem>
                  </GridWrapper>
                </div>
              </GridItem>
            </GridWrapper>
          </GridItem>
        </GridWrapper>
      </div>
    </ContentWrapper>
  );
};
