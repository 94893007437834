import styled from 'styled-components';
import { Card } from '@jsluna/react';
import { ThemeColors } from 'src/shared/styles/variables';

export const DeleteAccountCardWrapper = styled((props: any) => (
  <Card {...props} />
))`
  padding: 48px;

  .details-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 24px;
    color: #404040;
    margin-bottom: 24px;

    display: flex;
    button {
      margin-left: auto;
    }
  }

  .section {
    &:not(:last-of-type) {
      padding-bottom: 24px;
      margin-bottom: 36px;
      border-bottom: 1px solid ${ThemeColors.sectionSeparator};
    }
  }

  .section-detail {
    .section-label {
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-weight: 800;
      font-size: 16px;
      line-height: 24px;
      color: ${ThemeColors.monochromeDark};
      width: 234px;
    }
  }

  .section-actions {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .ln-c-button {
      margin-left: 24px;
    }
  }

  .account-details {
  }

  .roles-section {
  }
`;
export const RelativeDiv = styled('div')`
  position: relative;

  .notification-wrapper {
    top: -48px;
  }
`;
