import { ThemeColors } from 'src/shared/styles/variables';
import { Card } from '@jsluna/react';
import styled from 'styled-components';

export const PageHeading = styled('h3')`
  margin-bottom: 0;
  font-family: var(--ds-font-stack-display-2-font-family) !important;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: ${ThemeColors.black};
  margin-bottom: 24px;
`;
export const DetailsCard = styled(Card)`
  position: relative;
  padding: 42px;

  h4 {
    &:not(:first-of-type) {
      border-top: 1px solid ${ThemeColors.sectionSeparator};
      padding-top: 24px;
    }
  }

  .section-actions {
    text-align: right;
  }
`;

export const RelativeDiv = styled('div')`
  position: relative;

  .notification-wrapper {
    top: -48px;
  }
`;
