import DOMPurify from 'dompurify';
import React, { ComponentType, FC, ReactElement } from 'react';
import { DetailsRowContainer } from './detailsRow.styles';
interface IDetailsRow {
  name: string;
  value: string;
  icon?: ReactElement;
  nameFieldCss?: string;
  valueFieldCss?: string;
  link?: string;
  isHTML?: boolean;
}
export const DetailsRow: FC<IDetailsRow> = ({
  name,
  value,
  icon,
  nameFieldCss,
  valueFieldCss,
  link,
  isHTML,
}) => {
  return (
    <DetailsRowContainer className='ln-u-margin-bottom*3'>
      <div className={`name-field ${nameFieldCss}`}>{name}</div>
      <div className={`value-field ${valueFieldCss}`}>
        <>
          {icon}
          {isHTML ? (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(value),
              }}
            ></div>
          ) : link ? (
            <a href={link}>{value}</a>
          ) : (
            value
          )}
          {}
        </>
      </div>
    </DetailsRowContainer>
  );
};
