import styled from 'styled-components';
import { Card } from '@jsluna/react';
import { ThemeColors } from '../../shared/styles/variables';

export const DetailsCard = styled(Card)`
  position: relative;
  padding: 42px;

  .icon {
    width: 25px;
    height: 25px;
    background-color: green;
    border-radius: 50%;
    color: white;
    padding: 2.5px;
    margin-right: 12px;
  }

  .tick {
    background-color: green;
  }
  .cancel {
    background-color: red;
  }
  .warning {
    background-color: orange;
  }

  h4 {
    &:not(:first-of-type) {
      border-top: 1px solid ${ThemeColors.sectionSeparator};
      padding-top: 24px;
    }
  }
`;
