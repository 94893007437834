import React, { FC } from 'react';
import { ContainerError } from './serviceError.styles';
import { GridWrapper, GridItem } from '@jsluna/react';
import { ServiceErrorType } from '../../enums/serviceErrorTypes.enum';
import { ServiceErrorMessages } from '../../constants/serviceErrorMessages';

interface IServiceError {
  type: ServiceErrorType;
  title?: string;
  description?: string;
}

export const ServiceErrorPage: FC<IServiceError> = ({
  type,
  title,
  description,
}) => {
  return (
    <ContainerError>
      <GridWrapper verticalAlign='middle'>
        <GridItem
          size={{
            sm: '1/1',
            md: '1/2',
          }}
        >
          <div className='img-container'>
            <img
              className='img-error'
              src={`${process.env.PUBLIC_URL}/img/${type}.svg`}
              alt='Service Error'
            />
          </div>
        </GridItem>
        <GridItem
          size={{
            sm: '1/1',
            md: '1/2',
          }}
        >
          <h2 className=''>{title || ServiceErrorMessages[type].title}</h2>
          <p>{description || ServiceErrorMessages[type].description}</p>
        </GridItem>
      </GridWrapper>
    </ContainerError>
  );
};
