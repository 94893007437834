import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const SupplierCodesSectionContainer = styled('div')`
  .supplier-codes-container {
    flex: 1;

    .supplier-codes {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .section-label {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: ${ThemeColors.monochromeDark};
    width: 234px;
  }
  .supplier-code-title {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #${ThemeColors.monochromeDarker};
    margin-right: 20px;
  }
`;
