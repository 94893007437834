import DOMPurify from 'dompurify';
import { FC } from 'react';

interface IWaysOfWorkingSubSection {
  subSection: {
    title: string;
    body: any;
  };
  index: number;
}

export const WaysOfWorkingSubSection: FC<IWaysOfWorkingSubSection> = ({
  subSection,
  index,
}) => {
  const { title, body } = subSection;

  return (
    <>
      <h4 id={`sub-section-${index + 1}`} className='ln-u-push-top-xl'>
        {title}
      </h4>
      <div
        className='ln-u-body-2'
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(body, {
            ADD_TAGS: ['iframe'],
            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
          }),
        }}
      />
    </>
  );
};
