import React, { useEffect, useState } from 'react';
import { PageHeader } from 'src/components/PageHeader';
import { SitesWrapper } from './sites.styles';
import {
  SearchField,
  GridWrapper,
  GridItem,
  Button,
  Pagination,
  AsyncAutocompleteField,
  MultiAutocompleteField,
  Card,
  ProgressSpinner,
  SelectField,
} from '@jsluna/react';
import Container from 'src/components/Container/Container';
import { NoRecordCard } from 'src/components/NoRecordCard';
import { debounce } from 'lodash';
import { ProfileCardLoader } from '../profiles/components/profileCardLoader';
import { gql } from '@apollo/client';
import { useSitePage_SitesLazyQuery } from 'src/operations/generated/graphql';
import { SiteCard } from '@sainsburys-tech/supplier-experience-site-card';

gql`
  query SitePage_Sites($input: PaginatedSitesInput!) {
    paginatedSites(input: $input) {
      count
      page
      perPage
      sites {
        id
        name
        supplierCode
        supplierNumber
        approvedDomains
        rejectedDomains
        status
        activeStatus
        groupId
      }
    }
  }
`;

const pageSize = 21;

export const SitesPage = () => {
  const [searchKey, setSearchKey] = useState('');
  const [identifier, setIdentifier] = useState('sid');
  const [seed, setSeed] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [filters, setFilters] = useState({
    identifier: identifier,
    value: searchKey,
    page: 1,
    pageSize: pageSize,
  });

  const [
    searchSites,
    {
      data: { paginatedSites: { page = 1, sites = [], count = 1 } = {} } = {},
      error,
      loading,
    },
  ] = useSitePage_SitesLazyQuery({
    variables: {
      input: {
        ...filters,
      },
    },
  });

  interface IOption {
    label: string;
    value: string;
    defaultSelected?: boolean;
  }

  const identifierOptions: IOption[] = [
    { label: 'SID', value: 'sid', defaultSelected: true },
    { label: 'Name', value: 'name' },
    { label: 'Supplier code', value: 'supplierCode' },
    { label: 'Supplier number', value: 'supplierNumber' },
  ];

  const debouncedSetSearchKey = debounce(value => {
    setSearchKey(value);
  }, 500);

  const clearFilter = () => {
    setSearchKey('');

    setSeed(Math.random());
  };

  useEffect(() => {
    if (searchKey !== '') {
      const searchFilters = {
        value: searchKey,
        pageSize: pageSize,
        page: 1,
        identifier: identifier,
      };
      setCurrentPage(1);
      setFilters(searchFilters);
      searchSites();
    }
  }, [searchKey, identifier]);

  useEffect(() => {
    const searchFilters = {
      value: searchKey,
      pageSize: pageSize,
      identifier: identifier,
      page: currentPage,
    };
    if (currentPage !== 1) {
      setFilters(searchFilters);
      searchSites();
    }
  }, [currentPage]);

  return (
    <>
      <PageHeader
        heading={`All sites`}
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'All sites',
            link: '',
          },
        ]}
      />
      <Container>
        <SitesWrapper>
          <GridWrapper verticalAlign='middle'>
            <GridItem size={{ md: '1/5', xs: '1/1' }}>
              <SelectField
                name='select-field-1'
                label='Select identifier'
                options={identifierOptions}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setIdentifier(e.target.value);
                }}
                defaultValue={identifierOptions[0].value}
              />
            </GridItem>
            <GridItem size={{ md: '1/5', xs: '1/1' }}>
              <SearchField
                name='search-sites'
                label='Search sites'
                onChange={(e: any) => {
                  debouncedSetSearchKey(e.target.value);
                }}
                key={seed}
                hasButton={false}
              />
            </GridItem>
          </GridWrapper>
          {loading ? (
            <>
              <ProfileCardLoader />
              <ProfileCardLoader />
              <ProfileCardLoader />
            </>
          ) : sites.length > 0 ? (
            <>
              <GridWrapper>
                {sites?.map(site => (
                  <GridItem
                    key={site.id}
                    size={{ md: '1/3' }}
                    className='ln-u-margin-bottom*3'
                  >
                    <SiteCard
                      site={{
                        id: site.id,
                        name: site.name,
                        status: site.status,
                        activeStatus: site.activeStatus,
                      }}
                    />
                  </GridItem>
                ))}
              </GridWrapper>

              <div className='ln-u-text-align-center ln-u-margin-top*3'>
                <Pagination
                  showFirstAndLast
                  showPages
                  current={currentPage}
                  total={Math.ceil(count / pageSize)}
                  onChange={(page: number, e: any) => {
                    e.preventDefault();
                    setCurrentPage(page);
                  }}
                />
              </div>
            </>
          ) : (
            <NoRecordCard recordType='Sites' searchTerm={searchKey} />
          )}
        </SitesWrapper>
      </Container>
    </>
  );
};
