import { FC } from 'react';
import { Controller, UseFormReturn, FieldValues } from 'react-hook-form';
import { GridWrapper, GridItem } from '@jsluna/react';
import {
  TextInputField as LunaTextInput,
  TextAreaField,
  RadioButtonField,
} from '@jsluna/react';

import { ICreateRole } from '@interfaces/createRole.interface';

interface ICreateAccountTypeForm {
  onFormSubmit?: (values: ICreateRole) => void;
  formObj: UseFormReturn<FieldValues, any>;
  initialValues?: any;
}

export const CreateRoleForm: FC<ICreateAccountTypeForm> = ({
  onFormSubmit,
  formObj,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValidating },
    control,
  } = formObj;

  return (
    <div>
      <GridWrapper>
        <GridItem size={{ md: '1/3' }}>
          <Controller
            name='name'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <LunaTextInput
                {...field}
                onChange={field.onChange}
                error={errors?.name?.message}
                label='Name'
                placeholder='Please enter'
              />
            )}
          />

          <Controller
            name='description'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextAreaField
                {...field}
                label='Description'
                placeholder='Please enter'
                error={errors?.name?.message}
                rows={5}
              />
            )}
          />
          <Controller
            name='hiddenForRequests'
            control={control}
            render={({ field }) => (
              <RadioButtonField
                label='Hide from requests?'
                info={
                  'Do you want to prevent users from requesting this role? If you do, users would only be allowed access if if an admin of an account type requests it on their behalf'
                }
                {...field}
                onChange={field.onChange}
                error={errors?.hiddenForRequests?.message}
                name='radio-button-field-2'
                fullWidth
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
            )}
          />
        </GridItem>
      </GridWrapper>
    </div>
  );
};
