import React, { FC, useState } from 'react';
import { IconButton } from '@jsluna/react';
import { Section } from '../section.styles';
import { Plus, Minus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError, gql } from '@apollo/client';
import { AccountDetailStatus } from '../../../pages/viewProfile/components/accountDetailStatus';
import { RemoveRoleScreen } from '../../../screens/removeRoles';
import { RequestRoleScreen } from 'src/screens/requestRoles';
import {
  RoleSection_RoleInfoFragment,
  RoleSelection_AccountToRoleRelationshipFragment,
} from 'src/operations/generated/graphql';

const PlusIcon = Plus as unknown as React.FC;
const MinusIcon = Minus as unknown as React.FC;

interface IRoleSection {
  error: ApolloError | undefined;
  loading: boolean;
  existingRoles: RoleSelection_AccountToRoleRelationshipFragment[];
  fullRoleOptions: RoleSection_RoleInfoFragment[];
  handleRoleRequest: (roleIds: string[]) => Promise<void>;
  roleRequestError: ApolloError | undefined;
  roleRequestLoading: boolean;
  handleRoleRemoval: (roleIds: string[]) => Promise<void>;
  roleRemovalError: ApolloError | undefined;
  roleRemovalLoading: boolean;
  showActionPanel: boolean;
}

enum PanelAction {
  REQUEST_ROLE = 'REQUEST_ROLE',
  REMOVE_ROLE = 'REMOVE_ROLE',
}

gql`
  fragment RoleSection_RoleInfo on RoleProjection {
    ...RemoveRoleScreen_RoleInfo
  }
`;

gql`
  fragment RoleSelection_AccountToRoleRelationship on AccountToRoleRelationship {
    status
    value {
      ...RoleSection_RoleInfo
    }
  }
`;

export const RoleSection: FC<IRoleSection> = ({
  existingRoles,
  fullRoleOptions,
  handleRoleRequest,
  roleRequestError,
  roleRequestLoading,
  handleRoleRemoval,
  roleRemovalError,
  roleRemovalLoading,
  showActionPanel,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.REQUEST_ROLE:
        return (
          <RequestRoleScreen
            handleClose={handlePanelClose}
            existingRoleIds={existingRoles.map(({ value }) => value.id)}
            fullRoleOptions={fullRoleOptions}
            loading={roleRequestLoading}
            error={roleRequestError}
            handleConfirmation={handleRoleRequest}
          />
        );
      case PanelAction.REMOVE_ROLE:
        return (
          <RemoveRoleScreen
            handleClose={handlePanelClose}
            roles={existingRoles}
            loading={roleRemovalLoading}
            error={roleRemovalError}
            handleConfirmation={handleRoleRemoval}
          />
        );
    }
  };

  return (
    <>
      <Section>
        <h4 className='display-1 ln-u-margin-bottom*3'>Account roles</h4>
        {existingRoles.length ? (
          existingRoles?.map(({ value, status }) => (
            <AccountDetailStatus label={value.name || ''} status={status} />
          ))
        ) : (
          <p>There are no roles linked to this account.</p>
        )}

        {showActionPanel && (
          <div className='action-panel'>
            <IconButton
              variant='text'
              circle={false}
              disabled={false}
              hard={false}
              element='button'
              label='Request role'
              color='dark'
              onClick={() => {
                setShowSidePanel(PanelAction.REQUEST_ROLE);
              }}
            >
              <PlusIcon />
            </IconButton>
            <IconButton
              variant='text'
              circle={false}
              disabled={!existingRoles.length}
              hard={false}
              element='button'
              label='Remove role'
              color='dark'
              onClick={() => {
                setShowSidePanel(PanelAction.REMOVE_ROLE);
              }}
            >
              <MinusIcon />
            </IconButton>
          </div>
        )}
      </Section>

      <Drawer
        open={showSidePanel !== null}
        onClose={() => {
          setShowSidePanel(null);
        }}
        anchor='right'
        className='edit-app-group'
      >
        {renderPanel()}
      </Drawer>
    </>
  );
};
