import { gql } from '@apollo/client';
import React, { FC, useEffect, useState } from 'react';
import {
  DeleteOption,
  PermissionGroupSection,
} from 'src/components/Sections/PermissionGroupSection';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import {
  PermissionGroupComp_ApplicationInfoFragment,
  PermissionGroupComp_PermissionGroupFragment,
  useApplicationPermissionGroupsQuery,
} from 'src/operations/generated/graphql';
interface IPermissionGroupSection {
  application: PermissionGroupComp_ApplicationInfoFragment;
  disabled: boolean;
}

gql`
  fragment PermissionGroupComp_ApplicationInfo on ApplicationProjection {
    id
    name
  }
`;

gql`
  fragment PermissionGroupComp_PermissionGroup on PermissionGroupProjection {
    id
    name
    applicationId
    permissions {
      value {
        id
        name
      }
    }
  }
`;

gql`
  query ApplicationPermissionGroups($applicationId: String!) {
    applicationPermissionGroups(applicationId: $applicationId) {
      ...PermissionGroupComp_PermissionGroup
    }
  }
`;

export const PermissionGroupComp: FC<IPermissionGroupSection> = ({
  application,
  disabled,
}) => {
  const { data: { applicationPermissionGroups = [] } = {}, loading } =
    useApplicationPermissionGroupsQuery({
      variables: { applicationId: application.id },
    });

  const [appPermissionGroups, setAppPermissionGroups] = useState<
    PermissionGroupComp_PermissionGroupFragment[]
  >([]);

  const handlePermissionGroupAdd = (
    result: PermissionGroupComp_PermissionGroupFragment,
  ) => {
    setAppPermissionGroups([...appPermissionGroups, result]);
  };
  const handlePermissionGroupRemoval = (
    type: NotificationType,
    removed: string,
    removeType: DeleteOption,
    groupId?: string,
  ) => {
    if (removeType === DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP) {
      const filtered = appPermissionGroups.filter(
        group => group.id !== groupId,
      );
      const optimisticGroup = appPermissionGroups.find(
        group => group.id === groupId,
      );

      const optimisticPermissions = optimisticGroup?.permissions.filter(
        permission => permission.value.id !== removed,
      );

      if (optimisticGroup) {
        setAppPermissionGroups([
          ...filtered,
          { ...optimisticGroup, permissions: optimisticPermissions || [] },
        ]);
      }
    } else if (removeType === DeleteOption.DELETE_PERMISSION_GROUP) {
      const filtered = appPermissionGroups.filter(
        group => group.id !== removed,
      );
      setAppPermissionGroups(filtered);
    }
  };

  useEffect(() => {
    if (applicationPermissionGroups?.length) {
      setAppPermissionGroups(applicationPermissionGroups);
    }
  }, [applicationPermissionGroups]);

  return (
    <>
      {loading ? (
        <>LOADING</>
      ) : (
        <>
          {appPermissionGroups && (
            <PermissionGroupSection
              permissionGroup={appPermissionGroups}
              target={{
                id: application.id,
                displayName: application.name || '',
              }}
              handleConfirmation={handlePermissionGroupAdd}
              handleRemoval={handlePermissionGroupRemoval}
              loading={loading}
              error={undefined}
              disabled={disabled}
            />
          )}
        </>
      )}
    </>
  );
};
