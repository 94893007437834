import styled from 'styled-components';
import { Card } from '@jsluna/react';
import { ThemeColors } from '../../shared/styles/variables';

export const Section = styled('div')`
  padding-bottom: 48px;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${ThemeColors.sectionSeparator};
  }
  &:not(:first-of-type) {
    padding-top: 48px;
  }
  .action-panel {
    display: flex;
    justify-content: flex-end;
    margin-top: 24px;
  }

  .action-button {
    display: flex;
    justify-content: flex-end;
    text-align: right;
  }

  .tags-section {
    display: flex;
    align-items: flex-start;

    .tags-container {
      flex: 1;

      .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
      }
    }
  }

  .table-actions {
    text-align: right;
  }

  .account {
    padding-bottom: 24px;
    margin-bottom: 36px;
    border-bottom: 1px solid ${ThemeColors.sectionSeparator};
  }
`;
