export const directFetch = (url = '') => {
  return new Promise(async (resolve, reject) => {
    const res = await fetch(url, {
      credentials: 'same-origin', // include, *same-origin, omit
      // headers: {
      //   ...(isProd &&
      //     !url.includes('/api/public') && {
      //       Authorization: `Bearer ${window.sessionStorage.getItem('access_token')}`
      //     }),
      //   'Content-Type': 'application/json; charset=utf-8'
    });

    if (res.status === 401) {
      window.sessionStorage.clear();
      //window.location.replace(routes.accessRequired)
      reject();
    } else if (/^[4|5]\d+$/.test(res.status.toString())) {
      // If status code starts with 4 or 5
      reject();
    }

    resolve(res);
  });
};

export const fetchFile = (url: string, blob = false) => {
  return directFetch(url).then(async (res: any) => {
    const fileName =
      res.headers
        .get('content-disposition')
        ?.split('filename=')[1]
        .split(';')[0] || '';
    if (blob) {
      const b = await res.blob();
      return { fileName, blob: b };
    }
    let buffer;
    try {
      buffer = await res.arrayBuffer();
    } catch {
      buffer = await res.buffer();
    }
    return { fileName, buffer };
  });
};

export const createFileBlob = (buffer: any, fileType: any) =>
  new Blob([buffer], {
    type: fileType,
  });

export const fileSaver = (files: any) => {
  const temporaryDownloadLink = document.createElement('a');
  temporaryDownloadLink.style.display = 'none';

  document.body.appendChild(temporaryDownloadLink);

  for (let n = 0; n < files.length; n++) {
    const download = files[n];
    temporaryDownloadLink.setAttribute('href', download.href);

    const filenameLastChar = download.fileName.substr(
      download.fileName.length - 1,
    );

    if (filenameLastChar === '_')
      temporaryDownloadLink.setAttribute(
        'download',
        download.fileName.slice(0, -1),
      );
    else {
      temporaryDownloadLink.setAttribute('download', download.fileName);
    }

    temporaryDownloadLink.click();
  }

  document.body.removeChild(temporaryDownloadLink);
};
