import React, { FC } from 'react';
import { JournalError } from 'src/components/JournalError';
import { JournalLoading } from 'src/components/JournalLoading';
import { useGetSupplierArticleGroupings } from 'src/operations/queries/getSupplierArticleGroupings';
import { PlanBetterBlock } from 'src/pages/newsAndArticles/components/planBetterBlock';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { PlanForBetterDiv } from './planForBetterSection.styles';

export const PlanForBetterSection: FC = () => {
  const {
    data: { articles: planBetterData } = {},
    error: planBetterError,
    loading: planBetterLoading,
  } = useGetSupplierArticleGroupings('Plan 4 Better');

  return (
    <PlanForBetterDiv>
      <SectionHeader
        heading='Plan for Better'
        subHeading='Our sustainability goals built around the issues that matter most to you'
      />

      {planBetterLoading ? (
        <JournalLoading />
      ) : planBetterError ? (
        <JournalError />
      ) : (
        <>
          {planBetterData?.length > 0 && (
            <PlanBetterBlock data={planBetterData[0]} />
          )}
        </>
      )}
    </PlanForBetterDiv>
  );
};
