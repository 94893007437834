import React, { FC } from 'react';
import Container from 'src/components/Container/Container';
import { useAppSelector } from 'src/redux/hooks';
import { getProfileDetails } from 'src/redux/selectors';
import { PageHeader } from 'src/components/PageHeader';
import { getGreeting } from 'src/utils/getGreeting';
import { capitalize } from 'src/utils/capitalize';
import { QuickLinkSections } from './components/quickLinkSections';
import { SupplierNews } from '../newsAndArticles/components/SupplierNews';

import { PageSeparator } from './components/pageSeparator';
import { WorkingWithSainsburysSection } from './components/workingWithSainsburys';
import { SupplierJournalSection } from './components/supplierJournalSection';
import { PlanForBetterSection } from './components/planForBetterSection';
import { PopularHelpTopicsSection } from './components/popularHelpTopics';
import { useGetSupplierArticleGroupings } from '../../operations/queries/getSupplierArticleGroupings';
import { useGetSupplierNews } from '../../operations/queries/getSupplierNews';
import { ApplicationsWrapper } from '../applications/applications.styles';
import { SystemNotifications } from 'src/shared/components/systemNotification';
import { useGetSystemAlerts } from 'src/operations/queries/getPromotedContent';
import { MyAppsGroupTab } from 'src/shared/components/myAppsGroupTab';
import { AgricultureSection } from './components/AgricultureSection';

export const Dashboard: FC = () => {
  const profile = useAppSelector(getProfileDetails);

  const {
    data: { news: latestNews } = {},
    error: newsError,
    loading: newsLoading,
  } = useGetSupplierNews(1, 4);

  const {
    data: { articles: journalData } = {},
    error: journalError,
    loading: journalLoading,
  } = useGetSupplierArticleGroupings('Journal');

  const { data: { supplierCode = [] } = {}, data } = useGetSystemAlerts();

  return (
    <>
      <PageHeader
        // @ts-ignore
        heading={`${getGreeting()} ${capitalize(
          profile?.preferredName || profile?.firstName || '',
        )},`}
        topHeading='Together With'
        subHeading='Welcome to Together With.'
      />

      {supplierCode?.length > 0 && (
        <SystemNotifications notifications={supplierCode} />
      )}
      <Container className='ln-u-hard-top'>
        <QuickLinkSections
          latestNews={latestNews}
          newsError={newsError}
          newsLoading={newsLoading}
          journalData={journalData}
          journalError={journalError}
          journalLoading={journalLoading}
        />
        <PageSeparator />

        <ApplicationsWrapper>
          <MyAppsGroupTab accountId={profile?.selectedAccountId} />
        </ApplicationsWrapper>
        <PageSeparator />
        <WorkingWithSainsburysSection />
        <PageSeparator />
        <SupplierNews
          isDashboard={true}
          latestNews={latestNews}
          newsError={newsError}
          newsLoading={newsLoading}
        />
        <PageSeparator />
        <SupplierJournalSection
          journalData={journalData}
          journalError={journalError}
          journalLoading={journalLoading}
        />
        <PageSeparator />
        <PlanForBetterSection />
        <PageSeparator />
        <AgricultureSection />
        <PageSeparator />
        <PopularHelpTopicsSection />
      </Container>
    </>
  );
};
