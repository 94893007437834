import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';
import { phoneRegExp } from 'src/constants/regExValidations';
import * as Yup from 'yup';

export default Yup.object().shape(
  {
    title: Yup.string().required(InviteProfileMessages.title.required),
    firstName: Yup.string()
      .required(InviteProfileMessages.firstName.required)
      .min(2, `${InviteProfileMessages.firstName.min} 2`)
      .max(35, `${InviteProfileMessages.firstName.max} 35`),
    lastName: Yup.string()
      .required(InviteProfileMessages.lastName.required)
      .min(2, `${InviteProfileMessages.lastName.min} 2`)
      .max(35, `${InviteProfileMessages.lastName.max} 35`),
    preferredName: Yup.string().when('preferredName', (value, schema) => {
      if (value?.length > 0) {
        return Yup.string()
          .min(2, `${InviteProfileMessages.preferredName.min} 2`)
          .max(35, `${InviteProfileMessages.preferredName.max} 35`);
      } else {
        return Yup.string().notRequired();
      }
    }),
    emailAddress: Yup.string().email(InviteProfileMessages.emailAddress.valid),
    countryCode: Yup.string().required(
      InviteProfileMessages.countryCode.required,
    ),
    contactNumber: Yup.string()
      .matches(phoneRegExp, InviteProfileMessages.contactNumber.valid)
      .required(InviteProfileMessages.contactNumber.required),
    TAndCAgreed: Yup.bool().oneOf([true], 'Checkbox selection is required'),
  },
  [
    ['contactNumber', 'contactNumber'],
    ['preferredName', 'preferredName'],
  ],
);
