import React, { FC, useEffect, useState } from 'react';
import { PermissionSelectorContainer } from './permissionSelector.styles';
import { Button, ProgressSpinner, Checkbox } from '@jsluna/react';
import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ApolloError } from '@apollo/client';
import { InfoPanel } from 'src/shared/components/infoPanel';
import { InfoPanelTypes } from 'src/enums/infoPanelTypes.enum';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import {
  AppPermissionFragment,
  AppPermissionGroupFragment,
  LinkAppsSection_LinkAppInfoFragment,
  useGetAppPermissionGroupsLazyQuery,
  useGetAppPermissionsLazyQuery,
} from '../../operations/generated/graphql';

export enum SelectorType {
  PERMISSION = 'PERMISSION',
  PERMISSION_GROUP = 'PERMISSION_GROUP',
}

interface IPermissionOptions {
  label: string;
  value: AppPermissionFragment | AppPermissionGroupFragment;
  defaultChecked: boolean;
}

interface IPermissionSelectionScreen {
  application: LinkAppsSection_LinkAppInfoFragment;
  handleClose: () => void;
  existingPermissionsOrGroups: string[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (selectedPermissions: string[]) => void;
  target: { id: string; displayName: string };
  type: SelectorType;
}

export const PermissionSelection: FC<IPermissionSelectionScreen> = ({
  handleClose,
  existingPermissionsOrGroups,
  loading,
  handleConfirmation,
  target,
  error,
  application,
  type,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
    existingPermissionsOrGroups,
  );

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const [permissionOptions, setPermissionOptions] = useState<
    IPermissionOptions[]
  >([]);

  useEffect(() => {
    setSelectedPermissions(existingPermissionsOrGroups);
  }, [existingPermissionsOrGroups]);

  const [getAppPermissions, { data: { applicationPermissions = [] } = {} }] =
    useGetAppPermissionsLazyQuery({
      variables: { applicationId: application.id },
    });

  const [
    getAppPermissionGroups,
    {
      data: { applicationPermissionGroups = [] } = {},
      error: applicationPermissionGroupsError,
      loading: applicationPermissionGroupsLoading,
    },
  ] = useGetAppPermissionGroupsLazyQuery({
    variables: { applicationId: application.id },
  });

  useEffect(() => {
    if (type === SelectorType.PERMISSION) {
      getAppPermissions();
    } else {
      getAppPermissionGroups();
    }
  }, []);

  useEffect(() => {
    if (applicationPermissions?.length) {
      const baseOptions =
        type === SelectorType.PERMISSION
          ? applicationPermissions
          : applicationPermissionGroups;

      if (baseOptions?.length) {
        const options = baseOptions.map(permission => ({
          label: permission.name,
          value: permission,
          defaultChecked: existingPermissionsOrGroups?.includes(permission.id),
        }));
        setPermissionOptions(options as IPermissionOptions[]);
      }
    }
  }, [applicationPermissions]);
  useEffect(() => {
    if (applicationPermissionGroups?.length) {
      const baseOptions =
        type === SelectorType.PERMISSION
          ? applicationPermissions
          : applicationPermissionGroups;
      if (baseOptions?.length) {
        const options = baseOptions.map(permission => ({
          label: permission.name,
          value: permission,
          defaultChecked: existingPermissionsOrGroups?.includes(permission.id),
        }));
        setPermissionOptions(options as IPermissionOptions[]);
      }
    }
  }, [applicationPermissionGroups]);

  const handleReset = () => {
    setFeedBackMessage(null);
  };

  const onPermissionSelected = (
    e: React.ChangeEvent<HTMLInputElement>,
    permission: AppPermissionFragment | AppPermissionGroupFragment,
  ) => {
    if (e.target.checked) {
      setSelectedPermissions([...selectedPermissions, permission.id]);
    } else {
      const updatedPermission = selectedPermissions.filter(
        perm => perm !== permission.id,
      );
      setSelectedPermissions(updatedPermission);
    }
  };

  const handleSubmission = () => {
    handleConfirmation(selectedPermissions);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  const panelLabel =
    type === SelectorType.PERMISSION ? 'Permissions' : 'Permission Groups';

  return (
    <>
      <PermissionSelectorContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${panelLabel} have been updated`
                  : `Unable to update ${panelLabel}. Please try again.`
              }
              saveButtonText={'Close'}
              onCloseHandler={handleClose}
              resetButtonText={`${
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Further changes'
                  : 'Try again'
              }`}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${panelLabel} successfully updated`
                  : `Failed to update ${panelLabel}`
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              What {panelLabel} does this role need for {application.name}?
            </div>
            {permissionOptions.length === 0 && (
              <InfoPanel
                infoType={InfoPanelTypes.INFORMATION}
                infoText={`There aren't any ${panelLabel} associated with this application.`}
              />
            )}
            {permissionOptions.map(option => (
              <div key={option.value.id}>
                <Checkbox
                  name={`chb-${option.value.id}`}
                  label={option.label}
                  defaultChecked={existingPermissionsOrGroups?.includes(
                    option.value.id,
                  )}
                  fullWidth
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onPermissionSelected(e, option.value);
                  }}
                />
                <p className='permission-desc'>{option.value.description}</p>
              </div>
            ))}
          </>
        )}
      </PermissionSelectorContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={handleSubmission}
            variant='filled'
            disabled={
              JSON.stringify(existingPermissionsOrGroups) ===
              JSON.stringify(selectedPermissions)
            }
          >
            {loading && <ProgressSpinner />}
            Update {panelLabel}
          </Button>
          <Button
            onClick={() => {
              handleClose();
              setSelectedPermissions(existingPermissionsOrGroups);
            }}
            variant='outlined'
          >
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
