import React, { FC, useContext, useEffect, useState } from 'react';
import {
  Button,
  Table,
  GridItem,
  SearchField,
  GridWrapper,
} from '@jsluna/react';
import Container from 'src/components/Container/Container';
import { ITag } from '@interfaces/tag.interface';
import { PageHeader } from '../../components/PageHeader';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { More, Search } from '@jsluna/icons';
import { RelativeDiv, TagManagerWrapper } from './TagManager.styles';
import { NoRecordCard } from '../../components/NoRecordCard';
import { DeleteTag } from './components/deleteTag';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { TagFeedback } from './components/feedback/feedback';
import { Drawer } from '@mui/material';
import { CreateTag } from 'src/screens/createTag';
import { EditTag } from 'src/screens/editTag';
import { AuthContext } from '../../providers/AuthProvider';
import { APP_ACCESS } from 'src/enums/permissions.enum';

import {
  useTagManagerQuery,
  useDeleteTagMutation,
  useCreateTagMutation,
  useUpdateTagDescriptionMutation,
  TagManagerFragment,
  DeleteTagFragment,
} from 'src/operations/generated/graphql';
import { gql } from '@apollo/client';

const MoreIcon = More as unknown as React.FC;
const SearchIcon = Search as unknown as React.FC;

export const TagManager: FC = () => {
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);

  gql`
    fragment TagManager on TagProjection {
      id
      name
      description
    }
  `;

  gql`
    query TagManager {
      tags {
        ...TagManager
        ...DeleteTag
      }
    }
  `;

  const { data: { tags } = { tags: [] } } = useTagManagerQuery();

  const [allTags, setAllTags] = useState<TagManagerFragment[]>([]);
  const [filteredTags, setFilteredTags] = useState<TagManagerFragment[]>([]);
  const [searchKey, setSearchKey] = useState('');
  const [showDeleteBox, setShowDeleteBox] = useState<string | null>(null);
  const [tagName, setTagName] = useState<string | null>(null);
  const [tagDescription, setTagDescription] = useState<string | null>(null);
  const [editId, setEditId] = useState<string | null>(null);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<{
    notificationFor: string;
    type: NotificationType;
  } | null>(null);

  const [showCreateTag, setShowCreateTag] = useState(false);

  useEffect(() => {
    if (tags.length) {
      setFilteredTags(tags);
      setAllTags(tags);
    }
  }, [tags]);

  useEffect(() => {
    const filtered = allTags.filter(tag =>
      tag.name?.toLowerCase()?.startsWith(searchKey?.toLowerCase()),
    );
    setFilteredTags(filtered);
  }, [searchKey]);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openElem, setOpenElem] = useState<null | string>(null);
  const open = Boolean(anchorEl);
  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    selectedId: string,
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenElem(selectedId);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpenElem(null);
  };
  const [editMutate, { error: editError, loading: editLoading }] =
    useUpdateTagDescriptionMutation();

  const submitEditTag = async (tagDescription: string) => {
    if (!editId) return; //TODO: added for typescript
    try {
      await editMutate({
        variables: {
          id: editId,
          description: tagDescription,
        },
      });
      const updatedTags = filteredTags.map(tag => {
        if (tag.id === editId) {
          tag = { ...tag, description: tagDescription };
        }
        return tag;
      });
      setFilteredTags(updatedTags);
    } catch (error) {}
  };

  const [deleteMutate, { error: deleteError, loading: deleteLoading }] =
    useDeleteTagMutation();
  const submitDeleteTag = async (selectedTag: DeleteTagFragment) => {
    const optimisticTags = filteredTags.filter(
      tag => tag.id !== selectedTag.id,
    );
    try {
      await deleteMutate({
        variables: {
          id: selectedTag.id,
        },
      });

      setFilteredTags(optimisticTags);
      setShowDeleteBox(null);
    } catch (error) {}
  };

  const [createMutate, { loading: createLoading, error: createError }] =
    useCreateTagMutation();
  const submitCreateTag = async (values: any) => {
    try {
      const id = await createMutate({
        variables: {
          tag: { name: values.name, description: values.description },
        },
      });
      if (id.data?.createTag?.id) {
        setFilteredTags([
          ...filteredTags,
          {
            id: id.data.createTag.id,
            name: values.name,
            description: values.description,
          },
        ]);
      }
    } catch (error) {}
  };

  return (
    <>
      <PageHeader
        heading={`Tag Manager`}
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'tags',
            link: '',
          },
        ]}
        subHeading='Create and manage tags'
      />

      <RelativeDiv>
        {feedback && (
          <TagFeedback
            type={feedback.type}
            onClose={() => {
              setFeedback(null);
            }}
          />
        )}
      </RelativeDiv>

      <Container>
        {hasPermission(APP_ACCESS.TAG_MANAGEMENT) ? (
          <TagManagerWrapper>
            <div className='tag-manager-action'>
              <GridWrapper verticalAlign='middle'>
                <GridItem size={{ md: '1/3', xs: '1/1' }}>
                  <SearchField
                    name='search-tags'
                    label='Search tags'
                    onChange={(e: any) => {
                      setSearchKey(e.target.value);
                    }}
                    hasAction={true}
                    action={
                      <Button>
                        <SearchIcon />
                      </Button>
                    }
                  />
                </GridItem>
              </GridWrapper>
              <Button
                className='btn-add-tag'
                circle={false}
                disabled={false}
                hard={false}
                element='button'
                variant='filled'
                onClick={() => {
                  setShowCreateTag(true);
                }}
              >
                Add tag
              </Button>
            </div>

            {filteredTags.length ? (
              <Table
                rowKey='orderedLine.Name'
                responsive
                data={filteredTags}
                caption='This responsive view can also be applied to sortable content.'
                visuallyHiddenCaption
                columns={[
                  {
                    name: 'Tag',
                    accessor: (rowData: ITag) => ({ value: rowData.name }),
                  },
                  {
                    name: 'Description',
                    accessor: (rowData: ITag) => ({
                      value: rowData.description,
                    }),
                  },
                  {
                    name: 'Manage',
                    accessor: (rowData: ITag) => ({
                      value: rowData,
                    }),
                    render: ({ value }: { value: ITag }) => {
                      const { id, name, description } = value;
                      return (
                        <>
                          <div className='more-actions'>
                            <IconButton
                              onClick={(
                                event: React.MouseEvent<HTMLElement>,
                              ) => {
                                handleClick(event, id);
                              }}
                              size='small'
                              sx={{ ml: 2 }}
                              aria-controls={open ? 'tag-menu' : undefined}
                              aria-haspopup='true'
                              aria-expanded={open ? 'true' : undefined}
                            >
                              <MoreIcon />
                            </IconButton>
                          </div>

                          <Menu
                            anchorEl={anchorEl}
                            id='tag-menu'
                            open={openElem === id}
                            onClose={handleClose}
                            onClick={handleClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: 'visible',
                                mt: 1.5,
                                'border': '1px solid #D8D8D8',
                                borderRadius: '8px',
                                '&:before': {
                                  content: '""',
                                  display: 'block',
                                  position: 'absolute',
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  transform: 'translateY(-50%) rotate(45deg)',
                                  zIndex: 0,
                                  fontFamily: 'Mary Ann',
                                  fontWeight: 'bold',
                                  fontSize: '18px',
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: 'left',
                              vertical: 'top',
                            }}
                            anchorOrigin={{
                              horizontal: 'left',
                              vertical: 'bottom',
                            }}
                          >
                            <MenuItem
                              className='menu-item'
                              onClick={() => {
                                setOpenEdit(true);
                                setEditId(id);
                                setTagName(name);
                                setTagDescription(
                                  description ? description : '',
                                );
                                handleClose();
                              }}
                            >
                              Edit description
                            </MenuItem>
                            <MenuItem
                              className='menu-item'
                              onClick={() => {
                                setShowDeleteBox(id);
                                handleClose();
                              }}
                            >
                              Delete Tag
                            </MenuItem>
                          </Menu>
                        </>
                      );
                    },
                  },
                ]}
              />
            ) : (
              <NoRecordCard recordType='tags' />
            )}
          </TagManagerWrapper>
        ) : (
          <div>you don't have access</div>
        )}
        {showCreateTag && (
          <Drawer
            open={showCreateTag}
            onClose={() => {
              setShowCreateTag(false);
            }}
            anchor='right'
          >
            <CreateTag
              handleConfirmation={submitCreateTag}
              error={createError}
              loading={createLoading}
              handleClose={() => {
                setShowCreateTag(false);
              }}
            />
          </Drawer>
        )}
      </Container>
      {showDeleteBox && (
        <DeleteTag
          setFeedBack={setFeedback}
          handleConfirmation={submitDeleteTag}
          isOpen={showDeleteBox !== null}
          selectedTag={filteredTags?.filter(tag => tag.id === showDeleteBox)[0]}
          setShowDeleteBox={setShowDeleteBox}
          loading={deleteLoading}
          error={deleteError}
        />
      )}
      {openEdit && (
        <Drawer
          open={openEdit}
          onClose={() => {
            setOpenEdit(false);
            setEditId(null);
          }}
          anchor='right'
          className='edit-tag drawer-on-top'
        >
          <EditTag
            setOpenEdit={setOpenEdit}
            setEditId={setEditId}
            openEdit={openEdit}
            error={editError}
            loading={editLoading}
            handleConfirmation={submitEditTag}
            tagName={tagName}
            description={tagDescription}
          />
        </Drawer>
      )}
    </>
  );
};
