import React, { FC, useContext, useEffect, useState } from 'react';
import { AccountTypeDetailsWrapper } from './accountTypeDetails.styles';
import {
  IAccountType,
  IAccountTypeDetails,
} from 'src/interfaces/accountTypes.interface';
import { AccountTypeOptions } from 'src/enums/accountTypeOptions.enum';
import { SectionDetails } from '../details';
import { SectionRoles } from '../roles';
import { gql, useQuery } from '@apollo/client';
import { AccountSelectorSection } from 'src/components/Sections/AccountSelectorSection';
import { IAccountDetailsWithProfile } from 'src/interfaces/account.interface';
import { LinkAppsSection } from 'src/components/Sections/LinkAppSection';
import { IApplicationDetails } from 'src/interfaces/application.interface';
import { ITag } from '@interfaces/tag.interface';
import { TagsSection } from 'src/components/Sections/TagsSection/tags';
import { DomainSection } from '../../../../components/Sections/DomainSection';
import { AuthContext } from '../../../../providers/AuthProvider';

import {
  useRemoveApplicationAudienceForAccountTypeMutation,
  useAddApplicationAudienceForAccountTypeMutation,
  useAssociateAdminsToAccountTypeMutation,
  useAssociateApproversToAccountTypeMutation,
  useDissociateAdminFromAccountTypeMutation,
  useDissociateApproverFromAccountTypeMutation,
  useUpdateAccountTypeDescriptionMutation,
  useDissociateTagFromAccountTypeMutation,
  useAssociateTagsToAccountTypeMutation,
  useAddApprovedDomainToAccountTypeMutation,
  useRemoveApprovedDomainFromAccountTypeMutation,
  useAddRejectedDomainToAccountTypeMutation,
  useRemoveRejectedDomainFromAccountTypeMutation,
  useAccountTypeDetails_AccountTypeQuery,
  useAccountTypeDetails_TagsQuery,
  useAccountTypeDetails_ApplicationsQuery,
  AccountSelectorSection_AccountsFragment,
  LinkAppsSection_LinkAppInfoFragment,
  TagSelection_TagsFragment,
} from 'src/operations/generated/graphql';

interface IAccountTypeDetails2 {
  accountTypeId: string;
}

gql`
  fragment AccountTypeDetails_AccountTypeInfo on AccountTypeProjection {
    id
    name
    description
    checkType
    approvedDomains
    rejectedDomains
    linkedApplications {
      value {
        ...LinkAppsSection_linkAppInfo
      }
    }
    tags {
      value {
        ...TagSelection_Tags
      }
    }
    admins {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    approvers {
      value {
        ...AccountSelectorSection_accounts
      }
    }
  }
`;

gql`
  query AccountTypeDetails_Applications {
    applications {
      ...LinkAppsSection_linkAppInfo
    }
  }
`;

gql`
  query AccountTypeDetails_AccountType($id: String!) {
    accountType(id: $id) {
      ...AccountTypeDetails_AccountTypeInfo
    }
  }
`;

gql`
  query AccountTypeDetails_Tags {
    tags {
      ...TagSelection_Tags
    }
  }
`;

export const AccountTypeDetails: FC<IAccountTypeDetails2> = ({
  accountTypeId,
}) => {
  // @ts-ignore
  const { isAccountTypeAdminOf, isAccountTypeApproverOf } =
    useContext(AuthContext);

  const { data: { accountType = null } = {} } =
    useAccountTypeDetails_AccountTypeQuery({
      variables: { id: accountTypeId },
    });

  const { data: { tags: tagOptions = [] } = {} } =
    useAccountTypeDetails_TagsQuery();

  const { data: { applications: applicationOptions } = {} } =
    useAccountTypeDetails_ApplicationsQuery();

  const [details, setDetails] = useState<IAccountTypeDetails | null>(null);
  const [admins, setAdmins] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [approvers, setApprovers] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [linkedApps, setLinkedApps] = useState<
    LinkAppsSection_LinkAppInfoFragment[]
  >([]);
  const [approvedDomains, setApprovedDomains] = useState<string[]>([]);
  const [rejectedDomains, setRejectedDomains] = useState<string[]>([]);
  const [tags, setTags] = useState<TagSelection_TagsFragment[]>([]);

  useEffect(() => {
    if (!accountType) {
      setDetails(null);
      setApprovers([]);
      setAdmins([]);
      setLinkedApps([]);
      setTags([]);
      setApprovedDomains([]);
      setRejectedDomains([]);
    }

    if (accountType) {
      const { id, name, description, checkType } = accountType;
      setDetails({ id, name, description, checkType });
      setAdmins(accountType.admins.map(admin => admin.value) || []);
      setApprovers(accountType.approvers.map(approver => approver.value) || []);
      setLinkedApps(
        accountType.linkedApplications?.map(app => app.value) || [],
      );
      setTags(accountType.tags.map(tag => tag.value) || []);
      setApprovedDomains(accountType.approvedDomains);
      setRejectedDomains(accountType.rejectedDomains);
    }
  }, [accountType]);

  const [
    addAdminsMutation,
    { loading: addAdminsLoading, error: addAdminsError },
  ] = useAssociateAdminsToAccountTypeMutation();

  const handleAdminSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    if (!accountType) return;
    const selectedAdmins = accountsSelected.map(account => account.id);
    try {
      await addAdminsMutation({
        variables: {
          accountTypeId: accountType.id,
          accountIds: selectedAdmins,
        },
      });
      setAdmins([...admins, ...accountsSelected]);
    } catch (e) {}
  };

  const [
    removeAdminMutation,
    { loading: removeAdminLoading, error: removeAdminError },
  ] = useDissociateAdminFromAccountTypeMutation();

  const handleAdminSelectionRemoval = async (accountId: string) => {
    if (!accountType) return;
    const filteredAccounts = admins.filter(account => account.id !== accountId);
    try {
      await removeAdminMutation({
        variables: {
          accountTypeId: accountType.id,
          accountId: accountId,
        },
      });
      setAdmins(filteredAccounts);
    } catch (e) {}
  };

  const [
    addApproversMutation,
    { loading: addApproversLoading, error: addApproversError },
  ] = useAssociateApproversToAccountTypeMutation();
  const handleApproverSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    if (!accountType) return;
    const selectedApprovers = accountsSelected.map(account => account.id);
    try {
      await addApproversMutation({
        variables: {
          accountTypeId: accountType.id,
          accountIds: selectedApprovers,
        },
      });
      setApprovers([...approvers, ...accountsSelected]);
    } catch (e) {}
  };

  const [
    removeApproverMutation,
    { loading: removeApproverLoading, error: removeApproverError },
  ] = useDissociateApproverFromAccountTypeMutation();

  const handleApproverSelectionRemoval = async (accountId: string) => {
    if (!accountType) return;
    const filteredAccounts = approvers.filter(
      account => account.id !== accountId,
    );
    try {
      await removeApproverMutation({
        variables: {
          accountTypeId: accountType.id,
          accountId: accountId,
        },
      });
      setApprovers(filteredAccounts);
    } catch (e) {}
  };

  const [linkAppMutation, { loading: linkAppLoading, error: linkAppError }] =
    useAddApplicationAudienceForAccountTypeMutation();

  const handleLinkAppsConfirmation = async (
    appsSelected: LinkAppsSection_LinkAppInfoFragment[],
  ) => {
    if (!accountType) return;
    const selectedApplications = appsSelected.map(app => app.id);
    try {
      await linkAppMutation({
        variables: {
          accountTypeId: accountType.id,
          applicationIds: selectedApplications,
        },
      });
      setLinkedApps([...linkedApps, ...appsSelected]);
    } catch (e) {}
  };

  const [
    updateAccountTypeDescriptionMutation,
    {
      loading: updateAccountTypeDescriptionLoading,
      error: updateAccountTypeDescriptionError,
    },
  ] = useUpdateAccountTypeDescriptionMutation();
  const handleUpdateAccountTypeDescriptionConfirmation = async (
    description: string,
  ) => {
    if (!accountType) return;
    try {
      await updateAccountTypeDescriptionMutation({
        variables: { accountTypeId: accountType.id, description },
      });
      setDetails({
        id: accountType.id,
        name: accountType.name,
        description,
        checkType: accountType.checkType,
      });
    } catch (e) {}
  };

  const [
    unlinkAppMutation,
    { loading: unlinkAppLoading, error: unlinkAppError },
  ] = useRemoveApplicationAudienceForAccountTypeMutation();
  const handleLinkAppRemoval = async (appId: string) => {
    if (!accountType) return;
    const filteredApps = linkedApps.filter(app => app.id !== appId);
    try {
      await unlinkAppMutation({
        variables: {
          accountTypeId: accountType.id,
          applicationId: appId,
        },
      });
      setLinkedApps(filteredApps);
    } catch (e) {}
  };

  const [
    addTagToAccountTypeMutation,
    { loading: addTagToAccountTypeLoading, error: addTagToAccountTypeError },
  ] = useAssociateTagsToAccountTypeMutation();
  const handleTagConfirmation = async (
    tagsSelected: TagSelection_TagsFragment[],
  ) => {
    const tagIds = tagsSelected.map(tag => tag.id);
    if (!accountType) return;
    try {
      await addTagToAccountTypeMutation({
        variables: { accountTypeId: accountType.id, tagIds },
      });
      setTags([...tags, ...tagsSelected]);
    } catch (e) {}
  };

  const [
    removeTagToAccountTypeMutation,
    {
      loading: removeTagToAccountTypeLoading,
      error: removeTagToAccountTypeError,
    },
  ] = useDissociateTagFromAccountTypeMutation();
  const handleTagRemoval = async (tagId: string) => {
    if (!accountType) return;
    const filteredTags = tags.filter(tag => tag.id !== tagId);
    try {
      await removeTagToAccountTypeMutation({
        variables: { accountTypeId: accountType.id, tagId },
      });
      setTags(filteredTags);
    } catch (e) {}
  };

  const [
    addApprovedDomainMutation,
    { loading: addApprovedDomainLoading, error: addApprovedDomainError },
  ] = useAddApprovedDomainToAccountTypeMutation();
  const handleApprovedDomainConfirmation = async (domain: string) => {
    if (!accountType) return;
    try {
      await addApprovedDomainMutation({
        variables: { id: accountType.id, domain },
      });
      setApprovedDomains([...approvedDomains, domain]);
    } catch (e) {}
  };

  const [
    addRejectedDomainMutation,
    { loading: addRejectedDomainLoading, error: addRejectedDomainError },
  ] = useAddRejectedDomainToAccountTypeMutation();
  const handleRejectedDomainConfirmation = async (domain: string) => {
    if (!accountType) return;
    try {
      await addRejectedDomainMutation({
        variables: { id: accountType.id, domain },
      });
      setRejectedDomains([...rejectedDomains, domain]);
    } catch (e) {}
  };

  const [
    removeApprovedDomainMutation,
    { loading: removeApprovedDomainLoading, error: removeApprovedDomainError },
  ] = useRemoveApprovedDomainFromAccountTypeMutation();
  const handleApprovedDomainRemoval = async (selectedDomains: string[]) => {
    if (!accountType) return;
    const filteredDomains = approvedDomains.filter(
      domain => !selectedDomains.includes(domain),
    );

    try {
      await removeApprovedDomainMutation({
        variables: { id: accountType.id, domains: selectedDomains },
      });
      setApprovedDomains(filteredDomains);
    } catch (e) {}
  };

  const [
    removeRejectedDomainMutation,
    { loading: removeRejectedDomainLoading, error: removeRejectedDomainError },
  ] = useRemoveRejectedDomainFromAccountTypeMutation();
  const handleRejectedDomainRemoval = async (selectedDomains: string[]) => {
    if (!accountType) return;
    const filteredDomains = rejectedDomains.filter(
      domain => !selectedDomains.includes(domain),
    );
    try {
      await removeRejectedDomainMutation({
        variables: { id: accountType.id, domains: selectedDomains },
      });
      setRejectedDomains(filteredDomains);
    } catch (e) {}
  };

  return (
    <AccountTypeDetailsWrapper>
      {accountType && (
        <>
          <SectionDetails
            handleConfirmation={handleUpdateAccountTypeDescriptionConfirmation}
            accountTypeDetails={details}
            loading={updateAccountTypeDescriptionLoading}
            error={updateAccountTypeDescriptionError}
            target={{ id: accountType.id, displayName: 'Account type' }}
            disabled={!isAccountTypeAdminOf(accountType.id)}
          />
          {accountType.checkType === AccountTypeOptions.DOMAIN && (
            <DomainSection
              loading={addApprovedDomainLoading || addRejectedDomainLoading}
              error={addApprovedDomainError || addRejectedDomainError}
              target={{ id: accountType.id, displayName: 'Account type' }}
              existingSelectedApprovedDomains={approvedDomains}
              existingSelectedRejectedDomains={rejectedDomains}
              handleApprovedConfirmation={handleApprovedDomainConfirmation}
              handleRejectedConfirmation={handleRejectedDomainConfirmation}
              handleApprovedRemoval={handleApprovedDomainRemoval}
              handleRejectedRemoval={handleRejectedDomainRemoval}
              disabled={!isAccountTypeAdminOf(accountType.id)}
            />
          )}
          <SectionRoles
            accountType={accountType}
            disabled={!isAccountTypeAdminOf(accountType.id)}
          />

          <AccountSelectorSection
            loading={addAdminsLoading || removeAdminLoading}
            error={addAdminsError || removeAdminError}
            existingAccounts={admins}
            target={{
              id: accountType.id,
              displayName: accountType.name,
              type: 'Admin',
              origin: 'account type',
            }}
            handleConfirmation={handleAdminSelectionConfirmation}
            handleRemoval={handleAdminSelectionRemoval}
            disabled={!isAccountTypeAdminOf(accountType.id)}
          />

          <AccountSelectorSection
            loading={addApproversLoading || removeApproverLoading}
            error={addApproversError || removeApproverError}
            existingAccounts={approvers}
            target={{
              id: accountType.id,
              displayName: accountType.name,
              type: 'Approver',
              origin: 'account type',
            }}
            handleConfirmation={handleApproverSelectionConfirmation}
            handleRemoval={handleApproverSelectionRemoval}
            disabled={!isAccountTypeAdminOf(accountType.id)}
          />

          <LinkAppsSection
            loading={linkAppLoading || unlinkAppLoading}
            error={linkAppError || unlinkAppError}
            linkedApps={linkedApps}
            target={{ id: accountType.id, displayName: accountType.name }}
            linkedAppOptions={applicationOptions || []}
            handleConfirmation={handleLinkAppsConfirmation}
            handleRemoval={handleLinkAppRemoval}
            disabled={!isAccountTypeAdminOf(accountType.id)}
          />

          <TagsSection
            loading={addTagToAccountTypeLoading}
            error={addTagToAccountTypeError}
            tags={tags}
            target={{ id: accountType.id, displayName: accountType.name }}
            tagOptions={tagOptions}
            handleConfirmation={handleTagConfirmation}
            handleRemoval={handleTagRemoval}
            disabled={!isAccountTypeAdminOf(accountType.id)}
          />
        </>
      )}
    </AccountTypeDetailsWrapper>
  );
};
