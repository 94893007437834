import styled from 'styled-components';

export const WorkingWithDiv = styled('div')`
  margin-top: 2rem;
  .working-with-sainsburys-wrapper {
    h2 {
      display: none;
    }
  }
`;
