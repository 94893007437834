import Styled from 'styled-components';

const StyledHR = Styled.hr`
  margin: 4rem auto;
`;

export const PageSeparator = () => {
  return <StyledHR />;
};

export default PageSeparator;
