import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const AppCHB = styled('label')`
  display: flex;
  margin-bottom: 24px;

  .app-img {
    width: 36px;
    height: 36px;
    border-radius: 5px;
    margin-right: 15px;
    color: #fff;
    background-color: ${ThemeColors.primary};
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-size: 14px;
    line-height: 34px;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    text-transform: uppercase;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .app-info {
    width: calc(100% - 85px);
    .app-name {
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 24px;
      color: ${ThemeColors.monoChromeBlack};
    }

    .app-desc {
      font-family: 'Arial';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: ${ThemeColors.descriptionText};
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  .checkbox {
    width: 34px;

    input {
      appearance: none;
      width: 24px;
      height: 24px;
      border: 1px solid #737373;
      border-radius: 3px;
      margin-left: 10px;
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        width: 15px;
        height: 17px;
        transform: scale(0);
        transform-origin: left bottom;
        box-shadow: inset 1em 1em ${ThemeColors.primary};
        clip-path: polygon(0 0, 50% 0, 100% 0, 100% 100%, 50% 100%, 0 100%);
        position: absolute;
        top: 7px;
        left: 0px;
        transition: 0.4s cubic-bezier(0.8, 0, 0.2, 1);
        transition-property: background-color, clip-path, border-color;
        transition-delay: 0.1s, 0s;
      }

      &:checked {
        &::before {
          transform: scale(1.5);
          clip-path: polygon(
            28% 38%,
            41% 53%,
            75% 24%,
            86% 38%,
            40% 78%,
            15% 50%
          );
        }
      }
    }
  }
`;
