import styled from 'styled-components';

export const JournalErrorContainer = styled('div')`
  min-height: calc(100vh - 342px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .error-container {
    width: 600px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .img-section {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 200px;
      }
    }
    .text-section {
      padding-left: 30px;
      .title {
      }
      .message {
        font-size: 15px;
      }
    }
  }
`;
