import styled from 'styled-components';

export const CreateApplicationWrapper = styled('div')`
  .relationships-h5 {
    margin: 20px auto;
  }
  .btn-wrapper {
    margin: 15px auto;
    display: flex;

    button {
      margin-left: auto;
    }
  }

  .audience-display {
    display: flex;
    margin-bottom: 20px;

    .audience-pill {
      padding: 2px 10px;

      background: var(--ds-modifier-button-color-base);
      color: #fff;
      margin-right: 10px;
      border-radius: 15px;

      button {
        min-height: 10px;
        min-width: 10px;
        margin-left: 10px;
      }

      svg {
        padding: 0;
        height: 20px;
        width: 20px;
        color: #fff;
      }
    }
  }
`;
