import React, { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Container from 'src/components/Container/Container';

import { PageHeader } from '../../components/PageHeader';
import { DetailsRow } from 'src/shared/components/detailsRow';
import { DetailsCard } from './viewApplication.styles';
import { Section } from '../../components/Sections/section.styles';
import { Button, RadioButtonField } from '@jsluna/react';
import { IApplicationDetails } from '../../interfaces/application.interface';
import { ITag } from '../../interfaces/tag.interface';
import { TagsSection } from '../../components/Sections/TagsSection';
import { LinkAppsSection } from '../../components/Sections/LinkAppSection';
import { AccountSelectorSection } from '../../components/Sections/AccountSelectorSection';
import { gql } from '@apollo/client';
import { Drawer } from '@mui/material';
import { EditApplicationRadioScreen } from './screens/viewApplicationRadioScreen';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { EditApplicationURLScreen } from './screens/viewApplicationURLScreen';
import { EditApplicationDetailsScreen } from './screens/viewApplicationEditDetailsScreen';
import { PermissionsSection } from 'src/components/Sections/PermissionsSection';
import { IPermission } from 'src/interfaces/permission.interface';
import { PermissionGroupComp } from './components/PermissionGroupComp';
import { AuthContext } from '../../providers/AuthProvider';

import {
  useViewApplicaion_TagsQuery,
  useViewApplicaion_GetPermissionsQuery,
  useViewApplicaion_ApplicationInfoQuery,
  useViewApplicaion_ApplicationsListQuery,
  AccountSelectorSection_AccountsFragment,
  ViewApplicaion_GetPermissionsFragment,
  TagSelection_TagsFragment,
  LinkAppsSection_LinkAppInfoFragment,
  useViewApplicaion_GetPermissionsLazyQuery,
} from 'src/operations/generated/graphql';

gql`
  fragment ViewApplicaion_ApplicationInfo on ApplicationProjection {
    id
    name
    acronym
    shortDescription
    longDescription
    admins {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    approvers {
      value {
        ...AccountSelectorSection_accounts
      }
    }
    tags {
      value {
        ...TagSelection_Tags
      }
    }
    proxyLocation
    isLive
    prospectiveAllowed
    linkedApplications {
      value {
        ...LinkAppsSection_linkAppInfo
      }
    }
    slug
    type
  }
`;

gql`
  query ViewApplicaion_Tags {
    tags {
      ...TagSelection_Tags
    }
  }
`;

gql`
  fragment ViewApplicaion_GetPermissions on PermissionProjection {
    id
    name
  }
`;

gql`
  query ViewApplicaion_GetPermissions($applicationId: String!) {
    applicationPermissions(applicationId: $applicationId) {
      ...ViewApplicaion_GetPermissions
      ...PermissionsSection_PermissionInfo
    }
  }
`;

gql`
  query ViewApplicaion_ApplicationInfo($id: String!) {
    application(id: $id) {
      ...ViewApplicaion_ApplicationInfo
    }
  }
`;

gql`
  query ViewApplicaion_ApplicationsList {
    applications {
      id
      name
      acronym
      shortDescription
      longDescription
    }
  }
`;

import {
  useAssociateTagsToApplicationMutation,
  useAssociateAdminsToApplicationMutation,
  useAssociateDependentApplicationsMutation,
  useAssociateApproversToApplicationMutation,
  useDissociateAdminsFromApplicationMutation,
  useDissociateApproversFromApplicationMutation,
  useDissociateTagsFromApplicationMutation,
  useDissociateDependentApplicationsMutation,
  useUpdateApplicationDetailsMutation,
  useUpdateApplicationIsLiveMutation,
  useUpdateApplicationProspectiveAllowedMutation,
  useUpdateApplicationProxyLocationMutation,
} from 'src/operations/generated/graphql';
import { PermissionExtractSection } from './components/PermissionExtractSection';
import { ViewApplicationLoader } from './components/applicationsLoader/viewApplicationLoader';

interface IDetailsToChange {
  acronym?: string;
  name?: string;
  shortDescription?: string;
  longDescription?: string;
}

enum PanelAction {
  EDIT_LIVE_STATUS = 'EDIT_LIVE_STATUS',
  EDIT_INTEGRATION_STATUS = 'EDIT_INTEGRATION_STATUS',
  EDIT_PROSPECTIVE_STATUS = 'EDIT_PROSPECTIVE_STATUS',
  EDIT_REQUIRED_STATUS = 'EDIT_REQUIRED_STATUS',
  EDIT_PROXY_URL = 'EDIT_PROXY_URL',
  EDIT_DETAILS = 'EDIT_DETAILS',
}

export const ViewApplication: FC = () => {
  // @ts-ignore
  const { isAppAdminOf, isAppApproverOf } = useContext(AuthContext);

  const { id } = useParams();
  const [approvers, setApprovers] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [admins, setAdmins] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [tags, setTags] = useState<TagSelection_TagsFragment[]>([]);

  const [linkedApps, setLinkedApps] = useState<
    LinkAppsSection_LinkAppInfoFragment[]
  >([]);
  const [proxyLocation, setProxyLocation] = useState('');
  const [applicationId, setApplicationId] = useState('');
  const [details, setDetails] = useState({
    name: '',
    acronym: '',
    shortDescription: '',
    longDescription: '',
  });
  const [isLive, setIsLive] = useState<boolean>(false);
  const [prospectiveAllowed, setProspectiveAllowed] = useState<boolean>(false);
  const [applicationPermissions, setApplicationPermissions] = useState<
    ViewApplicaion_GetPermissionsFragment[]
  >([]);

  const { loading: applicationLoading, data: { application = null } = {} } =
    useViewApplicaion_ApplicationInfoQuery({ variables: { id: id || '' } });

  const { loading: tagsLoading, data: { tags: tagOptions = [] } = {} } =
    useViewApplicaion_TagsQuery();

  const {
    loading: applicationsLoading,
    data: { applications: applicationOptions = [] } = {},
  } = useViewApplicaion_ApplicationsListQuery();

  const [
    getApplicationPermissions,
    {
      data: { applicationPermissions: appPermissions = null } = {},
      refetch,
      loading: applicationPermissionsLoading,
    },
  ] = useViewApplicaion_GetPermissionsLazyQuery({
    variables: { applicationId },
    fetchPolicy: 'no-cache',
  });

  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  useEffect(() => {
    if (!application) return;
    const {
      acronym,
      shortDescription,
      longDescription,
      name,
      admins,
      approvers,
      tags,
      proxyLocation,
      isLive,
      prospectiveAllowed,
      linkedApplications,
    } = application;
    setDetails({
      acronym: acronym || '',
      shortDescription: shortDescription || '',
      longDescription: longDescription || '',
      name: name || '',
    });
    setIsLive(isLive || false);
    setProspectiveAllowed(prospectiveAllowed || false);
    setAdmins(admins.map(admin => admin.value) || []);
    setApprovers(approvers.map(approver => approver.value) || []);
    setTags(tags.map(admin => admin.value) || []);
    setLinkedApps(linkedApplications?.map(app => app.value) || []);
    setProxyLocation(proxyLocation || '');
    setApplicationId(application.id);
  }, [application]);

  useEffect(() => {
    if (!applicationId) return;
    getApplicationPermissions();
  }, [applicationId]);

  useEffect(() => {
    if (appPermissions) {
      setApplicationPermissions(appPermissions);
    }
  }, [appPermissions]);

  interface yorN {
    value: string;
    label: string;
    defaultChecked: boolean;
  }

  const defaultOption = (value: boolean): yorN[] => {
    const options = [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
    ];
    const opt = value ? 'yes' : 'no';
    return options.map(option => {
      return {
        ...option,
        defaultChecked: option.value === opt,
      };
    });
  };

  const [linkAppMutation, { loading: linkAppLoading, error: linkAppError }] =
    useAssociateDependentApplicationsMutation();

  const [detailsMutation, { loading: detailsLoading, error: detailsError }] =
    useUpdateApplicationDetailsMutation();

  const [isLiveMutation, { loading: isLiveLoading, error: isLiveError }] =
    useUpdateApplicationIsLiveMutation();

  const [
    prospectiveMutation,
    { loading: prospectiveLoading, error: prospectiveError },
  ] = useUpdateApplicationProspectiveAllowedMutation();

  const [
    proxyLocationMutation,
    { loading: proxyLocationLoading, error: proxyLocationError },
  ] = useUpdateApplicationProxyLocationMutation();

  const handleLinkAppsConfirmation = async (
    appsSelected: LinkAppsSection_LinkAppInfoFragment[],
  ) => {
    if (!application?.id) return;
    const selectedIds = appsSelected.map(app => app.id);
    try {
      await linkAppMutation({
        variables: {
          applicationId: application.id,
          dependentApplicationIds: selectedIds,
        },
      });
      setLinkedApps([...linkedApps, ...appsSelected]);
    } catch (e) {}
  };

  const [
    unlinkAppMutation,
    { loading: unlinkAppLoading, error: unlinkAppError },
  ] = useDissociateDependentApplicationsMutation();
  const handleLinkAppRemoval = async (appId: string) => {
    const filteredApps = linkedApps.filter(app => app.id !== appId);
    try {
      await unlinkAppMutation({
        variables: {
          applicationId: application?.id || '',
          dependentApplicationIds: appId,
        },
      });
      setLinkedApps(filteredApps);
    } catch (e) {}
    setLinkedApps(filteredApps);
  };

  const [
    associateTagMutation,
    { loading: associateTagLoading, error: associateTagError },
  ] = useAssociateTagsToApplicationMutation();
  const handleTagConfirmation = async (
    tagsSelected: TagSelection_TagsFragment[],
  ) => {
    if (!application?.id) return;
    const selectedIds = tagsSelected.map(tag => tag.id);
    try {
      await associateTagMutation({
        variables: { id: application.id, tagIds: selectedIds },
      });
      setTags([...tags, ...tagsSelected]);
    } catch (e) {}
  };

  const [
    dissociateTagMutation,
    { loading: dissociateTagLoading, error: dissociateTagError },
  ] = useDissociateTagsFromApplicationMutation();
  const handleTagRemoval = async (tagId: string) => {
    const filteredTags = tags.filter(tag => tag.id !== tagId);
    try {
      await dissociateTagMutation({
        variables: { id: application?.id || '', tagIds: [tagId] },
      });
      setTags(filteredTags);
    } catch (e) {}
  };

  const [
    associateApproverMutation,
    { loading: associateApproverLoading, error: associateApproverError },
  ] = useAssociateApproversToApplicationMutation();
  const handleApproverSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    const selectedIds = accountsSelected.map(account => account.id);
    try {
      await associateApproverMutation({
        variables: {
          applicationId: application?.id || '',
          accountIds: selectedIds,
        },
      });
      setApprovers([...approvers, ...accountsSelected]);
    } catch (e) {}
  };

  const [
    dissociateApproversMutation,
    { error: dissociateApproverError, loading: dissociateApproverLoading },
  ] = useDissociateApproversFromApplicationMutation();
  const handleApproverSelectionRemoval = async (accountId: string) => {
    const filteredAccounts = approvers.filter(
      account => account.id !== accountId,
    );
    try {
      await dissociateApproversMutation({
        variables: {
          applicationId: application?.id || '',
          accountIds: accountId,
        },
      });
      setApprovers(filteredAccounts);
    } catch (e) {}
  };

  const [
    associateAdminsMutation,
    { loading: associateAdminsLoading, error: associateAdminsError },
  ] = useAssociateAdminsToApplicationMutation();
  const handleAdminSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    const selectedIds = accountsSelected.map(account => account.id);
    try {
      await associateAdminsMutation({
        variables: {
          applicationId: application?.id || '',
          accountIds: selectedIds,
        },
      });
      setAdmins([...admins, ...accountsSelected]);
    } catch (e) {}
  };

  const [
    dissociateAdminMutation,
    { loading: dissociateAdminLoading, error: dissociateAdminError },
  ] = useDissociateAdminsFromApplicationMutation();
  const handleAdminSelectionRemoval = async (accountId: string) => {
    const filteredAccounts = admins.filter(account => account.id !== accountId);
    try {
      await dissociateAdminMutation({
        variables: {
          applicationId: application?.id || '',
          accountIds: accountId,
        },
      });
    } catch (e) {}
    setAdmins(filteredAccounts);
  };

  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);

  const handlePanelClose = () => {
    setShowSidePanel(null);
    setFeedBackMessage(null);
  };

  const handleEditDetails = async (value: IDetailsToChange) => {
    try {
      const valuesToSubmit = Object.fromEntries(
        Object.entries(value).filter(([_, entry]) => entry != ''),
      );
      await detailsMutation({
        variables: {
          id: application?.id || '',
          input: valuesToSubmit,
        },
      });
      setDetails({ ...details, ...value });
    } catch (e) {}
  };

  const handleIsLive = async (value: boolean) => {
    try {
      await isLiveMutation({
        variables: {
          id: application?.id || '',
          isLive: value,
        },
      });
      setIsLive(value);
    } catch (e) {}
  };

  const handleProspective = async (value: boolean) => {
    try {
      await prospectiveMutation({
        variables: {
          id: application?.id || '',
          prospectiveAllowed: value,
        },
      });
      setProspectiveAllowed(value);
    } catch (e) {}
  };

  const handleProxyLocation = async (value: string) => {
    try {
      await proxyLocationMutation({
        variables: {
          id: application?.id || '',
          proxyLocation: value,
        },
      });
      setProxyLocation(value);
    } catch (e) {}
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.EDIT_DETAILS:
        return (
          <EditApplicationDetailsScreen
            details={details}
            target={{
              id: application?.id || '',
              displayName: application?.name || '',
            }}
            loading={detailsLoading}
            error={detailsError}
            handleClose={handlePanelClose}
            handleConfirmation={handleEditDetails}
            detailsHeading={{
              heading: 'Edit application details',
              subHeading:
                'Complete the fields you want to change, empty fields will not be updated',
            }}
          />
        );
      case PanelAction.EDIT_LIVE_STATUS:
        return (
          <EditApplicationRadioScreen
            target={{
              id: application?.id || '',
              displayName: application?.name || '',
            }}
            loading={isLiveLoading}
            error={isLiveError}
            handleClose={handlePanelClose}
            handleConfirmation={handleIsLive}
            radioHeading={{
              heading: 'Set application live status',
              subHeading: 'Is this application live?',
              feedBackStatus: 'live',
            }}
            defaultValue={isLive}
          />
        );
      case PanelAction.EDIT_PROSPECTIVE_STATUS:
        return (
          <>
            <EditApplicationRadioScreen
              target={{
                id: application?.id || '',
                displayName: application?.name || '',
              }}
              loading={prospectiveLoading}
              error={prospectiveError}
              handleClose={handlePanelClose}
              handleConfirmation={handleProspective}
              radioHeading={{
                heading: 'Set application prospective status',
                subHeading:
                  'Will prospective accounts be allowed to use this app?',
                feedBackStatus: 'prospective allowed',
              }}
              defaultValue={prospectiveAllowed}
            />
          </>
        );
      case PanelAction.EDIT_PROXY_URL:
        return (
          <>
            <>
              <EditApplicationURLScreen
                target={{
                  id: application?.id || '',
                  displayName: application?.name || '',
                }}
                loading={proxyLocationLoading}
                error={proxyLocationError}
                handleClose={handlePanelClose}
                handleConfirmation={handleProxyLocation}
                urlHeading={{
                  heading: 'Set application proxy location',
                  subHeading:
                    'Enter the new proxy location for this application',
                }}
              />
            </>
          </>
        );
    }
  };

  const handlePermissionAdd = (permission: IPermission) => {
    refetch();
    setApplicationPermissions([...applicationPermissions, permission]);
  };
  const handlePermissionRemoval = (type: NotificationType) => {
    refetch();
  };

  const redirectToApp = () => {
    if (application?.type === 'EXT') {
      window.open(proxyLocation);
    } else {
      window.open(`/apps/${application?.slug}`);
    }
  };

  return (
    <>
      <PageHeader
        heading={`${details.name}`}
        breadcrumbLinks={[
          { name: 'home', link: '/' },
          { name: 'Applications', link: '/applications' },
          { name: details.name, link: '' },
        ]}
        subHeading='Manage app details, administrators and approvers.'
      />

      {applicationLoading || applicationsLoading ? (
        <ViewApplicationLoader />
      ) : !application ? (
        <p>No Data</p>
      ) : (
        <Container size='sm'>
          <DetailsCard>
            <Section>
              <div className='section-actions'>
                <h4 className='display-1 ln-u-margin-bottom*3'>Details</h4>
                <Button
                  circle={false}
                  hard={false}
                  element='button'
                  variant='filled'
                  onClick={redirectToApp}
                >
                  Open application
                </Button>
              </div>
              <DetailsRow name='Acronym' value={details.acronym} />
              <DetailsRow name='Name' value={details.name} />
              <DetailsRow
                name='Short description:'
                value={details.shortDescription}
                isHTML={true}
              />
              <DetailsRow
                name='Long description:'
                value={details.longDescription}
                isHTML={true}
              />
              {(isAppAdminOf(id) || isAppApproverOf(id)) && (
                <>
                  <div className='action-button'>
                    <Button
                      variant='text'
                      circle={false}
                      disabled={!isAppAdminOf(id)}
                      hard={false}
                      element='button'
                      color='dark'
                      onClick={() => setShowSidePanel(PanelAction.EDIT_DETAILS)}
                    >
                      Edit details
                    </Button>
                  </div>
                  <RadioButtonField
                    label={'Is the app live?'}
                    info={'Description of what this means'}
                    name='radio-button-field-1'
                    fullWidth
                    disabled={!isAppAdminOf(id)}
                    options={defaultOption(isLive)}
                    value={isLive ? 'yes' : 'no'}
                  />
                  <div className='action-button'>
                    <Button
                      variant='text'
                      circle={false}
                      disabled={!isAppAdminOf(id)}
                      hard={false}
                      element='button'
                      color='dark'
                      onClick={() =>
                        setShowSidePanel(PanelAction.EDIT_LIVE_STATUS)
                      }
                    >
                      Edit live status
                    </Button>
                  </div>
                  <RadioButtonField
                    label={
                      'Will prospective accounts be allowed to use this app?'
                    }
                    info={'Description of what this means'}
                    name='radio-button-field-3'
                    fullWidth
                    disabled={!isAppAdminOf(id)}
                    options={defaultOption(prospectiveAllowed)}
                    value={prospectiveAllowed ? 'yes' : 'no'}
                  />

                  <div className='action-button'>
                    <Button
                      variant='text'
                      circle={false}
                      disabled={!isAppAdminOf(id)}
                      hard={false}
                      element='button'
                      color='dark'
                      onClick={() =>
                        setShowSidePanel(PanelAction.EDIT_PROSPECTIVE_STATUS)
                      }
                    >
                      Manage prospective allowed
                    </Button>
                  </div>

                  <DetailsRow name='Proxy URL:' value={proxyLocation} />
                  <div className='action-button'>
                    <Button
                      variant='text'
                      circle={false}
                      disabled={!isAppAdminOf(id)}
                      hard={false}
                      element='button'
                      color='dark'
                      onClick={() =>
                        setShowSidePanel(PanelAction.EDIT_PROXY_URL)
                      }
                    >
                      Edit proxy URL
                    </Button>
                  </div>
                </>
              )}
            </Section>

            {(isAppAdminOf(id) || isAppApproverOf(id)) && (
              <>
                <AccountSelectorSection
                  handleRemoval={handleAdminSelectionRemoval}
                  existingAccounts={admins}
                  loading={associateAdminsLoading || dissociateAdminLoading}
                  error={associateAdminsError || dissociateAdminError}
                  handleConfirmation={handleAdminSelectionConfirmation}
                  target={{
                    id: application.id,
                    displayName: application?.name || '',
                    type: 'Admin',
                    origin: 'application',
                  }}
                  disabled={!isAppAdminOf(id)}
                />

                <AccountSelectorSection
                  handleRemoval={handleApproverSelectionRemoval}
                  existingAccounts={approvers}
                  loading={
                    associateApproverLoading || dissociateApproverLoading
                  }
                  error={associateApproverError || dissociateApproverError}
                  handleConfirmation={handleApproverSelectionConfirmation}
                  target={{
                    id: application.id,
                    displayName: application?.name || '',
                    type: 'Approver',
                    origin: 'application',
                  }}
                  disabled={!isAppAdminOf(id)}
                />
              </>
            )}
            {(isAppAdminOf(id) || isAppApproverOf(id)) && (
              <>
                <LinkAppsSection
                  loading={linkAppLoading || unlinkAppLoading}
                  error={linkAppError || unlinkAppError}
                  linkedApps={linkedApps}
                  target={{
                    id: application.id,
                    displayName: application?.name || '',
                  }}
                  linkedAppOptions={applicationOptions || []}
                  handleConfirmation={handleLinkAppsConfirmation}
                  handleRemoval={handleLinkAppRemoval}
                  disabled={!isAppAdminOf(id)}
                />

                <TagsSection
                  tags={tags}
                  target={{
                    id: application.id,
                    displayName: application?.name || '',
                  }}
                  handleConfirmation={handleTagConfirmation}
                  loading={associateTagLoading || dissociateTagLoading}
                  error={associateTagError || dissociateTagError}
                  handleRemoval={handleTagRemoval}
                  tagOptions={tagOptions}
                  disabled={!isAppAdminOf(id)}
                />

                <PermissionsSection
                  permissions={applicationPermissions}
                  target={{
                    id: application.id,
                    displayName: application?.name || '',
                  }}
                  handleConfirmation={handlePermissionAdd}
                  loading={associateTagLoading || dissociateTagLoading}
                  error={associateTagError || dissociateTagError}
                  handleRemoval={handlePermissionRemoval}
                  disabled={!isAppAdminOf(id)}
                />

                <PermissionGroupComp
                  application={application}
                  disabled={!isAppAdminOf(id)}
                />
              </>
            )}
            {id && isAppAdminOf(id) && applicationPermissions.length > 0 && (
              <PermissionExtractSection applicationID={id} />
            )}
          </DetailsCard>
        </Container>
      )}

      <Drawer
        open={showSidePanel !== null}
        onClose={handlePanelClose}
        anchor='right'
        className='view-application'
      >
        {renderPanel()}
      </Drawer>
    </>
  );
};
