import React, { FC } from 'react';
import { FilterSelectWrapper } from './filterSelect.styles';
import { MultiAutocompleteField } from '@jsluna/react';

interface IFilterSelect {
  filterOptions: any[];
  placeholder: string;
  onChangeFn: (selected: any[]) => void;
  label?: string;
  hideLabel?: boolean;
  defaultSelectedOptions?: any;
}

export const FilterSelect: FC<IFilterSelect> = ({
  filterOptions,
  placeholder,
  onChangeFn,
  label,
  hideLabel,
  defaultSelectedOptions,
}) => {
  return (
    <FilterSelectWrapper>
      <MultiAutocompleteField
        name='multi-autocomplete-field-1'
        placeholder={placeholder}
        label={label}
        options={filterOptions}
        role='search'
        className='filters-select'
        hideLabel={hideLabel}
        onSelect={onChangeFn}
        defaultSelectedOptions={defaultSelectedOptions}
      />
    </FilterSelectWrapper>
  );
};
