import styled from 'styled-components';
export const ProtectedLayoutContainer = styled('div')`
  display: flex;

  .route-container {
    width: 100%;
  }

  .header-container {
    position: relative;
  }
`;
