import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { routes } from 'src/constants/routes';

export const TopNavigation: FC = () => {
  const links = [
    { title: 'Home', href: routes.root, active: false },
    {
      title: 'Trading Terms & Conditions',
      href: routes.tradingTermsAndConditions,
      active: true,
    },
  ];
  return (
    <TopOfPage
      title='Sainsbury&#39;s Trading Terms &amp; Conditions For Grocery'
      breadcrumbs={links}
      LinkType={Link}
    />
  );
};
