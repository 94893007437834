import styled from 'styled-components';
import { Card } from '@jsluna/react';

export const TagManagerWrapper = styled((props: any) => <Card {...props} />)`
  .menu-item {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: bold;
    font-size: 18px;
  }

  .tag-manager-action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .ln-o-grid {
      min-width: calc(100% - 150px);
    }
    .btn-add-tag {
      margin-left: auto;
      width: 120px;
    }
  }
`;

export const RelativeDiv = styled('div')`
  position: relative;

  .notification-wrapper {
    top: -48px;
  }
`;
