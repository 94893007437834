import React, { FC } from 'react';
import { AppTitles } from 'src/constants/titles.enum';
import { GridWrapper, GridItem } from '@jsluna/react';
import { QUERY_APP_ICON } from 'src/constants/icons';

export const FeaturedAppSection: FC = () => {
  const featuredLeftBoxSizes = {
    xs: '1/1',
    sm: '1/1',
    md: '6/12',
    lg: '6/12',
    xl: '6/12',
  };

  const featuredRightBoxSizes = {
    xs: '1/1',
    sm: '1/1',
    md: '6/12',
    lg: '6/12',
    xl: '6/12',
  };

  return (
    <GridWrapper className='landing-page__featured-app'>
      <GridItem
        size={featuredLeftBoxSizes}
        className='landing-page__featured-app__left-box'
      >
        <div className='landing-page__featured-app__left-box__inner'>
          <div className='landing-page__featured-app__left-box__icon-box'>
            <img
              className='landing-page__featured-app__icon'
              width={46}
              height={46}
              src={QUERY_APP_ICON}
              alt='Query App'
            />
            <div className='ln-u-padding-left'>Featured</div>
          </div>
          <h2 className='ln-u-display-4 landing-page__section-title ln-u-flush-bottom ln-u-padding-top'>
            Working with Sainsbury&#39;s
          </h2>
          <p className='landing-page__featured-app__description'>
            On Together With you can find our Trading T&#38;Cs and Supplier
            Handbook in addition to our dedicated Supplier Support app.
          </p>
          <p className='landing-page__featured-app__description'>
            Quickly and easily find the information and answers you need to work
            with us.
          </p>
        </div>
      </GridItem>
      <GridItem
        size={featuredRightBoxSizes}
        className='landing-page__featured-app__right-box'
      >
        <div className='landing-page__featured-app__device-image' />
      </GridItem>
    </GridWrapper>
  );
};
