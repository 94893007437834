import styled from 'styled-components';

export const WOWContainer = styled('div')`
  .ways-of-working__main-panel {
    border-left: 0;
  }
  @media (min-width: 960px) {
    .ways-of-working__main-panel {
      border-left: ln-px-to-rem(2px) solid #f2f2f2;
      padding-right: 1.5rem;
    }
  }
  .ways-of-working__main-panel .wow-content {
    padding-right: 1rem;
  }
  @media (min-width: 960px) {
    .ways-of-working__main-panel .wow-content {
      padding-right: 1.5rem;
    }
  }
  @media (min-width: 960px) {
    .ways-of-working__main-panel .wow-article {
      padding-top: 1.5rem;
    }
  }
  .ways-of-working__main-panel .wow-article h4,
  .ways-of-working__main-panel .wow-article h6 {
    color: #f06c00;
  }
  .ways-of-working__main-panel .wow-article blockquote {
    border-left: 1rem solid #f06c00;
    background: #f2f2f2;
    padding: 2rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
    font-style: italic;
  }
  .ways-of-working__main-panel .wow-article blockquote p:last-of-type {
    margin-bottom: 0;
  }
  .ways-of-working__main-panel .wow-article ul li {
    margin-left: 0.5rem;
    padding-left: 0.5rem;
  }
  .ways-of-working__main-panel .wow-article pre {
    font-family: arial, sans-serif;
    background-color: rgba(240, 108, 0, 0.1);
    margin: 2rem 0;
    padding: 2rem;
    white-space: normal;
  }
  .ways-of-working__main-panel .wow-article img {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .ways-of-working__main-panel .wow-article table {
    border-collapse: collapse;
    width: 100%;
  }
  .ways-of-working__main-panel .wow-article table thead tr {
    font-size: 1.125rem;
    line-height: 1.333;
    font-weight: 800;
    border-bottom: 1px solid #f06c00;
  }
  .ways-of-working__main-panel .wow-article table thead tr th {
    padding: 1rem;
  }
  .ways-of-working__main-panel .wow-article table tbody tr {
    background-color: white;
  }
  .ways-of-working__main-panel .wow-article table tbody tr.even {
    background-color: #f2f2f2;
  }
  .ways-of-working__main-panel .wow-article table tbody tr td {
    padding: 1rem;
  }

  .light-grey-menu-box {
    background-color: #f2f2f2;
    margin: 1rem 1rem 1rem 0;
    padding: 1rem 2rem;
  }

  .ln-u-color-orange {
    color: #f06c00 !important;
  }

  .back-to-top {
    position: fixed;
    right: 1rem;
    top: 50%;
    z-index: 2;
  }

  .scroll-back-to-top,
  .navigation__back,
  .t-side-menu__mobile {
    @media (min-width: 960px) {
      display: block;
    }

    display: none;
  }

  .cursor--pointer {
    cursor: pointer;
  }
`;
