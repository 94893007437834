import { FC, useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import updateProfileSchema from './updateProfile.schema';
import {
  AutocompleteField,
  TextInputField,
  Button,
  ProgressSpinner,
  Checkbox,
} from '@jsluna/react';
import {
  createCountryCodeOptions,
  createTitleOptions,
  getDefaultOption,
} from 'src/utils/createOptions';
import { IRequestProfile } from '@interfaces/requestProfile.interface';
import { IProfile } from '@interfaces/profile.interface';
import { FormWrapper } from './UpdateProfileForm.styles';
import { routes } from 'src/constants/routes';
import { LoggedInProfileFullFragment } from '../../../../operations/generated/graphql';

const TITLE_PLACEHOLDER = 'Select title';
export const COUNTRY_CODE_PLACEHOLDER = 'Select country code';

interface ICreateProfileForm {
  onSubmit: (data: IRequestProfile) => void;
  loading: boolean;
  initialValue?: LoggedInProfileFullFragment;
}

export const UpdateProfileForm: FC<ICreateProfileForm> = ({
  onSubmit,
  loading,
  initialValue,
}) => {
  const titleOptions = createTitleOptions();
  const countryCodeOptions = createCountryCodeOptions();

  const defaultCountryCodeOption =
    initialValue?.countryCode &&
    getDefaultOption(countryCodeOptions, initialValue.countryCode);

  const defaultTitleOption =
    initialValue?.title && getDefaultOption(titleOptions, initialValue.title);

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(updateProfileSchema),
    mode: 'onChange',
  });

  const submitHandler = (formData: any) => {
    onSubmit(formData);
  };

  useEffect(() => {
    if (initialValue?.title) {
      setValue('title', initialValue?.title);
    }
    if (initialValue?.countryCode) {
      setValue('countryCode', initialValue?.countryCode);
    }
  }, []);

  return (
    <FormWrapper>
      <form onSubmit={handleSubmit(submitHandler)}>
        <Controller
          name='title'
          control={control}
          render={({ field }) => (
            <AutocompleteField
              {...field}
              error={errors?.title?.message}
              label='Title'
              options={titleOptions}
              placeholder={TITLE_PLACEHOLDER}
              defaultSelectedOption={defaultTitleOption}
              onSelect={async (selected: any) => {
                setValue('title', selected?.value || '');
                selected && (await trigger('title'));
              }}
            />
          )}
        />

        <Controller
          name='firstName'
          control={control}
          defaultValue={initialValue?.firstName}
          render={({ field }) => (
            <TextInputField
              {...field}
              label='First Name'
              error={errors?.firstName?.message}
            />
          )}
        />

        <Controller
          name='lastName'
          control={control}
          defaultValue={initialValue?.lastName}
          render={({ field }) => (
            <TextInputField
              {...field}
              label='Last Name'
              error={errors?.lastName?.message}
            />
          )}
        />
        <Controller
          name='preferredName'
          control={control}
          defaultValue={initialValue?.preferredName}
          render={({ field }) => (
            <TextInputField
              {...field}
              label='Preferred Name'
              error={errors?.preferredName?.message}
            />
          )}
        />
        <Controller
          name='emailAddress'
          control={control}
          defaultValue={initialValue?.emailAddress}
          render={({ field }) => (
            <TextInputField
              {...field}
              label='Email address'
              error={errors?.emailAddress?.message}
              disabled={true}
            />
          )}
        />

        <Controller
          name='countryCode'
          control={control}
          render={({ field }) => (
            <AutocompleteField
              {...field}
              error={errors?.countryCode?.message}
              label='Country code'
              options={countryCodeOptions}
              defaultSelectedOption={defaultCountryCodeOption}
              placeholder={COUNTRY_CODE_PLACEHOLDER}
              onSelect={async (selected: any) => {
                setValue('countryCode', selected?.value || '');
                selected && (await trigger('countryCode'));
              }}
            />
          )}
        />

        <Controller
          name='contactNumber'
          control={control}
          defaultValue={initialValue?.contactNumber}
          render={({ field }) => (
            <TextInputField
              {...field}
              onChange={field.onChange}
              error={errors?.contactNumber?.message}
              label='Contact phone number'
            />
          )}
        />

        <div className='terms-and-conditions'>
          <div className='title'>Terms & Conditions</div>
          <Controller
            name='TAndCAgreed'
            control={control}
            defaultValue={initialValue?.TAndCAgreed}
            render={({ field }) => (
              <>
                <Checkbox
                  {...field}
                  label={
                    <>
                      By confirming your details you are agreeing to our{' '}
                      <a href={routes.termsAndConditions}>
                        terms & conditions.
                      </a>{' '}
                    </>
                  }
                  onChange={field.onChange}
                  error={errors?.TAndCAgreed?.message}
                />
              </>
            )}
          />
          {errors?.TAndCAgreed?.message && (
            <div
              id='TAndCAgreedValidation'
              className='ln-c-field-info ln-c-field-info--error'
              role='alert'
              aria-live='assertive'
            >
              Terms and conditions needs to be accepted
            </div>
          )}
        </div>

        <Button type='submit' variant='filled' className='add-profile'>
          {loading && (
            <ProgressSpinner size='icon' className='ln-u-push-right-sm' />
          )}
          Confirm details
        </Button>
      </form>
    </FormWrapper>
  );
};
