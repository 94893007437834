export enum AppHosts {
  AccountService = '/account-service',
  AccountProjection = '/account-projection',
  ApplicationService = '/application-service',
  ApplicationProjection = '/application-projection',
  HierarchyProjection = '/hierarchy-projection',
  NotificationProjection = '/notification-projection',
  OrganisationService = '/organisation-service',
  OrganisationProjection = '/organisation-projection',
  PermissionService = '/permission-service',
  PermissionProjection = '/permission-projection',
  ProfileService = '/profile-service',
  ProfileProjection = '/profile-projection',
  TagService = '/tag-service',
  TagProjection = '/tag-projection',
  RoutingProjection = '/routing-projection',
}
