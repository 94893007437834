import React, { FC, useEffect, useState } from 'react';
import { InvalidRowModal } from '../invalidRowModel/invalidRowModel';

interface IValidEntries {
  rowData: any;
}

export const ValidEntries: FC<IValidEntries> = ({ rowData }) => {
  const {
    total_entries,
    total_valid_entries,
    publish_failed_description,
    invalid_row_numbers,
  } = rowData;
  const [isOpen, setIsOpen] = useState(false);
  const [needsModal, setNeedsModal] = useState(false);

  useEffect(() => {
    setNeedsModal(invalid_row_numbers || publish_failed_description);
  }, [publish_failed_description, invalid_row_numbers]);

  const onOpen = (e: any) => {
    e.preventDefault();
    setIsOpen(true);
  };
  const onClose = () => setIsOpen(false);

  new Intl.DateTimeFormat();

  const CellValueWrapper = ({ value, renderer }: any) => {
    if (!value) return <span>-</span>;
    return renderer ? renderer() : value;
  };

  return (
    <>
      {needsModal && (
        <InvalidRowModal
          isOpen={isOpen}
          handleClose={onClose}
          invalidRows={
            invalid_row_numbers ? invalid_row_numbers.split(',') : undefined
          }
          publishFailedDescription={publish_failed_description}
        />
      )}
      <CellValueWrapper
        value={total_entries}
        renderer={() => (
          <span {...(needsModal && { className: 'link' })} onClick={onOpen}>
            {total_valid_entries}/{total_entries}
          </span>
        )}
      />
    </>
  );
};
