import React, { FC, useState } from 'react';
import { RoleSectionWrapper } from './roleSections.styles';
import { DetailsRow } from 'src/shared/components/detailsRow';
import { IconButton } from '@jsluna/react';
import { Plus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError, gql } from '@apollo/client';
import { UpdateRoleDetailsScreen } from '../screens/updateRoleDetailsScreen';
import { RoleDetailsSection_RoleDetailsFragment } from 'src/operations/generated/graphql';

interface IDetailsToChange {
  name?: string;
  description?: string;
}
interface ISectionDetails {
  handleConfirmation: (role: IDetailsToChange) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  role: RoleDetailsSection_RoleDetailsFragment;
}

const PlusIcon = Plus as unknown as React.FC;

gql`
  fragment RoleDetailsSection_RoleDetails on RoleProjection {
    id
    name
    description
  }
`;

export const RoleDetailsSection: FC<ISectionDetails> = ({
  role,
  handleConfirmation,
  loading,
  error,
  target,
}) => {
  const [showEditDetails, setShowEditDetails] = useState(false);

  const handleCloseEditDetails = () => {
    setShowEditDetails(false);
  };

  return (
    <RoleSectionWrapper>
      {role && (
        <>
          <h4 className='display-1 ln-u-margin-bottom*3'>Role details</h4>
          <DetailsRow name='Name' value={role.name ? role.name : 'N/A'} />
          <DetailsRow
            name='Description'
            value={role.description ? role.description : 'N/A'}
          />
          <div className='action-panel'>
            <IconButton
              variant='text'
              circle={false}
              disabled={false}
              hard={false}
              element='button'
              label='Edit details'
              color='dark'
              onClick={() => {
                setShowEditDetails(true);
              }}
            >
              <PlusIcon />
            </IconButton>
          </div>

          <Drawer
            open={showEditDetails}
            onClose={handleCloseEditDetails}
            anchor='right'
            className='drawer-on-top edit-account-type'
          >
            <UpdateRoleDetailsScreen
              details={role}
              target={target}
              loading={loading}
              error={error}
              handleClose={handleCloseEditDetails}
              handleConfirmation={handleConfirmation}
              detailsHeading={{
                heading: 'Update role details',
                subHeading: 'Update the role details',
              }}
            />
          </Drawer>
        </>
      )}
    </RoleSectionWrapper>
  );
};
