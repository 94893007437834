import React, { FC, useEffect, useState } from 'react';
import { Button, CheckboxField, ProgressSpinner } from '@jsluna/react';
import { ApolloError } from '@apollo/client';

import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { RemoveRoleContainer } from './removeRoles.styles';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { RoleSelection_AccountToRoleRelationshipFragment } from 'src/operations/generated/graphql';

interface IRoleOptions {
  label: string;
  value: string;
}

interface IRemoveRoleScreen {
  handleClose: () => void;
  roles: RoleSelection_AccountToRoleRelationshipFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (roles: string[]) => Promise<void>;
}

export const RemoveRoleScreen: FC<IRemoveRoleScreen> = ({
  handleClose,
  roles,
  loading,
  error,
  handleConfirmation,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const [roleOptions, setRoleOptions] = useState<IRoleOptions[]>([]);

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  useEffect(() => {
    const options = roles.map<IRoleOptions>(({ value }) => ({
      label: value.name || '',
      value: value.id,
    }));
    setRoleOptions(options);
  }, [roles]);

  const handleReset = () => {
    setFeedBackMessage(null);
    setSelectedRoles([]);
  };

  const onRolesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedRoles([...selectedRoles, value]);
    } else {
      const filteredRoles = selectedRoles.filter(
        role => role !== e.target.value,
      );
      setSelectedRoles(filteredRoles);
    }
  };

  const handleSubmission = async () => {
    await handleConfirmation(selectedRoles);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <RemoveRoleContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Selected roles have successfully been removed from your account'
                  : 'Unable to remove role. Please try again.'
              }
              saveButtonText={'Close'}
              onCloseHandler={handleClose}
              resetButtonText={`${
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Remove more roles'
                  : 'Try again'
              }`}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Account updated'
                  : 'Failed to remove role'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>{`Remove access to a role`}</div>
            {roleOptions.length > 0 ? (
              <CheckboxField
                info='You can select more than one role.'
                name='roles-to-remove-checkbox'
                options={roleOptions}
                onChange={onRolesSelected}
              />
            ) : (
              <p>There are no roles to remove.</p>
            )}
          </>
        )}
      </RemoveRoleContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={handleSubmission}
            variant='filled'
            disabled={!selectedRoles.length}
          >
            {loading && <ProgressSpinner />}
            {'Remove roles'}
          </Button>
          <Button
            onClick={() => {
              setSelectedRoles([]);
              handleClose();
            }}
            variant='outlined'
          >
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
