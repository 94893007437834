import React, { FC } from 'react';
import { JournalError } from 'src/components/JournalError';
import { JournalLoading } from 'src/components/JournalLoading';
import { useGetSupplierArticleGroupings } from 'src/operations/queries/getSupplierArticleGroupings';
import { PlanBetterBlock } from 'src/pages/newsAndArticles/components/planBetterBlock';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { AgricultureSectionDiv } from './AgricultureSection.styles';

export const AgricultureSection: FC = () => {
  const {
    data: { articles: planBetterData } = {},
    error: planBetterError,
    loading: planBetterLoading,
  } = useGetSupplierArticleGroupings('Agriculture');

  return (
    <AgricultureSectionDiv>
      <SectionHeader heading='Agriculture' />
      {planBetterLoading ? (
        <JournalLoading />
      ) : planBetterError ? (
        <JournalError />
      ) : (
        <>
          {planBetterData?.length > 0 && (
            <PlanBetterBlock data={planBetterData[0]} isAgriculture={true} />
          )}
        </>
      )}
    </AgricultureSectionDiv>
  );
};
