import React, { FC, useState } from 'react';
import {
  IconButton,
  Button,
  Modal,
  ModalHeading,
  TextInputField,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from '@jsluna/react';
import { Section } from '../section.styles';
import { Plus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError, gql } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { AddNewPermissionGroupScreen } from 'src/screens/addNewPermissionGroup';
import { PermissionGroupRow } from 'src/pages/viewApplication/components/permissionGroupRow';
import {
  PermissionDeleteRow_PermissionInfoFragment,
  PermissionGroupRow_PermissionGroupInfoFragment,
  useDeletePermissionGroupMutation,
  useDissociatePermissionsFromGroupMutation,
} from 'src/operations/generated/graphql';
const PlusIcon = Plus as unknown as React.FC;

export enum DeleteOption {
  DISSOCIATE_PERMISSION_FROM_GROUP = 'DISSOCIATE_PERMISSION_FROM_GROUP',
  DELETE_PERMISSION_GROUP = 'DELETE_PERMISSION_GROUP',
}
interface IPermissionGroupsSection {
  permissionGroup: PermissionGroupRow_PermissionGroupInfoFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  handleConfirmation: (result: any) => void;
  handleRemoval: (
    type: NotificationType,
    removedId: string,
    deletedType: DeleteOption,
    groupId?: string,
  ) => void;
  disabled: boolean;
}

gql`
  fragment PermissionGroupSection_PermissionGroupInfo on PermissionGroupProjection {
    ...PermissionGroupRow_PermissionGroupInfo
  }
`;

export const PermissionGroupSection: FC<IPermissionGroupsSection> = ({
  permissionGroup,
  target,
  handleConfirmation,
  handleRemoval,
  loading,
  error,
  disabled,
}) => {
  const [showSidePanel, setShowSidePanel] = useState(false);
  const [showModal, setShowModal] = useState<DeleteOption | null>(null);
  const [selectedPermission, setSelectedPermission] =
    useState<PermissionDeleteRow_PermissionInfoFragment | null>(null);
  const [selectedPermissionGroup, setSelectedPermissionGroup] =
    useState<PermissionGroupRow_PermissionGroupInfoFragment | null>(null);
  const [permissionName, setPermissionName] = useState('');

  const [deletePermissionGroupMutation] = useDeletePermissionGroupMutation();
  const [dissociatePermissionsFromGroupMutation] =
    useDissociatePermissionsFromGroupMutation();

  const handlePanelClose = () => {
    setShowSidePanel(false);
  };

  const deleteOperation = async () => {
    if (showModal === DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP) {
      await dissociatePermission();
    } else if (showModal === DeleteOption.DELETE_PERMISSION_GROUP) {
      await deletePermissionGroup();
    }
    handleReset();
  };
  const handleReset = () => {
    setShowModal(null);
    setSelectedPermission(null);
  };

  const dissociatePermission = async () => {
    if (!selectedPermission || !selectedPermissionGroup) return;
    try {
      const removedID = await dissociatePermissionsFromGroupMutation({
        variables: {
          input: {
            permissionIds: [selectedPermission?.id],
            groupId: selectedPermissionGroup?.id,
            applicationId: target.id,
          },
        },
      });

      if (
        removedID?.data?.dissociatePermissionsFromGroup?.dissociatedPermissions
          ?.length
      ) {
        handleRemoval(
          NotificationType.SUCCESS,
          '',
          DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP,
          removedID.data.dissociatePermissionsFromGroup.groupId,
        );
      } else {
        handleRemoval(
          NotificationType.FAILURE,
          '',
          DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP,
        );
      }
    } catch (error) {
      handleRemoval(
        NotificationType.FAILURE,
        '',
        DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP,
      );
    }
  };
  const deletePermissionGroup = async () => {
    if (!selectedPermissionGroup) return;

    try {
      const removedID = await deletePermissionGroupMutation({
        variables: {
          id: selectedPermissionGroup.id,
        },
      });
      if (removedID) {
        handleRemoval(
          NotificationType.SUCCESS,
          '',
          DeleteOption.DELETE_PERMISSION_GROUP,
        );
      } else {
        handleRemoval(
          NotificationType.FAILURE,
          '',
          DeleteOption.DELETE_PERMISSION_GROUP,
        );
      }
    } catch (error) {
      handleRemoval(
        NotificationType.FAILURE,
        '',
        DeleteOption.DELETE_PERMISSION_GROUP,
      );
    }
  };

  const getDeleteMessage = () => {
    const permissionName =
      showModal === DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP
        ? selectedPermission?.name
        : selectedPermissionGroup?.name;
    return `You're deleting the "${permissionName}". Please note this action can't be undone.`;
  };
  const isDisabled = () => {
    const selectedName =
      showModal === DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP
        ? selectedPermission?.name
        : selectedPermissionGroup?.name;
    return permissionName === selectedName;
  };

  return (
    <Section>
      <h4>Permission Groups</h4>

      {permissionGroup.length > 0 ? (
        <div className='permissions-section'>
          <div className='permissions-container'>
            <div className='permissions'>
              {permissionGroup.map(permissionGroup => (
                <PermissionGroupRow
                  applicationId={target.id}
                  disabled={disabled}
                  permissionGroup={permissionGroup}
                  setSelectedPermission={perm => {}}
                  deleteHandler={(
                    selected: any,
                    deleteOption: DeleteOption,
                  ) => {
                    setShowModal(deleteOption);
                    if (
                      deleteOption ===
                      DeleteOption.DISSOCIATE_PERMISSION_FROM_GROUP
                    ) {
                      setSelectedPermission(selected);
                      if (permissionGroup?.id) {
                        setSelectedPermissionGroup(permissionGroup);
                      }
                    } else if (
                      deleteOption === DeleteOption.DELETE_PERMISSION_GROUP
                    ) {
                      setSelectedPermissionGroup(selected);
                    }
                  }}
                  key={permissionGroup.id}
                />
              ))}
            </div>
          </div>
          <div className='section-actions'>
            <IconButton
              variant='text'
              circle={false}
              disabled={disabled}
              hard={false}
              element='button'
              label='Add permission group'
              color='dark'
              onClick={() => setShowSidePanel(true)}
            >
              <PlusIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <>
          <div className='body-1 ln-u-margin-bottom*3'>
            There are no permission groups currently associated with{' '}
            {target.displayName}.
          </div>
          <Button
            element='button'
            variant='filled'
            onClick={() => setShowSidePanel(true)}
            disabled={disabled}
          >
            Add permission group
          </Button>
        </>
      )}

      <Drawer
        open={showSidePanel}
        onClose={handlePanelClose}
        anchor='right'
        className='add-permission'
      >
        <AddNewPermissionGroupScreen
          applicationId={target.id}
          closeHandler={handlePanelClose}
          addCallback={handleConfirmation}
        />
      </Drawer>

      <Modal
        restrictClose
        open={showModal != null}
        headingId='permission-delete-confirmation'
      >
        <ModalHeading element='h3' className='name-modal'>
          {getDeleteMessage()}
        </ModalHeading>
        You will lose relations associated with this permission group.
        <TextInputField
          name='confirm-account-field'
          required='*'
          label='Please confirm the name'
          onChange={(e: any) => {
            setPermissionName(e.target.value);
          }}
        />
        <ButtonGroupWrapper className='ln-u-margin-left'>
          <ButtonGroupPrimary>
            <FilledButton
              className='ln-u-margin-right'
              disabled={!isDisabled()}
              onClick={() => deleteOperation()}
            >
              Confirm
            </FilledButton>
            <OutlinedButton
              onClick={() => {
                handleReset();
              }}
            >
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </Section>
  );
};
