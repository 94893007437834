import React, { FC, useState } from 'react';
import { EditAppGroupContainer } from './editAppGroup.styles';
import { Tabs, TabLink, TabPanel } from '@jsluna/react';

import { ApplicationDeleteRow } from 'src/shared/components/applicationDeleteRow';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { AddApplicationsToGroup } from './components/addApplicationsToGroup/addApplicationsToGroup';
import { ScreenFeedback } from '../components/screenFeedback';
import { gql, useQuery } from '@apollo/client';
import { IApplicationGroup } from 'src/interfaces/applicationGroup.interface';

import {
  useAddApplicationsToMyGroupMutation,
  useRemoveApplicationsFromMyGroupMutation,
  useDeleteApplicationGroupMutation,
  useEditAppGroupScreen_MyApplicationsQuery,
  EditAppGroupScreen_ApplicationInfoFragment,
} from 'src/operations/generated/graphql';

interface ITabs {
  [key: string]: string;
}

interface IEditAppGroupScreen {
  closeAppGroupHandler: () => void;
  selectedApplicationGroup: IApplicationGroup | null;
}

gql`
  fragment EditAppGroupScreen_ApplicationInfo on ApplicationProjection {
    id
    name
    acronym
    shortDescription
  }
`;

gql`
  query EditAppGroupScreen_MyApplications {
    myApplications {
      ...EditAppGroupScreen_ApplicationInfo
    }
  }
`;

export const EditAppGroupScreen: FC<IEditAppGroupScreen> = ({
  closeAppGroupHandler,
  selectedApplicationGroup,
}) => {
  const [activeTab, setActiveTab] = useState('addApps');
  const [showFeedBack, setShowFeedback] = useState<string>('');
  const [feedBackMessage, setFeedBackMessage] = useState<string>('');

  const {
    data: { myApplications: applications } = {},
    error: applicationsError,
    loading: applicationsLoading,
  } = useEditAppGroupScreen_MyApplicationsQuery();

  const [existingApplications, setExistingApplications] = useState<
    EditAppGroupScreen_ApplicationInfoFragment[]
  >(selectedApplicationGroup ? selectedApplicationGroup.applications : []);

  const [
    addApplicationsToMyGroupMutation,
    { error: addingError, loading: addingLoading },
  ] = useAddApplicationsToMyGroupMutation();
  const [
    deleteApplicationGroupMutation,
    { error: removeError, loading: removeLoading },
  ] = useDeleteApplicationGroupMutation();
  const [removeApplicationFromGroup, { loading: deleteGroupLoading }] =
    useRemoveApplicationsFromMyGroupMutation();

  const tabs: ITabs = {
    addApps: 'Add apps',
    removeApps: 'Remove apps',
  };

  const handleUpdateGroup = async (
    selectedApps: EditAppGroupScreen_ApplicationInfoFragment[],
  ) => {
    if (!selectedApplicationGroup) return;
    const initialSelection = selectedApplicationGroup?.applications.map(app => {
      return app.id;
    });
    const selectedAppsIds = selectedApps.map(app => app.id);
    const addedId = await addApplicationsToMyGroupMutation({
      variables: {
        input: {
          groupName: selectedApplicationGroup?.name,
          applicationIds: [...selectedAppsIds, ...initialSelection],
        },
      },
    });
    if (addedId?.data?.addApplicationsToMyGroup) {
      setShowFeedback('added');
      setFeedBackMessage(
        `Selected apps have  successfully been added to your group`,
      );
      setExistingApplications(existingApplications.concat(selectedApps));
    }
  };

  const removeApp = async (
    appToDelete: EditAppGroupScreen_ApplicationInfoFragment,
  ) => {
    if (!selectedApplicationGroup) return;
    const appId = appToDelete.id;
    const deletedID = await removeApplicationFromGroup({
      variables: {
        input: {
          groupName: selectedApplicationGroup?.name,
          applicationIds: [appId],
        },
      },
    });
    if (deletedID?.data?.removeApplicationsFromMyGroup) {
      setShowFeedback('removed');
      setFeedBackMessage(
        `Application removed from "${selectedApplicationGroup?.name}" group.`,
      );
      setExistingApplications(
        existingApplications.filter(app => {
          return app.id !== appToDelete.id;
        }),
      );
    }
  };

  const deleteAppGroupHandler = async () => {
    if (!selectedApplicationGroup) return;
    const { data } = await deleteApplicationGroupMutation({
      variables: {
        applicationGroupId: selectedApplicationGroup?.id,
      },
    });

    if (data?.deleteApplicationGroup?.id) {
      setShowFeedback('removed');
      setFeedBackMessage(
        `Application group "${selectedApplicationGroup?.name}" is removed`,
      );
    }
  };

  const renderTabBody = () => {
    switch (activeTab) {
      case 'addApps':
        return (
          <>
            {applications && (
              <AddApplicationsToGroup
                mappedApplications={applications}
                existingApplications={existingApplications}
                handleUpdateGroup={handleUpdateGroup}
                closeAppGroupHandler={closeAppGroupHandler}
                addingLoading={addingLoading}
                deleteAppGroupHandler={deleteAppGroupHandler}
              />
            )}
          </>
        );
      case 'removeApps':
        return (
          <>
            <div className='tab-heading'>Remove apps from the list</div>

            {existingApplications.map(app => (
              <ApplicationDeleteRow
                key={app.id}
                application={app}
                deleteHandler={() => {
                  removeApp(app);
                }}
              />
            ))}
          </>
        );
    }
  };

  const handleReset = () => {
    setShowFeedback('');
  };

  return (
    <EditAppGroupContainer>
      {showFeedBack !== '' ? (
        <>
          <ScreenFeedback
            isLoading={false}
            notificationMessage={'Account updated'}
            notificationType={NotificationType.SUCCESS}
            feedBackMessage={feedBackMessage}
            saveButtonText={'Close'}
            onCloseHandler={closeAppGroupHandler}
            resetButtonText={'Further changes'}
            resetHandler={handleReset}
          />
        </>
      ) : (
        <>
          <h4>Edit {selectedApplicationGroup?.name}</h4>
          <Tabs type='pill'>
            {Object.keys(tabs).map(tab => (
              <TabLink
                key={tab}
                onClick={() => setActiveTab(tab)}
                active={activeTab === tab}
              >
                {tabs[tab]}
              </TabLink>
            ))}
          </Tabs>
          <TabPanel>{renderTabBody()}</TabPanel>
        </>
      )}
    </EditAppGroupContainer>
  );
};
