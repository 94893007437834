import styled from 'styled-components';
import { Card } from '@jsluna/react';
import { ThemeColors } from '../../shared/styles/variables';

export const DetailsCard = styled('div')`
  position: relative;
  padding: 42px;
  display flex;
  min-height: calc(100vh - 580px);
  max-height: 100vh;

  &:not(:first-of-type) {
    margin-top: 24px;
  }

  .profile-card{
    padding: 20px !important;

    .name-field{
      width: 120px;
    }

    .value-field{
      word-break: break-all;
    }


  }

  .tickIcon {
    width: 25px;
    height: 25px;
    background-color: green;
    border-radius: 50%;
    color: white;
    padding: 2.5px;
    margin-right: 12px;
  }
  h4 {
    &:not(:first-of-type) {
      border-top: 1px solid ${ThemeColors.sectionSeparator};
      padding-top: 24px;
    }
  }

  .profile-section{
    width: 30%;
    background-color: '#fff';

  }
  .notification-section{
    width: 70%;
    overflow: auto;
    margin-left: 20px;
  }

  .assign-block{
    display: flex;

    .ln-c-form-option{
      margin-left: auto;
    }
  }

  .requested-roles{
    display: flex;
    align-items: center;
  }

  .roleInfo{
    background-color: #1976D2;
    align-items: flex-start;
    margin: 16px 0;
    svg{
      margin-right: 8px;
      height: 24px;
      width: 44px;

    }
  }
  
  .MuiCard-root {
    padding: 20px 10px;
    & + .MuiCard-root {
      margin-top: 30px;
    }
  }
`;

export const NotificationContainer = styled('div')`
  .feedback-container {
    position: relative;
    margin-top: -48px;
    z-index: 3;
  }
`;
