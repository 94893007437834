import React, { FC, useState } from 'react';
import {
  AccountTypeDetailSection,
  DrawerPanel,
} from '../accountTypeDetails/accountTypeDetails.styles';
import { IAccountTypeDetails } from 'src/interfaces/accountTypes.interface';
import { DetailsRow } from 'src/shared/components/detailsRow';
import { IconButton } from '@jsluna/react';
import { Plus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { UpdateAccountTypeDescriptionScreen } from '../screens/editAccountTypeDetailsScreen';
import { ApolloError } from '@apollo/client';

interface ISectionDetails {
  handleConfirmation: (status: string) => Promise<void>;
  accountTypeDetails: IAccountTypeDetails | null;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  disabled?: boolean;
}

const PlusIcon = Plus as unknown as React.FC;
export const SectionDetails: FC<ISectionDetails> = ({
  handleConfirmation,
  accountTypeDetails,
  loading,
  error,
  target,
  disabled = true,
}) => {
  const [showEditDetails, setShowEditDetails] = useState(false);

  const handleCloseEditDetails = () => {
    setShowEditDetails(false);
  };

  return (
    <AccountTypeDetailSection>
      {accountTypeDetails && (
        <>
          <h4 className='display-1 ln-u-margin-bottom*3'>Details</h4>
          <DetailsRow name='Name' value={accountTypeDetails.name} />
          <DetailsRow
            name='Description'
            value={accountTypeDetails.description}
          />
          <DetailsRow name='Check type' value={accountTypeDetails.checkType} />
          <div className='action-panel'>
            <IconButton
              variant='text'
              circle={false}
              disabled={disabled}
              hard={false}
              element='button'
              label='Edit details'
              color='dark'
              onClick={() => {
                setShowEditDetails(true);
              }}
            >
              <PlusIcon />
            </IconButton>
          </div>

          <Drawer
            open={showEditDetails}
            onClose={handleCloseEditDetails}
            anchor='right'
            className='drawer-on-top edit-account-type'
          >
            <UpdateAccountTypeDescriptionScreen
              target={target}
              loading={loading}
              error={error}
              handleClose={handleCloseEditDetails}
              handleConfirmation={handleConfirmation}
              detailsHeading={{
                heading: 'Edit account type description',
                subHeading: 'Update the account type description',
              }}
              oldDescription={accountTypeDetails.description}
            />
          </Drawer>
        </>
      )}
    </AccountTypeDetailSection>
  );
};
