import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const AccountTypeManagementWrapper = styled('div')`
  .heading-section {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 12px 8px;
      line-height: 27px;
    }

    h4 {
      margin-bottom: 0;
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-weight: 800;
      font-size: 32px;
      line-height: 40px;
      color: ${ThemeColors.monochromeDarker};
    }

    margin-bottom: 48px;
  }
  .account-type-accordion {
    margin-bottom: 24px;

    .ln-c-accordion__head {
      .ln-c-accordion__icon {
        right: 52px;
      }
    }

    &.ln-c-accordion {
      &.is-open {
        .ln-c-accordion__body {
          padding: 48px;
          padding-top: 24px;
        }

        .ln-c-accordion__head {
          .ln-c-accordion__title {
            border-bottom: 1px solid ${ThemeColors.sectionSeparator};
          }
        }
      }
    }
  }
`;
export const RelativeDiv = styled('div')`
  position: relative;

  .notification-wrapper {
    top: -48px;
  }
`;
