import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const StatusDiv = styled('div')`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  width: 100%;

  .chb-display {
    background: ${ThemeColors.chbBackground};
    border-radius: 3px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${ThemeColors.white};
    }
  }

  .delete-button {
    margin-left: auto;
    margin-right: 15px;
    z-index: 5;
  }

  .label {
    margin-left: 16px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${ThemeColors.monochromeDark};
    text-transform: capitalize;
  }

  .requested-pill {
    background: ${ThemeColors.primaryFocus};
    border-radius: 1000px;
    padding: 2px 16px;
    margin-left: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }

  .less-padding {
    .ln-c-button {
      padding: 0 !important;
    }
  }
`;

export const PermissionGroupContainer = styled('div')`
  .less-padding {
    .ln-c-button {
      padding: 0 !important;
      min-height: 32px;
      min-width: 32px;
    }
  }

  .margin-40 {
    padding-left: 58px;
  }

  .permission-msg {
    font-weight: bold;
    margin-bottom: 8px;
  }
`;
