import styled from 'styled-components';

export const NoContentWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  flex-direction: column;

  img {
    width: 100px;
  }

  .no-content-message {
    text-align: center;
    max-width: 300px;
    margin-top: 15px;
    font-size: 18px;
    color: #545454;

    span {
      font-weight: bold;
    }

    div {
      font-size: 16px;
      color: #979797;
    }
  }
`;
