import styled from 'styled-components';

export const SystemNotificationWrapper = styled('div')`
  margin-top: -48px;
  margin-bottom: 48px;

  .ln-c-accordion__head {
    background-color: #1976d2 !important;
    .ln-c-accordion__icon {
      display: none;
    }

    .ln-c-accordion__toggle {
      display: flex;
      vertical-align: middle;
    }
  }

  .ln-c-accordion__body {
    padding: 0px !important;
    background-color: #1976d2 !important;
  }
`;

export const AccordionTitle = styled('div')`
  width: 100%;
  color: var(--monochrome-0, #fff);
  font-family: var(--ds-font-stack-display-2-font-family) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 800;
  line-height: 24px;

  display: flex;
  vertical-align: middle;

  .ln-c-icon {
    height: 24px;
    width: 24px;
    margin-right: 12px;

    &:last-child {
      margin-left: 12px;
    }
  }
`;
export const SingleNotificationAccordion = styled('div')`
  display: flex;
  background: #1976d2;
  padding-bottom: 24px;
  padding-left: 37px;

  .action {
    text-align: right;
    width: 200px;
    .ln-c-button {
      font-size: 16px;
    }
  }

  .content {
    width: calc(100% - 200px);
  }

  &:not(:last-child) {
    margin-bottom: 24px;
    border-bottom: 1px solid #fff;
  }
  &:first-child {
    padding-top: 24px;
  }

  .title {
    color: var(--monochrome-0, #fff);
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-size: 16px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px;
  }

  .message {
    color: var(--monochrome-0, #fff);
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
`;
