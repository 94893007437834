import React, { FC } from 'react';
import { AppTitles } from 'src/constants/titles.enum';
import { GridWrapper, GridItem } from '@jsluna/react';
import {
  ALL_APPS_ILLUSTRATION_ICON,
  FAVOURITES_ILLUSTRATION_ICON,
  REGISTER_ILLUSTRATION_ICON,
} from 'src/constants/icons';
import { envConfiguration } from 'src/utils/loadEnvConfig';

export const WorkWithSection: FC = () => {
  const howItWorksSizes = {
    xs: '1/1',
    sm: '1/1',
    md: '1/3',
    lg: '1/3',
    xl: '1/3',
  };

  return (
    <GridWrapper
      className='background--v-light-grey landing-page__how-it-works'
      verticalAlign='middle'
    >
      <GridItem size={'1/1'} className='ln-u-text-align-center'>
        <h2 className='ln-u-text-align-center ln-u-display-4 landing-page__section-title'>
          How {envConfiguration.howItWorksSubject} works
        </h2>
      </GridItem>
      <GridItem size={'1/1'} className='ln-u-text-align-center'>
        <GridWrapper equalHeight>
          <GridItem
            size={howItWorksSizes}
            className='ln-u-text-align-center ln-u-margin-top*4'
          >
            <GridWrapper className='ln-u-text-align-center'>
              <GridItem size={'1/1'} className='ln-u-text-align-center'>
                <img
                  className='landing-page__how-it-works__image'
                  width={240}
                  height={233}
                  src={REGISTER_ILLUSTRATION_ICON}
                  alt='Supplier Support devices'
                />
              </GridItem>
              <GridItem size={'1/1'}>
                <h2 className='ln-u-text-align-center ln-u-display-2 landing-page__how-it-works__sub-title ln-u-margin-top*3'>
                  Register for access
                </h2>
              </GridItem>
              <GridItem size={'1/1'} className='ln-u-text-align-center'>
                <div className='description'>
                  You&#39;ll need to register to use {AppTitles.APP_TITLE}
                </div>
              </GridItem>
            </GridWrapper>
          </GridItem>
          <GridItem
            size={howItWorksSizes}
            className='ln-u-text-align-center ln-u-margin-top*4'
          >
            <GridWrapper className='ln-u-text-align-center'>
              <GridItem size={'1/1'} className='ln-u-text-align-center'>
                <img
                  className='landing-page__how-it-works__image'
                  width={240}
                  height={233}
                  src={ALL_APPS_ILLUSTRATION_ICON}
                  alt='Query App devices'
                />
              </GridItem>
              <GridItem size={'1/1'}>
                <h2 className='ln-u-text-align-center ln-u-display-2 landing-page__how-it-works__sub-title ln-u-margin-top*3'>
                  One place for all
                </h2>
              </GridItem>
              <GridItem size={'1/1'} className='ln-u-text-align-center'>
                <div className='description'>
                  One central and dedicated place for all apps that you use for
                  working together with Sainsbury&#39;s
                </div>
              </GridItem>
            </GridWrapper>
          </GridItem>
          <GridItem
            size={howItWorksSizes}
            className='ln-u-text-align-center ln-u-margin-top*4'
          >
            <GridWrapper className='ln-u-text-align-center'>
              <GridItem size={'1/1'} className='ln-u-text-align-center'>
                <img
                  className='landing-page__how-it-works__image'
                  width={240}
                  height={233}
                  src={FAVOURITES_ILLUSTRATION_ICON}
                  alt='Query App devices'
                />
              </GridItem>
              <GridItem size={'1/1'}>
                <h2 className='ln-u-text-align-center ln-u-display-2 landing-page__how-it-works__sub-title ln-u-margin-top*3'>
                  Add favourites
                </h2>
              </GridItem>
              <GridItem size={'1/1'} className='ln-u-text-align-center'>
                <div className='description'>
                  Keep a list of your favourite apps, helping to make them
                  easily accessible as you sign in
                </div>
              </GridItem>
            </GridWrapper>
          </GridItem>
        </GridWrapper>
      </GridItem>
    </GridWrapper>
  );
};
