import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';
import { phoneRegExp } from 'src/constants/regExValidations';
import * as Yup from 'yup';

export default Yup.object().shape(
  {
    title: Yup.string(),
    firstName: Yup.string().when('firstName', (value, schema) => {
      if (value?.length > 0) {
        return Yup.string()
          .required(InviteProfileMessages.firstName.required)
          .min(2, `${InviteProfileMessages.firstName.min} 2`)
          .max(35, `${InviteProfileMessages.firstName.max} 35`);
      } else {
        return Yup.string().notRequired();
      }
    }),
    lastName: Yup.string().when('lastName', (value, schema) => {
      if (value?.length > 0) {
        return Yup.string()
          .min(2, `${InviteProfileMessages.lastName.min} 2`)
          .max(35, `${InviteProfileMessages.lastName.max} 35`);
      } else {
        return Yup.string().notRequired();
      }
    }),
    preferredName: Yup.string().when('preferredName', (value, schema) => {
      if (value?.length > 0) {
        return Yup.string()
          .min(2, `${InviteProfileMessages.preferredName.min} 2`)
          .max(35, `${InviteProfileMessages.preferredName.max} 35`);
      } else {
        return Yup.string().notRequired();
      }
    }),
    countryCode: Yup.string(),
    contactNumber: Yup.string().when('contactNumber', (value, schema) => {
      if (value?.length > 0) {
        return Yup.string()
          .matches(phoneRegExp, InviteProfileMessages.contactNumber.valid)
          .required(InviteProfileMessages.contactNumber.required);
      } else {
        return Yup.string().notRequired();
      }
    }),
  },
  [
    ['firstName', 'firstName'],
    ['lastName', 'lastName'],
    ['preferredName', 'preferredName'],
    ['contactNumber', 'contactNumber'],
  ],
);
