import { mimeTypes } from 'src/constants/mimeTypes';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { CookieNames } from 'src/enums/cookieNames';
import { envConfiguration } from 'src/utils/loadEnvConfig';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'src/constants/msal';

interface ISheetData {
  products: {
    valid_count: number;
    invalid_count: number;
  };
  supplier_pack: {
    valid_count: number;
    invalid_count: number;
  };
}

const useUploadBrexitFile = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<ISheetData | null>(null);

  const { instance, accounts } = useMsal();

  const mutate = async (file: any) => {
    const token = await instance.acquireTokenSilent({
      ...loginRequest,
      account: accounts[0],
    });

    setLoading(true);
    const formData = new FormData();
    if (!file.type || file.type === '') {
      const cloneFile = new File([file], file.name, {
        ...file,
        type: mimeTypes.XLSM,
      });
      formData.append('file', cloneFile);
    } else {
      formData.append('file', file);
    }
    fetch(`${envConfiguration.twLongEndpoint}api/brexit/datafeed/upload`, {
      method: 'POST',
      headers: {
        'authorization': `Bearer ${token.accessToken}`,
      },
      body: formData,
    })
      .then(res => {
        if (res.status === 200) {
          setSuccess(true);
          setLoading(false);
          return res.json();
        } else {
          setError(res.statusText);
        }
      })
      .then(jsonData => {
        setData(jsonData);
      })
      .catch(err => {
        setError(err);
        setLoading(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const reset = () => {
    setSuccess(false);
    setError(null);
    setLoading(false);
  };

  return { mutate, success, loading, error, reset, data };
};

export default useUploadBrexitFile;
