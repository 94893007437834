import { compile } from 'path-to-regexp';

export const routes = {
  root: '/',
  dashboard: '/dashboard',
  login: '/login',
  permissions: '/permissions',
  applications: '/applications',
  viewProfile: '/view-profile',
  myProfile: '/my-profile',
  createProfile: '/create-profile',
  viewApplication: '/applications/:id',
  createApplication: '/create-application',
  createAccountType: '/create-account-type',
  profiles: '/profiles',
  organisations: '/organisations',
  viewOrganisation: '/view-organisation/:organisationId',
  viewSite: '/view-site/:siteId',
  viewAllPermissionsForApplication: '/view-all-permissions/:applicationId',
  completeInvitation: '/invitation',
  inviteProfile: '/invite',
  requestAccount: '/request-account',
  registerForAccess: '/register-for-access',
  serverError: '/server-error',
  movedPermanently: '/moved-permanently',
  pageMoved: '/page-moved',
  news: '/news',
  newsArticle: '/news/:newsId',
  newsAndArticles: '/news-and-articles',
  supplierJournals: '/news-and-articles/journals',
  plan4Better: '/news-and-articles/plan-for-better',
  agriculture: '/news-and-articles/agriculture',
  supplierJournal: '/news-and-articles/journals/:journalId',
  journalArticle: '/news-and-articles/journals/:journalId/article/:articleId',
  planForBetterWT: '/news-and-articles/plan-for-better/bltc5f10aeea4b5d71b',
  newsArticles: '/news-and-articles/news',
  articleNews: '/news-and-articles/news/article/:articleId',
  planForBetterJournal: '/news-and-articles/plan-for-better/:journalId',
  planForBetterArticle:
    '/news-and-articles/plan-for-better/:journalId/article/:articleId',
  agricultureParentJournal:
    '/news-and-articles/agriculture/blt461d4506855c3389',
  agricultureJournal: '/news-and-articles/agriculture/:journalId',
  agricultureArticle:
    '/news-and-articles/agriculture/:journalId/article/:articleId',
  gscop: '/gscop',
  waysOfWorkingUrl: '/ways-of-working/',
  waysOfWorkingUrlSection: '/ways-of-working/:section',
  tradingTermsAndConditions: '/trading-terms-and-conditions',
  termsAndConditions: '/terms-and-conditions',
  waysOfWorkingUrlSubSection: '/ways-of-working/:section/:subSection',
  brexitUpload: '/brexit-product-upload',
  deleteAccount: '/delete-account',
  tags: '/tags',
  createRole: '/accountType/:accountTypeId/role/create',
  manageAccountType: '/manage-account-type',
  manageRole: 'manage-role/:roleId',
  prospective: 'prospective',
  registrations: 'registrations',
  privacyPolicy: '/privacy-policy',
  supplierSupport: 'https://supplier-support.togetherwith.co.uk',
  serviceDashboard: '/service-dashboard',
  sites: '/sites',
  sitesGroups: '/site-groups',
  feedback: '/feedback',
};

export const viewSiteRoute = compile(routes.viewSite);
export const viewOrganisationRoute = compile(routes.viewOrganisation);
export const createRoleRoute = compile(routes.createRole);
export const manageRoleRoute = compile(routes.manageRole);
