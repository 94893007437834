import { FC, useContext, useEffect, useState } from 'react';
import {
  SearchField,
  GridWrapper,
  GridItem,
  Button,
  Pagination,
  AsyncAutocompleteField,
  MultiAutocompleteField,
  Card,
} from '@jsluna/react';
import { Tick } from '@jsluna/icons';
import { useLocation } from 'react-router';

const TickIcon = Tick as unknown as React.FC;
import { debounce } from 'lodash';
import { DownloadSection, ProfileWrapper } from './profile.styles';
import { gql } from '@apollo/client';

import Container from 'src/components/Container/Container';
import { PageHeader } from 'src/components/PageHeader';
import { NoRecordCard } from 'src/components/NoRecordCard';
import { ProfileCard } from '@sainsburys-tech/supplier-experience-profile-card';
import { IOption } from 'src/interfaces/option.interface';
import { FilterSelect } from 'src/components/filterSelect';
import { ProfileCardLoader } from './components/profileCardLoader';
import { AuthContext } from 'src/providers/AuthProvider';
import { APP_ACCESS } from 'src/enums/permissions.enum';
import {
  ProfilePage_ProfilesQueryVariables,
  useProfilePage_AccountTypesQuery,
  useProfilePage_HierarchyLazyQuery,
  useProfilePage_ProfilesLazyQuery,
  useProfilePage_RolesQuery,
  useProfilePage_TagsQuery,
} from 'src/operations/generated/graphql';

gql`
  fragment ProfilePage_AccountTypes on AccountTypeProjection {
    id
    name
  }
`;

gql`
  fragment ProfilePage_Tags on TagProjection {
    id
    name
  }
`;

gql`
  fragment ProfilePage_Roles on RoleProjection {
    id
    name
  }
`;

gql`
  fragment ProfilePage_Hierarchy on HierarchyProjection {
    id
    name
    level
  }
`;

gql`
  fragment ProfilePage_Profile on ProfileProjection {
    id
    firstName
    lastName
    preferredName
    title
    emailAddress
    countryCode
    contactNumber
    accounts {
      value {
        id
        name
        organisationGroups {
          id
          name
        }
      }
    }
  }
`;

gql`
  query ProfilePage_Profiles(
    $searchTerms: String
    $accountTypeIds: [String!]
    $tagIds: [String!]
    $roleIds: [String!]
    $hierarchyIds: [String!]
    $statuses: [ProfileStatus!]
    $page: Float
    $pageSize: Float
  ) {
    paginatedProfiles(
      searchTerms: $searchTerms
      accountTypeIds: $accountTypeIds
      tagIds: $tagIds
      roleIds: $roleIds
      hierarchyIds: $hierarchyIds
      statuses: $statuses
      page: $page
      pageSize: $pageSize
    ) {
      page
      count
      perPage
      profiles {
        ...ProfilePage_Profile
      }
    }
  }
`;

gql`
  query ProfilePage_Roles {
    roles {
      ...ProfilePage_Roles
    }
  }
`;

gql`
  query ProfilePage_Hierarchy(
    $searchTerms: String
    $page: Float
    $pageSize: Float
  ) {
    paginatedHierarchies(
      searchTerms: $searchTerms
      page: $page
      pageSize: $pageSize
    ) {
      hierarchies {
        ...ProfilePage_Hierarchy
      }
    }
  }
`;

gql`
  query ProfilePage_Tags {
    tags {
      ...ProfilePage_Tags
    }
  }
`;
gql`
  query ProfilePage_AccountTypes {
    accountTypes {
      ...ProfilePage_AccountTypes
    }
  }
`;

enum ProfileStatus {
  REQUESTED = 'REQUESTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

interface DropDownValues {
  id: string;
  [key: string]: any;
}

export const ProfilePage: FC = () => {
  const statuses = [
    { label: 'Approved', value: ProfileStatus.APPROVED },
    { label: 'Rejected', value: ProfileStatus.REJECTED },
    { label: 'Requested', value: ProfileStatus.REQUESTED },
  ];

  const queryParams = new URLSearchParams(location.search);
  const queryParamSearchKey = queryParams.get('searchKey') || '';
  const queryParamSelectedAT = queryParams.getAll('accountTypeIds');
  const queryParamSelectedTag = queryParams.getAll('tagIds');
  const queryParamSelectedRoles = queryParams.getAll('roleIds');
  const queryParamSelectedHierarchies = queryParams.getAll('hierarchyIds');
  const queryParamSelectedStatuses = queryParams.getAll('statuses');
  const queryParamPage = parseInt(queryParams.get('page') || '1', 10);
  const queryHierarchySearchKey = queryParams.get('hierarchySearch') || '';

  const pageSize = 21;
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);
  const [requestedExport, setRequestedExport] = useState(false);
  const [searchKey, setSearchKey] = useState(queryParamSearchKey);
  const [selectedAT, setSelectedAT] = useState<string[]>(queryParamSelectedAT);
  const [selectedTag, setSelectedTag] = useState<string[]>(
    queryParamSelectedTag,
  );
  const [selectedRoles, setSelectedRoles] = useState<string[]>(
    queryParamSelectedRoles,
  );
  const [selectedHierarchies, setSelectedHierarchies] = useState<string[]>(
    queryParamSelectedHierarchies,
  );
  const [selectedStatuses, setSelectedStatuses] = useState<string[]>(
    queryParamSelectedStatuses,
  );
  const [firstLoad, setFirstLoad] = useState(true);
  const [currentPage, setCurrentPage] = useState(queryParamPage);
  const [filters, setFilters] = useState<ProfilePage_ProfilesQueryVariables>({
    searchTerms: searchKey,
    page: 1,
    pageSize: pageSize,
  });

  const [hierarchySearchKey, setHierarchySearchKey] = useState(
    queryHierarchySearchKey,
  );

  const [seed, setSeed] = useState(1);

  const { data: { accountTypes = [] } = {} } =
    useProfilePage_AccountTypesQuery();

  const { data: { tags = [] } = {} } = useProfilePage_TagsQuery();

  const { data: { roles = [] } = {} } = useProfilePage_RolesQuery();

  const [
    getHierarchies,
    { data: { paginatedHierarchies: { hierarchies = [] } = {} } = {} },
  ] = useProfilePage_HierarchyLazyQuery({
    variables: {
      searchTerms: '',
      page: currentPage,
      pageSize: 25,
    },
  });

  const [
    searchProfiles,
    {
      data: {
        paginatedProfiles: { page = 1, profiles = [], count = 1 } = {},
      } = {},
      error,
      loading,
    },
  ] = useProfilePage_ProfilesLazyQuery({
    variables: { ...filters },
  });

  const loadOptions = async (value: string) => {
    setHierarchySearchKey(value);
    const result = await getHierarchies({
      variables: {
        searchTerms: value,
        page: currentPage,
        pageSize: 25,
      },
    });

    const url = new URL(window.location.href);

    const searchParams = new URLSearchParams(url.search);
    searchParams.append('hierarchySearch', value);

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);

    return result.data?.paginatedHierarchies?.hierarchies.map(item => ({
      label: `${item.level === null ? 'N/A' : item.level} - ${item.name}`,
      value: item.id.toString(),
    }));
  };

  useEffect(() => {
    const searchFilters: any = {
      searchTerms: searchKey,
      pageSize: pageSize,
      page: 1,
    };

    if (selectedAT.length) {
      searchFilters.accountTypeIds = selectedAT;
    }

    if (selectedTag.length) {
      searchFilters.tagIds = selectedTag;
    }

    if (selectedRoles.length) {
      searchFilters.roleIds = selectedRoles;
    }

    if (selectedHierarchies.length) {
      searchFilters.hierarchyIds = selectedHierarchies;
    }

    if (selectedStatuses.length) {
      searchFilters.statuses = selectedStatuses;
    }

    setCurrentPage(1);

    setFilters(searchFilters);
    updateURLParams(searchFilters);
    searchProfiles();
  }, [
    searchKey,
    selectedAT,
    selectedTag,
    selectedRoles,
    selectedHierarchies,
    selectedStatuses,
  ]);

  useEffect(() => {
    const searchFilters: any = {
      searchTerms: searchKey,
      pageSize: pageSize,
      page: currentPage,
    };

    if (selectedAT.length) {
      searchFilters.accountTypeIds = selectedAT;
    }

    if (selectedTag.length) {
      searchFilters.tagIds = selectedTag;
    }

    if (selectedRoles.length) {
      searchFilters.roleIds = selectedRoles;
    }

    if (selectedHierarchies.length) {
      searchFilters.hierarchyIds = selectedHierarchies;
    }

    if (selectedStatuses.length) {
      searchFilters.statuses = selectedStatuses;
    }
    if (!firstLoad || currentPage !== 1) {
      setFilters(searchFilters);
      searchProfiles();
      updateURLParams(searchFilters);
    }
  }, [currentPage]);

  const debouncedSetSearchKey = debounce(value => {
    setSearchKey(value);
  }, 500);

  const clearFilter = () => {
    setSearchKey('');
    setSelectedAT([]);
    setSelectedTag([]);
    setSelectedRoles([]);
    setSelectedHierarchies([]);
    setSelectedStatuses([]);
    setCurrentPage(1);
    setSeed(Math.random());
  };

  const downloadFile = () => {
    setRequestedExport(false);
    const params = [];
    searchKey && params.push('searchTerms=' + searchKey);
    selectedAT.length && params.push('accountTypeIds=' + selectedAT.join(','));
    selectedTag.length && params.push('tagIds=' + selectedTag.join(','));
    selectedRoles.length && params.push('roleIds=' + selectedRoles.join(','));
    selectedHierarchies.length &&
      params.push('hierarchyIds=' + selectedHierarchies.join(','));
    selectedStatuses.length &&
      params.push('statuses=' + selectedStatuses.join(','));
    const reportUrl = `profile-projection/reports/rainbowExtract${
      params.length ? '?' + params.join('&') : ''
    }`;
    fetch(reportUrl, {
      method: 'GET',
      mode: 'cors',
    })
      .then(res => {
        if (!res.ok && res.status !== 200) {
          throw new Error(`${res.status}`);
        }
      })
      .catch(err => console.error('Error', err))
      .finally(() => {
        setRequestedExport(true);
      });
  };

  const updateURLParams = (
    searchFilters: ProfilePage_ProfilesQueryVariables,
  ) => {
    const searchParams = new URLSearchParams();
    if (searchFilters.searchTerms)
      searchParams.set('searchKey', searchFilters.searchTerms);

    if (Array.isArray(searchFilters.accountTypeIds))
      searchFilters.accountTypeIds.forEach(id =>
        searchParams.append('accountTypeIds', id),
      );
    if (Array.isArray(searchFilters.tagIds))
      searchFilters.tagIds.forEach(id => searchParams.append('tagIds', id));
    if (Array.isArray(searchFilters.roleIds))
      searchFilters.roleIds.forEach(id => searchParams.append('roleIds', id));
    if (Array.isArray(searchFilters.hierarchyIds))
      searchFilters.hierarchyIds.forEach(id =>
        searchParams.append('hierarchyIds', id),
      );
    if (Array.isArray(searchFilters.statuses))
      searchFilters.statuses.forEach(status =>
        searchParams.append('statuses', status),
      );
    searchFilters.page &&
      searchFilters.page > 1 &&
      searchParams.set('page', searchFilters.page.toString());

    const newUrl = `${location.pathname}?${searchParams.toString()}`;
    window.history.replaceState({}, '', newUrl);
  };

  const getDefaultOptions = (
    arrayOfObjects: DropDownValues[],
    selected: string[],
  ) => arrayOfObjects.filter(obj => selected.includes(obj.id));

  return (
    <>
      <PageHeader
        heading={`All profiles`}
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'All Profiles',
            link: '',
          },
        ]}
      />
      <Container>
        <ProfileWrapper>
          {hasPermission(APP_ACCESS.DOWNLOAD_RAINBOW_REPORT) && (
            <Card className='ln-u-margin-bottom*3'>
              <DownloadSection>
                <div className=''>
                  <div className='display-2'>Request rainbow report</div>
                  <div className='body-1'>
                    Click the button to be sent an email of requested suppliers
                    report in excel format.
                  </div>
                </div>
                <Button
                  circle={false}
                  disabled={requestedExport}
                  hard={false}
                  element='button'
                  variant='filled'
                  onClick={downloadFile}
                >
                  {requestedExport && <TickIcon />}{' '}
                  {!requestedExport
                    ? 'Request rainbow export'
                    : 'Rainbow export requested'}
                </Button>
              </DownloadSection>
            </Card>
          )}
          <GridWrapper verticalAlign='middle'>
            <GridItem size={{ md: '1/5', xs: '1/1' }}>
              <SearchField
                name='search-profile'
                label='Search profiles'
                onChange={(e: any) => {
                  debouncedSetSearchKey(e.target.value);
                }}
                key={seed}
                hasButton={false}
              />
            </GridItem>
          </GridWrapper>
          <GridWrapper verticalAlign='middle'>
            <GridItem size={{ md: '4/5', xs: '1/1' }}>
              <div className='ln-c-label'>Filters</div>

              <div key={seed} className='filter-section'>
                <div className='hierarchy-filter'>
                  <AsyncAutocompleteField
                    name='hierarchy-autocomplete-field'
                    placeholder='Select hierarchy'
                    label='Select hierarchy'
                    loadOptions={loadOptions}
                    hideLabel
                    onSelect={(selected: IOption[]) => {
                      setSelectedHierarchies(
                        selected.map(hierarchy => hierarchy.value),
                      );
                    }}
                  >
                    <MultiAutocompleteField
                      onSelect={(selected: IOption[]) => {
                        setSelectedHierarchies(
                          selected.map(hierarchy => hierarchy.value),
                        );
                      }}
                    />
                  </AsyncAutocompleteField>
                </div>

                {tags?.length > 0 && (
                  <FilterSelect
                    placeholder='Tag'
                    filterOptions={[
                      ...tags.map(tag => ({
                        label: tag.name,
                        value: tag.id,
                      })),
                    ]}
                    onChangeFn={(selected: IOption[]) => {
                      setSelectedTag(selected.map(tag => tag.value));
                    }}
                    defaultSelectedOptions={getDefaultOptions(
                      tags,
                      selectedTag,
                    ).map(tag => ({
                      label: tag.name,
                      value: tag.id,
                    }))}
                  />
                )}
                {accountTypes?.length > 0 && (
                  <FilterSelect
                    placeholder='Account type'
                    filterOptions={[
                      ...accountTypes.map(opt => ({
                        label: opt.name,
                        value: opt.id,
                      })),
                    ]}
                    onChangeFn={(selected: IOption[]) => {
                      setSelectedAT(
                        selected.map(accountType => accountType.value),
                      );
                    }}
                    defaultSelectedOptions={getDefaultOptions(
                      accountTypes,
                      selectedAT,
                    ).map(accountType => ({
                      label: accountType.name,
                      value: accountType.id,
                    }))}
                  />
                )}
                {roles && roles?.length > 0 && (
                  <FilterSelect
                    placeholder='Role'
                    filterOptions={[
                      ...roles.map(role => ({
                        label: role.name,
                        value: role.id,
                      })),
                    ]}
                    onChangeFn={(selected: IOption[]) => {
                      setSelectedRoles(selected.map(role => role.value));
                    }}
                    defaultSelectedOptions={getDefaultOptions(
                      roles,
                      selectedRoles,
                    ).map(role => ({
                      label: role.name,
                      value: role.id,
                    }))}
                  />
                )}
                {statuses && statuses?.length > 0 && (
                  <FilterSelect
                    placeholder='Status'
                    filterOptions={[
                      ...statuses.map(status => ({
                        label: status.label,
                        value: status.value,
                      })),
                    ]}
                    onChangeFn={(selected: IOption[]) => {
                      setSelectedStatuses(selected.map(status => status.value));
                    }}
                    defaultSelectedOptions={statuses
                      .filter(obj => selectedStatuses.includes(obj.value))
                      .map(status => ({
                        label: status.label,
                        value: status.value,
                      }))}
                  />
                )}
              </div>
            </GridItem>

            <GridItem
              size={{ md: '1/5', xs: '1/1' }}
              className='ln-u-text-align-right'
            >
              <Button
                variant='text'
                circle={false}
                disabled={false}
                hard={false}
                element='button'
                color='dark'
                onClick={clearFilter}
              >
                Clear filter
              </Button>
            </GridItem>
          </GridWrapper>

          {loading ? (
            <>
              <ProfileCardLoader />
              <ProfileCardLoader />
              <ProfileCardLoader />
            </>
          ) : profiles?.length > 0 ? (
            <>
              <GridWrapper>
                {profiles?.map(profile => (
                  <GridItem
                    key={profile.id}
                    size={{ md: '1/3' }}
                    className='ln-u-margin-bottom*3'
                  >
                    <ProfileCard profile={profile} />
                  </GridItem>
                ))}
              </GridWrapper>

              <div className='ln-u-text-align-center ln-u-margin-top*3'>
                <Pagination
                  showFirstAndLast
                  showPages
                  current={currentPage}
                  total={Math.ceil(count / pageSize)}
                  onChange={(page: number, e: any) => {
                    e.preventDefault();
                    setFirstLoad(false);
                    setCurrentPage(page);
                  }}
                />
              </div>
            </>
          ) : (
            <NoRecordCard recordType='Profiles' searchTerm={searchKey} />
          )}
        </ProfileWrapper>
      </Container>
    </>
  );
};
