import React, { FC, useState } from 'react';
import Container from 'src/components/Container/Container';
import { Card } from '@jsluna/react';
import { UpdateProfileForm } from './components/updateProfileForm/updateProfileForm';

import { IRequestProfile } from '@interfaces/requestProfile.interface';
import { NoRecordCard } from 'src/components/NoRecordCard';
import { PageHeader } from 'src/components/PageHeader';
import { CompleteInvitationWrapper } from './completeInvitation.styles';
import { InfoPanel } from 'src/shared/components/infoPanel';
import { InfoPanelTypes } from 'src/enums/infoPanelTypes.enum';
import { FeedbackCard } from './components/feedbackCard';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { NotificationType } from 'src/enums/notificationTypes.enum';

import { routes } from 'src/constants/routes';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'src/constants/msal';

import {
  CompleteProfileInvitationMutation,
  useCompleteProfileInvitationMutation,
  useLoggedInProfileQuery,
} from 'src/operations/generated/graphql';

export const CompleteInvitation: FC = () => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [completeProfileInvitationMutation, { error, loading }] =
    useCompleteProfileInvitationMutation();
  const { instance, accounts } = useMsal();

  const [data, setData] = useState<CompleteProfileInvitationMutation | null>(
    null,
  );

  const {
    data: { myProfile } = {},
    error: profileError,
    loading: profileLoading,
  } = useLoggedInProfileQuery();

  const handleProfileCreate = async (values: IRequestProfile) => {
    if (!myProfile?.id) return;

    try {
      const { data } = await completeProfileInvitationMutation({
        variables: {
          id: myProfile?.id,
          profile: {
            firstName: values.firstName,
            lastName: values.lastName,
            preferredName: values.preferredName ? values.preferredName : null,
            countryCode: values.countryCode,
            contactNumber: values.contactNumber,
            title: values.title,
            TAndCAgreed: values.TAndCAgreed,
          },
        },
      });

      setData(data || null);
    } catch (error) {}
    setShowFeedback(true);
  };

  const reIssueCookies = async () => {
    try {
      const token = await instance.acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      });

      fetch('/issueCookies', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jwt: token.accessToken,
        }),
      }).then((data: any) => {
        window.location.href = routes.login;
      });
    } catch (error) {
      logOut();
    }
  };

  const logOut = () => {
    window.location.href = '/logout';
  };

  return (
    <CompleteInvitationWrapper>
      {myProfile?.TAndCAgreed === true ? (
        <FeedbackCard
          title='You’ve already completed the invitation. You’re all good to go.'
          message='There are no active invitation present at the moment.'
          buttonText='Get started'
          goToLink={''}
          buttonClickHandler={reIssueCookies}
        />
      ) : myProfile?.TAndCAgreed === false ? (
        <>
          <PageHeader
            heading={`Hi ${myProfile.firstName}`}
            subHeading={`${
              showFeedback
                ? 'You’re invited to join Together With'
                : 'Your Together With account has been approved.'
            }`}
          />
          {showFeedback ? (
            <div className='feedback-zone'>
              <NotificationBanner
                message={
                  data?.completeProfileInvitation?.id
                    ? 'You’re all setup'
                    : 'Something went wrong'
                }
                messageType={
                  data?.completeProfileInvitation?.id
                    ? NotificationType.SUCCESS
                    : NotificationType.FAILURE
                }
                loading={loading}
              />
              <FeedbackCard
                title={`We’ve set you up in Together With. You’re all good to go.`}
                message='Simply use the email address associated with the email we just sent you and the password you normally use to access that email.'
                buttonText='Get started'
                goToLink={''}
                subHeading='How to log in.'
                buttonClickHandler={reIssueCookies}
              />
            </div>
          ) : (
            <Container>
              {myProfile?.TAndCAgreed === false && (
                <>
                  <h3>
                    Please check your details are correct so we can get you
                    logged in
                  </h3>
                  <p>
                    This will give you access to our supplier portal and all the
                    apps you need to manage your products, as well as T&Cs and
                    the supplier handbook.
                  </p>
                </>
              )}

              <Card>
                <h3>Personal details</h3>
                <InfoPanel
                  infoType={InfoPanelTypes.INFORMATION}
                  infoText={
                    'Please check and edit any details that are incorrect'
                  }
                />
                <div className='form-wrapper'>
                  <UpdateProfileForm
                    onSubmit={handleProfileCreate}
                    loading={loading}
                    initialValue={myProfile}
                  />
                </div>
              </Card>
            </Container>
          )}
        </>
      ) : (
        <NoRecordCard message='The invitation id provided is not valid or broken' />
      )}
    </CompleteInvitationWrapper>
  );
};
