import React, { FC, useContext } from 'react';

import { Outlet } from 'react-router-dom';
import { AuthContext, IAuthType } from '../../providers/AuthProvider';

interface IPermissionBasedRoute {
  allowedPermissions?: string[];
}

export const PermissionBasedRoute: FC<IPermissionBasedRoute> = ({
  allowedPermissions,
}) => {
  const authContext = useContext(AuthContext);
  const { authState } = authContext as {
    authState: IAuthType;
  };

  const { permissions } = authState;

  const canAccess = allowedPermissions
    ? allowedPermissions.some(allowedPermission =>
        permissions.includes(allowedPermission),
      )
    : true;

  return <>{canAccess ? <Outlet /> : <div>No access to this page</div>}</>;
};
