import React, { FC, useState } from 'react';
import { IconButton, Button, GridWrapper, GridItem } from '@jsluna/react';
import { Section } from '../section.styles';
import { LinkApplicationScreen } from '../../../screens/linkApplications';
import { Plus, Delete } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError, gql } from '@apollo/client';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';
import { LinkAppsSection_LinkAppInfoFragment } from 'src/operations/generated/graphql';
import { useNavigate } from 'react-router';

const DeleteIcon = Delete as unknown as React.FC;
const PlusIcon = Plus as unknown as React.FC;

interface ILinkAppsSection {
  loading: boolean;
  error: ApolloError | undefined;
  linkedApps: LinkAppsSection_LinkAppInfoFragment[];
  target: { id: string; displayName: string };
  linkedAppOptions: LinkAppsSection_LinkAppInfoFragment[];
  handleConfirmation: (
    LinkApps: LinkAppsSection_LinkAppInfoFragment[],
  ) => Promise<void>;
  handleRemoval: (appId: string) => Promise<void>;
  disabled?: boolean;
}

enum PanelAction {
  LINK_APP_SELECTOR = 'LINK_APP_SELECTOR',
}

gql`
  fragment LinkAppsSection_linkAppInfo on ApplicationProjection {
    id
    name
    acronym
    shortDescription
    longDescription
    slug
    proxyLocation
    type
  }
`;

export const LinkAppsSection: FC<ILinkAppsSection> = ({
  linkedApps,
  target,
  linkedAppOptions,
  handleConfirmation,
  handleRemoval,
  loading,
  error,
  disabled = true,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const navigate = useNavigate();
  const handlePanelClose = () => {
    setShowSidePanel(null);
  };
  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.LINK_APP_SELECTOR:
        return (
          <LinkApplicationScreen
            target={target}
            loading={loading}
            error={error}
            handleClose={handlePanelClose}
            existingSelectedAppIds={linkedApps.map(app => app.id)}
            fullApplicationOptions={linkedAppOptions}
            handleConfirmation={handleConfirmation}
          />
        );
    }
  };

  return (
    <Section>
      {linkedApps.length > 0 ? (
        <>
          <h4 className='display-1 ln-u-margin-bottom*3'>
            Applications linked to account type
          </h4>

          <div className='ln-u-padding-top'>
            <GridWrapper>
              {linkedApps.map(linkedApp => (
                <GridItem
                  size={{ md: '1/3' }}
                  key={linkedApp.id}
                  className='ln-u-margin-bottom*2'
                >
                  <AppTile
                    application={linkedApp}
                    ChevronSection={() => (
                      <Button
                        variant='text'
                        onClick={() => {
                          handleRemoval(linkedApp.id);
                        }}
                        color='dark'
                        disabled={disabled}
                      >
                        <DeleteIcon />
                      </Button>
                    )}
                    ActionButtonSection={() => <></>}
                    navigateToDetailsPage={() => {
                      navigate(`/applications/${linkedApp.id}`);
                    }}
                  />
                </GridItem>
              ))}
            </GridWrapper>
          </div>
          <div className='table-actions'>
            <IconButton
              variant='text'
              circle={false}
              disabled={disabled}
              hard={false}
              element='button'
              label='Link another app'
              color='dark'
              onClick={() => setShowSidePanel(PanelAction.LINK_APP_SELECTOR)}
            >
              <PlusIcon />
            </IconButton>
          </div>
        </>
      ) : (
        <>
          <h4>Link applications </h4>
          <div className='body-1 ln-u-margin-bottom*3'>
            There are no apps currently associated with this account type.
          </div>
          <Button
            element='button'
            variant='filled'
            onClick={() => setShowSidePanel(PanelAction.LINK_APP_SELECTOR)}
            disabled={disabled}
          >
            Link apps
          </Button>
        </>
      )}

      <Drawer
        open={showSidePanel !== null}
        onClose={handlePanelClose}
        anchor='right'
        className='add-application'
      >
        {renderPanel()}
      </Drawer>
    </Section>
  );
};
