import { InviteProfileMessages } from 'src/constants/inviteProfileMessages';
import { phoneRegExp } from 'src/constants/regExValidations';
import * as Yup from 'yup';

export default Yup.object().shape(
  {
    title: Yup.string().required(InviteProfileMessages.title.required),
    firstName: Yup.string()
      .required(InviteProfileMessages.firstName.required)
      .min(1, `${InviteProfileMessages.firstName.min} 1`)
      .max(35, `${InviteProfileMessages.firstName.max} 35`),
    lastName: Yup.string()
      .required(InviteProfileMessages.lastName.required)
      .min(1, `${InviteProfileMessages.lastName.min} 1`)
      .max(35, `${InviteProfileMessages.lastName.max} 35`),
    emailAddress: Yup.string().email(InviteProfileMessages.emailAddress.valid),
    countryCode: Yup.string().required(
      InviteProfileMessages.countryCode.required,
    ),
    contactNumber: Yup.string()
      .matches(phoneRegExp, InviteProfileMessages.contactNumber.valid)
      .required(InviteProfileMessages.contactNumber.required),
  },
  [['contactNumber', 'contactNumber']],
);
