import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_JOURNAL_ARTICLE_PAGE_QUERY = gql`
  query ($id: String!, $journalId: String!) {
    article(uid: $id)
      @rest(
        type: "article"
        path: "content-projection/contentStack/journal/:uid"
      ) {
      body
      child_type
      creation_date
      image
      image_caption
      intro
      summary
      tags
      thumbnail_image
      title
      uid
    }
    journal(uid: $journalId)
      @rest(
        type: "journal"
        path: "content-projection/contentStack/supplierJournal/:uid"
      ) {
      child_type
      children
      creation_date
      summary
      tags
      teaser_image
      title
      type
      uid
    }
    supplierJournals(id: $journalId)
      @rest(
        type: "supplierJournals"
        path: "content-projection/contentStack/supplierArticleGroupings?type=Journal"
      ) {
      child_type
      children
      creation_date
      summary
      tags
      teaser_image
      title
      type
      uid
    }
  }
`;

export const useGetJournalArticlePage = (id: string, journalId: string) => {
  return useQuery(GET_JOURNAL_ARTICLE_PAGE_QUERY, {
    variables: { id, journalId },
    context: { clientName: EndPoint.PPS_REST_ENDPOINT },
  });
};
