import React, { FC, useEffect, useState } from 'react';
import { AddEmailDomainContainer } from './addEmailDomains.styles';
import { Button, ProgressSpinner, TextInputField } from '@jsluna/react';
import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ApolloError } from '@apollo/client';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

interface IAddDomainScreen {
  handleClose: () => void;
  addedDomains: string[];
  removedDomains: string[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (domain: string) => Promise<void>;
  target: { id: string; displayName: string };
  isApprovalList: boolean;
}

export const AddDomainScreen: FC<IAddDomainScreen> = ({
  handleClose,
  addedDomains,
  removedDomains,
  loading,
  error,
  handleConfirmation,
  target,
  isApprovalList,
}) => {
  const inApprovedList = 'This is in the approved list';
  const inRemovedList = 'This is in the rejection list!';
  const listName = isApprovalList ? 'approval' : 'rejection';

  const containedInAddedDomainListMessage = isApprovalList
    ? inApprovedList
    : inRemovedList;
  const containedInRemovedDomainListMessage = isApprovalList
    ? inRemovedList
    : inApprovedList;

  const addEmailDomainSchema = Yup.object().shape({
    domain: Yup.string()
      .test(
        'containedInAddedDomainList',
        containedInAddedDomainListMessage,
        (value = '') => {
          const domain = value.charAt(0) === '@' ? value.slice(1) : value;
          return !addedDomains.includes(domain);
        },
      )
      .test(
        'containedInRemovedDomainList',
        containedInRemovedDomainListMessage,
        (value = '') => {
          const domain = value.charAt(0) === '@' ? value.slice(1) : value;
          return !removedDomains.includes(domain);
        },
      )
      .required('The domain is required'),
  });

  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    reset();
    setFeedBackMessage(null);
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(addEmailDomainSchema),
    mode: 'onChange',
  });

  const handleSubmission = async (data: any) => {
    const domain = data.domain;
    const formattedDomain = domain.charAt(0) === '@' ? domain.slice(1) : domain;
    await handleConfirmation(formattedDomain);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <AddEmailDomainContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${
                      target.id.includes('AT_')
                        ? `Email domains successfully added to the automatic ${listName} list`
                        : 'Email domain successfully added'
                    }`
                  : 'Unable to add new email domain. Please try again.'
              }
              saveButtonText={'Close'}
              onCloseHandler={handleClose}
              resetButtonText={`${
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Add a new domain'
                  : 'Try again'
              }`}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${
                      target.id.includes('AT_')
                        ? 'Account type updated'
                        : 'Email domain list updated'
                    }`
                  : 'Failed to add email domain'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              {`Add an email domain to the automatic ${listName} list.`}
            </div>

            <form id='add-email-domain' onSubmit={handleSubmit(() => {})}>
              <Controller
                name='domain'
                control={control}
                render={({ field }) => (
                  <TextInputField
                    {...field}
                    onChange={field.onChange}
                    error={errors?.domain?.message}
                    label='Enter email domain'
                    info='e.g. companyname.co.uk'
                  />
                )}
              />
            </form>
          </>
        )}
      </AddEmailDomainContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            form='add-email-domain'
            onClick={handleSubmit(handleSubmission)}
            variant='filled'
            disabled={loading || !isValid}
          >
            {loading && <ProgressSpinner />}
            Add domain
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
