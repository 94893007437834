import { ThemeColors, InfoPanel } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const InfoPanelWrapper = styled((props: any) => <div {...props} />)`
  padding: 14px;
  display: flex;
  background-color: ${props =>
    InfoPanel[props.StatusType as string].panelColor};
  align-items: center;

  .info-text {
    font-family: 'Arial';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${props => InfoPanel[props.StatusType as string].textColor};
  }

  svg {
    color: ${props => InfoPanel[props.StatusType as string].textColor};
    margin-right: 14px;
  }
`;
