import React, { FC } from 'react';
import { PermissionRowContainer } from './permissionRow.styles';
import { Button } from '@jsluna/react';
import { Delete } from '@jsluna/icons';
import { gql } from '@apollo/client';
import { PermissionDeleteRow_PermissionInfoFragment } from 'src/operations/generated/graphql';

const DeleteIcon = Delete as unknown as React.FC;

interface IPemissionDeleteRow extends React.ComponentPropsWithoutRef<'div'> {
  permission: PermissionDeleteRow_PermissionInfoFragment;
  deleteHandler: (
    permission: PermissionDeleteRow_PermissionInfoFragment,
  ) => void;
  customClass?: string;
  disabled: boolean;
}

gql`
  fragment PermissionDeleteRow_PermissionInfo on PermissionProjection {
    id
    name
  }
`;

export const PermissionDeleteRow: FC<IPemissionDeleteRow> = ({
  permission,
  deleteHandler,
  customClass,
  disabled,
  ...props
}) => {
  const { id, name } = permission;
  return (
    <PermissionRowContainer
      key={props.key}
      className={customClass ? customClass : ''}
    >
      <div className='permission-row'>
        <div className='permission-name'>
          {name} ({id})
        </div>
        <div className='deleteBox'>
          <Button
            variant='text'
            onClick={() => deleteHandler(permission)}
            color='dark'
            disabled={disabled}
          >
            <DeleteIcon />
          </Button>
        </div>
      </div>
    </PermissionRowContainer>
  );
};
