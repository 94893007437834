import React, { FC, useState } from 'react';
import { Button, ProgressSpinner } from '@jsluna/react';
import { Section } from 'src/components/Sections/section.styles';
import { DownloadSection } from './permissionExtractSection.styles';

interface IPermissionExtractSection {
  applicationID: string;
}

export const PermissionExtractSection: FC<IPermissionExtractSection> = ({
  applicationID,
}) => {
  const [loading, setLoading] = useState(false);

  const downloadFile = () => {
    setLoading(true);
    fetch(
      `/application-projection/application/permissionExtract?appId=${applicationID}`,
      {
        method: 'GET',
        mode: 'cors',
      },
    )
      .then(res => res.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `Permissions-${applicationID}.csv`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Section>
      <h4>Permission extract</h4>
      <div className='body-1 ln-u-margin-bottom*3'>
        Click the below button to download the extract of permissions associated
        with this application
      </div>
      <DownloadSection>
        <Button
          element='button'
          variant='filled'
          onClick={() => {
            downloadFile();
          }}
          disabled={loading}
        >
          {loading && <ProgressSpinner />}
          Download permission extract
        </Button>
      </DownloadSection>
    </Section>
  );
};
