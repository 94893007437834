import styled from 'styled-components';

export const DetailRow = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  .label {
    width: 250px;
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 24px;
    color: #404040;
  }

  .action {
    width: 50px;
  }

  .value {
    flex: 1;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #545454;
    .ln-c-form-group {
      margin-bottom: 0;
    }
  }

  &.email-row {
    width: 100%;
  }
`;
