import React, { FC, useContext, useState } from 'react';
import { Card, Button } from '@jsluna/react';

import Container from 'src/components/Container/Container';
import { CreateRoleContainer, Section } from './createRole.styles';

import { CreateRoleForm } from './forms/CreateRoleForm';

import { Drawer } from '@mui/material';
import { IApplication } from 'src/interfaces/application.interface';
import { ITag } from 'src/interfaces/tag.interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import roleSchema from './forms/schemas/createRole.schema';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { routes } from 'src/constants/routes';
import { PageHeader } from 'src/components/PageHeader';
import { TagsSection } from 'src/components/Sections/TagsSection';
// import { GET_ALL_TAGS } from 'src/operations/queries/getAllTags';
import { useQuery } from '@apollo/client';
import { LinkAppsWithPermissionsSection } from '../../components/Sections/LinkAppWithPermissionSection';
// import { GET_APPLICATIONS_QUERY } from 'src/operations/queries/getApplications';
import { SelectorType } from 'src/screens/permissionSelector';
import { useParams } from 'react-router';
import { AuthContext } from '../../providers/AuthProvider';

import {
  LinkAppsSection_LinkAppInfoFragment,
  RoleCreation,
  TagSelection_TagsFragment,
  useAccountApplicationGroupsLazyQuery,
  useCreateAccType_GetApplicationsQuery,
  useCreateRoleMutation,
  useTagsQuery,
} from 'src/operations/generated/graphql';

export enum PanelAction {}

export interface IPermissionIdsByAppId {
  [key: string]: string[];
}

export interface IRoleTempData {
  linkedApps: IApplication[];
  tags: ITag[];
}

export const CreateRole: FC = () => {
  // @ts-ignore
  const { isAccountTypeAdminOf } = useContext(AuthContext);

  const [createRoleMutation, { error, loading }] = useCreateRoleMutation();
  const { accountTypeId } = useParams();
  const [feedback, setFeedback] = useState<NotificationType | null>(null);
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const [linkedApps, setLinkedApps] = useState<
    LinkAppsSection_LinkAppInfoFragment[]
  >([]);
  const [tags, setTags] = useState<TagSelection_TagsFragment[]>([]);
  const [permissions, setPermissions] = useState<IPermissionIdsByAppId>({});
  const [permissionGroups, setPermissionGroups] =
    useState<IPermissionIdsByAppId>({});

  const { data: { tags: tagOptions = [] } = {} } = useTagsQuery();

  const { data: { applications: applicationOptions = null } = {} } =
    useCreateAccType_GetApplicationsQuery();

  const handleTagConfirmation = async (
    tagsSelected: TagSelection_TagsFragment[],
  ) => {
    setTags([...tags, ...tagsSelected]);
  };

  const handleTagRemoval = async (tagId: string) => {
    const filteredTags = tags.filter(tag => tag.id !== tagId);
    setTags(filteredTags);
  };

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      default:
        return <div></div>;
    }
  };

  const formObj = useForm({
    resolver: yupResolver(roleSchema),
  });

  const { handleSubmit, reset } = formObj;

  const submitHandler = async (data: any) => {
    const addedTags = tags.map(tag => tag.id);
    const addedApps = linkedApps.map(
      (app: LinkAppsSection_LinkAppInfoFragment) => app.id,
    );

    const addedPermission: string[] = Object.keys(permissions).length
      ? Object.keys(permissions).reduce((acc: string[], obj: string) => {
          return [...acc, ...permissions[obj]];
        }, [])
      : [];
    const addedPermissionGroups: string[] = Object.keys(permissionGroups).length
      ? Object.keys(permissionGroups).reduce((acc: string[], obj: string) => {
          return [...acc, ...permissionGroups[obj]];
        }, [])
      : [];

    const payload = {
      name: data.name,
      description: data.description,
      hiddenForRequests: data.hiddenForRequests === 'yes' ? true : false,
      accountTypeId: accountTypeId,
      tagIds: addedTags,
      applicationIds: addedApps,
      permissionIds: addedPermission,
      permissionGroupIds: addedPermissionGroups,
    } as RoleCreation;

    try {
      const createdRole = await createRoleMutation({
        variables: {
          role: payload,
        },
      });
      if (createdRole?.data?.createRole?.id) {
        setFeedback(NotificationType.SUCCESS);
      } else {
        setFeedback(NotificationType.FAILURE);
      }
    } catch (error) {
      setFeedback(NotificationType.FAILURE);
    }
  };

  const handleLinkAppsConfirmation = async (
    appsSelected: LinkAppsSection_LinkAppInfoFragment[],
  ) => {
    setLinkedApps([...linkedApps, ...appsSelected]);
  };

  const handleAppRemoval = (appID: string) => {
    const filteredApps = linkedApps.filter(app => app.id !== appID);
    setLinkedApps(filteredApps);
  };

  const handlePermissionOrPermissionGroupUpdate = (
    selected: IPermissionIdsByAppId,
    type: SelectorType,
  ) => {
    if (type === SelectorType.PERMISSION) {
      setPermissions(selected);
    } else {
      setPermissionGroups(selected);
    }
  };

  return (
    <>
      <PageHeader
        heading='Create a new role'
        subHeading='Create a new user role to associate with an account type.'
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'Create role',
            link: '/create-role',
          },
        ]}
      />
      <Container>
        {isAccountTypeAdminOf(accountTypeId) ? (
          <CreateRoleContainer>
            {feedback ? (
              <Card>
                {feedback === NotificationType.SUCCESS ? (
                  <>
                    <h5>New role has been created</h5>
                  </>
                ) : (
                  <>
                    <h5>Something went wrong</h5>
                  </>
                )}

                <div className='ln-u-margin-ends*4'>
                  <Button
                    element='a'
                    variant='filled'
                    className='ln-u-margin-right*3'
                    href={routes.root}
                  >
                    Go to homepage
                  </Button>
                  <Button
                    element='button'
                    variant='outlined'
                    onClick={() => {
                      reset();
                      setFeedback(null);
                      setLinkedApps([]);
                      setTags([]);
                      setPermissions({});
                      setPermissionGroups({});
                    }}
                  >
                    {feedback === NotificationType.SUCCESS
                      ? 'Add another role'
                      : 'Try again'}
                  </Button>
                </div>
              </Card>
            ) : (
              <form onSubmit={handleSubmit(submitHandler)}>
                <h3 className='ln-u-margin-bottom*4'>Role details</h3>
                <Card>
                  <Section>
                    <CreateRoleForm formObj={formObj} />
                  </Section>

                  {!!applicationOptions && (
                    <Section>
                      <LinkAppsWithPermissionsSection
                        loading={false}
                        error={undefined}
                        linkedApps={linkedApps}
                        target={{
                          id: '',
                          displayName: 'role',
                        }}
                        linkedAppOptions={applicationOptions}
                        handleConfirmation={handleLinkAppsConfirmation}
                        handleRemoval={handleAppRemoval}
                        existingPermissions={permissions}
                        existingPermissionGroups={permissionGroups}
                        handlePermissionOrPermissionGroupUpdate={
                          handlePermissionOrPermissionGroupUpdate
                        }
                      />
                    </Section>
                  )}

                  <Section>
                    <TagsSection
                      loading={false}
                      error={undefined}
                      tags={tags}
                      target={{ id: '', displayName: 'role' }}
                      tagOptions={tagOptions}
                      handleConfirmation={handleTagConfirmation}
                      handleRemoval={handleTagRemoval}
                      disabled={false}
                    />
                  </Section>
                </Card>
                <div className='ln-u-margin-ends*4'>
                  <Button element='button' variant='filled' type='submit'>
                    Create role
                  </Button>
                </div>
              </form>
            )}
          </CreateRoleContainer>
        ) : (
          <div>you do not have access</div>
        )}
        <Drawer
          open={showSidePanel !== null}
          onClose={handlePanelClose}
          anchor='right'
          className='add-application'
        >
          {renderPanel()}
        </Drawer>

        {/*{showModal && (*/}
        {/*  <CreatedStatusModal*/}
        {/*    showModal={showModal}*/}
        {/*    handleClose={() => {*/}
        {/*      setShowModal(false);*/}
        {/*    }}*/}
        {/*    isLoading={loading}*/}
        {/*    error={error}*/}
        {/*    accountTypeID={data?.createAccountType?.id}*/}
        {/*  />*/}
        {/*)}*/}
      </Container>
    </>
  );
};
