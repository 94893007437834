import { AppLayout } from 'src/AppLayout';
import ApolloClientProvider from 'src/providers/ApolloClientProvider';
import { useAppDispatch } from 'src/redux/hooks';
import { setLoggedIn } from 'src/redux/reducer';
import { MsalProvider } from '@azure/msal-react';
import { UrlString } from '@azure/msal-common';

import 'src/index.scss';

import { ReactNode } from 'react';
import { Configuration, PublicClientApplication } from '@azure/msal-browser';
import { envConfiguration } from 'src/utils/loadEnvConfig';

type AppProps = {
  children: ReactNode;
};

const msalConfiguration: Configuration = {
  auth: {
    clientId: envConfiguration.clientId,
    authority: envConfiguration.authority,
    postLogoutRedirectUri: '/',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
  system: {
    pollIntervalMilliseconds: 10,
  },
};

const pca = new PublicClientApplication(msalConfiguration);

const isAuthPopup = () => {
  const hash = window.location.hash;
  return UrlString.hashContainsKnownProperties(hash);
};

function Root({ children }: AppProps) {
  const dispatch = useAppDispatch();

  fetch('/validateCookie')
    .then(response => response.json())
    .then(data => {
      dispatch(setLoggedIn(data));
    });

  if (isAuthPopup()) {
    return (
      <div>
        <h1>Authenticating...</h1>
      </div>
    );
  } else {
    return (
      <MsalProvider instance={pca}>
        <ApolloClientProvider>
          <AppLayout>{children}</AppLayout>
        </ApolloClientProvider>
      </MsalProvider>
    );
  }
}

export default Root;
