import React, { FC, useState, useEffect } from 'react';
import { Button, ProgressSpinner, AutocompleteField } from '@jsluna/react';
import { ApplicationDeleteRow } from 'src/shared/components/applicationDeleteRow';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { EditAppGroupScreen_ApplicationInfoFragment } from 'src/operations/generated/graphql';

interface IOption {
  label: string;
  value: EditAppGroupScreen_ApplicationInfoFragment;
}

interface IAddApplicationsToGroup {
  mappedApplications: EditAppGroupScreen_ApplicationInfoFragment[];
  existingApplications: EditAppGroupScreen_ApplicationInfoFragment[];
  handleUpdateGroup: (
    appIds: EditAppGroupScreen_ApplicationInfoFragment[],
  ) => Promise<void>;
  addingLoading: boolean;
  deleteAppGroupHandler: () => void;
  closeAppGroupHandler: () => void;
}

export const AddApplicationsToGroup: FC<IAddApplicationsToGroup> = ({
  mappedApplications,
  existingApplications,
  handleUpdateGroup,
  addingLoading,
  deleteAppGroupHandler,
  closeAppGroupHandler,
}) => {
  const [selectedApps, setSelectedApps] = useState<
    EditAppGroupScreen_ApplicationInfoFragment[]
  >([]);
  const [appSearchOptions, setAppSearchOptions] = useState<IOption[]>([]);
  const [previewSelectedApps, setPreviewSelectedApps] = useState<
    EditAppGroupScreen_ApplicationInfoFragment[]
  >([]);

  useEffect(() => {
    const options: IOption[] = [];
    const existingApplicationIds = existingApplications.map(
      existingApplication => existingApplication.id,
    );
    mappedApplications.map(application => {
      if (!existingApplicationIds.includes(application.id)) {
        options.push({
          label: application.name || '',
          value: application,
        });
      }
    });

    setAppSearchOptions(options);
  }, []);

  const handleSelectSearch = (selected: IOption) => {
    if (selected) {
      const { value } = selected;
      setSelectedApps([...selectedApps, value]);
      setPreviewSelectedApps([...previewSelectedApps, value]);
      setAppSearchOptions(
        appSearchOptions.filter((toRemove: IOption) => toRemove !== selected),
      );
    }
  };
  const handleRemoveSelection = (appID: string) => {
    setPreviewSelectedApps(
      previewSelectedApps.filter(toRemove => toRemove.id !== appID),
    );
    setSelectedApps(selectedApps.filter(toRemove => toRemove.id !== appID));
  };

  return (
    <>
      <div className='tab-heading'>Search for apps to add:</div>
      {mappedApplications && (
        <AutocompleteField
          name='search-apps-to-add'
          placeholder='e.g. PMA'
          label='search apps'
          options={appSearchOptions}
          role='search'
          hideLabel
          onSelect={(selected: any) => {
            handleSelectSearch(selected);
          }}
        />
      )}

      <div className='panel-body'>
        {previewSelectedApps.map(application => (
          <ApplicationDeleteRow
            key={application.id}
            application={application}
            deleteHandler={() => {
              handleRemoveSelection(application.id);
            }}
          />
        ))}
      </div>
      <PanelActions className='no-gap'>
        <Button
          onClick={() => {
            handleUpdateGroup(selectedApps);
          }}
          variant='filled'
          disabled={selectedApps.length === 0 || addingLoading}
        >
          {addingLoading && <ProgressSpinner />}
          {addingLoading ? 'Adding' : 'Add to Group'}
        </Button>
        <Button onClick={closeAppGroupHandler} variant='outlined'>
          Cancel
        </Button>
        <div className='delete-section'>
          <Button
            variant='text'
            circle={false}
            disabled={addingLoading}
            hard={false}
            element='button'
            color='dark'
            onClick={() => {
              deleteAppGroupHandler();
            }}
          >
            Delete Group
          </Button>
        </div>
      </PanelActions>
    </>
  );
};
