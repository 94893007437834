import React, { FC } from 'react';
import { GridWrapper, GridItem, Card, Button } from '@jsluna/react';

interface INavigationType {
  index: number;
  title: string;
}

interface IBottomMenu {
  showNumberInTitle: boolean;
  setCurrentIndex: (section: any, sub_section?: any) => void;
  sectionsTitles: any[];
  currentIndex: number;
  totalSections: number;
}

const NAVIGATION_SIZES = { default: '1/2', sm: '1/2' };

export const BottomMenu: FC<IBottomMenu> = ({
  setCurrentIndex,
  showNumberInTitle,
  sectionsTitles,
  currentIndex,
  totalSections,
}) => {
  const leftNavigationObject: INavigationType = {
    title: sectionsTitles[currentIndex - 2],
    index: currentIndex - 1,
  };
  const rightNavigationObject: INavigationType = {
    title: sectionsTitles[currentIndex],
    index: currentIndex + 1,
  };

  const leftNavigationObj = currentIndex > 1 ? leftNavigationObject : null;
  const rightNavigationObj =
    currentIndex < totalSections ? rightNavigationObject : null;

  return (
    <GridWrapper equalHeight className='ln-u-flush ln-u-bg-color-grey-light'>
      <GridItem
        size={NAVIGATION_SIZES}
        className='bottom-nav__left ln-u-bg-color-white'
      >
        {leftNavigationObj ? (
          <Card
            onClick={() => setCurrentIndex(leftNavigationObj.index)}
            className='cursor--pointer t-bottom-nav-left ln-u-hard-sides'
          >
            <p>Previous</p>
            <div className='ln-u-display-1 ln-u-color-orange'>
              {showNumberInTitle ? `${leftNavigationObj.index}. ` : ''}
              {leftNavigationObj.title}
            </div>
          </Card>
        ) : (
          <div />
        )}
      </GridItem>
      <GridItem
        size={NAVIGATION_SIZES}
        className='bottom-nav__right--left-border ln-u-soft-right ln-u-bg-color-white'
      >
        {rightNavigationObj ? (
          <Card
            onClick={() => setCurrentIndex(rightNavigationObj.index)}
            className=' cursor--pointer t-bottom-nav-right ln-u-hard-sides'
          >
            <p>Next</p>
            <div className='ln-u-display-1  ln-u-color-orange'>
              {showNumberInTitle ? `${rightNavigationObj.index}. ` : ''}
              {rightNavigationObj.title}
            </div>
          </Card>
        ) : (
          <div />
        )}
      </GridItem>
    </GridWrapper>
  );
};
