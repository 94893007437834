import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const FormWrapper = styled('div')`
  .ln-c-form-group {
    width: 400px;
  }

  .terms-and-conditions {
    margin-top: 20px;
    margin-bottom: 48px;

    .title {
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
    }

    .terms {
      font-family: 'Arial';
      font-weight: 400;
      font-size: 18px;
      line-height: 26px;
      margin-top: 20px;

      a {
        color: ${ThemeColors.linkColor};
      }
    }
  }
`;
