import React, { FC } from 'react';
import Drawer from '@mui/material/Drawer';
import { useApplicationsContext } from '../../context/applications.context';
import { AddNewAppGroupScreen } from 'src/screens/addNewAppGroup';
import { actions } from '../../redux';

export const AddNewAppGroup: FC = () => {
  const { state, dispatch } = useApplicationsContext();

  const handleAssignModalClose = () => {
    dispatch({
      type: actions.HIDE_ADD_NEW_APP_GROUP,
    });
  };

  return (
    <Drawer
      open={state.showAddNewAppGroup}
      onClose={handleAssignModalClose}
      anchor='right'
      className='drawer-on-top add-new-app-group'
    >
      <AddNewAppGroupScreen closeHandler={handleAssignModalClose} />
    </Drawer>
  );
};
