import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const UpdateAccountTypeContainer = styled('div')`
  width: 448px;
  padding: 24px;
  padding-bottom: 0;

  .panel-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${ThemeColors.black};
    margin-bottom: 18px;
    margin-top: 24px;
  }
`;
