import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_SUPPLIER_ARTICLE_GROUPINGS_QUERY = gql`
  query ($typeInput: String!) {
    articles(type: $typeInput)
      @rest(
        type: "articles"
        path: "content-projection/contentStack/supplierArticleGroupings?type=:type"
      ) {
      child_type
      children
      creation_date
      summary
      tags
      teaser_image
      title
      type
      uid
    }
  }
`;

export const useGetSupplierArticleGroupings = (type: string) => {
  return useQuery(GET_SUPPLIER_ARTICLE_GROUPINGS_QUERY, {
    variables: { typeInput: type },
    context: { clientName: EndPoint.PPS_REST_ENDPOINT },
  });
};
