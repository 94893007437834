import styled from 'styled-components';

export const StatDiv = styled('div')`
  h5 {
    margin-bottom: 8px;
  }
  .status-block {
    display: flex;
    justify-content: space-between;
  }

  .ln-c-card {
    border-radius: 8px;
    border-top: 5px solid;
  }

  &.loading {
    .ln-c-card {
      border-top: 5px solid #404040;
    }
  }

  &.up {
    .ln-c-card {
      border-top: 5px solid #2e7d32;
    }
  }

  &.down {
    .ln-c-card {
      border-top: 5px solid #d50000;
    }
  }

  .badge {
    padding: 5px 10px;

    display: inline-block;
    border-radius: 20px;
    span {
      color: #fff;
    }

    &.ok {
      background: #2e7d32;
    }
    &.not-ok {
      background: #d50000;
    }
  }

  .service-list {
    display: flex;

    .service {
      padding: 5px 10px;
      padding-left: 15px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 11px;
        border-radius: 6px;
      }

      &.ok {
        &:before {
          background-color: #2e7d32;
        }
      }
      &.not-ok {
        &:before {
          background-color: #d50000;
        }
      }
    }
  }
`;
