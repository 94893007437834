import './newRelic';
import React from 'react';
import Root from './Root';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ProfilePage } from './pages/profiles';
import { CreateRole } from './pages/createRole';
import { ApplicationsPage } from './pages/applications';
import { CreateAccountType } from './pages/createAccountType';
import { CreateApplication } from './pages/createApplication';
import { ViewApplication } from './pages/viewApplication';

import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ViewOrganisation } from './pages/viewOrganisation';

import { createRoot } from 'react-dom/client';
import { ViewProfile } from './pages/viewProfile';
import { InviteProfile } from './pages/inviteProfile';
import { ServiceErrorPage } from './pages/ServiceError';
import { ServiceErrorType } from './enums/serviceErrorTypes.enum';
import { CompleteInvitation } from './pages/completeInvitation';
import { routes } from 'src/constants/routes';
import { SupplierNewsPage } from './pages/supplierNews';
import { NewsAndArticlesPage } from './pages/newsAndArticles';
import { SupplierJournalsPage } from './pages/supplierJournals';
import { SupplierJournalPage } from './pages/supplierJournal';
import { JournalArticlePage } from './pages/journalArticle';
import { Navigate } from 'react-router-dom';
import { NewsArticlePage } from './pages/newsArticle';
import { GSCOPPage } from './pages/gscop/gscop';
import { WaysOfWorkingPage } from './pages/waysOfWorking';
import { TermsAndConditions } from './pages/termsAndConditions';
import { BrexitUpload } from './pages/brexitUpload';
import { LoginPage } from './pages/login';
import { RequestAccount } from './pages/requestAccount';
import { DeleteAccount } from './pages/deleteAccount';
import { TagManager } from './pages/tagManager/tagManager';
import { AccountTypeManagement } from './pages/accountTypeManagement';
import { ViewSite } from './pages/viewSite';
import { Notifications } from './pages/notification';
import { ManageRole } from './pages/roleManagement/manageRole';
import { AuthProvider } from './providers/AuthProvider';
import { PermissionBasedRoute } from './Layouts/ProtectedLayout/PermissionBasedRoute';
import { Registrations } from './pages/registrations';
import { RootPage } from './pages/RootPage';
import { PlatformTermsAndConditions } from './pages/platformTermsAndConditions';
import { Privacy } from './pages/privacy';
import { ServiceDashboard } from './pages/serviceDashboard';
import { PPSLayout } from './Layouts/PPSLayout';
import { SitesPage } from './pages/sites';
import { MovedPage } from './pages/movedPage';
import { GroupSitesPage } from './pages/groupSites';
import { FeedbackPage } from './pages/feedback';

const container = document.getElementById('root');
const rootContainer = createRoot(container!);

rootContainer.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        <Root>
          <BrowserRouter>
            <Routes>
              <Route element={<PPSLayout />}>
                <Route
                  path={routes.serviceDashboard}
                  element={<ServiceDashboard />}
                />
                <Route
                  path={routes.applications}
                  element={<ApplicationsPage />}
                />
                <Route path={routes.viewProfile}>
                  <Route index element={<ViewProfile />} />
                  <Route path=':profileId' element={<ViewProfile />} />
                  <Route
                    path=':profileId/accounts/:accountId'
                    element={<ViewProfile />}
                  />
                </Route>
                <Route
                  path={routes.createApplication}
                  element={<CreateApplication />}
                />
                <Route
                  path={routes.viewApplication}
                  element={<ViewApplication />}
                />
                <Route
                  path={routes.createAccountType}
                  element={<CreateAccountType />}
                />
                <Route path={routes.profiles} element={<ProfilePage />} />
                <Route
                  path={routes.completeInvitation}
                  element={<CompleteInvitation />}
                />
                <Route
                  path={routes.newsArticles}
                  element={<SupplierNewsPage />}
                />
                <Route
                  path={routes.articleNews}
                  element={<NewsArticlePage />}
                />

                <Route path={routes.gscop} element={<GSCOPPage />} />
                <Route
                  path={routes.waysOfWorkingUrl}
                  element={<WaysOfWorkingPage />}
                />
                <Route
                  path={routes.waysOfWorkingUrlSection}
                  element={<WaysOfWorkingPage />}
                />
                <Route
                  path={routes.waysOfWorkingUrlSubSection}
                  element={<WaysOfWorkingPage />}
                />
                <Route path={routes.brexitUpload} element={<BrexitUpload />} />
                <Route
                  path={routes.inviteProfile}
                  element={<InviteProfile />}
                />
                <Route
                  path={routes.createProfile}
                  element={<InviteProfile />}
                />
                <Route
                  path={routes.requestAccount}
                  element={<RequestAccount />}
                />
                <Route
                  path={routes.deleteAccount}
                  element={<DeleteAccount />}
                />
                <Route path={routes.tags} element={<TagManager />} />
                <Route
                  path={routes.manageAccountType}
                  element={<AccountTypeManagement />}
                />
                <Route element={<PermissionBasedRoute />}>
                  <Route path={routes.manageRole} element={<ManageRole />} />
                </Route>

                <Route path={routes.viewSite} element={<ViewSite />} />
                <Route
                  path={'/accounts/:targetId/notifications'}
                  element={<Notifications />}
                />
                <Route path={routes.createRole} element={<CreateRole />} />
                <Route
                  path={routes.registrations}
                  element={<Registrations />}
                />
                <Route
                  path={routes.tradingTermsAndConditions}
                  element={<TermsAndConditions />}
                />

                <Route
                  path={routes.viewOrganisation}
                  element={<ViewOrganisation />}
                />
                <Route path={routes.privacyPolicy} element={<Privacy />} />
                <Route path={routes.sites} element={<SitesPage />} />
                <Route path={routes.sitesGroups} element={<GroupSitesPage />} />
              </Route>
              <Route element={<PPSLayout isPublic={true} />}>
                <Route
                  path={routes.supplierJournals}
                  element={<SupplierJournalsPage />}
                />
                <Route path={routes.feedback} element={<FeedbackPage />} />
                <Route
                  path={routes.supplierJournal}
                  element={<SupplierJournalPage />}
                />
                <Route
                  path={routes.journalArticle}
                  element={<JournalArticlePage />}
                />
                <Route
                  path={routes.planForBetterJournal}
                  element={<SupplierJournalPage />}
                />
                <Route
                  path={routes.planForBetterArticle}
                  element={<JournalArticlePage />}
                />
                <Route
                  path={routes.agriculture}
                  element={<Navigate to={routes.agricultureParentJournal} />}
                />
                <Route
                  path={routes.agricultureJournal}
                  element={<SupplierJournalPage />}
                />
                <Route
                  path={routes.agricultureArticle}
                  element={<JournalArticlePage />}
                />
                <Route
                  path={routes.newsAndArticles}
                  element={<NewsAndArticlesPage />}
                />
                <Route
                  path={routes.termsAndConditions}
                  element={<PlatformTermsAndConditions />}
                />
                <Route path={routes.root} element={<RootPage />} />

                <Route
                  path={routes.registerForAccess}
                  element={<InviteProfile />}
                />
                <Route
                  path={routes.serverError}
                  element={<ServiceErrorPage type={ServiceErrorType.E500} />}
                />
                <Route
                  path={routes.movedPermanently}
                  element={<ServiceErrorPage type={ServiceErrorType.E301} />}
                />
                <Route
                  path='*'
                  element={<ServiceErrorPage type={ServiceErrorType.E404} />}
                />

                <Route path={routes.login} element={<LoginPage />} />
              </Route>
              <Route path={routes.pageMoved} element={<MovedPage />} />
            </Routes>
          </BrowserRouter>
        </Root>
      </Provider>
    </AuthProvider>
  </React.StrictMode>,
);

reportWebVitals();
