import styled from 'styled-components';

export const FilterSelectWrapper = styled('div')`
  margin-right: 10px;
  .ln-c-input-group {
    border-radius: 25px;

    background-image: none;

    .ln-c-autocomplete {
      width: 120px;
    }

    &:hover {
      border-color: var(--ds-modifier-border-interactive-width) solid
        var(--ds-mode-default-border-interactive) !important;
      box-shadow: none !important;
    }

    .ln-c-input-group__control {
      background-color: var(--ds-mode-default-foreground);
      background-image: linear-gradient(
        var(--ds-mode-default-foreground),
        var(--ds-mode-default-foreground)
      );
      border-radius: 25px;
      border: none;

      .ln-c-autocomplete__input {
        border-radius: 25px;
        min-width: 10px;
      }
    }
  }

  .ln-c-input-group__action {
    border: var(--ds-modifier-border-interactive-width) solid
      var(--ds-mode-default-border-interactive);
    border-left: none;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    background-color: var(--ds-mode-default-foreground);
    margin: -1px 0;

    &:hover {
      background: transparent;
    }
  }

  .ln-c-input-group {
    .ln-c-autocomplete {
      width: 100%;
    }
  }
`;
