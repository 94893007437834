import React, { FC, useEffect, useState } from 'react';
import { CheckboxField, Button, ProgressSpinner } from '@jsluna/react';
import { EditSupplierCodeContainer } from './editSupplierCode.styles';
import { gql } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ScreenFeedback } from '../components/screenFeedback';
import { DrawerViews } from 'src/pages/viewProfile/components/supplierCodesSection';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

import {
  AddSupplierCode_SiteInfoFragment,
  SupplierCodesSection_AccountToSiteRelationshipFragment,
  useAddSupplierCode_GroupSitesQuery,
  useAssociateAccountToSitesMutation,
} from 'src/operations/generated/graphql';

gql`
  fragment AddSupplierCode_SiteInfo on SiteProjection {
    id
    name
  }
`;

gql`
  query AddSupplierCode_GroupSites($groupId: String!) {
    groupSites(groupId: $groupId) {
      ...AddSupplierCode_SiteInfo
    }
  }
`;

interface IAddSupplierCode {
  setDrawerView: (status: string) => void;
  setIsDrawerOpen: (status: boolean) => void;
  drawerView: string;
  addHandler: (requestedSites: AddSupplierCode_SiteInfoFragment[]) => void;
  selectedAccountId: string;
  groupId?: string;
  sites?: SupplierCodesSection_AccountToSiteRelationshipFragment[];
}

interface IAddSupplierCodeOption {
  label: string;
  value: string;
}
export const AddSupplierCode: FC<IAddSupplierCode> = ({
  setDrawerView,
  setIsDrawerOpen,
  drawerView,
  groupId,
  addHandler,
  selectedAccountId,
  sites,
}) => {
  const [supplierCodesOptions, setSupplierCodesOptions] = useState<
    IAddSupplierCodeOption[]
  >([]);
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const {
    data: { groupSites = [] } = {},
    error: sitesError,
    loading: sitesLoading,
    refetch: sitesRefetch,
  } = useAddSupplierCode_GroupSitesQuery({
    variables: { groupId: groupId || '' },
  });

  const [
    associateMutate,
    { error: associateError, loading: associateLoading },
  ] = useAssociateAccountToSitesMutation();
  const [requestedSupplierCodes, setRequestedSupplierCodes] = useState<
    string[]
  >([]);

  const supplierCodeSelected = (e: any) => {
    const value = e.target.value;
    if (e.target.checked) {
      setRequestedSupplierCodes([...requestedSupplierCodes, value]);
    } else {
      const index = requestedSupplierCodes.indexOf(value, 0);
      if (index > -1) {
        setRequestedSupplierCodes(
          requestedSupplierCodes.filter(
            requestedSupplierCode => requestedSupplierCode !== e.target.value,
          ),
        );
      }
    }
  };

  const submitSupplierCodes = async () => {
    const result = await associateMutate({
      variables: {
        id: selectedAccountId,
        siteIds: requestedSupplierCodes,
      },
    });
    if (result?.data?.associateAccountToSites?.id) {
      setFeedBackMessage(NotificationType.SUCCESS);
      const selected = groupSites?.filter(site =>
        requestedSupplierCodes.includes(site.id),
      );
      addHandler(selected || []);
    } else {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  };
  useEffect(() => {
    if (groupSites && groupSites?.length > 0) {
      const options = groupSites.reduce((acc, groupSite) => {
        if ((sites || [])?.every(site => site.value.id !== groupSite.id)) {
          return [
            ...acc,
            {
              label: `${groupSite.name} - ${groupSite.id}`,
              value: groupSite.id,
            },
          ];
        }
        return acc;
      }, [] as IAddSupplierCodeOption[]);
      setSupplierCodesOptions(options);
    }
  }, [groupSites]);

  return (
    <EditSupplierCodeContainer>
      {feedBackMessage ? (
        <>
          <ScreenFeedback
            isLoading={associateLoading}
            notificationType={feedBackMessage}
            feedBackMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? 'Your request for supplier codes has been submitted'
                : 'Unable to add subsidiaries. Please try again.'
            }
            feedBackInfo='Once your request has been reviewed, you’ll get a notification of the outcome in Together With.'
            saveButtonText={'Close'}
            onCloseHandler={() => {
              setIsDrawerOpen(false);
              setDrawerView('');
            }}
            resetButtonText={'Add new codes'}
            resetHandler={() => {
              setRequestedSupplierCodes([]);
              setFeedBackMessage(null);
              setDrawerView(DrawerViews.ADD_SUBSIDIARY);
            }}
            notificationMessage={
              feedBackMessage === NotificationType.SUCCESS
                ? 'Request submitted'
                : 'Failed to add subsidiary'
            }
          />
        </>
      ) : (
        <>
          <h4>Add supplier codes to your account</h4>
          {supplierCodesOptions?.length > 0 && (
            <CheckboxField
              name='add-supplier-codes-field'
              info='You can select more than one supplier code.'
              options={supplierCodesOptions}
              onChange={supplierCodeSelected}
            />
          )}
        </>
      )}

      {!feedBackMessage && (
        <PanelActions>
          <>
            <Button
              onClick={() => {
                submitSupplierCodes();
              }}
              variant='filled'
              disabled={sitesLoading || requestedSupplierCodes.length === 0}
              type='submit'
            >
              {sitesLoading && <ProgressSpinner />}
              Add codes
            </Button>

            <Button
              hard={false}
              variant='outlined'
              onClick={() => {
                setIsDrawerOpen(false);
                setDrawerView('');
              }}
            >
              Cancel
            </Button>
          </>
        </PanelActions>
      )}
    </EditSupplierCodeContainer>
  );
};
