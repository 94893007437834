import React, { FC, useState } from 'react';
import { PageHeader } from 'src/components/PageHeader';
import Container from 'src/components/Container/Container';
import { DeleteAccountCardWrapper } from './deleteAccount.styles';
import { RadioButtonField, Button } from '@jsluna/react';
import { gql } from '@apollo/client';
import { useAppSelector } from 'src/redux/hooks';
import { getSelectedAccount } from 'src/redux/selectors';
import { DeleteAccountPopup } from './deleteAccountPopup';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { RelativeDiv } from './deleteAccount.styles';
import { AccountFeedback } from './deleteAccountFeedback';
import {
  DeleteAccount_AccountFragment,
  useDeleteAccount_MyProfileQuery,
} from 'src/operations/generated/graphql';

gql`
  fragment DeleteAccount_Account on AccountProjection {
    id
    name
    status
  }
`;

gql`
  fragment DeleteAccount_MyProfile on ProfileProjection {
    id
    invitationStatus
    selectedAccountId
    accounts {
      value {
        ...DeleteAccount_Account
      }
    }
  }
`;

gql`
  query DeleteAccount_MyProfile {
    myProfile {
      ...DeleteAccount_MyProfile
    }
  }
`;

export const DeleteAccount: FC = () => {
  const selectedAccount = useAppSelector(getSelectedAccount);
  const [requestedAccount, setRequestedAccount] =
    useState<DeleteAccount_AccountFragment>({
      id: '',
      name: '',
    });
  const [feedback, setFeedback] = useState<{
    notificationFor: string;
    type: NotificationType;
  } | null>(null);

  const [myProfileAccounts, setMyProfileAccounts] =
    useState<DeleteAccount_AccountFragment | null>(null);

  const { data: { myProfile = null } = {} } = useDeleteAccount_MyProfileQuery();

  const [showPopup, setShowPopup] = useState(false);

  const handleDeleteClose = (type?: NotificationType) => {
    if (type) {
      setFeedback({
        type,
        notificationFor: 'delete',
      });
      const optimisticAccounts = myProfile?.accounts.find(
        account => account.value.id !== requestedAccount.id,
      );
      setMyProfileAccounts(optimisticAccounts?.value || null);
    }
  };

  const createRadioOptions = (accounts: DeleteAccount_AccountFragment[]) => {
    const options = accounts
      .map(account => ({
        label: account.name || '',
        value: account.id,
        checked:
          requestedAccount.id !== '' && requestedAccount.id === account.id,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
    return options;
  };
  const accountSelected = (e: any) => {
    const id = e.target.value;
    const account =
      myProfile?.accounts.find(account => account.value.id === id) || null;
    setRequestedAccount({ id, name: account?.value.name || '' });
  };
  return (
    <>
      <PageHeader
        heading={`Delete account`}
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'Delete account',
            link: '',
          },
        ]}
      />
      <RelativeDiv>
        {feedback && (
          <>
            <AccountFeedback
              type={feedback.type}
              onClose={() => {
                setFeedback(null);
              }}
            />
          </>
        )}
      </RelativeDiv>
      <Container size='xs'>
        <DeleteAccountCardWrapper>
          <div className='details-heading'>
            Which account would you like to delete?
          </div>
          <div className=''>
            {myProfile && myProfile?.accounts?.length > 0 && (
              <RadioButtonField
                label='Select the account you want to delete.'
                info='Please note once deleted this action cannot be done and you will lose access to all data and permissions associated with that account.'
                name='delete-radio-button-field'
                fullWidth
                options={createRadioOptions(
                  myProfile?.accounts.map(account => account.value) || [],
                )}
                onChange={accountSelected}
              />
            )}
          </div>
          <Button
            onClick={() => {
              setShowPopup(true);
            }}
            variant='filled'
            aria-haspopup='true'
            disabled={requestedAccount.id === ''}
          >
            Delete selected
          </Button>
          {selectedAccount && (
            <DeleteAccountPopup
              requestedAccount={requestedAccount}
              setRequestedAccount={setRequestedAccount}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              handleClose={handleDeleteClose}
            />
          )}
        </DeleteAccountCardWrapper>
      </Container>
    </>
  );
};
