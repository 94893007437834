import { FC } from 'react';
import { Plus, Edit, Cancel } from '@jsluna/icons';
import { UpdateEmailButton } from '../profileCard.styles';

const CancelIcon = Cancel as unknown as React.FC;

interface IResyncProfileEmail {
  onClick: () => void;
  showCancel: boolean;
}

export const ResyncProfileEmail: FC<IResyncProfileEmail> = ({
  onClick,
  showCancel,
}) => {
  return (
    <UpdateEmailButton
      variant='text'
      circle={false}
      disabled={false}
      hard={false}
      element='button'
      label='Resync profile'
      hideLabel
      onClick={onClick}
    >
      {showCancel ? <CancelIcon /> : <>Resync profile</>}
    </UpdateEmailButton>
  );
};
