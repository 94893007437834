import { FC, useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router';
import { Button } from '@jsluna/react';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { AccountCardWrapper } from './accountCard.styles';
import { TagsSection } from 'src/components/Sections/TagsSection';
import { SupplierCodesSection } from '../supplierCodesSection';
import { Drawer } from '@mui/material';
import { DetailsRow } from 'src/shared/components/detailsRow';
import { Section } from 'src/components/Sections/section.styles';
import { UpdateAccountDetailsScreen } from './screens/updateAccountScreen';
import { AccountSwitcher } from './accountSwitcher';
import { RoleSection } from 'src/components/Sections/RoleSection';
import { APP_ACCESS } from 'src/enums/permissions.enum';
import { AuthContext } from 'src/providers/AuthProvider';
import { routes } from 'src/constants/routes';

import {
  useAssociateTagsToAccountMutation,
  useDissociateTagsFromAccountMutation,
  useRequestRoleMutation,
  useDissociateAccountFromRolesMutation,
  useAccountUpdateDetailsMutation,
  useAccountCard_AccountDetailsLazyQuery,
  TagSelection_TagsFragment,
  useAccountCard_TagsLazyQuery,
  useAccountCard_RolesByAccountTypeLazyQuery,
  UpdateAccountDetailsScreen_AccountInfoFragment,
  RoleSelection_AccountToRoleRelationshipFragment,
  RoleStatusEnum,
} from 'src/operations/generated/graphql';

gql`
  query AccountCard_AccountDetails($id: String!) {
    account(id: $id) {
      id
      ...UpdateAccountDetailsScreen_AccountInfo
      ...AccountSwitcher_AccountInfo
      name
      accountTypeId
      accountType {
        id
        name
        checkType
      }
      roles {
        ...RoleSelection_AccountToRoleRelationship
      }
      organisationGroups {
        id
        name
      }
      sites {
        ...SupplierCodesSection_AccountToSiteRelationship
      }
      tags {
        value {
          ...TagSelection_Tags
        }
      }
      status
      jobTitle
    }
  }
`;

gql`
  query AccountCard_Tags($accountTypeId: String!) {
    tagsByAccountType(accountTypeId: $accountTypeId) {
      ...TagSelection_Tags
    }
  }
`;

gql`
  query AccountCard_RolesByAccountType($accountTypeId: String!) {
    rolesByAccountType(accountTypeId: $accountTypeId) {
      ...RoleSection_RoleInfo
    }
  }
`;

interface IAccountCard {
  accountId: string;
  profileId: string;
  isMyProfile: boolean;
}

enum PanelAction {
  EDIT_ACCOUNT_DETAILS = 'EDIT_ACCOUNT_DETAILS',
}

export const AccountCard: FC<IAccountCard> = ({
  accountId,
  profileId,
  isMyProfile,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const [details, setDetails] =
    useState<UpdateAccountDetailsScreen_AccountInfoFragment>({
      id: '',
      name: 'NOT_AVAILABLE',
      jobTitle: 'NOT_AVAILABLE',
    });
  const [tags, setTags] = useState<TagSelection_TagsFragment[]>([]);
  const [selectedAccountId, setSelectedAccountId] = useState(accountId);

  const [rolesToShow, setRolesToShow] = useState<
    Pick<RoleSelection_AccountToRoleRelationshipFragment, 'status' | 'value'>[]
  >([]);
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);
  const { profileId: urlProfileId } = useParams();
  const navigate = useNavigate();
  const [getAccount, { data: { account = null } = {} }] =
    useAccountCard_AccountDetailsLazyQuery({
      variables: { id: selectedAccountId },
      fetchPolicy: 'no-cache',
    });

  const [
    getRoleOptions,
    {
      data: { rolesByAccountType = [] } = {},
      error: rolesError,
      loading: rolesLoading,
    },
  ] = useAccountCard_RolesByAccountTypeLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [getTagOptions, { data: { tagsByAccountType: tagOptions = [] } = {} }] =
    useAccountCard_TagsLazyQuery();

  useEffect(() => {
    if (selectedAccountId != '') {
      getAccount();
    }
  }, [selectedAccountId]);

  useEffect(() => {
    if (account) {
      const { name, tags, accountTypeId, roles, jobTitle } = account;
      getTagOptions({ variables: { accountTypeId: accountTypeId || '' } });
      getRoleOptions({ variables: { accountTypeId: accountTypeId || '' } });
      setDetails({ id: '', name: name || '', jobTitle: jobTitle || '' });
      setTags(tags.map(tag => tag.value) || []);
      setRolesToShow(roles || []);
    }
  }, [account]);

  const [
    dissociateTagsFromAccountMutation,
    {
      loading: dissociateTagsFromAccountLoading,
      error: dissociateTagsFromAccountError,
    },
  ] = useDissociateTagsFromAccountMutation();

  const handleTagRemoval = async (tagId: string) => {
    if (!account) return;
    const filteredTags = tags.filter(tag => tag.id !== tagId);
    try {
      await dissociateTagsFromAccountMutation({
        variables: { accountId: account.id, tagIds: [tagId] },
      });
      setTags(filteredTags);
    } catch (e) {}
  };

  const [
    associateTagsToAccountMutation,
    {
      loading: associateTagsToAccountLoading,
      error: associateTagsToAccountError,
    },
  ] = useAssociateTagsToAccountMutation();

  const handleTagConfirmation = async (
    selectedTags: TagSelection_TagsFragment[],
  ) => {
    if (!account) return;
    const selectedTagIds = selectedTags.map(tag => tag.id);
    try {
      await associateTagsToAccountMutation({
        variables: { accountId: account.id, tagIds: selectedTagIds },
      });
      setTags([...tags, ...selectedTags]);
    } catch (e) {}
  };

  const [
    requestRolesMutation,
    { loading: requestRolesLoading, error: requestRolesError },
  ] = useRequestRoleMutation();

  const handleRequestRoleConfirmation = async (roleIds: string[]) => {
    if (!account) return;

    try {
      const requestedRolesMutationResult = await requestRolesMutation({
        variables: {
          input: {
            accountId: account.id,
            roleIds,
          },
        },
      });

      if (requestedRolesMutationResult?.data?.requestRoles?.id) {
        const rolesToAdd = rolesByAccountType
          .filter(role => roleIds.includes(role.id))
          .map(role => ({ value: role, status: RoleStatusEnum.Requested }));
        setRolesToShow([...rolesToShow, ...rolesToAdd]);
      }
    } catch (e) {}
  };

  const [
    dissociateAccountFromRolesMutation,
    {
      loading: dissociateAccountFromRolesLoading,
      error: dissociateAccountFromRolesError,
    },
  ] = useDissociateAccountFromRolesMutation();

  const handleDissociateRoleFromAccountConfirmation = async (
    roleIds: string[],
  ) => {
    if (!account) return;

    try {
      const dissociatedMutationResult =
        await dissociateAccountFromRolesMutation({
          variables: {
            id: account.id,
            roleIds,
          },
        });

      if (dissociatedMutationResult?.data?.dissociateAccountFromRoles?.id) {
        const updatedRoles = rolesToShow.filter(
          role => !roleIds.includes(role.value.id),
        );

        setRolesToShow([...updatedRoles]);
      }
    } catch (e) {}
  };

  const [
    updateAccountDetailsMutation,
    { loading: updateAccountDetailsLoading, error: updateAccountDetailsError },
  ] = useAccountUpdateDetailsMutation();

  const handleAccountDetailsConfirmation = async (value: any) => {
    const { accountName, accountJobTitle } = value;
    if (!account) return;
    if (!accountName && !accountJobTitle) return;
    try {
      await updateAccountDetailsMutation({
        variables: {
          id: account.id,
          input: {
            name: accountName,
            jobTitle: accountJobTitle,
          },
        },
      });
      setDetails({
        name: accountName ? accountName : account.name,
        id: account.id,
        jobTitle: accountJobTitle ? accountJobTitle : account.jobTitle,
      });
    } catch (e) {}
  };

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    if (!account) return;

    switch (showSidePanel) {
      case PanelAction.EDIT_ACCOUNT_DETAILS:
        return (
          <UpdateAccountDetailsScreen
            target={{ id: account.id, displayName: 'account' }}
            loading={updateAccountDetailsLoading}
            error={updateAccountDetailsError}
            handleClose={handlePanelClose}
            handleConfirmation={handleAccountDetailsConfirmation}
            heading='Edit account details'
            account={account}
            details={details || { name: '', jobTitle: '' }}
          />
        );
    }
  };

  const handleAccountSwitcher = (accountId: string) => {
    navigate(`${routes.viewProfile}/${urlProfileId}/accounts/${accountId}`);
    setSelectedAccountId(accountId);
  };

  return (
    <AccountCardWrapper>
      <>
        <Section>
          {!isMyProfile && (
            <AccountSwitcher
              accountId={accountId}
              profileId={profileId}
              selectedAccountId={selectedAccountId}
              setSelectedAccountId={handleAccountSwitcher}
              details={details}
            />
          )}
          {!account ? (
            <div className='no-account-selected'>No account details found</div>
          ) : (
            <>
              <h2 className='ln-u-display-3 ln-u-margin-bottom*3'>
                Account summary
              </h2>
              <h4 className='display-1 ln-u-margin-bottom*3'>
                {'Account details'}
              </h4>
              <DetailsRow name='Id:' value={accountId} />
              <DetailsRow name='Name:' value={details.name || ''} />
              <DetailsRow name='Job title:' value={details.jobTitle || ''} />
              <DetailsRow
                name='Account type:'
                value={account.accountTypeId || ''}
              />
              {!isMyProfile && (
                <DetailsRow
                  name='Status:'
                  value={account.status || 'UNAVAILABLE'}
                />
              )}
              {account.organisationGroups?.length === 1 && (
                <DetailsRow
                  name='Parent company:'
                  value={account.organisationGroups[0].name || ''}
                />
              )}

              <div className='action-button account'>
                {(hasPermission(APP_ACCESS.UPDATE_USER_DETAILS) ||
                  isMyProfile) && (
                  <Button
                    variant='text'
                    circle={false}
                    disabled={false}
                    hard={false}
                    element='button'
                    color='dark'
                    onClick={() =>
                      setShowSidePanel(PanelAction.EDIT_ACCOUNT_DETAILS)
                    }
                  >
                    Edit account details
                  </Button>
                )}
              </div>

              <div className='section roles-section'>
                <RoleSection
                  loading={rolesLoading}
                  error={rolesError}
                  existingRoles={rolesToShow}
                  fullRoleOptions={rolesByAccountType}
                  handleRoleRequest={handleRequestRoleConfirmation}
                  roleRequestLoading={requestRolesLoading}
                  roleRequestError={requestRolesError}
                  roleRemovalLoading={dissociateAccountFromRolesLoading}
                  roleRemovalError={dissociateAccountFromRolesError}
                  handleRoleRemoval={
                    handleDissociateRoleFromAccountConfirmation
                  }
                  showActionPanel={
                    hasPermission(APP_ACCESS.UPDATE_USER_DETAILS) || isMyProfile
                  }
                />
              </div>
              {account?.accountType?.checkType === 'ORGANISATION' && (
                <div className='section subsidiaries-section'>
                  <div className='details-heading'>
                    Supplier codes linked to {details.name}
                  </div>
                  <SupplierCodesSection
                    selectedAccountId={accountId}
                    sites={account?.sites}
                    disabled={
                      !hasPermission(APP_ACCESS.UPDATE_USER_DETAILS) &&
                      !isMyProfile
                    }
                  />
                </div>
              )}
              <TagsSection
                tags={tags}
                handleRemoval={handleTagRemoval}
                loading={associateTagsToAccountLoading}
                error={associateTagsToAccountError}
                tagOptions={tagOptions || []}
                target={{ id: account.id, displayName: account.name || '' }}
                handleConfirmation={handleTagConfirmation}
                disabled={
                  !hasPermission(APP_ACCESS.UPDATE_USER_DETAILS) && !isMyProfile
                }
                type='account'
              />
            </>
          )}

          <Drawer
            open={showSidePanel !== null}
            onClose={() => setShowSidePanel(null)}
            anchor='right'
            className='drawer-on-top edit-account-name'
          >
            {renderPanel()}
          </Drawer>
        </Section>
      </>
    </AccountCardWrapper>
  );
};
