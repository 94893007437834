import slugCreator from 'slugify';

export const slugify = (slug: string) =>
  slugCreator(slug, {
    trim: true,
    locale: 'EN',
    replacement: '-',
    strict: true,
    lower: true,
  });
