import React from 'react';
import { ErrorCrossWrapper } from './ErrorCross.styles';

export const ErrorCross = () => {
  return (
    <ErrorCrossWrapper>
      <svg
        className='checkmark'
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 52 52'
      >
        {' '}
        <circle
          className='checkmark__circle'
          cx='26'
          cy='26'
          r='25'
          fill='none'
        />{' '}
        <path className='error_cross' fill='none' d='M35.4,36L16,16.7' />
        <path className='error_cross' fill='none' d='M16.1,36L36,16' />
      </svg>
    </ErrorCrossWrapper>
  );
};
