import React, { FC, useEffect, useState } from 'react';
import { Checkbox } from '@jsluna/react';
import { SupplierCodeStatus } from '../supplierCodeStatus';
import { TermsAndConditions_GroupSitesFragment } from 'src/operations/generated/graphql';

interface ITradingTermsAndConditionsSupplierCodes {
  supplierCodes: Array<TermsAndConditions_GroupSitesFragment>;
  supplierCodesAuthority: boolean;
  updateSupplierCodesAuthority: (value: boolean) => void;
}

export const TradingTermsAndConditionsSupplierCodes: FC<
  ITradingTermsAndConditionsSupplierCodes
> = ({
  supplierCodes,
  supplierCodesAuthority,
  updateSupplierCodesAuthority,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [availableSupplierCodes, setAvailableSupplierCodes] = useState<
    TermsAndConditions_GroupSitesFragment[]
  >([]);
  const [unAvailableSupplierCodes, setUnAvailableSupplierCodes] = useState<
    TermsAndConditions_GroupSitesFragment[]
  >([]);

  useEffect(() => {
    const availableCodes: TermsAndConditions_GroupSitesFragment[] = [];
    const unAvailableCodes: TermsAndConditions_GroupSitesFragment[] = [];

    supplierCodes.map((supplierCode: TermsAndConditions_GroupSitesFragment) => {
      if (
        supplierCode.tncStatus !== 'Approved' &&
        supplierCode.tncStatus !== 'Declined'
      ) {
        availableCodes.push(supplierCode);
      } else {
        unAvailableCodes.push(supplierCode);
      }
    });

    setAvailableSupplierCodes(availableCodes);
    setUnAvailableSupplierCodes(unAvailableCodes);
  }, []);

  return (
    <div className='ln-c-card ln-u-soft-lg'>
      {supplierCodes && supplierCodes.length > 0 ? (
        <>
          <h2>Before accepting our Terms and Conditions</h2>
          <p>
            In accepting these terms, you acknowledge that{' '}
            <strong>
              you have the correct rights and authority to accept these Terms
              and Conditions on behalf of your company.
            </strong>
          </p>

          {availableSupplierCodes.length > 0 && (
            <Checkbox
              className='t-supplier-codes'
              name='supplier-codes-authority'
              label='Yes, I have the authority'
              defaultChecked={supplierCodesAuthority}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const value = e.target.checked;
                updateSupplierCodesAuthority(value);
                if (!e.target.checked) {
                  setError('Please confirm you have authority');
                } else {
                  setError(null);
                }
              }}
            />
          )}
          {error && (
            <div
              id='checkbox-field-5Validation'
              className='ln-c-field-info ln-c-field-info--error'
              role='alert'
              aria-live='assertive'
            >
              {error}
            </div>
          )}

          {availableSupplierCodes.length > 0 && (
            <h5>Available supplier codes</h5>
          )}

          {availableSupplierCodes.map((supplierCode: any) => (
            <SupplierCodeStatus
              label={`${supplierCode.supplierCode} - ${supplierCode.name}`}
              status='AVAILABLE'
            />
          ))}

          {unAvailableSupplierCodes.length > 0 && (
            <h5 className='ln-u-margin-top*2'>Unavailable supplier codes</h5>
          )}

          {unAvailableSupplierCodes.map((supplierCode: any) => (
            <SupplierCodeStatus
              label={`${supplierCode.supplierCode} - ${supplierCode.name} (${supplierCode.status})`}
              status='UNAVAILABLE'
            />
          ))}
        </>
      ) : null}
    </div>
  );
};
