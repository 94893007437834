import React, { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { getSelectedAccount } from 'src/redux/selectors';
import { AddTagsContainer } from './addTagsPanel.styles';
import { Button, MultiAutocompleteField } from '@jsluna/react';
import { ITag } from 'src/interfaces/tag.interface';
import { ApolloError } from '@apollo/client';
import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { TagSelection_TagsFragment } from 'src/operations/generated/graphql';

interface IAddTagsPanel {
  handleClose: () => void;
  existingTagIds: string[];
  fullTagOptions: TagSelection_TagsFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (
    tags: TagSelection_TagsFragment[],
  ) => Promise<void | boolean>;
  target: { id: string; displayName: string };
}

interface ITagOption {
  label: string;
  value: string;
}

export const AddTagsPanel: FC<IAddTagsPanel> = ({
  handleClose,
  existingTagIds,
  fullTagOptions,
  handleConfirmation,
  loading,
  error,
  target,
}) => {
  const selectedAccount = useAppSelector(getSelectedAccount);

  const [selectedTags, setSelectedTags] = useState<ITagOption[]>([]);
  const [tagOptions, setTagOptions] = useState<ITagOption[]>([]);
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const handleSelect = (selected: ITagOption[]) => {
    setSelectedTags(selected);
  };

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  useEffect(() => {
    const tagOptions = fullTagOptions.reduce((acc, tag) => {
      if (existingTagIds.includes(tag.id)) return acc;
      return [...acc, { label: tag.name || '', value: tag.id }];
    }, [] as ITagOption[]);

    setTagOptions(tagOptions);
  }, [fullTagOptions, existingTagIds]);

  const handleAddTag = async () => {
    const tags = selectedTags
      .map(selectedTag =>
        fullTagOptions.find(tag => tag.id === selectedTag.value),
      )
      .filter(item => item);
    const result = await handleConfirmation(
      tags as TagSelection_TagsFragment[],
    );

    if (typeof result === 'boolean') {
      setFeedBackMessage(
        result ? NotificationType.SUCCESS : NotificationType.FAILURE,
      );
    } else {
      setFeedBackMessage(NotificationType.SUCCESS);
    }
  };

  const handleReset = () => {
    setFeedBackMessage(null);
    setSelectedTags([]);
  };

  const onCloseHandler = () => {
    handleClose();
  };

  return (
    <>
      <AddTagsContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={false}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Tags successfully added'
                  : `Unable to add tag to ${target.displayName}. Please try again.`
              }
              saveButtonText={'Close'}
              onCloseHandler={onCloseHandler}
              resetButtonText={'Add more tags'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${target.id.includes('ACC_') ? `Account` : `Tags`} updated`
                  : `Failed to add tag to ${target.displayName}`
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>Tag Manager</div>
            <>
              {tagOptions.length > 0 && (
                <MultiAutocompleteField
                  name='multi-autocomplete-field-1'
                  label={'Add tags'}
                  info={'e.g. buyer'}
                  options={tagOptions}
                  onSelect={handleSelect}
                />
              )}
            </>
            {/*)}*/}
            {tagOptions.length == 0 && (
              <div className='no-tags-to-select'>
                There are no tags left on this account type to be added to your
                account {target.displayName}.
              </div>
            )}
          </>
        )}
      </AddTagsContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={handleAddTag}
            variant='filled'
            disabled={loading || selectedTags.length === 0}
          >
            Add tags
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
