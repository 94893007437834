import React, { FC, useEffect, useState } from 'react';
import {
  Modal,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
  ModalHeading,
} from '@jsluna/react';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ProfileCard_ProfileInfoFragment } from 'src/operations/generated/graphql';
interface IRequestedProfile {
  id: string;
  firstName: string;
  lastName: string;
}

interface IFeedback {
  notificationFor: 'INVITE' | 'REVOKE';
  type: NotificationType;
}

interface IRemoveProfileAccessPopup {
  handleConfirmation: (status: any) => Promise<void>;
  setFeedBackMessage: (status: IFeedback) => void | null;
  loading: boolean;
  error: ApolloError | undefined;
  setShowPopup: (status: boolean) => void;
  profile: ProfileCard_ProfileInfoFragment;
  showPopup: Boolean;
}

export const RemoveProfileAccessPopup: FC<IRemoveProfileAccessPopup> = ({
  profile,
  handleConfirmation,
  setFeedBackMessage,
  loading,
  error,
  showPopup,
  setShowPopup,
}) => {
  const { id, firstName, lastName } = profile;

  const handleReset = () => {
    setShowPopup(false);
  };

  const handleSubmit = (id: string) => {
    handleConfirmation(id);
    handleReset();
    setFeedBackMessage({
      notificationFor: 'REVOKE',
      type: NotificationType.SUCCESS,
    });
  };
  useEffect(() => {
    if (error) {
      setFeedBackMessage({
        notificationFor: 'REVOKE',
        type: NotificationType.FAILURE,
      });
    }
  }, [error]);
  return (
    <>
      <Modal
        restrictClose
        open={showPopup}
        headingId='select-account-confirmation'
      >
        <ModalHeading
          element='h3'
          className='name-modal'
        >{`You're removing access for ${firstName} ${lastName}'s profile. Please note this action can't be undone.`}</ModalHeading>
        <ButtonGroupWrapper className='ln-u-margin-left'>
          <ButtonGroupPrimary>
            <FilledButton
              className='ln-u-margin-right'
              onClick={() => handleSubmit(id)}
            >
              Confirm
            </FilledButton>
            <OutlinedButton
              onClick={() => {
                handleReset();
              }}
            >
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </>
  );
};
