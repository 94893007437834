import { FC, useContext, useState } from 'react';
import { Card, Button } from '@jsluna/react';

import Container from 'src/components/Container/Container';
import {
  CreateAccountTypeContainer,
  Section,
} from './createAccountType.styles';

import { CreateAccountTypeForm } from 'src/components/Forms';
import { CreatedStatusModal } from './components/StatusModal';
import { ITag } from 'src/interfaces/tag.interface';
import { IProfile } from 'src/interfaces/profile.interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import accountTypeSchema from 'src/components/Forms/schemas/createAccountType.schema';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { routes } from 'src/constants/routes';
import { PageHeader } from 'src/components/PageHeader';
import { AccountSelectorSection } from 'src/components/Sections/AccountSelectorSection';
import { LinkAppsSection } from 'src/components/Sections/LinkAppSection';
import { TagsSection } from 'src/components/Sections/TagsSection';
import { DomainSection } from 'src/components/Sections/DomainSection';
import { AuthContext } from '../../providers/AuthProvider';
import { APP_ACCESS } from 'src/enums/permissions.enum';

import {
  AccountSelectorSection_AccountsFragment,
  LinkAppsSection_LinkAppInfoFragment,
  TagSelection_TagsFragment,
  useCreateAccountTypeMutation,
  useCreateAccType_GetApplicationsQuery,
  useGetAllTagsQuery,
} from 'src/operations/generated/graphql';

export enum PanelAction {
  ADD_ADMIN = 'ADD_DOMAIN',
  ADD_APPROVER = 'ADD_APPROVER',
  LINK_APP = 'LINK_APP',
  ADD_TAG = 'ADD_TAG',
  ADD_DOMAIN_TO_APPROVED_DOMAIN = 'ADD_DOMAIN_TO_APPROVED_DOMAIN',
  ADD_DOMAIN_TO_REJECTED_DOMAIN = 'ADD_DOMAIN_TO_AREJECTED_DOMAIN',
  REMOVE_DOMAIN_FROM_APPROVED_DOMAIN = 'REMOVE_DOMAIN_FROM_APPROVED_DOMAIN',
  REMOVE_DOMAIN_FROM_REJECTED_DOMAIN = 'REMOVE_DOMAIN_FROM_REJECTED_DOMAIN',
}

export interface IAccountTypeTempData {
  admins: IProfile[];
  approver: IProfile[];
  linkedApps: LinkAppsSection_LinkAppInfoFragment[];
  tags: ITag[];
  roles: string[];
  isTypeDomain: boolean;
  approvedDomains: string[];
  rejectedDomains: string[];
}

export const CreateAccountType: FC = () => {
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);

  const [createAccountTypeMutation, { error, loading }] =
    useCreateAccountTypeMutation();

  const [feedback, setFeedback] = useState<NotificationType | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [admins, setAdmins] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [approvers, setApprovers] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [linkedApps, setLinkedApps] = useState<
    LinkAppsSection_LinkAppInfoFragment[]
  >([]);
  const [tags, setTags] = useState<TagSelection_TagsFragment[]>([]);
  const [approvedDomains, setApprovedDomains] = useState<string[]>([]);
  const [rejectedDomains, setRejectedDomains] = useState<string[]>([]);
  const [accountTypeTempData, setAccountTypeTempData] =
    useState<IAccountTypeTempData>({
      admins: [],
      approver: [],
      linkedApps: [],
      tags: [],
      roles: [],
      isTypeDomain: false,
      approvedDomains: [],
      rejectedDomains: [],
    });

  const { data: { tags: tagOptions = [] } = {} } = useGetAllTagsQuery();

  const { data: { applications: applicationOptions = null } = {} } =
    useCreateAccType_GetApplicationsQuery();

  const approvedAndRejectIfTypeDomain = (data: IAccountTypeTempData) =>
    data.isTypeDomain
      ? {
          approvedDomains: data.approvedDomains,
          rejectedDomains: data.rejectedDomains,
        }
      : {};

  const handleLinkAppsConfirmation = async (
    appsSelected: LinkAppsSection_LinkAppInfoFragment[],
  ) => {
    setLinkedApps([...linkedApps, ...appsSelected]);
  };
  const handleLinkAppRemoval = async (appId: string) => {
    const filteredApps = linkedApps.filter(app => app.id !== appId);
    setLinkedApps(filteredApps);
  };

  const handleTagConfirmation = async (
    tagsSelected: TagSelection_TagsFragment[],
  ) => {
    setTags([...tags, ...tagsSelected]);
  };

  const handleTagRemoval = async (tagId: string) => {
    const filteredTags = tags.filter(tag => tag.id !== tagId);
    setTags(filteredTags);
  };

  const handleApproverSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    const approverSet = new Set<AccountSelectorSection_AccountsFragment>(
      approvers.concat(accountsSelected),
    );
    setApprovers([...approverSet]);
  };

  const handleApproverSelectionRemoval = async (accountId: string) => {
    const filteredAccounts = approvers.filter(
      account => account.id !== accountId,
    );
    setApprovers(filteredAccounts);
  };

  const handleAdminSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    const adminSet = new Set<AccountSelectorSection_AccountsFragment>(
      admins.concat(accountsSelected),
    );
    setAdmins([...adminSet]);
  };
  const handleAdminSelectionRemoval = async (accountId: string) => {
    const filteredAccounts = admins.filter(account => account.id !== accountId);
    setAdmins(filteredAccounts);
  };

  const handleApprovedDomainConfirmation = async (selectedDomain: string) => {
    setAccountTypeTempData({
      ...accountTypeTempData,
      approvedDomains: [...accountTypeTempData.approvedDomains, selectedDomain],
    });
    setApprovedDomains([...approvedDomains, selectedDomain]);
  };
  const handleApprovedDomainRemoval = async (selectedDomains: string[]) => {
    const filteredDomains = approvedDomains.filter(
      domain => !selectedDomains.includes(domain),
    );
    setAccountTypeTempData({
      ...accountTypeTempData,
      approvedDomains: filteredDomains,
    });
    setApprovedDomains(filteredDomains);
  };

  const handleRejectedDomainConfirmation = async (selectedDomain: string) => {
    setAccountTypeTempData({
      ...accountTypeTempData,
      rejectedDomains: [...accountTypeTempData.rejectedDomains, selectedDomain],
    });
    setRejectedDomains([...rejectedDomains, selectedDomain]);
  };
  const handleRejectedDomainRemoval = async (selectedDomains: string[]) => {
    const filteredDomains = rejectedDomains.filter(
      domain => !selectedDomains.includes(domain),
    );
    setAccountTypeTempData({
      ...accountTypeTempData,
      rejectedDomains: filteredDomains,
    });
    setRejectedDomains(filteredDomains);
  };

  const formObj = useForm({
    resolver: yupResolver(accountTypeSchema),
  });

  const { handleSubmit, reset } = formObj;

  const submitHandler = async (data: any) => {
    const addedAdmins = admins.map(
      (admin: AccountSelectorSection_AccountsFragment) => admin.id,
    );
    const addedApprovers = approvers.map(
      (approver: AccountSelectorSection_AccountsFragment) => approver.id,
    );

    const addedTags = tags.map(tag => tag.id);
    const audiences = linkedApps.map(app => app.id);
    try {
      const createdAccountType = await createAccountTypeMutation({
        variables: {
          type: {
            name: data.name,
            description: data.description,
            checkType: data.checkType,
            admins: addedAdmins,
            approvers: addedApprovers,
            tags: addedTags,
            audiences,
            ...approvedAndRejectIfTypeDomain(accountTypeTempData),
          },
        },
      });
      if (createdAccountType?.data?.createAccountType?.id) {
        setFeedback(NotificationType.SUCCESS);
      } else {
        setFeedback(NotificationType.FAILURE);
      }
    } catch (error) {
      setFeedback(NotificationType.FAILURE);
    }
  };

  return (
    <>
      <PageHeader
        heading='Create account type'
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'Manage account types',
            link: '/manage-account-type',
          },
          {
            name: 'Create account type',
            link: '/create-account-type',
          },
        ]}
      />
      <Container>
        {hasPermission(APP_ACCESS.CREATE_ACCOUNT_TYPE) ? (
          <CreateAccountTypeContainer>
            {feedback ? (
              <Card>
                {feedback === NotificationType.SUCCESS ? (
                  <>
                    <h5>Account Type has been added to Together With</h5>
                    <div className='body-1'>
                      We aim to review all account requests within 48 hours.
                    </div>
                  </>
                ) : (
                  <>
                    <h5>Something went wrong</h5>
                  </>
                )}

                <div className='ln-u-margin-ends*4'>
                  <Button
                    element='a'
                    variant='filled'
                    className='ln-u-margin-right*3'
                    href={routes.root}
                  >
                    Go to homepage
                  </Button>
                  <Button
                    element='button'
                    variant='outlined'
                    onClick={() => {
                      reset();
                      setAccountTypeTempData({
                        admins: [],
                        approver: [],
                        linkedApps: [],
                        tags: [],
                        roles: [],
                        isTypeDomain: true,
                        approvedDomains: [],
                        rejectedDomains: [],
                      });
                      setFeedback(null);
                    }}
                  >
                    {feedback === NotificationType.SUCCESS
                      ? 'Add another account type'
                      : 'Try again'}
                  </Button>
                </div>
              </Card>
            ) : (
              accountTypeTempData && (
                <form onSubmit={handleSubmit(submitHandler)}>
                  <h3 className='ln-u-margin-bottom*4'>Account type details</h3>
                  <Card>
                    <Section>
                      <CreateAccountTypeForm
                        formObj={formObj}
                        setApplicationTempData={setAccountTypeTempData}
                      />
                    </Section>
                    {accountTypeTempData.isTypeDomain && (
                      <DomainSection
                        disabled={false}
                        loading={false}
                        error={undefined}
                        target={{
                          id: 'N/A',
                          displayName: 'new account type',
                        }}
                        existingSelectedApprovedDomains={approvedDomains}
                        existingSelectedRejectedDomains={rejectedDomains}
                        handleApprovedConfirmation={
                          handleApprovedDomainConfirmation
                        }
                        handleRejectedConfirmation={
                          handleRejectedDomainConfirmation
                        }
                        handleApprovedRemoval={handleApprovedDomainRemoval}
                        handleRejectedRemoval={handleRejectedDomainRemoval}
                      />
                    )}
                    <AccountSelectorSection
                      loading={false}
                      error={undefined}
                      disabled={false}
                      existingAccounts={admins}
                      target={{
                        id: 'N/A',
                        displayName: 'new account type',
                        type: 'Admin',
                        origin: 'account type',
                      }}
                      handleConfirmation={handleAdminSelectionConfirmation}
                      handleRemoval={handleAdminSelectionRemoval}
                    />

                    <AccountSelectorSection
                      loading={false}
                      error={undefined}
                      disabled={false}
                      existingAccounts={approvers}
                      target={{
                        id: 'N/A',
                        displayName: 'new account type',
                        type: 'Approver',
                        origin: 'account type',
                      }}
                      handleConfirmation={handleApproverSelectionConfirmation}
                      handleRemoval={handleApproverSelectionRemoval}
                    />

                    <LinkAppsSection
                      loading={false}
                      error={undefined}
                      disabled={false}
                      linkedApps={linkedApps}
                      target={{ id: 'N/A', displayName: 'account type' }}
                      linkedAppOptions={applicationOptions || []}
                      handleConfirmation={handleLinkAppsConfirmation}
                      handleRemoval={handleLinkAppRemoval}
                    />

                    <TagsSection
                      loading={false}
                      error={undefined}
                      disabled={false}
                      tags={tags}
                      target={{ id: '', displayName: 'account type' }}
                      tagOptions={tagOptions}
                      handleConfirmation={handleTagConfirmation}
                      handleRemoval={handleTagRemoval}
                    />
                  </Card>
                  <div className='ln-u-margin-ends*4'>
                    <Button element='button' variant='filled' type='submit'>
                      Create account type
                    </Button>
                  </div>
                </form>
              )
            )}
          </CreateAccountTypeContainer>
        ) : (
          <div>you don't have access</div>
        )}
        {showModal && (
          <CreatedStatusModal
            showModal={showModal}
            handleClose={() => {
              setShowModal(false);
            }}
            isLoading={loading}
            error={error}
            accountTypeID={'data?.createAccountType?.id'}
          />
        )}
      </Container>
    </>
  );
};
