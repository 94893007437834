import DOMPurify from 'dompurify';
import React, { FC } from 'react';
import { WaysOfWorkingSubSection } from '../waysOfWorkingSubSection';
import { WaysOfWorkingSubSectionMenu } from '../waysOfWorkingSubSectionMenu';

interface IWaysOfWorkingContent {
  currentIndex: number;
  sections: any[];
}

export const WaysOfWorkingContent: FC<IWaysOfWorkingContent> = ({
  currentIndex,
  sections,
}) => {
  const currentSection = sections[currentIndex - 1];

  if (!currentSection) return null;

  const { title, body, sub_sections } = currentSection;

  const showSubSections = sub_sections?.length > 0;

  return (
    <div id={`section-${currentIndex}`}>
      <h3 data-testid='ways-of-working-section-title'>{title}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(body, {
            ADD_TAGS: ['iframe'],
            ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling'],
          }),
        }}
      />
      {showSubSections && (
        <>
          <WaysOfWorkingSubSectionMenu subSections={sub_sections} />
          {sub_sections.map((subSection: any, index: number) => (
            <WaysOfWorkingSubSection
              key={index}
              subSection={subSection}
              index={index}
            />
          ))}
        </>
      )}
    </div>
  );
};
