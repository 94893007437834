declare global {
  interface Window {
    envConfiguration: {
      authority: string;
      clientId: string;
      howItWorksSubject: string;
      twImagesEndpoint: string;
      twLongEndpoint: string;
      twShortEndpoint: string;
      env: string;
      newrelicApplicationId: string;
      newrelicLicenseKey: string;
      newrelicAccountId: string;
      newrelicTrustKey: string;
      newrelicAgentId: string;
    };
  }
}

export const envConfiguration = process.env.REACT_APP_LOAD_ENV_CONFIG
  ? window.envConfiguration
  : {
      authority: process.env.REACT_APP_AUTHORITY!,
      clientId: process.env.REACT_APP_CLIENT_ID!,
      howItWorksSubject: process.env.REACT_APP_HOW_IT_WORKS_SUBJECT!,
      twImagesEndpoint: process.env.REACT_APP_TW_IMAGES_ENDPOINT!,
      twLongEndpoint: process.env.REACT_APP_TW_LONG_ENDPOINT!,
      twShortEndpoint: process.env.REACT_APP_TW_SHORT_ENDPOINT!,
      env: process.env.REACT_APP_ENV!,
      newrelicApplicationId: process.env.REACT_APP_NR_APP_ID!,
      newrelicLicenseKey: process.env.REACT_APP_NR_LICENSE_KEY!,
      newrelicAccountId: process.env.REACT_APP_NR_ACCOUNT_ID!,
      newrelicTrustKey: process.env.REACT_APP_NR_TRUST_KEY!,
      newrelicAgentId: process.env.REACT_APP_NR_AGENT_ID!,
    };
