import React, { FC } from 'react';
import { JournalError } from 'src/components/JournalError';
import { JournalLoading } from 'src/components/JournalLoading';
import { JournalBlock } from 'src/pages/newsAndArticles/components/journalBlock';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { SupplierJournalDiv } from './supplierJournalSection.styles';

interface ISupplierJournalSection {
  journalData: any;
  journalError: any;
  journalLoading: boolean;
}

export const SupplierJournalSection: FC<ISupplierJournalSection> = ({
  journalData,
  journalError,
  journalLoading,
}) => {
  return (
    <SupplierJournalDiv>
      <SectionHeader
        heading='Supplier Journal'
        subHeading='Read the latest issue of our digital magazine exploring the work we do at Sainsbury’s'
      />

      {journalLoading ? (
        <JournalLoading />
      ) : journalError ? (
        <JournalError />
      ) : (
        <>
          {journalData?.length > 0 && (
            <JournalBlock journalData={journalData[0]} />
          )}
        </>
      )}
    </SupplierJournalDiv>
  );
};
