import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_BREXIT_UPLOAD_HISTORY_QUERY = gql`
  query {
    uploadHistory
      @rest(type: "uploadHistory", path: "api/brexit/datafeed/audits") {
      email_address
      file_name
      file_process_status
      file_processed_at
      invalid_row_numbers
      publish_failed_description
      publish_finished_at
      publish_started_at
      publish_status
      sheet_type
      total_entries
      total_invalid_entries
      total_published_entries
      total_unpublished_entries
      total_valid_entries
    }
  }
`;

export const useGetBrexitUploadUserHistory = (token: string) => {
  return useQuery(GET_BREXIT_UPLOAD_HISTORY_QUERY, {
    context: {
      clientName: EndPoint.TW_AUTHENTICATED_ENDPOINT,
      headers: {
        'authorization': `Bearer ${token}`,
      },
      fetchPolicy: 'no-cache',
    },
  });
};
