import React, { FC, useState } from 'react';
import {
  IconButton,
  Button,
  Modal,
  ModalHeading,
  TextInputField,
  ButtonGroupWrapper,
  ButtonGroupPrimary,
  FilledButton,
  OutlinedButton,
} from '@jsluna/react';
import { Section } from '../section.styles';
import { Plus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError, gql } from '@apollo/client';
import { IPermission } from 'src/interfaces/permission.interface';
import { AddNewPermissionScreen } from 'src/screens/addNewPermission';
import { PermissionDeleteRow } from 'src/shared/components/permissionRow';
import { NotificationType } from 'src/enums/notificationTypes.enum';

import {
  useDeletePermissionMutation,
  PermissionsSection_PermissionInfoFragment,
} from 'src/operations/generated/graphql';

const PlusIcon = Plus as unknown as React.FC;

interface IPermissionsSection {
  permissions: PermissionsSection_PermissionInfoFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  handleConfirmation: (permission: IPermission) => void;
  handleRemoval: (type: NotificationType) => void;
  disabled: boolean;
}

enum PanelAction {
  PERMISSION_SELECTOR = 'PERMISSION_SELECTOR',
}

gql`
  fragment PermissionsSection_PermissionInfo on PermissionProjection {
    id
    name
  }
`;

export const PermissionsSection: FC<IPermissionsSection> = ({
  permissions,
  target,
  handleConfirmation,
  handleRemoval,
  loading,
  error,
  disabled,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedPermission, setSelectedPermission] =
    useState<PermissionsSection_PermissionInfoFragment | null>(null);
  const [permissionName, setPermissionName] = useState('');

  const [deletePermissionMutation] = useDeletePermissionMutation();

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const deletePermission = async () => {
    if (!selectedPermission) return;
    try {
      const removedID = await deletePermissionMutation({
        variables: {
          id: selectedPermission.id,
        },
      });
      if (removedID?.data?.deletePermission) {
        handleRemoval(NotificationType.SUCCESS);
      } else {
        handleRemoval(NotificationType.FAILURE);
      }
    } catch (error) {
      handleRemoval(NotificationType.FAILURE);
    }
    handleReset();
  };
  const handleReset = () => {
    setShowModal(false);
    setSelectedPermission(null);
  };

  return (
    <Section>
      <h4>Permissions</h4>

      {permissions.length > 0 ? (
        <div className='permissions-section'>
          <div className='permissions-container'>
            <div className='permissions'>
              {permissions.map(permission => (
                <PermissionDeleteRow
                  permission={permission}
                  disabled={disabled}
                  deleteHandler={permission => {
                    setShowModal(true);
                    setSelectedPermission(permission);
                  }}
                  key={permission.id}
                />
              ))}
            </div>
          </div>
          <div className='section-actions'>
            <IconButton
              variant='text'
              circle={false}
              hard={false}
              element='button'
              label='Add permission'
              color='dark'
              onClick={() => setShowSidePanel(PanelAction.PERMISSION_SELECTOR)}
              disabled={disabled}
            >
              <PlusIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <>
          <div className='body-1 ln-u-margin-bottom*3'>
            There are no permissions currently associated with this{' '}
            {target.displayName}.
          </div>
          <Button
            element='button'
            variant='filled'
            onClick={() => setShowSidePanel(PanelAction.PERMISSION_SELECTOR)}
            disabled={disabled}
          >
            Add permission
          </Button>
        </>
      )}

      <Drawer
        open={showSidePanel !== null}
        onClose={handlePanelClose}
        anchor='right'
        className='add-permission'
      >
        <AddNewPermissionScreen
          applicationId={target.id}
          closeHandler={handlePanelClose}
          addHandler={handleConfirmation}
        />
      </Drawer>

      <Modal
        restrictClose
        open={showModal}
        headingId='permission-delete-confirmation'
      >
        <ModalHeading
          element='h3'
          className='name-modal'
        >{`You're deleting the permission "${selectedPermission?.name}". Please note this action can't be undone.`}</ModalHeading>
        You will lose relations associated with this permission.
        <TextInputField
          name='confirm-account-field'
          required='*'
          label='Please confirm your permission name'
          onChange={(e: any) => {
            setPermissionName(e.target.value);
          }}
        />
        <ButtonGroupWrapper className='ln-u-margin-left'>
          <ButtonGroupPrimary>
            <FilledButton
              className='ln-u-margin-right'
              disabled={permissionName !== selectedPermission?.name}
              onClick={() => deletePermission()}
            >
              Confirm
            </FilledButton>
            <OutlinedButton
              onClick={() => {
                handleReset();
              }}
            >
              Cancel
            </OutlinedButton>
          </ButtonGroupPrimary>
        </ButtonGroupWrapper>
      </Modal>
    </Section>
  );
};
