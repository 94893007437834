import { FC, useContext, useState } from 'react';
import {
  Card,
  BreadcrumbsWrapper,
  BreadcrumbsItem,
  Button,
} from '@jsluna/react';
import Container from 'src/components/Container/Container';
import { CreateAppContainer, Section } from './createApplication.styles';
import { useQuery } from '@apollo/client';
import { NavLink } from 'react-router-dom';
import { CreateApplicationForm } from 'src/components/Forms';
import { CreatedStatusModal } from './components/StatusModal';
import { IApplicationDetails } from 'src/interfaces/application.interface';
import { ITag } from 'src/interfaces/tag.interface';
import { IAccountDetailsWithProfile } from 'src/interfaces/account.interface';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import applicationSchema from 'src/components/Forms/schemas/createApplication.schema';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { routes } from 'src/constants/routes';
import { TagsSection } from '../../components/Sections/TagsSection';
import { AccountSelectorSection } from '../../components/Sections/AccountSelectorSection';
import { LinkAppsSection } from '../../components/Sections/LinkAppSection';
// import { GET_ALL_TAGS } from '../../operations/queries/getAllTags';
// import { GET_APPLICATIONS_QUERY } from '../../operations/queries/getApplications';
// import { GET_PROFILES_WITH_ACCOUNTS_QUERY } from '../../operations/queries/getProfilesWithAccounts';
import { IProfileDetailsWithAccounts } from '@interfaces/profile.interface';
import { AuthContext } from '../../providers/AuthProvider';
import { APP_ACCESS } from 'src/enums/permissions.enum';

import {
  AccountSelectorSection_AccountsFragment,
  ApplicationCreation,
  LinkAppsSection_LinkAppInfoFragment,
  TagSelection_TagsFragment,
  useApplicationsQuery,
  useCreateAccType_GetApplicationsQuery,
  useCreateApplicationMutation,
  useGet_Profiles_QueryQuery,
  useGetAllTagsQuery,
} from 'src/operations/generated/graphql';

export const CreateApplication: FC = () => {
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);

  const [createApplicationMutation, { error, loading }] =
    useCreateApplicationMutation();

  const [feedback, setFeedback] = useState<NotificationType | null>(null);

  const [admins, setAdmins] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [approvers, setApprovers] = useState<
    AccountSelectorSection_AccountsFragment[]
  >([]);
  const [linkedApps, setLinkedApps] = useState<
    LinkAppsSection_LinkAppInfoFragment[]
  >([]);
  const [tags, setTags] = useState<TagSelection_TagsFragment[]>([]);

  const { data: { tags: tagOptions = [] } = {} } = useGetAllTagsQuery();

  const { data: { applications: applicationOptions = null } = {} } =
    useCreateAccType_GetApplicationsQuery();

  const {
    data: { paginatedProfiles: { profiles: profileOptions = [] } = {} } = {},
  } = useGet_Profiles_QueryQuery();

  const handleLinkAppsConfirmation = async (
    appsSelected: LinkAppsSection_LinkAppInfoFragment[],
  ) => {
    setLinkedApps([...linkedApps, ...appsSelected]);
  };
  const handleLinkAppRemoval = async (appId: string) => {
    const filteredApps = linkedApps.filter(app => app.id !== appId);
    setLinkedApps(filteredApps);
  };

  const handleTagConfirmation = async (
    tagsSelected: TagSelection_TagsFragment[],
  ) => {
    setTags([...tags, ...tagsSelected]);
  };

  const handleTagRemoval = async (tagId: string) => {
    const filteredTags = tags.filter(tag => tag.id !== tagId);
    setTags(filteredTags);
  };

  const handleApproverSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    setApprovers([...approvers, ...accountsSelected]);
  };

  const handleApproverSelectionRemoval = async (accountId: string) => {
    const filteredAccounts = approvers.filter(
      account => account.id !== accountId,
    );
    setApprovers(filteredAccounts);
  };

  const handleAdminSelectionConfirmation = async (
    accountsSelected: AccountSelectorSection_AccountsFragment[],
  ) => {
    setAdmins([...admins, ...accountsSelected]);
  };
  const handleAdminSelectionRemoval = async (accountId: string) => {
    const filteredAccounts = admins.filter(account => account.id !== accountId);
    setAdmins(filteredAccounts);
  };

  const [showModal, setShowModal] = useState(false);

  const formObj = useForm({
    resolver: yupResolver(applicationSchema),
  });

  const {
    handleSubmit,
    formState: { errors },
    reset,
  } = formObj;

  const submitHandler = async (data: any) => {
    const adminIds = admins.map(admin => admin.id);
    const approverIds = approvers.map(approver => approver.id);
    const tagIds = tags.map(tag => tag.id);
    const linkedAppIds = linkedApps.map(app => app.id);

    try {
      const createdApplication = await createApplicationMutation({
        variables: {
          application: {
            acronym: data.acronym,
            name: data.name,
            shortDescription: data.shortDescription,
            longDescription: data.longDescription,
            proxyLocation: data.proxyLocation,
            isLive: data.isLive == 'yes',
            prospectiveAllowed: data.prospectiveAllowed === 'yes',
            admins: adminIds,
            approvers: approverIds,
            tags: tagIds,
            linkedApplications: linkedAppIds,
            type: data.type,
          },
        },
      });

      if (createdApplication?.data?.createApplication?.id) {
        setFeedback(NotificationType.SUCCESS);
      } else {
        setFeedback(NotificationType.FAILURE);
      }
    } catch (error) {
      setFeedback(NotificationType.FAILURE);
    }
  };

  return (
    <Container>
      {hasPermission(APP_ACCESS.CREATE_APPLICATION) ? (
        <CreateAppContainer>
          <BreadcrumbsWrapper className='breadcrumbs-wrapper'>
            <BreadcrumbsItem>
              <NavLink to='/'>Home</NavLink>
            </BreadcrumbsItem>
            <BreadcrumbsItem>
              <NavLink to='/applications'>Applications</NavLink>
            </BreadcrumbsItem>

            <BreadcrumbsItem active>new application</BreadcrumbsItem>
          </BreadcrumbsWrapper>
          {feedback ? (
            <Card>
              {feedback === NotificationType.SUCCESS ? (
                <>
                  <h5>Application has been added to Together With</h5>
                  <div className='body-1'>
                    We aim to review all account requests within 48 hours.
                  </div>
                </>
              ) : (
                <>
                  <h5>Something went wrong</h5>
                </>
              )}

              <div className='ln-u-margin-ends*4'>
                <Button
                  element='a'
                  variant='filled'
                  className='ln-u-margin-right*3'
                  href={routes.root}
                >
                  Go to homepage
                </Button>
                <Button
                  element='button'
                  variant='outlined'
                  onClick={() => {
                    reset();
                    setFeedback(null);
                  }}
                >
                  {feedback === NotificationType.SUCCESS
                    ? 'Add another app'
                    : 'Try again'}
                </Button>
              </div>
            </Card>
          ) : (
            <form onSubmit={handleSubmit(submitHandler)}>
              <Card>
                <Section>
                  <CreateApplicationForm formObj={formObj} />
                </Section>

                <AccountSelectorSection
                  loading={false}
                  error={undefined}
                  existingAccounts={admins}
                  target={{
                    id: 'N/A',
                    displayName: 'new application',
                    type: 'Admin',
                    origin: 'application',
                  }}
                  disabled={false}
                  handleConfirmation={handleAdminSelectionConfirmation}
                  handleRemoval={handleAdminSelectionRemoval}
                />

                <AccountSelectorSection
                  loading={false}
                  error={undefined}
                  existingAccounts={approvers}
                  target={{
                    id: 'N/A',
                    displayName: 'new application',
                    type: 'Approver',
                    origin: 'application',
                  }}
                  disabled={false}
                  handleConfirmation={handleApproverSelectionConfirmation}
                  handleRemoval={handleApproverSelectionRemoval}
                />

                {!!applicationOptions && (
                  <LinkAppsSection
                    loading={false}
                    error={undefined}
                    linkedApps={linkedApps}
                    target={{ id: 'N/A', displayName: 'new application' }}
                    linkedAppOptions={applicationOptions}
                    disabled={false}
                    handleConfirmation={handleLinkAppsConfirmation}
                    handleRemoval={handleLinkAppRemoval}
                  />
                )}

                <TagsSection
                  loading={false}
                  error={undefined}
                  tags={tags}
                  target={{ id: '', displayName: 'new application' }}
                  tagOptions={tagOptions}
                  disabled={false}
                  handleConfirmation={handleTagConfirmation}
                  handleRemoval={handleTagRemoval}
                />
              </Card>

              <div className='ln-u-margin-ends*4'>
                <Button element='button' variant='filled' type='submit'>
                  Add app to Together With
                </Button>
              </div>
            </form>
          )}
        </CreateAppContainer>
      ) : (
        <div>you do not have access</div>
      )}

      {showModal && (
        <CreatedStatusModal
          showModal={showModal}
          handleClose={() => {
            setShowModal(false);
          }}
          isLoading={loading}
          error={error}
          appID={'data?.createApplication?.id'} //TODO: update this at some point
        />
      )}
    </Container>
  );
};
