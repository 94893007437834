import styled from 'styled-components';
import { Card } from '@jsluna/react';
import { ThemeColors } from '../../shared/styles/variables';

export const DetailsCard = styled(Card)`
  position: relative;
  padding: 42px;
  display: flex;
  flex-direction: column;

  h4 {
    &:not(:first-of-type) {
      border-top: 1px solid ${ThemeColors.sectionSeparator};
      padding-top: 24px;
    }
  }

  .section-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 720px) {
      flex-direction: column-reverse;
      align-items: start;
    }
  }

  .section-actions button {
    @media (max-width: 720px) {
      width: -webkit-fill-available;
    }
  }
`;
