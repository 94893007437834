import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const StatusDiv = styled('div')`
  display: flex;
  padding: 12px 16px;
  .chb-display {
    background: ${ThemeColors.chbBackground};
    border-radius: 3px;
    height: 24px;
    width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: ${ThemeColors.white};
    }
  }

  .label {
    margin-left: 16px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${ThemeColors.monochromeDark};
  }

  .requested-pill {
    background: ${ThemeColors.primaryFocus};
    border-radius: 1000px;
    padding: 2px 16px;
    margin-left: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }

  .rejected-pill {
    background: ${ThemeColors.red};
    border-radius: 1000px;
    padding: 2px 16px;
    margin-left: 20px;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: #ffffff;
  }
`;
