import React, { FC } from 'react';
import Container from 'src/components/Container/Container';
import { PageHeader } from 'src/components/PageHeader';
import { Card, GridWrapper, GridItem, FilledButton } from '@jsluna/react';
import { PrivacyContainer } from './privacy.styles';
import { useNavigate } from 'react-router-dom';

export const Privacy: FC = () => {
  let navigate = useNavigate();
  return (
    <>
      <PageHeader
        heading={`Privacy Policy`}
        breadcrumbLinks={[
          {
            name: 'Home',
            link: '/',
          },
          {
            name: 'Privacy Policy',
            link: '',
          },
        ]}
      />

      <Container className='ln-u-hard-top'>
        <Card>
          <GridWrapper
            matrix
            horizontalAlign='center'
            className='ln-u-soft-top-xl'
          >
            <GridItem size={{ default: '5/6', lg: '3/4' }}>
              <PrivacyContainer>
                <p>
                  We understand that privacy and the security of your personal
                  information is extremely important. This policy sets out what
                  we do with your information, where and how we collect it, as
                  well as your rights over any personal information we hold
                  about you.
                </p>
                <h2 className='ln-u-display-1'>Who are we</h2>
                <p>
                  When we say &#39;we&#39; or &#39;us&#39; in this policy,
                  we&#39;re generally referring to Sainsbury&#39;s Supermarkets
                  Ltd (registered office: 33 Holborn, London, EC1N 2HT).
                </p>
                <h3 className='ln-u-display-1'>
                  What sort of information do we hold?
                </h3>
                <ul className='ln-u-padding-left*4'>
                  <li>
                    Information that you provide to us when you open a user
                    account such as your name, email address and job role;
                  </li>
                  <li>Information about your use of the platform;</li>
                  <li>
                    Your account login details, including your user name which
                    is your email
                  </li>
                  <li>
                    Information about the services that we provide to you and
                    the products you purchase;
                  </li>
                  <li>Your contact details; and</li>
                  <li>
                    Any written communication on this site, will be stored for
                    the duration of our retention policy
                  </li>
                </ul>
                <h3 className='ln-u-display-1'>
                  Our legal basis for processing your personal information
                </h3>
                <p>
                  Whenever we process your personal information we have to have
                  something called a &quot;legal basis&quot; for what we do. The
                  different legal bases we rely on are:
                </p>
                <ul className='ln-u-padding-left*4'>
                  <li>
                    <strong>Consent</strong>: You have told us you are happy for
                    us to process your personal information for a specific
                    purpose;
                  </li>
                  <li>
                    <strong>Legitimate interests</strong>: The processing is
                    necessary for us to conduct our business, but not where our
                    interests are overridden by your interests or rights.
                  </li>
                  <li>
                    <strong>Performance of a contract</strong>: We must process
                    your personal information in order to be able to provide you
                    with one of our products or services;
                  </li>
                  <li>
                    <strong>Prevention of fraud</strong>: Where we are required
                    to process your data in order to protect us and our
                    customers from fraud or money laundering;
                  </li>
                  <li>
                    <strong>Vital interests</strong>: The processing of your
                    personal information is necessary to protect you or someone
                    else&#39;s life;
                  </li>
                  <li>
                    <strong>Public information</strong>: Where we process
                    personal information which you have already made public;
                  </li>

                  <li>
                    <strong>Legal claims</strong>: The processing of your
                    personal information is necessary for the establishment,
                    exercise or defence of legal claims or whenever courts are
                    acting in their judicial capacity; and
                  </li>
                  <li>
                    <strong>Legal obligation</strong>: We are required to
                    process your personal information by law.
                  </li>
                </ul>
                <h3 className='ln-u-display-1'>
                  How do we use your information?
                </h3>
                <p>
                  There are a number of ways in which we use your personal
                  information, depending on how you interact with us. We may use
                  your information in the following ways:
                </p>
                <p>
                  <strong>Account creation and maintenance</strong> - we will
                  use your data to onboard you as a supplier and to manage our
                  ongoing relationship.
                </p>
                <p>
                  <strong>To provide information and support</strong> - we need
                  to use your personal information to make information and
                  updates available to you via your secure log in.
                </p>
                <p>
                  <strong>To optimise your experience</strong> - we try to
                  understand our suppliers so we can provide you with a great
                  tool that gives you engaging ways to access tools you need to
                  work with us, interesting content and relevant information.
                  Understanding how you interact with the content and browse our
                  webpages helps us to do this.
                </p>
                <p>
                  <strong>For safety and security</strong> - we use your
                  personal information to help provide safe and secure
                  environments for our colleagues to work in, our customers to
                  shop in and for our businesses to be conducted. To enable this
                  we carry out checks to help us ensure that our customers are
                  genuine to prevent fraud and to help customers use our
                  services appropriately.
                </p>
                <p>
                  <strong>Analytics and profiling</strong> - we use your
                  personal information for statistical analysis and to help us
                  understand more about our suppliers, farmers and growers. That
                  includes understanding the information and content that is
                  most regularly accessed, how it is accessed and who by e.g.
                  which business roles. This helps us to serve you better and to
                  find ways to improve our offer and websites so that you can
                  use them to help deliver value to our customers.
                </p>
                <p>
                  <strong>Contacting you</strong> - we use your personal
                  information to contact you: for example, by email, to update
                  you on newly available content that is relevant to you. We may
                  also contact you in relation to any questions you have raised
                  with us, either via email or within the platform and to
                  discuss the status of your account with us or to respond to
                  content access requests.
                </p>
                <h3 className='ln-u-display-1'>
                  Cookies and similar technologies
                </h3>
                <p>
                  We use cookies to help give you the best experience on our
                  websites and to allow us and third parties to tailor ads you
                  see on ours and other websites. For more information please
                  see our full{' '}
                  <a
                    // href={SAINSBURYS_COOKIE_POLICY_LINK.link}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    Cookie policy
                  </a>
                  .
                </p>
                <h3 className='ln-u-display-1'>
                  Who we might share your information with
                </h3>
                <div className='ln-u-push-top'>
                  <h3 className='ln-u-display-1 ln-u-flush-bottom'>
                    <em>The Sainsbury&#39;s Group</em>
                  </h3>
                  <div>
                    If appropriate we may share your personal information with
                    the other companies within the Sainsbury&#39;s Group
                  </div>
                </div>

                <div className='ln-u-push-top'>
                  <h3 className='ln-u-display-1 ln-u-flush-bottom'>
                    <em>Our service providers</em>
                  </h3>
                  <div>
                    We work with partners and suppliers so that they can help us
                    provide the products and services you require from us. These
                    third parties process your personal information on our
                    behalf and are required to meet our standards of security
                    before doing so. We only share information that allows them
                    to provide their services to us or to facilitate them
                    providing their services to you.
                  </div>
                </div>

                <div className='ln-u-push-top'>
                  <h3 className='ln-u-display-1 ln-u-flush-bottom'>
                    <em>Other organisations and individuals</em>
                  </h3>
                  <div>
                    we may share your personal information in certain scenarios.
                    For example:
                  </div>
                  <ul className='ln-u-padding-left*4'>
                    <li>
                      If we&#39;re discussing selling or transferring part or
                      all of a Sainsbury&#39;s Group business, we may share
                      information about you to prospective purchasers and their
                      advisers - but only so they can evaluate the relevant
                      business; or
                    </li>
                    <li>
                      If we are reorganised or sold to another organisation, we
                      may transfer information we hold about you to them so they
                      can continue to provide the Services to you.
                    </li>
                    <li>
                      If we are required to by law, under any code of practice
                      by which we are bound or where we are asked to do so by a
                      public or regulatory authority such as the police or the
                      Department for Work and Pensions;
                    </li>
                    <li>
                      If we need to do so in order to exercise or protect our
                      legal rights, users, systems and services; or
                    </li>
                    <li>
                      In response to requests from individuals (or their
                      representatives) seeking to protect their rights or the
                      rights of others. We will only share your personal
                      information in response to requests which do not override
                      your privacy interests. For example, we will not share
                      your personal information with individuals who are merely
                      curious about you, but we will share your personal
                      information to e.g. insurers, solicitors, employers etc.
                      which have a legitimate interest in your personal
                      information.
                    </li>
                  </ul>
                </div>
                <h3 className='ln-u-display-1'>
                  International transfers of your data
                </h3>
                <p>
                  From time to time we transfer your personal information to our
                  Group companies, suppliers or service providers based outside
                  of the EEA for the purposes described in this privacy policy
                  (please see the &quot;Who might we share your personal
                  information with?&quot; section above for further details).
                  When we do this, your personal information will continue to be
                  subject to one or more appropriate safeguards set out in the
                  law. These might be the use of model contracts in a form
                  approved by regulators, or having our suppliers sign up to an
                  independent privacy scheme approved by regulators (like the US
                  &#39;Privacy Shield&#39; scheme).
                </p>
                <h3 className='ln-u-display-1'>
                  International transfers of your data
                </h3>
                <p>
                  From time to time we transfer your personal information to our
                  Group companies, suppliers or service providers based outside
                  of the EEA for the purposes described in this privacy policy
                  (please see the &quot;Who might we share your personal
                  information with?&quot; section above for further details).
                  When we do this, your personal information will continue to be
                  subject to one or more appropriate safeguards set out in the
                  law. These might be the use of model contracts in a form
                  approved by regulators, or having our suppliers sign up to an
                  independent privacy scheme approved by regulators (like the US
                  &#39;Privacy Shield&#39; scheme).
                </p>
                <h3 className='ln-u-display-1'>Your rights</h3>
                <p>
                  You have a number of rights under data protection legislation
                  which, in certain circumstances, you may be able to exercise
                  in relation to the personal information we process about you.
                </p>
                <p>These include:</p>
                <ul className='ln-u-padding-left*4'>
                  <li>
                    the right to access a copy of the personal information we
                    hold about you;
                  </li>
                  <li>
                    the right to correction of inaccurate personal information
                    we hold about you;
                  </li>
                  <li>
                    the right to restrict our use of your personal information;
                  </li>
                  <li>the right to be forgotten;</li>
                  <li>the right of data portability; and</li>
                  <li>
                    the right to object to our use of your personal information.
                  </li>
                </ul>
                <p>
                  Where we rely on consent as the legal basis on which we
                  process your personal information, you may also withdraw that
                  consent at any time.
                </p>
                <p>
                  If you are seeking to exercise any of these rights, please
                  contact us using the details in the &quot;Contact Us&quot;
                  section below. Please note that we will need to verify your
                  identity before we can fulfil any of your rights under data
                  protection law. This helps us to protect the personal
                  information belonging to our customer against fraudulent
                  requests.
                </p>
                <h3 className='ln-u-display-1'>
                  Automated decision making and profiling
                </h3>
                <p>
                  We use automated decision making, including profiling, in
                  certain circumstances, such as when it is in our legitimate
                  interests to do so, or where we have a right to do so because
                  it is necessary for us to enter into, and perform, a contract
                  with you. We use profiling to enable us to give you the best
                  service across the Sainsbury&#39;s Group, including specific
                  marketing which we believe you will be interested in.
                </p>
                <p>
                  You have the right not to be subject to a decision based
                  solely on automated processing, including profiling, which has
                  legal effects for you or affects you in any other significant
                  way.
                </p>
                <p>
                  If you are seeking to exercise this right, please contact us
                  using the details in the &quot;Contact Us&quot; section below.
                </p>
                <h3 className='ln-u-display-1'>
                  How long do we keep your personal information for?
                </h3>
                <p>
                  We will keep your personal information for the purposes set
                  out in this privacy policy and in accordance with the law and
                  relevant regulations. We will never retain your personal
                  information for longer than is necessary. In most cases, our
                  retention period will come to an end 7 years after the end of
                  your relationship with us.
                </p>

                <h3 className='ln-u-display-1'>Security</h3>
                <p>
                  We take protecting your personal information seriously and are
                  continuously developing our security systems and processes.
                  Some of the controls we have in place are:
                </p>
                <ul className='ln-u-padding-left*4'>
                  <li>
                    We limit physical access to our buildings and user access to
                    our systems to only those that we believe are entitled to be
                    there;
                  </li>
                  <li>
                    We use technology controls for our information systems, such
                    as firewalls, user verification, strong data encryption, and
                    separation of roles, systems & data;
                  </li>
                  <li>
                    Systems are proactively monitored through a &quot;detect and
                    respond&quot; information security function;
                  </li>
                  <li>
                    We utilize industry &quot;good practice&quot; standards to
                    support the maintenance of a robust information security
                    management system; and
                  </li>
                  <li>
                    We enforce a &quot;need to know&quot; policy, for access to
                    any data or systems.
                  </li>
                </ul>
                <h3 className='ln-u-display-1'>Contact us</h3>
                <p>
                  If you have a question or a complaint about this policy, or
                  the way your personal information is processed, please email{' '}
                  <a href='mailto:privacy@sainsburys.co.uk'>
                    privacy@sainsburys.co.uk
                  </a>
                </p>
                <h3 className='ln-u-display-1'>Policy change</h3>
                <p>
                  This privacy policy was most recently updated in January 2022
                </p>
              </PrivacyContainer>
            </GridItem>
          </GridWrapper>

          <GridWrapper matrix horizontalAlign='center'>
            <GridItem size={{ default: '5/6', lg: '3/4' }}>
              <FilledButton
                className='ln-u-padding-sides*4 t-privacy-policy-back-button'
                onClick={() => navigate(-1)}
              >
                <span>Go back</span>
              </FilledButton>
            </GridItem>
          </GridWrapper>
        </Card>
      </Container>
    </>
  );
};
