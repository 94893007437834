import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Container from 'src/components/Container/Container';

import { PageHeader } from '../../components/PageHeader';
import { DetailsRow } from 'src/shared/components/detailsRow';
import { DetailsCard, NotificationContainer } from './notifications.styles';
import { Section } from '../../components/Sections/section.styles';
import { gql } from '@apollo/client';
import { Accordion, AccordionItem } from '@jsluna/react';
import { AccessRequest } from './components/AccessRequest';
import { Card } from '@mui/material';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { getFeedBack } from 'src/redux/selectors';
import { setHideFeedback } from 'src/redux/reducer';

import {
  useNotifications_AccountQuery,
  useNotifications_NotificationsByAccountQuery,
  Notification_InfoFragment,
} from 'src/operations/generated/graphql';

gql`
  fragment Notifications_AccountInfo on AccountProjection {
    id
    name
    accountTypeId
    isPartner
    partnerName
    partnerContact
    accountType {
      ...AccountRequestNotification_AccountTypeDetails
      checkType
    }
    profile {
      value {
        firstName
        lastName
        emailAddress
      }
    }
  }
`;

gql`
  fragment Notification_Info on NotificationProjection {
    entityId
    uid
    context
    message
    readIds
    heading
    type
    createdAt
    audience
    audienceId
    authorId
    category
    targetId
    sourceId
  }
`;

gql`
  query Notifications_Account($id: String!) {
    account(id: $id) {
      ...Notifications_AccountInfo
    }
  }
`;

gql`
  query Notifications_NotificationsByAccount(
    $page: Float!
    $filters: NotificationFilters
  ) {
    getNotifications(page: $page, filters: $filters) {
      unreadCount
      page
      data {
        ...Notification_Info
        assignedTo {
          id
          profile {
            value {
              firstName
              lastName
            }
          }
        }
      }
    }
  }
`;

export const Notifications: FC = () => {
  const dispatch = useAppDispatch();
  const feedback = useAppSelector(getFeedBack);

  const groupBy =
    <T extends Record<string, any>[]>(key: string) =>
    (array: T): Record<string, T> =>
      array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

  const { targetId: id } = useParams();

  const [contexts, setContexts] = useState<{ [key: string]: any[] }>({});

  const [activeNotifications, setActiveNotifications] = useState<
    Notification_InfoFragment[]
  >([]);

  const {
    data: { getNotifications: notifications = { data: [] } } = {},
    refetch,
  } = useNotifications_NotificationsByAccountQuery({
    variables: {
      page: 1,
      filters: {
        targetId: id,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: { account: accountData = null } = {} } =
    useNotifications_AccountQuery({
      variables: {
        id: id || '',
      },
    });

  useEffect(() => {
    if (!notifications?.data.length) return;
    setActiveNotifications(notifications.data);
    setContexts(groupBy('context')(activeNotifications));
  }, [notifications]);

  useEffect(() => {
    if (feedback?.targetId && feedback.type === NotificationType.SUCCESS) {
      const optimisticUpdates = activeNotifications.filter(
        notification => notification.sourceId !== feedback?.targetId,
      );
      setActiveNotifications(optimisticUpdates);
    }
  }, [feedback]);

  useEffect(() => {
    setContexts(groupBy('context')(activeNotifications));
  }, [activeNotifications]);

  return (
    <NotificationContainer>
      <PageHeader
        heading={`${accountData?.name} notifications`}
        breadcrumbLinks={[
          { name: 'home', link: '/' },
          { name: 'Notifications', link: '/notifications' },
          { name: `${accountData?.name}` || '', link: '' },
        ]}
      />
      {feedback && (
        <div className='feedback-container'>
          <NotificationBanner
            loading={false}
            messageType={feedback.type}
            message={feedback.message}
            onClose={() => {
              dispatch(setHideFeedback());
            }}
          />
        </div>
      )}

      {accountData ? (
        <Container size='md'>
          <DetailsCard>
            <div className='profile-section'>
              <Card className='profile-card'>
                <div className='fixed-div'>
                  <Section>
                    <h4 className='display-1 ln-u-margin-bottom*3'>
                      Profile details
                    </h4>
                    <DetailsRow
                      name='firstname'
                      value={accountData?.profile?.value.firstName || ''}
                    />
                    <DetailsRow
                      name='Lastname'
                      value={accountData?.profile?.value.lastName || ''}
                    />
                    <DetailsRow
                      name='Email address:'
                      value={accountData?.profile?.value.emailAddress || ''}
                    />
                  </Section>
                  <Section>
                    <h4 className='display-1 ln-u-margin-bottom*3'>
                      Account details
                    </h4>
                    <DetailsRow name='Name:' value={accountData?.name || ''} />
                    <DetailsRow
                      name='Account type:'
                      value={accountData?.accountTypeId || 'NOT AVAILABLE'}
                    />
                    <DetailsRow
                      name='isPartner:'
                      value={
                        accountData?.isPartner?.toString() || 'NOT AVAILABLE'
                      }
                    />
                    {accountData?.isPartner && (
                      <>
                        <DetailsRow
                          name='Partner name:'
                          value={
                            accountData?.partnerName?.toString() ||
                            'NOT AVAILABLE'
                          }
                        />
                        <DetailsRow
                          name='Partner contact:'
                          value={
                            accountData?.partnerContact?.toString() ||
                            'NOT AVAILABLE'
                          }
                        />
                      </>
                    )}
                  </Section>
                </div>
              </Card>
            </div>

            <div className='notification-section'>
              <Accordion style={'margin-top:25px'} titleElement='h3'>
                {contexts ? (
                  Object.keys(contexts).map(context => (
                    <AccordionItem
                      key={context}
                      id={`accordion-${context}`}
                      title={
                        context.replace(/_/g, ' ') +
                        ` (${contexts[context].length})`
                      }
                      className='account-type-accordion ln-u-margin-bottom*3'
                    >
                      {contexts[context].map((notification, i) => (
                        <>
                          <AccessRequest
                            notification={{ ...notification, context }}
                            key={`accessRequest-${notification.entityId}`}
                            accountDetails={accountData}
                            refetchNotification={() => {
                              refetch();
                            }}
                          />
                          {i + 1 !== contexts[context].length && <hr />}
                        </>
                      ))}
                    </AccordionItem>
                  ))
                ) : (
                  <>All approved</>
                )}
              </Accordion>
            </div>
          </DetailsCard>
        </Container>
      ) : (
        <p>No Data</p>
      )}
    </NotificationContainer>
  );
};
