import styled from 'styled-components';
import { Card } from '@jsluna/react';
import ContentLoader from 'react-content-loader';

export const NotificationLoading = styled((props: any) => (
  <ContentLoader {...props} />
))``;

export const NotificationWrapper = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;

  &.success {
    background-color: #296f2c;
  }
  &.failure {
    background-color: #d50000;
  }

  .feedback-zone {
    height: 0;
    transition: all 0.5s ease-in-out;
    max-width: 1400px;
    margin: 0 auto;

    font-family: 'Arial';
    font-size: 16px;
    color: #ffffff;

    display: flex;
    align-items: center;

    padding: 12px 0;
    height: 48px;

    svg {
      margin: 0 14px;
    }

    button {
      margin-left: auto;
      padding: 0;
      min-height: 24px;
      line-height: 1;

      svg {
        height: 24px;
        width: 24px;
        padding: 0;
        color: #fff;
      }
    }
  }
`;
