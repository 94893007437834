import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_TRADING_TERMS_QUERY = gql`
  query {
    tradingTerms @rest(type: "tradingTerms", path: "api/trading_terms.get") {
      created_at
      revision_date
      sections
      title
      uid
      updated_at
      _version
    }
  }
`;

export const useGetTradingTerms = () => {
  return useQuery(GET_TRADING_TERMS_QUERY, {
    context: { clientName: EndPoint.TW_AUTHENTICATED_ENDPOINT },
  });
};
