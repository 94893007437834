import { FC } from 'react';
import { List, ListItem } from '@jsluna/react';
import { ArrowRight } from '@jsluna/icons';
import { SubNavigationMenu } from '../subNavigation';
import { SideMenuWrapper } from './sideMenuNavigation.styles';
import { IconProps } from 'src/interfaces/icon.interface';

interface ISideNavigation {
  showNumberInTitle: boolean;
  navigationList: string[];
  setCurrentIndex: (section: any, sub_section?: any) => void;
  currentIndex: number;
  navigationSubMenuList: {};
  showSubMenuList: boolean;
  setSubSection: (index: number) => void;
}

const ArrowRightIcon = ArrowRight as unknown as React.FC<IconProps>;
export const SideNavigation: FC<ISideNavigation> = ({
  navigationList,
  showNumberInTitle,
  setCurrentIndex,
  currentIndex,
  navigationSubMenuList,
  showSubMenuList,
  setSubSection,
}) => {
  return (
    <SideMenuWrapper>
      <List
        type='bare'
        className='ln-u-display-flex ln-u-flex-direction-col navigation-mobile'
      >
        {navigationList.map((item, index) => {
          const itemIndex = index + 1;
          return (
            <ListItem key={index} className='navigation-mobile__item'>
              <div className='ln-u-display-flex ln-u-flex-direction-row ln-u-align-items-center'>
                <div className='ln-u-display-flex ln-u-flex-1'>
                  <span
                    className={`ln-u-font-weight-bold ln-u-display-flex ln-u-flex-1 side-nav__item t-side-nav-${itemIndex} ${
                      currentIndex === itemIndex ? 'is-active' : ''
                    }`}
                    onClick={() => setCurrentIndex(itemIndex)}
                  >
                    {showNumberInTitle ? `${itemIndex}. ${item}` : `${item}`}
                  </span>
                </div>
                <div className='ln-u-display-flex navigation__arrow-right'>
                  <ArrowRightIcon
                    className='ln-u-text-align-right'
                    aria-label='navigate'
                  />
                </div>
              </div>
              {showSubMenuList && (
                <SubNavigationMenu
                  navigationSubMenuList={navigationSubMenuList}
                  itemIndex={itemIndex}
                  setCurrentIndex={setCurrentIndex}
                  setSubSection={setSubSection}
                  currentIndex={currentIndex}
                />
              )}
            </ListItem>
          );
        })}
      </List>
    </SideMenuWrapper>
  );
};
