import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_SUPPLIER_JOURNAL_QUERY = gql`
  query ($id: String!) {
    journal(uid: $id)
      @rest(
        type: "journal"
        path: "content-projection/contentStack/supplierJournal/:uid"
      ) {
      child_type
      children
      creation_date
      summary
      tags
      teaser_image
      title
      type
      uid
    }
  }
`;

export const useGetSupplierJournal = (id: string) => {
  return useQuery(GET_SUPPLIER_JOURNAL_QUERY, {
    variables: { id },
    context: { clientName: EndPoint.PPS_REST_ENDPOINT },
  });
};
