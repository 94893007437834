import { FC, useState, useEffect } from 'react';
import { gql } from '@apollo/client';
import {
  SelectField,
  GridWrapper,
  GridItem,
  Button,
  Label,
} from '@jsluna/react';
import {
  AccountSwitcher_AccountInfoFragment,
  useAccountSwitcher_AccountOptionsQuery,
} from 'src/operations/generated/graphql';
import { Plus } from '@jsluna/icons';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constants/routes';
import { StyledLabel } from './accountSwitcher.styles';

const PlusIcon = Plus as unknown as React.FC;

gql`
  fragment AccountSwitcher_AccountInfo on AccountProjection {
    id
    name
  }
`;

gql`
  query AccountSwitcher_AccountOptions($profileId: String!) {
    profileAccounts(profileId: $profileId) {
      ...AccountSwitcher_AccountInfo
    }
  }
`;

interface IAccountOption {
  label: string;
  value: string;
}

interface IAccountSwitcher {
  selectedAccountId: string;
  setSelectedAccountId: (id: string) => void;
  profileId: string;
  accountId: string;
  details: AccountSwitcher_AccountInfoFragment;
}

export const AccountSwitcher: FC<IAccountSwitcher> = ({
  selectedAccountId,
  setSelectedAccountId,
  profileId,
  accountId,
  details,
}) => {
  const navigate = useNavigate();
  const { data: { profileAccounts = [] } = {} } =
    useAccountSwitcher_AccountOptionsQuery({
      variables: {
        profileId,
      },
      fetchPolicy: 'no-cache',
    });

  useEffect(() => {
    const accountOptionsTemp = accountOptions.map(account => {
      const accountName =
        details.id === account.value ? details.name : account.label;
      return { label: accountName, value: account.value };
    }, []);
    setAccountOptions(accountOptionsTemp as IAccountOption[]);
  }, [details]);

  const [accountOptions, setAccountOptions] = useState<IAccountOption[]>([]);

  useEffect(() => {
    if (profileAccounts.length) {
      const accountOptions = profileAccounts.map(account => {
        const accountName =
          details.id === account.id ? details.name : account.name;
        return { label: accountName, value: account.id };
      }, []);
      setAccountOptions(accountOptions as IAccountOption[]);

      if (!accountId) {
        setSelectedAccountId(profileAccounts[0].id);
      }
    }
  }, [profileAccounts]);

  return (
    <>
      <div className='account-selector'>
        <GridWrapper horizontalAlign='right' verticalAlign='middle'>
          <GridItem size={{ md: '1/2' }}>
            {accountOptions.length > 0 ? (
              <>
                <StyledLabel>
                  <Label>Select account</Label>
                  <a
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.stopPropagation();
                      navigate(
                        `${routes.requestAccount}?profileId=${profileId}`,
                      );
                    }}
                  >
                    <span>(</span> + Request account <span>)</span>
                  </a>
                </StyledLabel>
              </>
            ) : (
              <>
                <StyledLabel className='right-aligned'>
                  <a
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                      e.stopPropagation();
                      navigate(
                        `${routes.requestAccount}?profileId=${profileId}`,
                      );
                    }}
                  >
                    + Request account
                  </a>
                </StyledLabel>
              </>
            )}

            {accountOptions.length > 0 && (
              <SelectField
                name='account-switcher'
                label='Select account'
                options={accountOptions}
                defaultValue={selectedAccountId}
                onChange={async (
                  selected: React.ChangeEvent<HTMLInputElement>,
                ) => {
                  setSelectedAccountId(selected.target.value);
                }}
                hideLabel
              />
            )}
          </GridItem>
        </GridWrapper>
      </div>
    </>
  );
};
