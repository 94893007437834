import React, { FC } from 'react';
import { ArticleTeaser } from '@sainsburys-tech/suppex-article-teaser';
import { routes } from 'src/constants/routes';
import { useNavigate } from 'react-router-dom';
import { journalClickHandler } from 'src/utils/journalClickHandler';

interface IJournalArticle {
  title: string;
  summary: string;
}

interface IJournal {
  title: string;
  uid: string;
  summary: string;
  teaser_image: string;
  children: IJournalArticle[];
}

type IJournalBlock = {
  journalData: IJournal;
};

export const JournalBlock: FC<IJournalBlock> = ({ journalData }) => {
  const navigate = useNavigate();
  return (
    <div data-cy='supplierJournalSectionHeader' className='ln-u-padding-top*4'>
      <ArticleTeaser
        title='Supplier Journal'
        teaserTitle={`What's in the ${journalData.title} issue...`}
        imageTileProps={{
          onClickHandler: () =>
            navigate(`${routes.supplierJournals}/${journalData.uid}`),
          title: journalData.title,
          summary: journalData.summary,
          image: {
            url: journalData.teaser_image,
            alt: journalData.title,
            metaDesc: '',
          },
          style: 'grey',
        }}
        list={journalData.children.slice(0, 3).map((child: any) => ({
          title: child.title,
          subtitle: child.summary,
          onClickHandler: () =>
            journalClickHandler(child, navigate, journalData),
        }))}
        primaryButtonText={`Read ${journalData.title} issue`}
        primaryButtonHandler={() =>
          navigate(`${routes.supplierJournals}/${journalData.uid}`)
        }
        secondaryButtonText={'See all issues'}
        secondaryButtonHandler={() => navigate(routes.supplierJournals)}
      />
    </div>
  );
};
