import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_GSCOP_CONTENT_QUERY = gql`
  query {
    gscop @rest(type: "gscop", path: "api/gscop.get") {
      created_at
      header_title
      sections
      uid
      updated_at
      _version
    }
  }
`;

export const useGetGSCOPContent = () => {
  return useQuery(GET_GSCOP_CONTENT_QUERY, {
    context: { clientName: EndPoint.TW_AUTHENTICATED_ENDPOINT },
  });
};
