import styled from 'styled-components';
import { GridItem } from '@jsluna/react';
import { ThemeColors } from 'src/shared/styles/variables';

export const ProfileWrapper = styled('div')`
  .btn-add-profile {
    text-decoration: none;
  }

  .filter-section {
    display: flex;
  }

  .hierarchy-filter {
    margin-right: 15px;

    .ln-c-input-group {
      border-radius: 25px;
      background-image: none;
    }
  }
`;
export const RightAlignedGridItem = styled(GridItem)`
  text-align: right;
`;
export const LoadingContainer = styled('div')`
  min-height: 40vh;
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
`;

export const DownloadSection = styled('div')`
  display: flex;
  justify-content: space-between;

  .ln-c-progress-spinner {
    height: 25px;
    width: 25px;
    margin-right: 10px;
    color: ${ThemeColors.white};
  }
`;
