import React, { FC } from 'react';
import { INotification } from '@interfaces/notification.interface';
import { SiteRequest } from './SiteRequest';
import { AccessRequestType } from 'src/enums/accessRequestTypes.enum';
import { RoleRequest } from './RoleRequest';
import { AccountRequest } from './AccountRequest';
import { Notifications_AccountInfoFragment } from 'src/operations/generated/graphql';

interface IAccessRequest {
  notification: INotification;
  accountDetails: Notifications_AccountInfoFragment;
  refetchNotification: () => void;
}

export const AccessRequest: FC<IAccessRequest> = ({
  notification,
  accountDetails,
  refetchNotification,
}) => {
  const RequestPanels: { [key in AccessRequestType]: any } = {
    ACCOUNT_REQUEST: AccountRequest,
    ROLE_REQUEST: RoleRequest,
    SITE_REQUEST: SiteRequest,
  };

  const Component = RequestPanels[notification.type];

  return typeof Component !== 'undefined' ? (
    <Component
      notification={notification}
      accountDetails={accountDetails}
      refetchNotification={refetchNotification}
    />
  ) : (
    <p>No UI found for the context</p>
  );
};
