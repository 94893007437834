import React, { FC } from 'react';
import { NoContentWrapper } from './NoRecord.styles';

interface INoRecord {
  recordType?: string;
  searchTerm?: string;
  message?: string;
}

export const NoRecordCard: FC<INoRecord> = ({
  recordType,
  searchTerm,
  message,
}) => {
  return (
    <NoContentWrapper>
      <img src='img/404.svg' alt='No Results found' />

      <div className='no-content-message'>
        {searchTerm ? (
          <>
            Sorry, we couldn't find any matches for <span>{searchTerm}</span>
            <div>Please try searching with another term</div>
          </>
        ) : message ? (
          message
        ) : (
          ` No ${recordType || 'records'} found`
        )}
      </div>
    </NoContentWrapper>
  );
};
