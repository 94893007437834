import React, { FC } from 'react';
import { ProgressSpinner } from '@jsluna/react';
import { JournalLoadingContainer } from './JournalLoading.styles';
export const JournalLoading: FC = () => {
  return (
    <JournalLoadingContainer>
      <div className='loader-container'>
        <ProgressSpinner />
      </div>
    </JournalLoadingContainer>
  );
};
