import React, { useContext, useState } from 'react';
import Container from 'src/components/Container/Container';
import { PageHeader } from 'src/components/PageHeader';
import { ProgressSpinner, Button, Card } from '@jsluna/react';

import { AuthContext } from 'src/providers/AuthProvider';
import { APP_ACCESS } from 'src/enums/permissions.enum';
import { DownloadSection } from './registrations.styles';
import { getCurrentDate } from 'src/utils/getDate';

export const Registrations = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  // @ts-ignore
  const { hasPermission } = useContext(AuthContext);

  //TODO: For the table
  // const { data: { accounts: { count = 1, accounts = [] } = {} } = {} } =
  //   useQuery(GET_ACCOUNTS_FOR_REGISTRATIONS, {
  //     variables: {
  //       page: currentPage,
  //       pageSize: 10,
  //     },
  //   });

  const downloadFile = () => {
    setLoading(true);
    fetch('account-projection/account/registrations', {
      method: 'GET',
      mode: 'cors',
    })
      .then(res => res.blob())
      .then(blob => {
        const blobUrl = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = blobUrl;
        a.download = `Registrations-${getCurrentDate()}.csv`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(blobUrl);
      })
      .catch(err => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <PageHeader
        heading={`Registrations`}
        breadcrumbLinks={[
          {
            name: 'Together With',
            link: '/',
          },
          {
            name: 'Registrations',
            link: '',
          },
        ]}
        subHeading='Account details'
      />
      <Container>
        {/* TODO: For the table */}
        {/* {accounts.length > 0 && (
          <Table
            rowKey='id'
            data={accounts}
            caption='Captions'
            visuallyHiddenCaption
            className='ln-u-margin-bottom*3'
            columns={[
              {
                name: 'Id',
                accessor: (rowData: any) => ({
                  value: rowData.id,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'First name',
                accessor: (rowData: any) => ({
                  value: `${rowData.profile?.firstName}`,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Last name',
                accessor: (rowData: any) => ({
                  value: `${rowData.profile?.lastName}`,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Email',
                accessor: (rowData: any) => ({
                  value: rowData.profile?.emailAddress,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Supplier code',
                accessor: (rowData: any) => ({
                  value: rowData.sites?.supplierCode,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Company name',
                accessor: (rowData: any) => ({
                  value: rowData.organisationGroup?.name,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Approval status',
                accessor: (rowData: any) => ({
                  value: rowData.status,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Approval at',
                accessor: (rowData: any) => ({
                  value: rowData.statusChangedAt,
                }),
                hideLabel: true,
                className: 'customCol',
              },
              {
                name: 'Reason',
                accessor: (rowData: any) => ({
                  value: rowData.reason,
                }),
                hideLabel: true,
                className: 'customCol',
              },
            ]}
          />
        )}
        {count > 1 && (
          <div className='ln-u-text-align-center ln-u-margin-top*3'>
            <Pagination
              showFirstAndLast
              showPages
              current={currentPage}
              total={Math.ceil(count / 10)}
              onChange={(page: number, e: any) => {
                e.preventDefault();
                setCurrentPage(page);
              }}
            />
          </div>
        )} */}

        {hasPermission(APP_ACCESS.DOWNLOAD_REGISTRATIONS_REPORT) ? (
          <Card>
            <DownloadSection>
              <div className=''>
                <div className='display-2'>Download registrations report</div>
                <div className='body-1'>
                  Click the button to download registrations report in excel
                  format.
                </div>
              </div>
              <Button
                circle={false}
                disabled={loading}
                hard={false}
                element='button'
                variant='filled'
                onClick={downloadFile}
              >
                {loading && <ProgressSpinner />}
                Download
              </Button>
            </DownloadSection>
          </Card>
        ) : (
          <>No Permission</>
        )}
      </Container>
    </>
  );
};
