import styled from 'styled-components';
import { Card } from '@jsluna/react';

export const StatusSummary = styled(Card)`
  color: #fff;
  border-radius: 8px;
  padding: 12px 20px;
  margin-bottom: 24px;

  &.all-green {
    background: #2e7d32;
  }
  &.all-red {
    background: #d50000;
  }
  &.all-yellow {
    background: #ffd600;
  }
  &.loading {
    background: #404040;
  }
`;
