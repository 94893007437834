import styled from 'styled-components';

export const TableWrapper = styled('div')`
  .history-table {
    font-size: small;

    .publish-status {
      display: inline-table;
    }
    .completed {
      background-color: rgba(46, 125, 50, 0.5);
    }
    .failed {
      background-color: rgba(213, 0, 0, 0.5);
    }
    .in-process {
      background-color: rgba(240, 108, 0, 0.5);
    }
    .warn {
      background-color: rgba(240, 108, 0, 0.5);
    }
    .invalid-row-container {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 50px;

      @media (min-width: 960px) {
        display: block;
      }
    }
  }
`;
