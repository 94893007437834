import React, { FC, useState } from 'react';
import { IconButton, Button } from '@jsluna/react';
import { Section } from '../section.styles';
import { ITag } from '@interfaces/tag.interface';
import { Tag } from '../../Tag';
import { AddTagsPanel } from '../../../screens/addTags';
import { Plus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError, gql } from '@apollo/client';
import { TagSelection_TagsFragment } from 'src/operations/generated/graphql';

const PlusIcon = Plus as unknown as React.FC;

interface ITagsSection {
  loading: boolean;
  error: ApolloError | undefined;
  tags: TagSelection_TagsFragment[];
  target: { id: string; displayName: string };
  tagOptions: TagSelection_TagsFragment[];
  handleConfirmation: (
    tags: TagSelection_TagsFragment[],
  ) => Promise<void | boolean>;
  handleRemoval: (tagId: string) => Promise<void>;
  disabled?: boolean;
  type?: string;
}

enum PanelAction {
  TAG_SELECTOR = 'TAG_SELECTOR',
}

gql`
  fragment TagSelection_Tags on TagProjection {
    id
    name
  }
`;

export const TagsSection: FC<ITagsSection> = ({
  tags,
  target,
  tagOptions,
  handleConfirmation,
  handleRemoval,
  loading,
  error,
  disabled = true,
  type = '',
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.TAG_SELECTOR:
        return (
          <AddTagsPanel
            target={target}
            loading={loading}
            error={error}
            handleClose={handlePanelClose}
            existingTagIds={tags.map(tag => tag.id)}
            fullTagOptions={tagOptions}
            handleConfirmation={handleConfirmation}
          />
        );
    }
  };

  return (
    <Section>
      <h4>
        {type === 'account' ? 'Communication preferences/Tags' : 'Add tags'}
      </h4>
      {tags.length > 0 ? (
        <div className='tags-section'>
          <div className='tags-container'>
            <div className='tags'>
              {tags.map(tag => (
                <Tag
                  key={tag.id}
                  tag={tag}
                  loading={false}
                  disabled={disabled}
                  deleteHandler={handleRemoval}
                />
              ))}
            </div>
          </div>
          <div className='section-actions'>
            <IconButton
              variant='text'
              circle={false}
              disabled={disabled}
              hard={false}
              element='button'
              label='Add tag'
              color='dark'
              onClick={() => setShowSidePanel(PanelAction.TAG_SELECTOR)}
            >
              <PlusIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <>
          <div className='body-1 ln-u-margin-bottom*3'>
            There are no tags currently associated with this{' '}
            {target.displayName}.
          </div>
          <Button
            disabled={disabled}
            element='button'
            variant='filled'
            onClick={() => setShowSidePanel(PanelAction.TAG_SELECTOR)}
          >
            Add tags
          </Button>
        </>
      )}
      <Drawer
        open={showSidePanel !== null}
        onClose={handlePanelClose}
        anchor='right'
        className='add-application'
      >
        {renderPanel()}
      </Drawer>
    </Section>
  );
};
