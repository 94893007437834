import { FC } from 'react';
import { Controller, UseFormReturn, FieldValues } from 'react-hook-form';
import { GridWrapper, GridItem, RadioButtonField } from '@jsluna/react';
import { TextInputField as LunaTextInput, TextAreaField } from '@jsluna/react';
import { CreateApplicationWrapper } from './CreateApplicationForm.styles';
import { slugify } from 'src/utils/slugify';

interface ICreateApplicationForm {
  formObj: UseFormReturn<FieldValues, any>;
  initialValues?: any;
}

export const CreateApplicationForm: FC<ICreateApplicationForm> = ({
  formObj,
}) => {
  const {
    handleSubmit,
    formState: { errors, isValidating },
    control,
    watch,
  } = formObj;

  return (
    <CreateApplicationWrapper>
      <GridWrapper>
        <GridItem size={{ md: '1/3' }}>
          <h3 className='ln-u-margin-bottom*4'>Setup Details</h3>

          <Controller
            name='acronym'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <LunaTextInput
                {...field}
                onChange={field.onChange}
                error={errors?.acronym?.message}
                label='App acronym'
                info='This is used to display the app’s acronym in the in cards throughout Together With.'
                placeholder='Please enter'
              />
            )}
          />

          <Controller
            name='name'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <LunaTextInput
                {...field}
                onChange={field.onChange}
                error={errors?.name?.message}
                label='App name'
                info='This is used to display the app’s name in cards throughout Together With.'
                placeholder='Please enter'
              />
            )}
          />
          <LunaTextInput
            error={errors?.name?.message}
            label='App Slug'
            value={slugify(watch('name') || '')}
            disabled
          />

          <Controller
            name='shortDescription'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <LunaTextInput
                {...field}
                onChange={field.onChange}
                error={errors?.shortDescription?.message}
                label='Short description (75 character limit)'
                placeholder='Please enter'
              />
            )}
          />
          <Controller
            name='longDescription'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <TextAreaField
                {...field}
                onChange={field.onChange}
                error={errors?.longDescription?.message}
                label='Long description'
                placeholder='Please enter'
              />
            )}
          />

          <Controller
            name='type'
            control={control}
            render={({ field }) => (
              <RadioButtonField
                label='What is this type of application?'
                {...field}
                onChange={field.onChange}
                error={errors?.type?.message}
                name='radio-button-field-1'
                fullWidth
                options={[
                  { value: 'UI', label: 'UI' },
                  { value: 'SERVICE', label: 'Service' },
                  { value: 'EXT', label: 'External' },
                ]}
              />
            )}
          />

          <Controller
            name='isLive'
            control={control}
            render={({ field }) => (
              <RadioButtonField
                label='Is the app live?'
                {...field}
                onChange={field.onChange}
                error={errors?.isLive?.message}
                name='radio-button-field-2'
                fullWidth
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
            )}
          />
          <Controller
            name='prospectiveAllowed'
            control={control}
            render={({ field }) => (
              <RadioButtonField
                label='Will prospective accounts be allowed to use this app?'
                {...field}
                onChange={field.onChange}
                error={errors?.prospectiveAllowed?.message}
                name='radio-button-field-4'
                fullWidth
                options={[
                  { value: 'yes', label: 'Yes' },
                  { value: 'no', label: 'No' },
                ]}
              />
            )}
          />

          <Controller
            name='proxyLocation'
            control={control}
            defaultValue={''}
            render={({ field }) => (
              <LunaTextInput
                {...field}
                onChange={field.onChange}
                error={errors?.proxyLocation?.message}
                label='What is the proxy URL?'
                placeholder='Please enter'
              />
            )}
          />
        </GridItem>
      </GridWrapper>
    </CreateApplicationWrapper>
  );
};
