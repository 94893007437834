import { FC, useState } from 'react';
import { AlertText } from './components/alertText/alertText';
import { BecomeASupplier } from './components/becomeASupplier';
import { FeaturedAppSection } from './components/featuredAppSection';
import { TogetherWithSection } from './components/togetherWithSection';
import { WorkWithSection } from './components/workWithSection';
import { TWWrapper } from './twLandingPage.styles';

export const TWLandingPage: FC = () => {
  return (
    <>
      <TWWrapper>
        <TogetherWithSection />
        <WorkWithSection />
        <FeaturedAppSection />
        <BecomeASupplier />
      </TWWrapper>
    </>
  );
};
