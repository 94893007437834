export interface IWowGetURL {
  section?: string | null;
  sub_section?: string;
}

export const wowView = {
  getUrl: ({ section, sub_section }: IWowGetURL) =>
    `/ways-of-working${section ? `/${section}` : ''}${
      sub_section ? `/${sub_section}` : ''
    }`,
};
