import styled from 'styled-components';
import { GridItem } from '@jsluna/react';

export const ApplicationsWrapper = styled('div')`
  .btn-add-application {
    text-decoration: none;
  }

  .mobile-hidden {
    @media (max-width: 720px) {
      display: none;
    }
  }

  .app-grid-item {
    position: relative;
  }

  .application-actions {
    display: flex;
    margin-bottom: var(--ds-space-spacing-x4) !important;

    .btn-add-application {
      margin-left: auto;
      text-decoration: none;
    }
  }

  .category-card {
    cursor: pointer;
    padding: 10px;
    h5 {
      font-size: 18px;
      margin-bottom: 0;
    }

    :not(:last-child) {
      border-bottom: 1px solid #eccdb3;
    }
  }

  .custom-margin {
    margin-bottom: 48px;
  }
`;
export const RightAlignedGridItem = styled(GridItem)`
  text-align: right;
`;
export const LoadingContainer = styled('div')`
  min-height: 40vh;
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
`;
