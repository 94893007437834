import { IValidationMessages } from '@interfaces/validationMessages.interface';

export const CreateTagNameMessages: { [key: string]: IValidationMessages } = {
  name: {
    required: 'You must enter a tag name',
    min: 'Tag name must be longer than 3 characters',
  },
  description: {
    required: 'You must enter a description',
    min: 'Tag description must be longer than 5 characters',
  },
};
