import React, { FC, useEffect, useState } from 'react';
import { UpdateProfileContainer } from './updateProfileScreen.styles';
import { Controller, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import { TextInputField, AutocompleteField } from '@jsluna/react';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { IProfileDetails } from 'src/interfaces/profile.interface';
import {
  createCountryCodeOptions,
  createTitleOptions,
  getDefaultOption,
} from 'src/utils/createOptions';
import updateProfileDetailsSchema from 'src/components/Forms/schemas/updateProfileDetails.schema';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { ProfileCard_ProfileInfoFragment } from 'src/operations/generated/graphql';

interface IUpdateProfileScreen {
  handleClose: () => void;
  handleConfirmation: (status: any) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  heading: string;
  profile: ProfileCard_ProfileInfoFragment;
}

export const UpdateProfileScreen: FC<IUpdateProfileScreen> = ({
  handleConfirmation,
  handleClose,
  error,
  heading,
  loading,
  target,
  profile,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const countryCodeOptions = createCountryCodeOptions();
  const titleOptions = createTitleOptions();

  const formObj = useForm({
    resolver: yupResolver(updateProfileDetailsSchema),
    mode: 'onChange',
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    control,
    setValue,
    trigger,
  } = formObj;

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setFeedBackMessage(null);
  };

  const defaultCountryCodeOption =
    profile?.countryCode &&
    getDefaultOption(countryCodeOptions, profile.countryCode);

  const defaultTitleOption =
    profile?.title && getDefaultOption(titleOptions, profile.title);

  const handleFormSubmit = (values: any) => {
    handleConfirmation(values);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <UpdateProfileContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `The  details for ${profile.firstName}'s profile have been updated.`
                  : `Failed to update the description for ${profile.firstName}'s profile.`
              }
              saveButtonText={'Save and close'}
              onCloseHandler={handleClose}
              resetButtonText={'Further changes'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `Profile successfully updated`
                  : 'Failed to update profile'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              <h3>{heading}</h3>
            </div>
            <div className='panel-body'>
              <form
                id='update-profile'
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <Controller
                  name='title'
                  control={control}
                  render={({ field }) => (
                    <AutocompleteField
                      {...field}
                      error={errors?.title?.message}
                      label='Title'
                      options={titleOptions}
                      placeholder={profile.title}
                      defaultSelectedOption={defaultTitleOption}
                      onSelect={async (selected: any) => {
                        setValue('title', selected?.value || '', {
                          shouldDirty: true,
                        });
                        selected && (await trigger('title'));
                      }}
                    />
                  )}
                />

                <Controller
                  name='firstName'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='First Name'
                      onChange={field.onChange}
                      defaultValue={profile.firstName}
                      placeholder={profile.firstName}
                      error={errors?.firstName?.message}
                    />
                  )}
                />

                <Controller
                  name='lastName'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='Last Name'
                      onChange={field.onChange}
                      defaultValue={profile.lastName}
                      placeholder={profile.lastName}
                      error={errors?.lastName?.message}
                    />
                  )}
                />
                <Controller
                  name='preferredName'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      label='Preferred Name'
                      onChange={field.onChange}
                      defaultValue={profile.preferredName}
                      placeholder={profile.preferredName}
                      error={errors?.preferredName?.message}
                    />
                  )}
                />
                <Controller
                  name='countryCode'
                  control={control}
                  render={({ field }) => (
                    <AutocompleteField
                      {...field}
                      error={errors?.countryCode?.message}
                      label='Country code'
                      options={countryCodeOptions}
                      defaultValue={defaultCountryCodeOption}
                      placeholder={profile.countryCode}
                      onSelect={async (selected: any) => {
                        setValue('countryCode', selected?.value || '', {
                          shouldDirty: true,
                        });
                        selected && (await trigger('countryCode'));
                      }}
                    />
                  )}
                />
                <Controller
                  name='contactNumber'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      defaultValue={profile.contactNumber}
                      placeholder={profile.contactNumber}
                      error={errors?.contactNumber?.message}
                      label='Contact phone number'
                    />
                  )}
                />
              </form>
            </div>
          </>
        )}
      </UpdateProfileContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            form='update-profile'
            type='submit'
            variant='filled'
            disabled={!(isDirty && isValid)}
          >
            Update details
          </Button>
          <Button type='button' onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
