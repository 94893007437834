import React, { FC, useEffect, useState } from 'react';
import { AddNewPermissionContainer } from './addNewPermission.styles';
import { ProgressSpinner } from '@jsluna/react';

import { Button, AutocompleteField, TextInputField } from '@jsluna/react';

import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { IPermission } from 'src/interfaces/permission.interface';

import { useCreatePermissionMutation } from 'src/operations/generated/graphql';

interface IAddNewPermissionScreen {
  applicationId: string;
  closeHandler: () => void;
  addHandler: (added: IPermission) => void;
}

const addNewPermissionSchema = Yup.object().shape({
  name: Yup.string()
    .required('You must enter a  name')
    .min(1, 'Please enter a name')
    .max(35, 'Name must be less than 35 characters'),
  description: Yup.string()
    .required('You must enter a description')
    .max(75, 'Description must be at less than 75 characters long'),
});

export const AddNewPermissionScreen: FC<IAddNewPermissionScreen> = ({
  applicationId,
  closeHandler,
  addHandler,
}) => {
  const [showFeedBack, setShowFeedback] = useState<string>('');
  const [feedBackMessage, setFeedBackMessage] = useState<string>('');
  const [mutate, { error: addingError, loading: addingLoading }] =
    useCreatePermissionMutation();

  const handleAddPermission = async (values: any) => {
    try {
      const addedId = await mutate({
        variables: {
          permission: {
            name: values.name,
            description: values.description,
            applicationId,
          },
        },
      });
      if (addedId?.data?.createPermission) {
        setShowFeedback('added');
        setFeedBackMessage(`You’ve added new permission `);
        addHandler({
          id: addedId.data.createPermission.id,
          name: values.name,
          description: values.description,
          applicationId: applicationId,
          isActive: false,
        });
      }
    } catch (error) {
      setShowFeedback('failed');
      setFeedBackMessage(`Permission couldn't be added`);
    }
  };

  const handleReset = () => {
    reset();
    setShowFeedback('');
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(addNewPermissionSchema),
    mode: 'onChange',
  });

  return (
    <>
      <AddNewPermissionContainer>
        {showFeedBack !== '' ? (
          <ScreenFeedback
            isLoading={addingLoading}
            notificationType={
              showFeedBack === 'added'
                ? NotificationType.SUCCESS
                : NotificationType.FAILURE
            }
            feedBackMessage={feedBackMessage}
            notificationMessage={
              showFeedBack === 'added'
                ? 'Permission added'
                : 'Something went wrong'
            }
            saveButtonText={'Save and close'}
            onCloseHandler={closeHandler}
            resetButtonText={
              showFeedBack === 'added' ? 'Add more permissions' : 'Try again'
            }
            resetHandler={handleReset}
          />
        ) : (
          <>
            <h4>Create new permission</h4>

            <form
              id='add-new-permission'
              onSubmit={handleSubmit(handleAddPermission)}
            >
              <div className='panel-body'>
                <Controller
                  name='name'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.domain?.message}
                      info='e.g. admin'
                      label='Please name your permission'
                      placeholder='Enter your permission name'
                    />
                  )}
                />
                <Controller
                  name='description'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.domain?.message}
                      label='Description'
                      placeholder='Enter a description for the permission'
                    />
                  )}
                />
              </div>
            </form>
          </>
        )}
      </AddNewPermissionContainer>
      {showFeedBack === '' && (
        <PanelActions>
          <Button
            form='add-new-permission'
            type='submit'
            variant='filled'
            disabled={addingLoading || !isValid}
          >
            {addingLoading && <ProgressSpinner />}
            {addingLoading ? 'Adding' : 'Create permission'}
          </Button>
          <Button onClick={closeHandler} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
