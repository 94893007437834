import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_ARTICLE_FOR_NEWS_QUERY = gql`
  query ($id: String!) {
    article(uid: $id)
      @rest(
        type: "article"
        path: "content-projection/contentStack/journal/:uid"
      ) {
      body
      child_type
      creation_date
      image
      image_caption
      intro
      summary
      tags
      thumbnail_image
      title
      uid
    }
    news
      @rest(
        type: "news"
        path: "content-projection/contentStack/supplierNews?pageSize=4&page=1"
      ) {
      list
      total_results
    }
  }
`;

export const useArticleForNews = (id: string) => {
  return useQuery(GET_ARTICLE_FOR_NEWS_QUERY, {
    variables: { id },
    context: { clientName: EndPoint.PPS_REST_ENDPOINT },
  });
};
