import React, { FC, useEffect, useState } from 'react';
import { UpdateAccountTypeContainer } from './updateAccountTypeScreen.styles';
import { TextInputField } from '@jsluna/react';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

interface IHeading {
  heading: string;
  subHeading: string;
}

interface IUpdateAccountTypeDescriptionScreen {
  handleClose: () => void;
  handleConfirmation: (status: string) => Promise<void>;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  detailsHeading: IHeading;
  oldDescription: string;
}

export const UpdateAccountTypeDescriptionScreen: FC<
  IUpdateAccountTypeDescriptionScreen
> = ({
  handleConfirmation,
  handleClose,
  error,
  detailsHeading,
  loading,
  target,
  oldDescription,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setDescription('');
    setFeedBackMessage(null);
  };

  return (
    <>
      <UpdateAccountTypeContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `The account type has been updated`
                  : 'Failed to update the description for this account type.'
              }
              saveButtonText={'Close'}
              onCloseHandler={handleClose}
              resetButtonText={'Back'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `Account type updated`
                  : 'Failed to update account type'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              <h3>{detailsHeading.heading}</h3>
            </div>
            <div className='panel-body'>
              <TextInputField
                name='text-input-field-2'
                label='Description'
                defaultValue={oldDescription}
                onChange={(e: any) => setDescription(e.target.value)}
              />
            </div>
          </>
        )}
      </UpdateAccountTypeContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={() => {
              if (description !== '') {
                handleConfirmation(description);
                setFeedBackMessage(NotificationType.SUCCESS);
              }
            }}
            variant='filled'
            disabled={loading || description === ''}
          >
            Update details
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
