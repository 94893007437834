import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const PermissionSelectorContainer = styled('div')`
  width: 448px;
  padding: 24px;
  padding-bottom: 0;

  .panel-heading {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-style: normal;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    color: ${ThemeColors.black};
    margin-bottom: 18px;
    margin-top: 24px;
  }

  .ln-c-form-option__label {
    color: #404040;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  .permission-desc {
    color: #0c0c0c;
    font-family: Arial;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    margin-left: 16px;
  }
`;
