import React, { useCallback, useState, FC } from 'react';
import { TextButton } from '@jsluna/react';
import { fetchFile, fileSaver } from 'src/utils/tradingTermsUtils';
const TRADING_TERMS_AND_CONDITIONS_LINK =
  '/tw/api/resources-pdf?name=Terms and Conditions';

export const DocumentDownloader: FC = () => {
  const [loading, setLoading] = useState(false);

  const downloadHandler = () => {
    if (!loading) {
      setLoading(true);
      fetchFile(TRADING_TERMS_AND_CONDITIONS_LINK, true)
        .then((data: any) => {
          const { blob, fileName } = data;
          const href = URL.createObjectURL(blob);
          fileSaver([{ href, fileName }]);

          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
    }
  };

  return (
    <TextButton
      fullWidth
      className='ln-c-button--dark'
      disabled={loading}
      onClick={downloadHandler}
    >
      {/* <span data-cy="download-icon" className="ln-u-margin-right*2">
      <Download aria-label="Download" />
    </span> */}
      <span data-cy='download-text'>Download T&C&#39;s</span>
    </TextButton>
  );
};
