import React, { FC } from 'react';
import { NotificationBanner } from 'src/shared/components/notificationBanner/notificationBanner';
import { NotificationType } from 'src/enums/notificationTypes.enum';

interface IProfilePopupFeedback {
  notificationFor: String;
  type: NotificationType;
  onClose: () => void;
}

export const ProfilePopupFeedback: FC<IProfilePopupFeedback> = ({
  type,
  notificationFor,
  onClose,
}) => {
  return (
    <NotificationBanner
      loading={false}
      messageType={type}
      message={
        type === NotificationType.FAILURE
          ? `Error in  ${
              notificationFor === 'INVITE'
                ? 'inviting profile'
                : notificationFor === 'REVOKE'
                ? 'removing profile access'
                : notificationFor === 'ACTIVATE'
                ? 'activating AD user'
                : 're-syncing profile email'
            }`
          : `${
              notificationFor === 'INVITE'
                ? 'Profile invited'
                : notificationFor === 'REVOKE'
                ? 'Profile access removed'
                : notificationFor === 'ACTIVATE'
                ? 'Activated AD user'
                : 'Profile email re-synced'
            } successfully`
      }
      onClose={onClose}
    />
  );
};
