import React, { FC } from 'react';
import { ArticleTeaser } from '@sainsburys-tech/suppex-article-teaser';
import { routes } from 'src/constants/routes';
import { useNavigate } from 'react-router-dom';
import { journalClickHandler } from 'src/utils/journalClickHandler';

interface IJournalArticle {
  title: string;
  summary: string;
}

interface IJournal {
  title: string;
  uid: string;
  summary: string;
  teaser_image: string;
  children: IJournalArticle[];
}

type IJournalBlock = {
  data: IJournal;
  isAgriculture?: boolean;
};

export const PlanBetterBlock: FC<IJournalBlock> = ({ data, isAgriculture }) => {
  const navigate = useNavigate();
  return (
    <div data-cy='plan4BetterSectionHeader' className='ln-u-padding-top*4'>
      <ArticleTeaser
        title={isAgriculture ? 'Agriculture' : 'Plan for Better'}
        teaserTitle={`What's in the ${data.title}...`}
        imageTileProps={{
          onClickHandler: () => navigate(`${routes.plan4Better}/${data.uid}`),
          title: data.title,
          summary: data.summary,
          image: {
            url: data.teaser_image,
            alt: '',
            metaDesc: '',
          },
          style: 'grey',
        }}
        list={data.children?.slice(0, 5).map((child: any) => ({
          title: child?.title,
          subtitle: child?.summary,
          onClickHandler: () => journalClickHandler(child, navigate, data),
        }))}
        primaryButtonText={`Find out more`}
        primaryButtonHandler={() => {
          if (isAgriculture) {
            navigate(`${routes.agriculture}`);
          } else {
            navigate(`${routes.plan4Better}/${data.uid}`);
          }
        }}
      />
    </div>
  );
};
