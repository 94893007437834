import { FieldInfo } from '@jsluna/react';
import { WarningCard, WarningBody } from './CustomError.styles';

interface ICustomError {
  error: string;
  title?: string;
}

const CustomError = ({ error, title }: ICustomError) => (
  <>
    <WarningCard variant='warning'>
      <FieldInfo id='field-info-1' warning>
        {title || 'Something went wrong'}
      </FieldInfo>

      <WarningBody>{error}</WarningBody>
    </WarningCard>
  </>
);

export default CustomError;
