import { IAccount } from '@interfaces/account.interface';
import { countries } from 'countries-list';

enum ProfileTitleOptions {
  Mr = 'Mr',
  Mrs = 'Mrs',
  Ms = 'Ms',
  Miss = 'Miss',
  Mx = 'Mx',
  Dr = 'Dr',
}
enum Providers {
  AZURE = 'AZURE',
}

export interface DropDownOptions {
  label: string;
  value: string;
}

export const createCountryCodeOptions = () =>
  Object.entries(countries)
    .map(([code, country]) => ({
      label: `${country.name} (+${country.phone.split(',')[0]})`,
      value: code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const createTitleOptions = () =>
  Object.entries(ProfileTitleOptions)
    .map(([key]) => ({
      label: key,
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const getDefaultOption = (
  options: DropDownOptions[],
  initialValue: string | undefined,
) => options.find(option => option.value === initialValue);

export const getDefaultOptionCheckBox = (
  options: DropDownOptions[],
  initialValue: string[] | undefined,
) => options.filter(option => initialValue?.includes(option.value));

export const createProviderOptions = () =>
  Object.entries(Providers)
    .map(([key]) => ({
      label: key,
      value: key,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

export const createAccountOptions = (accounts: IAccount[]) =>
  accounts
    .map((account: IAccount) => ({
      label: account.name,
      value: account.id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
