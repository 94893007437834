import React, { FC } from 'react';
import { LatestHeadlinesWrapper } from './quickLinkHeadlines.styles';

interface ILink {
  name: string;
  link: string;
}

interface IQuickLinkHeadlines {
  heading: string;
  links: ILink[];
}

export const QuickLinkHeadlines: FC<IQuickLinkHeadlines> = ({
  heading,
  links,
}) => {
  return (
    <LatestHeadlinesWrapper>
      <div className='heading'>{heading}</div>
      <ul>
        {links.map((link: ILink) => (
          <li>
            <a href={link.link}>{link.name}</a>
          </li>
        ))}
      </ul>
    </LatestHeadlinesWrapper>
  );
};
