import { FC } from 'react';
import { DetailRow } from './profileDetailsRow.styles';
import { TextInputField, AutocompleteField } from '@jsluna/react';
import { DropDownOptions, getDefaultOption } from 'src/utils/createOptions';
import { UseFormReturn, Controller, FieldValues } from 'react-hook-form';

interface IProfileDetailRow {
  label: string;
  value: string;
  name: string;
  isEditable?: boolean;
  dropDownData?: DropDownOptions[];
  formObj?: UseFormReturn<FieldValues, any>;
  cssClass?: string;
  children?: React.ReactNode;
}
export const ProfileDetailRow: FC<IProfileDetailRow> = ({
  label,
  value,
  name,
  isEditable = false,
  dropDownData,
  formObj,
  cssClass,
  children,
}) => {
  const renderInputOptions = () => {
    const { formState, control, setValue, getValues, trigger } = formObj || {};
    const { errors } = formState || {};

    const defaultOption = dropDownData && getDefaultOption(dropDownData, value);

    return (
      <>
        {dropDownData ? (
          <Controller
            name={name}
            control={control}
            render={({ field }) => (
              <AutocompleteField
                {...field}
                error={errors && errors[name]?.message}
                label={label}
                options={dropDownData}
                defaultSelectedOption={defaultOption}
                placeholder={label}
                onSelect={async (selected: any) => {
                  setValue && setValue(name, selected?.value || '');
                  selected && trigger && (await trigger(name));
                }}
                hideLabel
              />
            )}
          />
        ) : (
          <Controller
            name={name}
            control={control}
            defaultValue={value}
            render={({ field }) => (
              <TextInputField
                {...field}
                onChange={field.onChange}
                error={errors && errors[name]?.message}
                label={label}
                hideLabel
              />
            )}
          />
        )}
      </>
    );
  };

  return (
    <DetailRow className={cssClass}>
      <div className='label'>{label}</div>
      <div className='value'>
        {isEditable && formObj ? renderInputOptions() : <>{value}</>}
        {children}
      </div>
    </DetailRow>
  );
};
