import * as Yup from 'yup';
import { CreateTagNameMessages } from 'src/constants/createTagValidationMessages';

export default Yup.object().shape(
  {
    name: Yup.string()
      .required(CreateTagNameMessages.name.required)
      .min(3, `${CreateTagNameMessages.name.min}`),
    description: Yup.string()
      .required(CreateTagNameMessages.description.required)
      .min(5, `${CreateTagNameMessages.description.min}`),
  },
  [],
);
