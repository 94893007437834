import { gql, useQuery } from '@apollo/client';
import { EndPoint } from 'src/enums/endPoint.enum';

export const GET_WAYS_OF_WORKING_QUERY = gql`
  query {
    wow @rest(type: "wow", path: "api/wow.get") {
      created_at
      header_title
      revision_date
      sections
      uid
      updated_at
      _version
    }
  }
`;

export const useGetWOWContent = () => {
  return useQuery(GET_WAYS_OF_WORKING_QUERY, {
    context: { clientName: EndPoint.TW_AUTHENTICATED_ENDPOINT },
  });
};
