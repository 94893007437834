import React, { FC, useState } from 'react';
import { AddNewPermissionGroupContainer } from './addNewPermissionGroup.styles';
import { ProgressSpinner } from '@jsluna/react';

import { Button, TextInputField } from '@jsluna/react';

import { ScreenFeedback } from '../components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { IPermissionGroup } from 'src/interfaces/permissionGroup.interface';
import { IPermission } from 'src/interfaces/permission.interface';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

import { useCreatePermissionGroupMutation } from 'src/operations/generated/graphql';

interface IAddNewPermissionScreen {
  applicationId: string;
  closeHandler: () => void;
  addCallback: (permissionGroup: IPermissionGroup) => void;
}

const addNewPermissionSchema = Yup.object().shape({
  name: Yup.string().required('You must enter a  name'),
  description: Yup.string().required('You must enter a description'),
});

export const AddNewPermissionGroupScreen: FC<IAddNewPermissionScreen> = ({
  applicationId,
  closeHandler,
  addCallback,
}) => {
  const [showFeedBack, setShowFeedback] = useState<string>('');
  const [feedBackMessage, setFeedBackMessage] = useState<string>('');
  const [mutate, { error: addingError, loading: addingLoading }] =
    useCreatePermissionGroupMutation();

  const handleAddPermission = async (values: any) => {
    const groupObj = {
      name: values.name,
      description: values.description,
      applicationId,
    };
    try {
      const addedId = await mutate({
        variables: {
          group: groupObj,
        },
      });
      if (addedId?.data?.createGroup) {
        setShowFeedback('added');
        setFeedBackMessage(`You’ve added new permission group`);
        addCallback({
          ...groupObj,
          applicationInternalCode: '',
          isActive: false,
          permissions: [] as IPermission[],
          id: addedId.data.createGroup.id,
        } as IPermissionGroup);
      }
    } catch (error) {
      setShowFeedback('failed');
      setFeedBackMessage(`Permission group couldn't be added`);
    }
  };

  const handleReset = () => {
    reset();
    setShowFeedback('');
  };

  const {
    handleSubmit,
    formState: { errors, isValid },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(addNewPermissionSchema),
    mode: 'onChange',
  });

  return (
    <>
      <AddNewPermissionGroupContainer>
        {showFeedBack !== '' ? (
          <ScreenFeedback
            isLoading={addingLoading}
            notificationType={
              showFeedBack === 'added'
                ? NotificationType.SUCCESS
                : NotificationType.FAILURE
            }
            feedBackMessage={feedBackMessage}
            notificationMessage={
              showFeedBack === 'added'
                ? 'Permission group added'
                : 'Something went wrong'
            }
            saveButtonText={'Close'}
            onCloseHandler={closeHandler}
            resetButtonText={
              showFeedBack === 'added'
                ? 'Add more permission group'
                : 'Try again'
            }
            resetHandler={handleReset}
          />
        ) : (
          <>
            <h4>Create new permission group</h4>

            <form
              id='add-new-permission-group'
              onSubmit={handleSubmit(handleAddPermission)}
            >
              <div className='panel-body'>
                <Controller
                  name='name'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.domain?.message}
                      info='e.g. admin'
                      label='Please name your permission group'
                      placeholder='Enter your permission group name'
                    />
                  )}
                />
                <Controller
                  name='description'
                  control={control}
                  render={({ field }) => (
                    <TextInputField
                      {...field}
                      onChange={field.onChange}
                      error={errors?.domain?.message}
                      label='Description'
                      placeholder='Enter a description for the permission group'
                    />
                  )}
                />
              </div>
            </form>
          </>
        )}
      </AddNewPermissionGroupContainer>
      {showFeedBack === '' && (
        <PanelActions>
          <Button
            form='add-new-permission-group'
            type='submit'
            variant='filled'
            disabled={addingLoading || !isValid}
          >
            {addingLoading && <ProgressSpinner />}
            {addingLoading ? 'Adding' : 'Create permission group'}
          </Button>
          <Button onClick={closeHandler} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
