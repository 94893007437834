import styled from 'styled-components';
import { Card, Heading5 } from '@jsluna/react';

export const WarningCard = styled(Card)`
  padding: 1rem;
`;

export const WarningBody = styled(Heading5)`
  margin-top: 1rem;
  margin-left: 2rem;
`;
