import React, { FC } from 'react';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { WorkingWithDiv } from './workingWithSainsburys.styles';
import { WorkingWithSainsburys } from '@sainsburys-tech/supex-working-with-sainsburys';

type IPinnedApp = {
  system_name: string;
  name: string;
  sub_category: null;
  pinned: boolean;
  link: string;
  icon: string;
  short_description: string;
  description: string;
  registration_instructions: string;
  icon_text: string;
};

// These values are not retrieved from BE since these are our internal apps
const pinnedApps = [
  {
    'system_name': 'gscop',
    'name': 'GSCOP: Your Rights Under the Code Explained',
    'sub_category': null,
    'pinned': true,
    'link': '/gscop',
    'icon':
      'https://eu-images.contentstack.com/v3/assets/blt6c5362df4c3bdbfc/blt9dd85821c61b0dbf/641a0632095d2a121aa6f5a6/gscop-icon.svg',
    'short_description':
      'See info about the Groceries Supply Code of Practice (GSCOP), your rights under it and contact information.',
    'description':
      'This contains contact details and information about the <b>Groceries Supply Code of Practice (GSCOP)</b> and your rights under it. You can also find the contact details for our teams, our code compliance office and our registered address.',
    'registration_instructions': '',
    'icon_text': '',
  },
  {
    'system_name': 'plan_for_better',
    'name': 'Help & Support',
    'sub_category': null,
    'pinned': true,
    'link': 'https://delist.hub-sainsburys.co.uk/',
    'icon':
      'https://eu-images.contentstack.com/v3/assets/blt6c5362df4c3bdbfc/blt38adcc198b90b12a/641a06645be233662d2ffc60/help-icon.svg',
    'short_description':
      'Our Help and Support pages answer common queries about Together With, apps, Terms and Conditions, GSCOP and much more.',
    'description': '<p>Not Needed.</p>',
    'registration_instructions': '',
    'icon_text': '',
  },
  {
    'system_name': 'trading_terms',
    'name': "Sainsbury's Trading Terms and Conditions for Grocery",
    'sub_category': null,
    'pinned': true,
    'link': '/trading-terms-and-conditions',
    'icon':
      'https://eu-images.contentstack.com/v3/assets/blt6c5362df4c3bdbfc/bltf7b2545bc54a3b7a/641a05f1095d2a121aa6f5a2/terms-icon.svg',
    'short_description':
      'Read and download our standard Trading Terms and Conditions for Grocery.',
    'description':
      'This contains our standard Trading Terms and Conditions for Grocery. It is important for you to read, be aware of these terms and accept them. When you accept these Trading Terms, you are giving the indication that you have the appropriate internal authority to act on behalf of your company.',
    'registration_instructions': '',
    'icon_text': 'terms icon',
  },
  {
    'system_name': 'ways_of_working',
    'name': 'Ways of Working: Our Supplier Handbook',
    'sub_category': null,
    'pinned': true,
    'link': '/ways-of-working',
    'icon':
      'https://eu-images.contentstack.com/v3/assets/blt6c5362df4c3bdbfc/blta54359a22c8369b0/641a0529e2657c080b893101/handbook-icon.svg',
    'short_description':
      'Providing your with detailed information on our policies, processes and tools that you will encounter as you trade with us.',
    'description':
      "Previously known as the supplier handbook on together with Sainsbury's. It is intended to provide you with the detailed information on all Sainsbury's policies, processes and tools that our will encounter as you trade with us.",
    'registration_instructions': '',
    'icon_text': 'Handbook icon',
  },
];

export const WorkingWithSainsburysSection: FC = () => {
  const appsOrder = [
    'gscop',
    'ways_of_working',
    'trading_terms',
    'plan_for_better',
  ];

  const sortedPinnedApps = pinnedApps.sort(
    (appA: IPinnedApp, appB: IPinnedApp) => {
      return (
        appsOrder.indexOf(appA.system_name) -
        appsOrder.indexOf(appB.system_name)
      );
    },
  );
  return (
    <WorkingWithDiv>
      <SectionHeader
        heading='Working with Sainsbury’s'
        subHeading='Our guides to help and support you when working with us'
      />
      <WorkingWithSainsburys
        title={'HIDDEN_TITLE'}
        subtitle={''}
        handlertext='Open'
        tiles={sortedPinnedApps}
      />
    </WorkingWithDiv>
  );
};
