import { URL, pageSlug } from 'src/constants/regExValidations';
import * as Yup from 'yup';

export default Yup.object().shape(
  {
    name: Yup.string()
      .required('You must enter a  name')
      .min(1, 'Please enter a name')
      .max(35, 'Name must be less than 35 characters'),
    shortDescription: Yup.string()
      .required('You must enter a description')
      .max(75, 'Description must be at less than 75 characters long'),
    longDescription: Yup.string().required('You must enter a description'),
    redirectLink: Yup.string().when('redirectLink', {
      is: (value: string) => value,
      then: Yup.string().matches(
        /((https?):\/\/)?(www\.)?[a-z0-9-]+(\.[a-z]{2,})+(\.[a-z]{2,})?(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!',
      ),
      otherwise: Yup.string(),
    }),
    acronym: Yup.string()
      .required('You must enter a acronym')
      .max(4, 'Max length 4 characters'),
    isLive: Yup.string().required('You must select a live state'),
    prospectiveAllowed: Yup.string().required(
      'You must select if prospective accounts can use this app',
    ),
    type: Yup.string().required('You must select the type of application'),
    audience: Yup.array(Yup.string()),
    tags: Yup.array(Yup.string()),
    relationships: Yup.string(),
    proxyLocation: Yup.string()
      .required('Please enter a proxy location')
      .matches(URL, 'Enter valid URL'),
  },
  [['redirectLink', 'redirectLink']],
);
