import React, { FC } from 'react';
import { AppTitles } from 'src/constants/titles.enum';
import { ProgressSpinner, FilledButton } from '@jsluna/react';
import { Link } from 'react-router-dom';
import { routes } from 'src/constants/routes';
import { useAppSelector } from 'src/redux/hooks';
import { getIsLoggedIn } from 'src/redux/selectors';
import { useNavigate } from 'react-router-dom';

export const TogetherWithSection: FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const login = () => {
    navigate('/login');
  };

  return (
    <>
      <div className='landing-page__together-with__hero-image' />
      <div className='landing-page__together-with'>
        <div className='landing-page__together-with__left-box'>
          <div className='ln-u-text-align-center landing-page__together-with__left-box__inner'>
            <h1 className='ln-u-display-6 landing-page__together-with__left-box__title ln-u-color-orange'>
              {AppTitles.APP_TITLE}
            </h1>
            <p className='landing-page__together-with__left-box__description'>
              A dedicated platform helping to manage day to day business
              activities.
            </p>
            {!isLoggedIn && (
              <div className='landing-page__together-with__navigation'>
                <FilledButton
                  onClick={login}
                  data-cy='login-button'
                  className='landing-page__together-with__navigation__button ln-c-button ln-c-button--primary cursor--pointer t-signin-button'
                >
                  Sign In
                </FilledButton>
                <Link
                  to={routes.registerForAccess}
                  data-cy='register-button'
                  className='landing-page__together-with__navigation__button ln-c-button ln-c-button--outlined'
                >
                  Register for access
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
