import { IArticle } from 'src/interfaces/article.interface';
import { IArticleChild } from 'src/interfaces/articleChild.interface';

export const formatCreationDate = (date?: string) => {
  return date
    ? new Intl.DateTimeFormat('en-GB', { dateStyle: 'long' }).format(
        new Date(date),
      )
    : null;
};

const generateGroupTag = (journalType: string) => {
  switch (journalType) {
    case 'Journal':
      return 'Supplier Journal';
    case 'Agriculture':
      return 'Agriculture';
    case 'Plan 4 Better':
    default:
      return 'Plan for Better';
  }
};

export const generateJournalTags = (journal: any) =>
  [
    generateGroupTag(journal.type),
    `${journal.children.length} articles`,
    ...journal.tags,
  ].filter(t => {
    return t !== null;
  });

export const generateJournalArticleTags = (
  article: IArticleChild,
  journal: any,
) => {
  const isJournal = journal.type === 'Journal';
  return [
    ...new Set(
      [
        ...(isJournal ? [formatCreationDate(article.creation_date)] : []),
        ...(article.tags || []),
        generateGroupTag(journal.type),
        journal.title,
      ].filter(t => {
        return t !== null;
      }),
    ),
  ];
};

export const generateNewsArticleTags = (article: IArticleChild) => {
  const tags: any[] = [];

  [formatCreationDate(article.creation_date), ...article.tags].filter(t => {
    if (t !== null) {
      tags.push(t);
    }
  });

  return tags;
};
