import styled from 'styled-components';

export const CompleteInvitationWrapper = styled('div')`
  .feedback-zone {
    padding: 12px 0;
    .notification-wrapper {
      top: 0;
    }
  }
  .sub-heading {
    font-size: 32px;
    line-height: 40px;
    font-weight: bold;
  }

  .ln-c-card {
    padding: 42px;
  }

  h3 {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-weight: bold;
    font-size: 26px;
    line-height: 32px;
    margin-bottom: 24px;
  }

  .form-wrapper {
    margin-top: 48px;
  }
`;
