import React, { FC } from 'react';
import { Accordion, AccordionItem } from '@jsluna/react';
import DOMPurify from 'dompurify';

interface ITradingTermsAndConditionsText {
  tradingTerms: any;
}

export const TradingTermsAndConditionsText: FC<
  ITradingTermsAndConditionsText
> = ({ tradingTerms }) => {
  return (
    <>
      <Accordion titleElement='h2'>
        {tradingTerms.sections.map((term: any, index: number) => (
          <AccordionItem
            key={index}
            id={String(index)}
            title={term.title}
            aria-label={`accordion-item-${index}`}
          >
            <div
              className='ln-u-body-1 trading-terms'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(term.body),
              }}
            />
          </AccordionItem>
        ))}
      </Accordion>
    </>
  );
};
