import { NavigateFunction } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router';
import { useCallback } from 'react';

export const journalClickHandler = (
  tile: any,
  navigate: NavigateFunction,
  parent?: any,
) => {
  if (tile.child_type === 'ARTICLE') {
    if (tile.tags.includes('News') || tile.tags.includes('news')) {
      navigate(`/news-and-articles/news/article/${tile.uid}`);
    } else {
      if (
        !location.pathname.includes('/journals/') &&
        !location.pathname.includes('/plan-for-better/') &&
        !location.pathname.includes('/agriculture/') &&
        parent?.uid !== undefined
      ) {
        if (parent.type === 'Journal') {
          navigate(
            `/news-and-articles/journals/${parent.uid}/article/${tile.uid}`,
          );
        } else if (parent.type === 'Plan 4 Better') {
          navigate(
            `/news-and-articles/plan-for-better/${parent.uid}/article/${tile.uid}`,
          );
        } else if (parent.type === 'Agriculture') {
          navigate(
            `/news-and-articles/agriculture/${parent.uid}/article/${tile.uid}`,
          );
        }
      } else {
        navigate(
          `${location.pathname.split('/article')[0]}/article/${tile.uid}`,
        );
      }
    }
  } else if (tile.child_type === 'ARTICLE_GROUPING') {
    if (tile.type === 'Journal') {
      navigate(`/news-and-articles/journals/${tile.uid}`);
    } else if (tile.type === 'Agriculture') {
      navigate(`/news-and-articles/agriculture/${tile.uid}`);
    } else {
      navigate(`/news-and-articles/plan-for-better/${tile.uid}`);
    }
  } else if (!tile.child_type) {
    if (tile.tags.includes('News') || tile.tags.includes('news')) {
      navigate(`/news-and-articles/news/article/${tile.uid}`);
    } else {
      navigate(`/news-and-articles/journals/${tile.uid}`);
    }
  } else {
    //external link
    window.open(tile.url, '_blank');
  }
};
