import styled from 'styled-components';
import { Card } from '@jsluna/react';
import { ThemeColors } from 'src/shared/styles/variables';

export const PopOverCard = styled((props: any) => <Card {...props} />)`
  border-radius: 8px;
  border: 1px solid #d8d8d8;
  padding: 16px;

  h5 {
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 27px;
    margin-bottom: 8px;
    cursor: pointer;
    &:hover {
      color: var(--ds-modifier-button-color-base);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const AppTileWithPermission = styled('div')`
  position: relative;
  padding-bottom: 48px;
  .manage-permission {
    position: absolute;
    bottom: 0;
    right: 34px;

    .btn-manage-permission {
      text-decoration: underline;
    }
  }

  .popover-card {
    background: red;
  }
`;

export const LinkAppContainer = styled('div')`
  .assigned-apps-h4 {
    border-top: 1px solid ${ThemeColors.sectionSeparator};
    margin: 48px 0;
    padding-top: 48px;
  }
`;
