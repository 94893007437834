import React, { FC } from 'react';
import {
  AccordionTitle,
  SystemNotificationWrapper,
} from './systemNotification.styles';
import { Accordion, AccordionItem } from '@jsluna/react';
import Container from 'src/components/Container/Container';
import { InfoCircle, ArrowDown } from '@jsluna/icons';
import { SingleNotification } from './systemNotification';
import { ISystemAlert } from 'src/interfaces/systemAlert.interface';

const InfoCircleIcon = InfoCircle as unknown as React.FC;
const ArrowDownIcon = ArrowDown as unknown as React.FC;

interface ISystemNotifications {
  notifications: ISystemAlert[];
}

export const SystemNotifications: FC<ISystemNotifications> = ({
  notifications,
}) => {
  const accordionTitle = () => (
    <AccordionTitle>
      <Container className='ln-u-hard-top ln-u-hard-bottom'>
        <InfoCircleIcon />
        View {notifications.length} alerts
        <ArrowDownIcon />
      </Container>
    </AccordionTitle>
  );

  return (
    <SystemNotificationWrapper>
      <Accordion titleElement={accordionTitle}>
        <AccordionItem
          id='accordion-item-1'
          title='View 4 alerts'
          data-testid='first-item'
        >
          <Container className='ln-u-hard-top ln-u-hard-bottom'>
            {notifications.map((notification: ISystemAlert, idx: number) => (
              <SingleNotification
                key={idx}
                message={notification.description}
                title={notification.title}
                buttonLink={notification.url}
                buttonName={notification.url_button_name}
              />
            ))}
          </Container>
        </AccordionItem>
      </Accordion>
    </SystemNotificationWrapper>
  );
};
