import { ThemeColors } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const PanelActions = styled('div')`
  border-top: 1px solid ${ThemeColors.chbBackground};
  padding: 24px 0;
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;

  Button {
    width: calc((100% - 72px) / 2);
    line-height: initial;
    text-align: center;
  }
  .delete-section {
    flex: 0 0 100%;
    text-align: right;
  }
  .ln-c-button--text {
    padding: 0;
    text-decoration: underline;

    &:hover {
      background-color: transparent;
      color: ${ThemeColors.monochromeDark};
    }
  }
  &.no-gap {
    margin: 0 -24px;
  }
`;
