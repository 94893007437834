import React, { FC, ReactNode } from 'react';
import { GridItem, GridWrapper } from '@jsluna/react';
import { Notifications } from '../notifications';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import {
  Tick as LunaTick,
  ErrorCircle as LunaErrorCircle,
} from '@jsluna/icons';
import { IconProps } from 'src/interfaces/icon.interface';

interface INotificationBanner {
  children: ReactNode;
  onClose: () => void;
  type?: string;
}
const ErrorCircle = LunaErrorCircle as unknown as React.FC<IconProps>;
const Tick = LunaTick as unknown as React.FC<IconProps>;
export const NotificationBanner: FC<INotificationBanner> = ({
  children,
  onClose,
  type = 'SUCCESS',
}) => (
  <Notifications
    styleType={`ln-u-hard-sides notifications--${
      type === NotificationType.FAILURE ? 'red' : 'confirm'
    }`}
    onClose={onClose}
  >
    <div className='notifications--accepted--top__tick-circle'>
      <GridWrapper matrix className='ln-u-text-align-center'>
        <GridItem size={{ md: '5/6', lg: '3/4' }}>
          <div className='ln-u-display-flex ln-u-align-items-center'>
            <div className='tick-icon ln-u-push-right-sm'>
              {type === NotificationType.FAILURE ? (
                <ErrorCircle className='ln-u-color-red' aria-label='failure' />
              ) : (
                <Tick className='ln-u-color-green' aria-label='success' />
              )}
            </div>
            <span className='t-settings-notification ln-u-body-2'>
              {children}
            </span>
          </div>
        </GridItem>
      </GridWrapper>
    </div>
  </Notifications>
);
