import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string()
    .required('You must enter a  name')
    .min(1, 'Please enter a name')
    .max(35, 'Name must be less than 35 characters'),
  description: Yup.string()
    .required('You must enter a description')
    .max(75, 'Description must be at less than 75 characters long'),
  hiddenForRequests: Yup.string().required('You must choose one'),
});
