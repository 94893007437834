import styled from 'styled-components';

export const StyledLabel = styled('div')`
  display: flex;
  align-items: center;

  &.right-aligned {
    justify-content: flex-end;
  }

  .ln-c-label {
    margin-bottom: 0;
    margin-right: 10px;
  }

  a {
    font-weight: 800;
    cursor: pointer;
    span {
      color: #000;
    }
  }

  button {
    &:hover {
      background: transparent !important;
      color: var(--ds-modifier-button-color-base) !important;
    }
  }
`;
