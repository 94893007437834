import React, { FC, useEffect, useState } from 'react';
import { ProfileAccountSelectorContainer } from './profileAccountSelector.styles';
import {
  IconButton,
  Accordion,
  AccordionItem,
  CheckboxField,
} from '@jsluna/react';

import { Cancel } from '@jsluna/icons';
import {
  AccountSelectorSection_AccountsFragment,
  AddAccountByProfileScreen_ProfilesFragment,
} from 'src/operations/generated/graphql';

const CancelIcon = Cancel as unknown as React.FC;

interface IAccountOption {
  label: string;
  value: string;
  defaultChecked: boolean;
  disabled: boolean;
}

interface IProfileAccountSelector {
  profile: AddAccountByProfileScreen_ProfilesFragment;
  deleteHandler: (id: string) => void;
  onAccountSelected: (
    account: AccountSelectorSection_AccountsFragment,
    checked: boolean,
  ) => void;
  selectedAccounts: string[];
  existingAccountIds: string[];
}

export const ProfileAccountSelector: FC<IProfileAccountSelector> = ({
  profile,
  deleteHandler,
  onAccountSelected,
  selectedAccounts,
  existingAccountIds,
}) => {
  const { lastName, firstName, id, emailAddress, accounts = [] } = profile;
  const [accountOptions, setAccountOptions] = useState<IAccountOption[]>([]);

  useEffect(() => {
    const accountOptions = accounts.map(account => {
      const selected = selectedAccounts.includes(account.value.id);
      const disabled = existingAccountIds.includes(account.value.id);
      return {
        label: account?.value.name || 'UNAVALIABLE',
        value: account.value.id,
        defaultChecked: selected || disabled,
        disabled,
      };
    });
    setAccountOptions(accountOptions);
  }, [selectedAccounts]);

  return (
    <ProfileAccountSelectorContainer>
      <div className='profile-section'>
        <div className='detail'>
          <div className='name'>{`${firstName} ${lastName}`}</div>
          <div className='email'>{emailAddress}</div>
        </div>
        <div className='action'>
          <IconButton
            variant='text'
            circle={false}
            disabled={false}
            hard={false}
            element='button'
            label=''
            color='dark'
            onClick={() => deleteHandler(id)}
            hideLabel
          >
            <CancelIcon />
          </IconButton>
        </div>
      </div>
      {accountOptions.length > 0 && (
        <div className='accounts-section'>
          <Accordion titleElement='h4'>
            <AccordionItem id='accordion-item-1' title='See user accounts'>
              <CheckboxField
                label='accounts'
                name='checkbox-field-1'
                options={accountOptions}
                hideLabel
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const { value, checked } = e.target;
                  const account = accounts.find(
                    account => account.value.id === value,
                  );
                  if (account) {
                    onAccountSelected(
                      {
                        ...account.value,

                        profile: {
                          value: { id, firstName, lastName, emailAddress },
                        },
                      },
                      checked,
                    );
                  }
                }}
              />
            </AccordionItem>
          </Accordion>
        </div>
      )}
    </ProfileAccountSelectorContainer>
  );
};
