import React, { FC, ReactNode } from 'react';
import { PageHeaderCard } from './PageHeader.styles';
import { BreadcrumbsWrapper, BreadcrumbsItem } from '@jsluna/react';
import { NavLink } from 'react-router-dom';
import { IBreadcrumbItem } from '@interfaces/breadcrumbItem.interface';
import Container from 'src/components/Container/Container';

interface IPageHeader {
  heading: string;
  subHeading?: string;
  topHeading?: string;
  breadcrumbLinks?: IBreadcrumbItem[];
  cssClass?: string;
}

export const PageHeader: FC<IPageHeader> = ({
  heading,
  subHeading,
  topHeading,
  breadcrumbLinks,
  cssClass,
}) => {
  return (
    <PageHeaderCard className={`${cssClass ? cssClass : ''}`}>
      <Container>
        {topHeading && <div className='top-heading'>{topHeading}</div>}
        {breadcrumbLinks && (
          <BreadcrumbsWrapper className='breadcrumbs-wrapper'>
            {breadcrumbLinks.map((link: IBreadcrumbItem, index: number) =>
              index === breadcrumbLinks.length - 1 ? (
                <BreadcrumbsItem key={index} active>
                  {link.name}
                </BreadcrumbsItem>
              ) : (
                <BreadcrumbsItem key={index}>
                  <NavLink to={link.link}>{link.name}</NavLink>
                </BreadcrumbsItem>
              ),
            )}
          </BreadcrumbsWrapper>
        )}
        <div className='header'>{heading}</div>
        {subHeading && <div className='sub-heading'>{subHeading}</div>}
      </Container>
    </PageHeaderCard>
  );
};
