import React, { FC } from 'react';
import { StatusDiv } from './accountDetailStatus.styles';
import { Tick, Minus } from '@jsluna/icons';
import {
  AssociationStatus,
  RoleStatusEnum,
} from 'src/operations/generated/graphql';

interface IAccountDetailStatus {
  label: string;
  status?: RoleStatusEnum | AssociationStatus;
  link?: string;
}

const TickIcon = Tick as unknown as React.FC;
const MinusIcon = Minus as unknown as React.FC;

export const AccountDetailStatus: FC<IAccountDetailStatus> = ({
  label,
  status,
  link,
}) => {
  return (
    <StatusDiv>
      <div className='chb-display'>{status ? <MinusIcon /> : <TickIcon />}</div>
      <div className='label'>{link ? <a href={link}> {label} </a> : label}</div>
      {status && (
        <div
          className={
            status === RoleStatusEnum.Requested ||
            status === AssociationStatus.Requested
              ? 'requested-pill'
              : status === RoleStatusEnum.Rejected ||
                status === AssociationStatus.Rejected
              ? 'rejected-pill'
              : ''
          }
        >
          {status === RoleStatusEnum.Requested ||
          status === AssociationStatus.Requested
            ? RoleStatusEnum.Requested
            : status === RoleStatusEnum.Rejected ||
              status === AssociationStatus.Rejected
            ? RoleStatusEnum.Rejected
            : ''}
        </div>
      )}
    </StatusDiv>
  );
};
