import React, { FC } from 'react';
import { SectionHeaderWrapper } from './sectionHeader.styles';
import { Button } from '@jsluna/react';

type ISectionHeader = {
  heading: string;
  subHeading?: string;
  className?: string;
  callToButton?: {
    title: string;
    action: () => void;
  };
};
export const SectionHeader: FC<ISectionHeader> = ({
  heading,
  subHeading,
  className,
  callToButton,
}) => {
  return (
    <SectionHeaderWrapper
      className={`${className} ${callToButton ? 'flex-layout' : ''}`}
    >
      <div className='heading-section'>
        <h4>{heading}</h4>
        {subHeading && <div className='sub-heading'>{subHeading}</div>}
      </div>
      {callToButton && (
        <Button
          circle={false}
          disabled={false}
          hard={false}
          element='button'
          variant='filled'
          onClick={() => {
            callToButton.action();
          }}
        >
          {callToButton.title}
        </Button>
      )}
    </SectionHeaderWrapper>
  );
};
