import React, { FC, useEffect, useState } from 'react';
import Container from 'src/components/Container/Container';
import { NavLink } from 'react-router-dom';
import {
  Card,
  BreadcrumbsWrapper,
  BreadcrumbsItem,
  ButtonGroupSecondary,
  ButtonGroupWrapper,
  OutlinedButton,
  FilledButton,
} from '@jsluna/react';
import { RequestAccountForm } from './components/requestAccountForm/requestAccountForm';

import { IRequestAccount } from '@interfaces/requestAccount.interface';
import { routes } from 'src/constants/routes';
import { useNavigate } from 'react-router';
import { useAppSelector } from 'src/redux/hooks';
import { getProfileDetails } from 'src/redux/selectors';

import {
  useRequestAccountMutation,
  useRequestRoleMutation,
  useRequest_Account_Profile_DetailsLazyQuery,
} from 'src/operations/generated/graphql';

import { useSearchParams } from 'react-router-dom';
import gql from 'graphql-tag';
import { ProfileDetailCard } from './components/profileCard';

gql`
  query Request_Account_Profile_Details($id: String!) {
    profile(id: $id) {
      id
      firstName
      lastName
      emailAddress
    }
  }
`;

export const RequestAccount: FC = () => {
  const currentProfile = useAppSelector(getProfileDetails);
  const [searchParams] = useSearchParams();

  const [getProfile, { data: { profile = null } = {} }] =
    useRequest_Account_Profile_DetailsLazyQuery();

  let navigate = useNavigate();
  const [showCompletion, setCompletion] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  const [mutate, { error, loading }] = useRequestAccountMutation();
  const [rolesRequest, { error: rolesError, loading: rolesLoading }] =
    useRequestRoleMutation();

  const handleAccountRequest = async (values: IRequestAccount) => {
    const requestProfile = profile ? profile : currentProfile;
    if (requestProfile) {
      setInitialValues(values);
      try {
        const isPartner = values.isPartner === 'yes';
        const requestedAccountId = await mutate({
          variables: {
            account: {
              name: values.name,
              jobTitle: values.jobTitle,
              accountTypeId: values.accountTypeId,
              profileId: requestProfile.id,
              siteId: values.siteId,
              email: requestProfile.emailAddress || '',
              isPartner,
              partnerName: isPartner ? values.partnerName : '',
              partnerContact: isPartner ? values.partnerContact : '',
              roleIds: values.roles,
              tagIds: values.tags,
            },
          },
        });

        if (requestedAccountId?.data?.requestAccount?.id) {
          await rolesRequest({
            variables: {
              input: {
                accountId: requestedAccountId.data.requestAccount.id,
                roleIds: values.roles,
              },
            },
          });
        }
      } catch (error) {}
      setCompletion(true);
    }
  };

  const heading = 'Request an Account';

  useEffect(() => {
    const profileId = searchParams.get('profileId');
    if (profileId) {
      getProfile({
        variables: {
          id: profileId,
        },
      });
    }
  }, []);

  const showProfile = profile || currentProfile;

  return (
    <div>
      <Container>
        <BreadcrumbsWrapper className='breadcrumbs-wrapper'>
          <BreadcrumbsItem>
            <NavLink to='/'>Home</NavLink>
          </BreadcrumbsItem>

          <BreadcrumbsItem active>{heading}</BreadcrumbsItem>
        </BreadcrumbsWrapper>
        {showCompletion === false ? (
          <>
            <h3>
              {heading}{' '}
              {profile?.firstName &&
                `for ${profile.firstName} ${profile?.lastName}`}
            </h3>
            <div className='ln-u-margin-bottom*3'>
              {showProfile && (
                <ProfileDetailCard
                  firstName={showProfile?.firstName || ''}
                  lastName={showProfile?.lastName || ''}
                  emailAddress={showProfile?.emailAddress || ''}
                />
              )}
            </div>
            <RequestAccountForm
              initialValue={initialValues}
              onSubmit={handleAccountRequest}
              loading={loading}
            />
          </>
        ) : (
          <>
            {error ? (
              <Card>
                <h4>Your account request has failed.</h4>
                Something has gone wrong, please try again. If the issue
                persists, please contact us at together.with@sainsburys.co.uk
                <ButtonGroupWrapper>
                  <ButtonGroupSecondary>
                    <FilledButton
                      className='ln-u-margin-right'
                      onClick={() => {
                        navigate(routes.root);
                      }}
                    >
                      Go to homepage
                    </FilledButton>
                    <OutlinedButton
                      onClick={() => {
                        setCompletion(false);
                      }}
                    >
                      try again
                    </OutlinedButton>
                  </ButtonGroupSecondary>
                </ButtonGroupWrapper>
              </Card>
            ) : (
              <Card>
                <h4>Your account request has been submitted.</h4>
                We aim to review all account requests within 48 hours.
                <ButtonGroupWrapper>
                  <ButtonGroupSecondary>
                    <FilledButton
                      className='ln-u-margin-right'
                      onClick={() => {
                        navigate(routes.root);
                      }}
                    >
                      Go to homepage
                    </FilledButton>
                    <OutlinedButton
                      onClick={() => {
                        setInitialValues({});
                        setCompletion(false);
                      }}
                    >
                      Request another account
                    </OutlinedButton>
                  </ButtonGroupSecondary>
                </ButtonGroupWrapper>
              </Card>
            )}
          </>
        )}
      </Container>
    </div>
  );
};
