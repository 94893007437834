import styled from 'styled-components';

export const SupplierJournalDiv = styled('div')`
  margin-top: 2rem;

  .article-teaser-wrapper__heading {
    display: none;
  }

  [data-cy='supplierJournalSectionHeader'] {
    padding-top: 0 !important;
    margin-bottom: 40px !important;
  }
`;
