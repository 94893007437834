interface IServiceErrorMessages {
  title: string;
  description: string;
}

export const ServiceErrorMessages: { [key: string]: IServiceErrorMessages } = {
  500: {
    title: '500',
    description:
      "Uh Oh, that's an error. Sit back tight while we look into it.",
  },
  404: {
    title: '404',
    description:
      "Uh Oh, the link you've followed has not formed a connection in our Together With eco system yet.",
  },
  301: {
    title: '301',
    description:
      "The page you've been looking to navigate has been moved permanently.",
  },
};
