import React, { FC, useEffect, useState } from 'react';
import { Button, CheckboxField, ProgressSpinner } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { ApolloError } from '@apollo/client';
import { UpdateAccountTypeContainer } from './updateAccountTypeScreen.styles';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';
import { SectionRoles_RolesByAccountTypeFragment } from 'src/operations/generated/graphql';

interface IRoleOptions {
  label: string;
  value: string;
}

interface IDeleteRoleScreen {
  handleClose: () => void;
  roles: SectionRoles_RolesByAccountTypeFragment[];
  loading: boolean;
  error: ApolloError | undefined;
  handleConfirmation: (roles: string[]) => Promise<void>;
  target: { id: string; displayName: string };
}

export const DeleteRoleScreen: FC<IDeleteRoleScreen> = ({
  handleClose,
  roles,
  loading,
  error,
  handleConfirmation,
  target,
}) => {
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const [roleOptions, setRoleOptions] = useState<IRoleOptions[]>([]);

  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  useEffect(() => {
    const options = roles.map<IRoleOptions>(role => ({
      label: role.name || '',
      value: role.id,
    }));
    setRoleOptions(options);
  }, [roles]);

  const handleReset = () => {
    setFeedBackMessage(null);
  };

  const onRolesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedRoles([...selectedRoles, value]);
    } else {
      const filteredRoles = selectedRoles.filter(
        role => role !== e.target.value,
      );
      setSelectedRoles(filteredRoles);
    }
  };
  const handleSubmission = async () => {
    await handleConfirmation(selectedRoles);
    setFeedBackMessage(NotificationType.SUCCESS);
  };

  return (
    <>
      <UpdateAccountTypeContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={loading}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `The selected ${
                      selectedRoles.length === 1 ? 'role' : 'roles'
                    } '${selectedRoles.join(', ')}'  ${
                      selectedRoles.length === 1 ? 'has' : 'have'
                    } been deleted from ${target.displayName}.`
                  : 'Unable to delete role. Please try again.'
              }
              saveButtonText={'Close'}
              onCloseHandler={handleClose}
              resetButtonText={`${
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Delete another'
                  : 'Try again'
              }`}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? 'Email role successfully deleted'
                  : 'Failed to delete email role'
              }
            />
          </>
        ) : (
          <>
            <div className='panel-heading'>
              {`Remove role from account type`}
            </div>
            {roleOptions.length > 0 && (
              <CheckboxField
                label='Select roles to remove.'
                name='approved-roles-checkbox'
                options={roleOptions}
                onChange={onRolesSelected}
              />
            )}
          </>
        )}
      </UpdateAccountTypeContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={handleSubmission}
            variant='filled'
            disabled={!selectedRoles.length}
          >
            {loading && <ProgressSpinner />}
            {selectedRoles.length < 2 ? 'Remove role' : 'Remove roles'}
          </Button>
          <Button
            onClick={() => {
              setSelectedRoles([]);
              handleClose();
            }}
            variant='outlined'
          >
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
