import React, { FC, useState } from 'react';
import { IconButton } from '@jsluna/react';
import { Section } from '../section.styles';
import { Plus, Minus } from '@jsluna/icons';
import { Drawer } from '@mui/material';
import { ApolloError } from '@apollo/client';
import { AccountDetailStatus } from '../../../pages/viewProfile/components/accountDetailStatus';
import { AddDomainScreen } from '../../../screens/addEmailDomains';
import { RemoveDomainScreen } from '../../../screens/removeEmailDomains';

const PlusIcon = Plus as unknown as React.FC;
const MinusIcon = Minus as unknown as React.FC;

interface IDomainSection {
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  existingSelectedApprovedDomains: string[];
  existingSelectedRejectedDomains: string[];
  handleApprovedConfirmation: (domain: string) => Promise<void>;
  handleRejectedConfirmation: (domain: string) => Promise<void>;
  handleApprovedRemoval: (domains: string[]) => Promise<void>;
  handleRejectedRemoval: (domains: string[]) => Promise<void>;
  disabled?: boolean;
}

enum PanelAction {
  ADD_APPROVED_DOMAIN = 'ADD_APPROVED_DOMAIN',
  REMOVE_APPROVED_DOMAIN = 'REMOVE_APPROVED_DOMAIN',
  ADD_REJECTED_DOMAIN = 'ADD_REJECTED_DOMAIN',
  REMOVE_REJECTED_DOMAIN = 'REMOVE_REJECTED_DOMAIN',
}

export const DomainSection: FC<IDomainSection> = ({
  loading,
  error,
  target,
  existingSelectedApprovedDomains,
  existingSelectedRejectedDomains,
  handleApprovedConfirmation,
  handleRejectedConfirmation,
  handleApprovedRemoval,
  handleRejectedRemoval,
  disabled = true,
}) => {
  const [showSidePanel, setShowSidePanel] = useState<PanelAction | null>(null);

  const handlePanelClose = () => {
    setShowSidePanel(null);
  };

  const renderPanel = () => {
    switch (showSidePanel) {
      case PanelAction.ADD_APPROVED_DOMAIN:
        return (
          <AddDomainScreen
            handleClose={handlePanelClose}
            addedDomains={existingSelectedApprovedDomains}
            removedDomains={existingSelectedRejectedDomains}
            loading={loading}
            error={error}
            handleConfirmation={handleApprovedConfirmation}
            target={target}
            isApprovalList={true}
          />
        );
      case PanelAction.REMOVE_APPROVED_DOMAIN:
        return (
          <RemoveDomainScreen
            handleClose={handlePanelClose}
            domains={existingSelectedApprovedDomains}
            loading={loading}
            error={error}
            handleConfirmation={handleApprovedRemoval}
            target={target}
            isApprovalList={true}
          />
        );

      case PanelAction.ADD_REJECTED_DOMAIN:
        return (
          <AddDomainScreen
            handleClose={handlePanelClose}
            addedDomains={existingSelectedRejectedDomains}
            removedDomains={existingSelectedApprovedDomains}
            loading={loading}
            error={error}
            handleConfirmation={handleRejectedConfirmation}
            target={target}
            isApprovalList={false}
          />
        );
      case PanelAction.REMOVE_REJECTED_DOMAIN:
        return (
          <RemoveDomainScreen
            handleClose={handlePanelClose}
            domains={existingSelectedRejectedDomains}
            loading={loading}
            error={error}
            handleConfirmation={handleRejectedRemoval}
            target={target}
            isApprovalList={false}
          />
        );
    }
  };

  return (
    <>
      <Section>
        <h4 className='display-1 ln-u-margin-bottom*3'>
          Approved email domains
        </h4>
        {existingSelectedApprovedDomains.map((domain: string) => (
          <AccountDetailStatus label={domain} />
        ))}

        <div className='action-panel'>
          <IconButton
            variant='text'
            circle={false}
            disabled={disabled}
            hard={false}
            element='button'
            label={
              target.id.includes('AT_') ? 'Add domains' : 'Add email domains'
            }
            color='dark'
            onClick={() => {
              setShowSidePanel(PanelAction.ADD_APPROVED_DOMAIN);
            }}
          >
            <PlusIcon />
          </IconButton>
          <IconButton
            variant='text'
            circle={false}
            disabled={disabled || !existingSelectedApprovedDomains.length}
            hard={false}
            element='button'
            label={
              target.id.includes('AT_')
                ? 'Remove domains'
                : 'Remove email domains'
            }
            color='dark'
            onClick={() => {
              setShowSidePanel(PanelAction.REMOVE_APPROVED_DOMAIN);
            }}
          >
            <MinusIcon />
          </IconButton>
        </div>
      </Section>

      <Section>
        <h4 className='display-1 ln-u-margin-bottom*3'>
          Rejected email domains
        </h4>
        {existingSelectedRejectedDomains.map((domain: string) => (
          <AccountDetailStatus label={domain} />
        ))}

        <div className='action-panel'>
          <IconButton
            variant='text'
            circle={false}
            disabled={disabled}
            hard={false}
            element='button'
            label={
              target.id.includes('AT_') ? 'Add domains' : 'Add email domains'
            }
            color='dark'
            onClick={() => {
              setShowSidePanel(PanelAction.ADD_REJECTED_DOMAIN);
            }}
          >
            <PlusIcon />
          </IconButton>
          <IconButton
            variant='text'
            circle={false}
            disabled={disabled || !existingSelectedRejectedDomains.length}
            hard={false}
            element='button'
            label={
              target.id.includes('AT_')
                ? 'Remove domains'
                : 'Remove email domains'
            }
            color='dark'
            onClick={() => {
              setShowSidePanel(PanelAction.REMOVE_REJECTED_DOMAIN);
            }}
          >
            <MinusIcon />
          </IconButton>
        </div>
      </Section>

      <Drawer
        open={showSidePanel !== null}
        onClose={() => {
          setShowSidePanel(null);
        }}
        anchor='right'
        className='edit-app-group'
      >
        {renderPanel()}
      </Drawer>
    </>
  );
};
