import styled from 'styled-components';

export const RoleRequestWrapper = styled('div')`
  .assign-to-me {
    display: flex;
    justify-content: flex-end;

    .assigned-info {
      padding-left: 20px;
      margin-right: auto;
      font-family: Arial;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #2e7d32;
        position: absolute;
        top: 6px;
        left: 0;
      }
    }
  }

  .requested-roles {
    span {
      text-transform: capitalize;
      margin-left: 5px;
    }
  }

  .account-type-row {
    display: flex;
    font-family: var(--ds-font-stack-display-2-font-family) !important;
    font-size: 18px;
    font-style: normal;
    font-weight: 800;
    line-height: 27px;
    margin: 16px 0;

    .label {
      font-weight: bold;
      margin-right: 16px;
    }
  }

  .loader-content {
    svg {
      height: 15px;
      width: 15px;

      margin-right: 10px;
    }

    &.white {
      svg {
        color: #fff;
      }
    }
  }
`;
