import React, { FC, useState } from 'react';
import { Tabs, TabLink, Card, TabPanel, Button } from '@jsluna/react';
import { GridWrapper, GridItem } from '@jsluna/react';
import { AppTile } from '@sainsburys-tech/supexp-app-tile';

import { ApplicationTabsWrapper } from './applicationTabs.styles';
import { ITagWithApplications } from '@interfaces/tag.interface';
import { capitalize } from 'src/utils/capitalize';
import { IApplication } from '@interfaces/application.interface';
import CustomError from 'src/components/CustomError/CustomError';
import { Drawer } from '@mui/material';
import { EditAppGroupScreen } from 'src/screens/editAppGroup/editAppGroup';
import { IApplicationGroup } from 'src/interfaces/applicationGroup.interface';
import { AddNewAppGroupScreen } from 'src/screens/addNewAppGroup';
import { SectionHeader } from 'src/shared/components/sectionHeader';
import { IApplicationTabs } from '../../myAppsGroupTab';
import { useNavigate } from 'react-router';

interface ITagWithApplicationsTabs {
  tags: IApplicationTabs[];
  addNewGroup: () => void;
  refreshHandler?: () => void;
}

enum ActiveSidePanel {
  ADD_APP_GROUP = 'ADD_APP_GROUP',
  EDIT_APP_GROUP = 'EDIT_APP_GROUP',
}

export const ApplicationTabs: FC<ITagWithApplicationsTabs> = ({
  tags,
  addNewGroup,
  refreshHandler,
}) => {
  const [activeTabId, setActiveTab] = useState<string>(tags[0]?.id || 'st');
  const [showSidePanel, setShowSidePanel] = useState<null | ActiveSidePanel>(
    null,
  );
  const [selectedAppGroup, setSelectedAppGroup] =
    useState<IApplicationGroup | null>(null);

  const activeTab = tags?.find(
    tag => tag?.id === activeTabId,
  ) as ITagWithApplications;

  const editAppGroupHandler = (appGroupId: string) => {
    const selected = tags.filter(tag => tag.id === appGroupId);

    const appGroup: IApplicationGroup = {
      applications: selected[0].applications,
      id: selected[0].id,
      name: selected[0].name,
    };

    setSelectedAppGroup(appGroup);

    setShowSidePanel(ActiveSidePanel.EDIT_APP_GROUP);
  };

  const handleSidePanelCose = () => {
    setShowSidePanel(null);
  };
  const closeAppGroupHandler = () => {
    setShowSidePanel(null);
    refreshHandler && refreshHandler();
  };

  const navigate = useNavigate();

  return (
    <ApplicationTabsWrapper>
      {tags?.length ? (
        <>
          <SectionHeader
            heading='My app groups'
            callToButton={{
              action: addNewGroup,
              title: 'Add new app group',
            }}
          />

          <Tabs>
            {tags?.map(tag => (
              <TabLink
                key={tag?.id}
                onClick={() => setActiveTab(tag?.id)}
                active={activeTabId === tag?.id}
              >
                {capitalize(tag?.name)}
              </TabLink>
            ))}
          </Tabs>
          <TabPanel>
            <Card>
              <GridWrapper>
                {activeTab?.applications?.map((application: IApplication) => (
                  <GridItem
                    size={{ md: '1/3', xs: '1/1' }}
                    className='app-grid-item'
                    key={application.id}
                  >
                    <AppTile
                      application={application}
                      navigateToDetailsPage={() => {
                        navigate(`/applications/${application.id}`);
                      }}
                    />
                  </GridItem>
                ))}
              </GridWrapper>
              <div className='ln-u-text-align-right'>
                <Button
                  variant='text'
                  circle={false}
                  disabled={false}
                  hard={false}
                  element='button'
                  color='dark'
                  className='edit-app-group-btn ln-u-hard'
                  onClick={() => {
                    editAppGroupHandler(activeTab.id);
                  }}
                >
                  Edit app group
                </Button>
              </div>
            </Card>
          </TabPanel>
        </>
      ) : (
        <CustomError error='Your account has no tags associated to it' />
      )}

      {showSidePanel !== null && (
        <Drawer
          open={showSidePanel !== null}
          onClose={handleSidePanelCose}
          anchor='right'
          className='edit-app-group'
        >
          {showSidePanel === ActiveSidePanel.EDIT_APP_GROUP ? (
            <EditAppGroupScreen
              closeAppGroupHandler={closeAppGroupHandler}
              selectedApplicationGroup={selectedAppGroup}
            />
          ) : (
            <AddNewAppGroupScreen closeHandler={handleSidePanelCose} />
          )}
        </Drawer>
      )}
    </ApplicationTabsWrapper>
  );
};
