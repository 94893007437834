import { FC } from 'react';
import { generateBreadcrumbs } from 'src/utils/getBreadcrumbs';
import { TopOfPage } from '@sainsburys-tech/suppex-top-of-page';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useGetSupplierJournal } from 'src/operations/queries/getSupplierJournal';
import { TileGroup } from '@sainsburys-tech/suppex-tile-group';
import { TileData } from '@sainsburys-tech/suppex-tile';
import { IArticleChild } from 'src/interfaces/articleChild.interface';
import { generateJournalArticleTags } from 'src/utils/generateJournalTags';
import { journalClickHandler } from 'src/utils/journalClickHandler';
import { JournalLoading } from 'src/components/JournalLoading';
import { JournalError } from 'src/components/JournalError';

export const SupplierJournalPage: FC = () => {
  const navigate = useNavigate();
  const { journalId } = useParams();
  const {
    data: { journal } = {},
    loading,
    error,
  } = useGetSupplierJournal(journalId || '');
  return (
    <>
      {loading ? (
        <JournalLoading />
      ) : error ? (
        <JournalError />
      ) : (
        <>
          <TopOfPage
            title={journal?.title}
            breadcrumbs={generateBreadcrumbs({ journal })}
            LinkType={Link}
          />
          <TileGroup
            title={'All articles'}
            variant={'list'}
            softTop
            style={'light'}
            tiles={journal?.children.map(
              (article: IArticleChild) =>
                ({
                  image: {
                    url: article.thumbnail_image || article.teaser_image,
                    alt: '',
                    metaDesc: '',
                  },
                  ...article,
                  tags: generateJournalArticleTags(article, journal),
                } as unknown as TileData),
            )}
            tileClickHandler={(tile: TileData) => {
              journalClickHandler(tile, navigate);
            }}
          />
        </>
      )}
    </>
  );
};
