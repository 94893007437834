import styled from 'styled-components';

export const PopularHelpTopicsDiv = styled('div')`
  margin-top: 2rem;
  .article-teaser-wrapper__heading {
    display: none;
  }

  .ln-o-matrix-list__item {
    h4 {
      font-family: var(--ds-font-stack-display-2-font-family) !important;
      font-weight: 800;
      font-size: 20px;
      line-height: 26px;
      color: #4c4c4c;
      cursor: pointer;
    }
  }
`;
