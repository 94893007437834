import React, { FC } from 'react';
import ContentLoader from 'react-content-loader';
import { StyledStatCardLoader } from './StatCardLoader.styles';

interface IStatCardLoader {
  serviceName: string;
}

export const StatCardLoader: FC<IStatCardLoader> = ({ serviceName }) => {
  return (
    <div>
      <StyledStatCardLoader className='ln-u-margin-bottom*3'>
        <h5>{serviceName}</h5>
        <ContentLoader
          speed={2}
          width={270}
          height={20}
          viewBox='0 0 270 20'
          backgroundColor='#737373'
          foregroundColor='#ecebeb'
        >
          <rect y='0' x='0' rx='3' ry='3' width='200' height='10' />
        </ContentLoader>
        <div>
          <ContentLoader
            speed={2}
            width={100}
            height={20}
            viewBox='0 0 100 20'
            backgroundColor='#737373'
            foregroundColor='#ecebeb'
          >
            <rect y='0' x='0' rx='3' ry='3' width='100' height='10' />
          </ContentLoader>
        </div>
      </StyledStatCardLoader>
    </div>
  );
};
