import React, { FC } from 'react';
import { SingleNotificationAccordion } from './systemNotification.styles';
import { Button } from '@jsluna/react';
import Container from 'src/components/Container/Container';
import { InfoCircle, ArrowDown } from '@jsluna/icons';

const InfoCircleIcon = InfoCircle as unknown as React.FC;
const ArrowDownIcon = ArrowDown as unknown as React.FC;

interface ISingleNotification {
  title: string;
  message: string;
  buttonName: string;
  buttonLink: string;
}

export const SingleNotification: FC<ISingleNotification> = ({
  title,
  message,
  buttonName,
  buttonLink,
}) => {
  const limitChar = (text: string) => {
    if (text.length > 200) {
      return `${text.substring(0, 200)}...`;
    } else {
      return text;
    }
  };

  return (
    <SingleNotificationAccordion>
      <div className='content'>
        <div className='title'>{limitChar(title)}</div>
        <div className='message'>{limitChar(message)}</div>
      </div>
      <div className='action'>
        {buttonLink !== '' && (
          <Button
            variant='outlined'
            circle={false}
            element='button'
            color='light'
            onClick={() => {
              window.open(buttonLink, '_blank');
            }}
          >
            {buttonName}
          </Button>
        )}
      </div>
    </SingleNotificationAccordion>
  );
};
