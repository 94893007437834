import React, { FC } from 'react';
import { InfoPanelWrapper } from './infoPanel.styles';
import { InfoPanelTypes } from 'src/enums/infoPanelTypes.enum';
import { InfoCircle, ErrorCircle } from '@jsluna/icons';

interface IInfoPanel {
  infoType: InfoPanelTypes;
  infoText: string;
  customClass?: string;
}

const InfoCircleIcon = InfoCircle as unknown as React.FC;
const ErrorCircleIcon = ErrorCircle as unknown as React.FC;

export const InfoPanel: FC<IInfoPanel> = ({
  infoType,
  infoText,
  customClass,
}) => {
  const renderIcon = () => {
    switch (infoType) {
      case InfoPanelTypes.INFORMATION:
        return <ErrorCircleIcon />;

      default:
        return <InfoCircleIcon />;
    }
  };

  return (
    <InfoPanelWrapper StatusType={infoType} className={customClass}>
      {renderIcon()}
      <div className='info-text'>{infoText}</div>
    </InfoPanelWrapper>
  );
};
