import React, { FC, useEffect, useState } from 'react';
import { EditApplicationsContainer } from './viewApplicationEditScreen.styles';
import { TextInputField } from '@jsluna/react';
import { IApplicationDetails } from 'src/interfaces/application.interface';
import { Button } from '@jsluna/react';
import { ScreenFeedback } from 'src/screens/components/screenFeedback';
import { ApolloError } from '@apollo/client';
import { NotificationType } from 'src/enums/notificationTypes.enum';
import { validatedUrlRegex } from 'src/constants/regExValidations';
import { PanelActions } from 'src/components/PanelActions/panelActions.styles';

interface IHeading {
  heading: string;
  subHeading: string;
}

interface IEditApplicationURLScreen {
  handleClose: () => void;
  handleConfirmation: (status: string) => void;
  loading: boolean;
  error: ApolloError | undefined;
  target: { id: string; displayName: string };
  urlHeading: IHeading;
}

export const EditApplicationURLScreen: FC<IEditApplicationURLScreen> = ({
  handleConfirmation,
  handleClose,
  error,
  urlHeading,
  target,
}) => {
  const [proxyLocation, setProxyLocation] = useState<string>('');
  const [feedBackMessage, setFeedBackMessage] =
    useState<NotificationType | null>(null);
  useEffect(() => {
    if (error) {
      setFeedBackMessage(NotificationType.FAILURE);
    }
  }, [error]);

  const handleReset = () => {
    setProxyLocation('');
    setFeedBackMessage(null);
  };

  return (
    <>
      <EditApplicationsContainer>
        {feedBackMessage ? (
          <>
            <ScreenFeedback
              isLoading={false}
              notificationType={feedBackMessage}
              feedBackMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `The proxy location for ${target.displayName} has been updated.`
                  : `Failed to update the proxy location for ${target.displayName}.`
              }
              saveButtonText={'Save and close'}
              onCloseHandler={handleClose}
              resetButtonText={'Further changes'}
              resetHandler={handleReset}
              notificationMessage={
                feedBackMessage === NotificationType.SUCCESS
                  ? `${target.displayName} successfully updated`
                  : `Failed to update ${target.displayName}`
              }
            />
          </>
        ) : (
          <>
            <div className='tab-heading'>
              <h3>{urlHeading.heading}</h3>
            </div>
            <div className='panel-body'>
              <TextInputField
                name='text-input-field-2'
                info='This must be a URL'
                label='Proxy location'
                onChange={(e: any) => setProxyLocation(e.target.value)}
              />
            </div>
          </>
        )}
      </EditApplicationsContainer>
      {!feedBackMessage && (
        <PanelActions>
          <Button
            onClick={() => {
              if (
                proxyLocation !== '' &&
                validatedUrlRegex.test(proxyLocation)
              ) {
                handleConfirmation(proxyLocation);
                setFeedBackMessage(NotificationType.SUCCESS);
              }
            }}
            variant='filled'
            disabled={!validatedUrlRegex.test(proxyLocation)}
          >
            Set proxy location
          </Button>
          <Button onClick={handleClose} variant='outlined'>
            Cancel
          </Button>
        </PanelActions>
      )}
    </>
  );
};
