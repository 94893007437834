import { PropsWithChildren, ReactNode } from 'react';
import styled from 'styled-components';
import { Container as LunaContainer } from '@jsluna/react';

const Container = styled((props: any) => (
  <LunaContainer size='md' {...props} />
)).attrs({
  className: 'ln-u-push-top-md',
})`
  margin-bottom: 7.5rem;
  min-height: 100vh;
  width: 100%;
`;

const MainContainer = styled('main')`
  min-height: calc(100vh - 48px);
  width: 100%;
  position: relative;
`;

interface IMainProps {
  children: ReactNode;
}

const Main: React.FC<IMainProps> = ({ children }) => (
  <MainContainer>{children}</MainContainer>
);

export default Main;
