import React, { FC } from 'react';
import Container from 'src/components/Container/Container';
import { PageHeader } from 'src/components/PageHeader';
import { Card, GridWrapper, GridItem, FilledButton } from '@jsluna/react';
import { TermsContainer } from './platformTermsAndConditions.styles';
import { useNavigate } from 'react-router-dom';
import { routes } from 'src/constants/routes';

const TOGETHER_WITH_URL = 'https://togetherwith.co.uk/';
const TOGETHER_WITH_EMAIL = 'together.with@sainsburys.co.uk';

export const PlatformTermsAndConditions: FC = () => {
  let navigate = useNavigate();
  return (
    <>
      <PageHeader
        heading={`Terms & Conditions`}
        breadcrumbLinks={[
          {
            name: 'Home',
            link: '/',
          },
          {
            name: 'Terms & Conditions',
            link: '',
          },
        ]}
      />

      <Container className='ln-u-hard-top'>
        <Card>
          <GridWrapper
            matrix
            horizontalAlign='center'
            className='ln-u-soft-top-xl'
          >
            <GridItem size={{ default: '5/6', lg: '3/4' }}>
              <TermsContainer>
                <ol className='terms__olist ln-u-soft-bottom'>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Sainsbury&#39;s Together With Terms and Conditions
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        Please read this Agreement carefully. It represents the
                        terms and conditions between You and Sainsbury&#39;s for
                        the use of Sainsbury&#39;s Together With (&quot;
                        <strong>Together With</strong>
                        &quot;).
                      </li>
                      <li className='terms__sub-olist__item'>
                        For the purposes of these Terms and Conditions: &quot;
                        <strong>We</strong>
                        &quot;, &quot;
                        <strong>Our</strong>
                        &quot; and &quot;
                        <strong>Us</strong>
                        &quot; refers to Sainsbury&#39;s Supermarkets Ltd and
                        its Affiliates. Affiliates means a company which is a
                        subsidiary or holding company of Sainsbury&#39;s
                        Supermarkets Ltd and any subsidiary of that holding
                        company. References to &quot;
                        <strong>You</strong>
                        &quot;, and &quot;
                        <strong>Your</strong>
                        &quot; refers to authorised Companies accessing this
                        website (including individuals who access this site on
                        behalf of their Company); reference to
                        &quot;Material&quot;, &quot;Materials&quot; or
                        &quot;Content&quot; includes data, information,
                        databases and apps. Some Apps are developed by or
                        exclusively for Sainsbury&#39;s (&quot;
                        <strong>Our Apps</strong>
                        &quot;).
                      </li>
                      <li className='terms__sub-olist__item'>
                        Together With is provided to allow You to view and
                        access from one central place apps we believe are
                        appropriate or required for You to conduct business with
                        Us. Commercial terms between You and Us are contained in
                        separate Contractual Agreements. You agree that neither
                        the Content nor use of Together With shall modify or
                        amend the terms and conditions of any existing Agreement
                        between Us.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We reserve the right, at Our absolute discretion, to
                        update and/or revise these terms and conditions. We may
                        change these terms and conditions at any time by
                        updating them. You will be required to read and accept
                        the new terms and conditions at next use to continue
                        using Together With.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You are permitted to use Together With and Our Apps
                        solely on the basis of these terms and conditions and
                        You must accept them and Our 
                        <a
                          href={routes.privacyPolicy}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          Privacy Policy
                        </a>
                         if You wish to use them. If You do not agree, You may
                        not use Together With. If You have any queries relating
                        to these terms and conditions or Together With please
                        contact{' '}
                        <a href={`mailto:${TOGETHER_WITH_EMAIL}`}>
                          {TOGETHER_WITH_EMAIL}
                        </a>
                        .
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Registration & Information Security
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        Registration is restricted to supplier businesses with
                        whom We have a contractual relationship.
                      </li>
                      <li className='terms__sub-olist__item'>
                        To use Together With You must apply by completing our
                        registration form. You must provide information we
                        require which will include, without limitation, Your
                        name, Your company name, supplier number, function, and
                        job title. You must ensure that any contact details
                        shown on Together With for Your business are correct and
                        up to date. You must notify Us immediately in the event
                        of any discrepancies or changes. You agree You are
                        authorised to provide the information You submit to
                        Together With. Individual users agree they have
                        authority to enter into an agreement with Us.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We will validate all applicants and authorisation will
                        be completed via email. Use of Together With (
                        <a
                          href={TOGETHER_WITH_URL}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          {TOGETHER_WITH_URL}
                        </a>
                        ) will be via Microsoft Active Directory (each an{' '}
                        <strong>“Account”</strong>). If You do not already use
                        Microsoft Active Directory You will be required to set
                        up access to use Together With. You must keep your
                        password confidential. Do not disclose it to
                        unauthorised users.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You are responsible for any transactions on Together
                        With submitted using Your password. You may change Your
                        password at any time. Where user IDS and passwords are
                        allocated to individual users, You must contact Us at{' '}
                        <a href='mailto:together.with@Sainsburys.co.uk'>
                          together.with@Sainsburys.co.uk
                        </a>
                        {' and'} request deactivation of their ID if they leave
                        Your business. You will remain responsible for any
                        activity relating to user IDs provided to You for two
                        business days following receipt by Us of a deactivation
                        request. We may, at our discretion deactivate
                        registrations if not used.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You agree that Your registration is personal to You and
                        You will not loan, hire or otherwise allow any 3
                        <sup>rd</sup> party to use Your registration details.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We reserve the right to suspend Your use of Together
                        With if We suspect misuse.
                      </li>
                      <li className='terms__sub-olist__item'>
                        By Registering, You agree that We may contact You and
                        serve notices to You via Together With, including
                        messages relevant to You and news and information about
                        Us. Some information will be personal to You or Your
                        company and will be sent only to You. Other information
                        will be available to all users. We may require You to
                        acknowledge You have read messages or other information.
                      </li>
                      <li className='terms__sub-olist__item'>
                        From time to time We may require You to review and edit
                        Your registration details to confirm they are correct.
                        Where You receive such a request, please review and edit
                        promptly. It is Your responsibility in accordance with
                        clause 3.9 to ensure You do not access unauthorised
                        Material or Content.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We may at Our discretion appoint or require a super user
                        at Your company or work location. The super user may be
                        granted access to all Accounts at Your company or work
                        location and may facilitate or prevent access to
                        confidential Material or Content.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Use of Together With
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        All Material, Materials and Content, including Apps is
                        Confidential Information except where such Material,
                        Content or App is publicly available.
                      </li>
                      <li className='terms__sub-olist__item'>
                        Your right to use Together With and Our Apps is subject
                        to any limits imposed by Us and any limits included in
                        any Agreements with Us.
                      </li>
                      <li className='terms__sub-olist__item'>
                        Following registration You will be able to see Apps
                        recommended by Us and request access to those Apps. You
                        will be able to place favourite Apps in one place and We
                        will be able to highlight new Apps which may be of
                        interest to You.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We may restrict Your access to certain Apps. When using
                        certain areas of Together With or accessing Apps
                        provided by 3<sup>rd</sup> parties You may be asked to
                        agree to additional terms and conditions. In the event
                        any 3<sup>rd</sup> party terms and conditions conflict
                        with these terms, then these terms will apply.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We will tell You about any restrictions or limits of
                        Together With. If We vary the steps and restrictions We
                        will send You an email, to the contact details provided
                        by You detailing the changes. You may be required to
                        access Together With to accept the changes. Your
                        continued use of Together With will signify Your
                        acceptance of the changes.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You are responsible for the accuracy, verification or
                        completeness of information inputted, transmitted or
                        transferred by You using Together With.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We may record access to Together With or Our Apps. By
                        accessing or using Together With or Our Apps You agree
                        to such recording. Where you submit comments to Us,
                        these comments will remain private and We will respond
                        directly to You.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You agree not to interrupt Together With or Our Apps in
                        any way, damage them or attempt to gain unauthorised
                        access to any part.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You agree, where We allow You to select from a menu of
                        options, You will select only those options directly
                        relevant to You. You agree You will not attempt to gain
                        access to Materials or Apps not directly relevant to You
                        or Your job role or gain unauthorised access through
                        means not intentionally made available to You. In
                        particular and to ensure compliance with Competition
                        Law, if You are permitted or required to access Our
                        Product Management tool You agree You will edit Your
                        Account and select only those supplier codes directly
                        relevant to You. You agree where We find You are able to
                        access and/or are accessing unauthorised or irrelevant
                        Materials or Content We may remove such access and/or
                        suspend Your Account as the case may be.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You must not use Together With in any unlawful manner or
                        for any unlawful purpose.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You must not use Together With in a way that is
                        incompatible with Our servers or the use of Together
                        With by others
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Together With Access and Sainsbury&#39;s Apps
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        Together With and Our Apps are provided on an &#39;as
                        is&#39; basis and access may be restricted by
                        circumstances outside Our control, including, without
                        limitation, atmospheric or other conditions. We may on
                        occasion interrupt the service but will aim to restore
                        it as quickly as possible. We may amend Together With or
                        Our Apps including without limitation, changing access
                        codes, passwords or technical specifications.
                      </li>
                      <li className='terms__sub-olist__item'>
                        Your access to Together With and Our Apps may
                        temporarily be restricted for repairs, standard
                        maintenance or the introduction of new Services or Apps.
                        Access to Together With will be restored as soon as
                        possible.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You agree that We have no control over any information
                        transmitted or received by You outside the limits of
                        this Agreement and We have no liability to You for such
                        use. You accept that We cannot guarantee the security of
                        Together With or Our Apps against unlawful access or use
                        or that Together With or Our Apps will never be faulty.
                      </li>
                      <li className='terms__sub-olist__item'>
                        Our policy is to protect Our facilities and data from
                        any risk which may result in computer error, negligent
                        virus transmission, fraud, loss of confidentiality or
                        disruption to Together With or Our Apps including
                        disruption to Our business process. By using the Service
                        You agree to support and comply with this policy.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You must not use Together With or Our Apps:
                        <ul className='terms__sub-ulist'>
                          <li className='terms__sub-ulist__item'>
                            For sending, forwarding, accessing or receiving
                            pornographic, abusive, offensive, discriminatory,
                            insulting, defamatory or otherwise inappropriate
                            material;
                          </li>
                          <li className='terms__sub-ulist__item'>
                            To access information which You have no legitimate
                            reason for accessing;
                          </li>
                          <li className='terms__sub-ulist__item'>
                            In a way that may interfere with Our provision of
                            Together With or Our Apps to You or any other
                            supplier or in contravention of the law.
                          </li>
                        </ul>
                        <div>
                          If We reasonably believe You have misused the Service
                          We may suspend Your access to it without notice to
                          You.
                        </div>
                      </li>
                      <li className='terms__sub-olist__item'>
                        You are responsible for ensuring that You comply with
                        all laws and regulations relating to Your use of the
                        internet and Together With.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Termination
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        We will reserve the right to deactivate Your account
                        following 60 consecutive days of inactivity. You will be
                        given notice 15 days before deactivation. If You do not
                        respond Your account will be automatically deactivated.
                        Clear guidance on how You can reactivate your account,
                        both within and outside of the 15 day warning period,
                        will be detailed in the notification email.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We may change Together With in any way or delete
                        features or Apps at any time and for any reason.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We reserve the right to terminate Together With at any
                        time without notice.
                      </li>
                      <li className='terms__sub-olist__item'>
                        We reserve the right to terminate or suspend access to
                        all or part of Together With at any time and at Our sole
                        discretion, with or without notice.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Third Party Links
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        Together With may contain links to third party websites,
                        Apps or resources. We have no control over such
                        websites, Apps and resources and You agree that links to
                        third party websites are provided solely for Your
                        convenience and that We are not responsible or liable
                        for the content of those websites, Apps or resources. If
                        You decide to click through to any third party websites
                        You do so entirely at Your own risk.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You agree that Your dealings with third parties found
                        through Together With including terms, conditions,
                        warranties or representations are solely between You and
                        the third party.
                      </li>
                      <li className='terms__sub-olist__item'>
                        You may only link to Together With with Our express
                        written permission.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>Privacy</span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        Registration data and other information about You is
                        subject to Our Privacy Policy. Our Privacy policy can be
                        found at{' '}
                        <a
                          href={routes.privacyPolicy}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          link
                        </a>
                        .
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Intellectual Property
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        The content of Together With and Our Apps including
                        software code and underlying data is protected by
                        copyright, trademarks, database and other registered and
                        unregistered intellectual property rights which are
                        owned by Us, by Our licensors or by third parties. You
                        may retrieve and display the content of Together With on
                        Your computer or print one copy of the Materials for
                        Your own commercial use. You may not otherwise
                        reproduce, modify, alter, copy, transfer, distribute or
                        use the Materials or Content without prior written
                        permission from Us.
                      </li>
                      <li className='terms__sub-olist__item'>
                        From time to time We may invite comments or feedback
                        which can be left either on the Together With or
                        individual Apps. When You write a comment We will make
                        it clear at that time if it is a public facing comment
                        or a private comment. If public facing We will respond
                        publicly. If private, We will respond privately via the
                        app if possible or, if not, by email. If You comment on
                        or provide feedback about Together With or Our Apps,
                        this information, unless You ask Us to keep it
                        confidential, will not be treated as confidential and We
                        may reproduce it or use it without limitation or
                        liability to You.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Indemnity
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        You agree, subject to clause 1.3 and any limits imposed
                        by the Commercial Agreement between You and Us, to
                        indemnify and hold Us harmless from all liabilities,
                        claims and reasonable expenses that arise from Your use
                        or misuse of Together With or Our Apps or from Your
                        violation of these terms and conditions, including,
                        subject to clause 2.4 any breach by persons acting on
                        Your behalf using Together With user IDs provided by
                        You.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Limitations of Liability
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        Together With and Our Apps are provided on an &#39;as
                        is&#39; basis, without warranties of any kind, either
                        express or implied, including without limitation any
                        implied warranties of satisfactory quality, or fitness
                        for particular purpose or non-infringement. We will not
                        be liable to You for any losses suffered or costs
                        incurred because:
                        <ol className='terms__sub-olist--alpha' type='a'>
                          <li className='terms__sub-olist--alpha__item'>
                            You are unable to use Together With and Our Apps for
                            any reason;
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            Together With or Our Apps fail to work as expected
                            or are defective and We have not corrected these
                            errors including any incompatibility with Your
                            equipment or software;
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            There is a reduced or failed Service provision by
                            any third party operator including, without
                            limitation, mobile operators or software providers.
                          </li>
                        </ol>
                      </li>
                      <li className='terms__sub-olist__item'>
                        We assume no liability for any modification, suspension
                        or discontinuance of Together With or Our Apps.
                      </li>
                      <li className='terms__sub-olist__item'>
                        <p>
                          We exclude all liability, so far as is permitted by
                          law, including, without limitation, direct, indirect
                          or consequential loss or damages or any loss of
                          profits, loss of revenue, business interruption, loss
                          of data or otherwise resulting from the use of
                          Together With or Our Apps, whether based in contract
                          or tort, product liability or otherwise.
                        </p>
                        This does not include or limit in any way Our liability:
                        <ol className='terms__sub-olist--alpha' type='a'>
                          <li className='terms__sub-olist--alpha__item'>
                            For death or personal injury caused by Our
                            negligence;
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            For fraud or fraudulent misrepresentation;
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            For any matter for which it would be illegal for Us
                            to exclude, or attempt to exclude, Our liability.
                          </li>
                        </ol>
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Events outside Our control
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        We will not be liable or responsible for any failure to
                        perform, or delay in performance of, any of Our
                        obligations under a Contract that is caused by events
                        outside Our reasonable control (Force Majeure Event).
                      </li>
                      <li className='terms__sub-olist__item'>
                        Force Majeure Event includes any act, event,
                        non-happening, omission or accident beyond Our
                        reasonable control and includes in particular (without
                        limitation) the following:
                        <ol className='terms__sub-olist--alpha' type='a'>
                          <li className='terms__sub-olist--alpha__item'>
                            Strikes, lock-outs or other industrial action
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            Civil commotion, riot, invasion, terrorist attack or
                            threat of terrorist attack, war (whether declared or
                            not) or threat or preparation for war.
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            Fire, explosion, storm, flood, earthquake,
                            subsidence, epidemic or other natural disaster.
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            Impossibility of the use of public or private
                            telecommunications networks including mass
                            telecommunication failures or denial of Service
                            attacks.
                          </li>
                          <li className='terms__sub-olist--alpha__item'>
                            The acts, decrees, legislation, regulations or
                            restrictions of any government.
                          </li>
                        </ol>
                      </li>
                      <li className='terms__sub-olist__item'>
                        Our performance under any Contract is deemed to be
                        suspended for the period that the Force Majeure Event
                        continues, and We will have an extension of time for
                        performance for the duration of that period. We will use
                        Our reasonable endeavours to bring the Force Majeure
                        Event to a close or to find a solution by which Our
                        Service may be performed despite the Force Majeure
                        Event.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>Waiver</span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        If We fail, at any time to insist upon strict
                        performance of any of Your obligations or any of these
                        terms and conditions, or if We fail to exercise any of
                        the rights or remedies to which We are entitled to this
                        shall not constitute a waiver of such rights or remedies
                        and shall not relieve You from compliance with such
                        obligations.
                      </li>
                      <li className='terms__sub-olist__item'>
                        A waiver by Us of any default shall not constitute a
                        waiver of any subsequent default.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Severability
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        If any of these Terms and Conditions are determined by
                        any competent authority to be invalid, unlawful or
                        unenforceable to any extent, such term, condition or
                        provision will to that extent be severed from the
                        remaining terms, conditions and provisions which will
                        continue to be valid to the fullest extent permitted by
                        law.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Jurisdiction
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        English law is the governing law of this Agreement.
                        Where these terms and conditions are translated into
                        languages other than English, in the event of any
                        inconsistency or conflict the English language terms and
                        conditions take precedence.
                      </li>
                    </ol>
                  </li>
                  <li className='terms__olist__item'>
                    <span className='terms__olist__item--heading'>
                      Company details
                    </span>
                    <ol className='terms__sub-olist'>
                      <li className='terms__sub-olist__item'>
                        Together With and Our Apps are owned and operated by
                        Sainsbury&#39;s Supermarkets Ltd, registered number
                        3261722, a company registered in England and Wales whose
                        registered office is at 33 Holborn, London EC1N 2HT.
                        <br />
                        Vat no 660 4548 36
                      </li>
                    </ol>
                  </li>
                </ol>
              </TermsContainer>
            </GridItem>
          </GridWrapper>
        </Card>
      </Container>
    </>
  );
};
