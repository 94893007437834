import styled from 'styled-components';

export const TWWrapper = styled('div')`
  .landing-page__together-with__hero-image {
    background-image: url('https://images.togetherwith.co.uk/heroimage.jpg');
    background-position: center 70%;
    background-repeat: no-repeat;
    background-size: cover;
    max-height: 9.125rem;
    min-height: 9.125rem;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  @media (min-width: 960px) {
    .landing-page__together-with__hero-image {
      background-position: center 73%;
      max-height: 28.5rem;
      min-height: 28.5rem;
    }
  }
  .landing-page__section-title {
    font-size: 2rem;
    line-height: 1.25;
  }
  @media (min-width: 960px) {
    .landing-page__section-title {
      font-size: 2.5rem;
    }
  }
  .landing-page__together-with {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 2;
  }
  @media (min-width: 960px) {
    .landing-page__together-with {
      justify-content: flex-end;
    }
  }
  .landing-page__together-with__left-box {
    display: inline-block;
    position: relative;
    vertical-align: top;
    width: 100%;
  }
  @media (min-width: 960px) {
    .landing-page__together-with__left-box {
      bottom: 0;
      position: absolute;
      right: 0;
      width: 50%;
    }
  }
  .landing-page__together-with__left-box__inner {
    background-color: #fff;
    margin: 0;
    max-width: 100%;
    padding: 2rem 1rem 2.5rem;
  }
  @media (min-width: 960px) {
    .landing-page__together-with__left-box__inner {
      margin: auto 4.25rem 4rem auto;
      max-width: 40rem;
      padding: 2rem 3.8125rem 2.625rem;
    }
  }
  .landing-page__together-with__left-box__title {
    font-size: 2.5rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 800;
    line-height: 1.25;
  }
  @media (min-width: 960px) {
    .landing-page__together-with__left-box__title {
      font-size: 3.875rem;
      line-height: 1.23;
    }
  }
  .landing-page__together-with__left-box__description {
    font-size: 1.125rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: 1.44;
    margin: 1rem auto 2.875rem;
    max-width: 19.4375rem;
    text-align: center;
  }
  @media (min-width: 960px) {
    .landing-page__together-with__left-box__description {
      font-size: 1.25rem;
      line-height: 1.4;
    }
  }
  @media (min-width: 960px) {
    .landing-page__together-with__left-box__description {
      margin: 1rem auto 2.875rem;
      max-width: 31rem;
    }
  }
  .landing-page__together-with__image {
    height: 44.8125rem;
    max-width: none;
    width: auto;
  }
  .landing-page__together-with__navigation {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
  }
  @media (min-width: 960px) {
    .landing-page__together-with__navigation {
      flex-direction: row;
    }
  }
  .landing-page__together-with__navigation__button {
    margin: 0.4rem;
    max-width: 80%;
    width: 13.75rem;
  }
  .landing-page__together-with__navigation__button .loading-icon {
    margin: auto;
  }
  .landing-page__featured-app__left-box__inner {
    margin: auto;
    max-width: 19.4375rem;
    padding: 2rem 0 1.5rem;
    text-align: center;
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__left-box__inner {
      padding-bottom: 3rem;
      text-align: left;
    }
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__left-box__inner {
      max-width: 32.5rem;
      padding: 4.625rem 0 5.25rem;
    }
  }
  .landing-page__featured-app__left-box__icon-box {
    align-items: center;
    display: flex;
    height: 2.875rem;
    justify-content: center;
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__left-box__icon-box {
      justify-content: left;
    }
  }
  .landing-page__featured-app__right-box {
    display: inline-flex;
    justify-content: center;
    position: relative;
    vertical-align: bottom;
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__right-box {
      vertical-align: bottom;
    }
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__right-box {
      vertical-align: top;
    }
  }
  .landing-page__featured-app__device-image {
    background-image: url('https://images.togetherwith.co.uk/queryapp-devices-mobile-v4.svg');
    background-repeat: no-repeat;
    background-size: contain;
    max-height: 12.875rem;
    max-width: 20.5rem;
    min-height: 12.875rem;
    position: relative;
    width: 100%;
    z-index: 1;
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__device-image {
      background-image: url('https://images.togetherwith.co.uk/queryapp-devices-tablet-v4.svg');
      max-height: 16.75rem;
      max-width: 16.3125rem;
      min-height: 16.75rem;
    }
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__device-image {
      background-image: url('https://images.togetherwith.co.uk/queryapp-devices-desktop-v4.svg');
      max-height: 24.875rem;
      max-width: 29.5625rem;
      min-height: 24.875rem;
      position: absolute;
      top: 0;
    }
  }
  .landing-page__featured-app__description {
    font-size: 1rem;
    line-height: 1.44;
    margin: 1.5rem auto 0;
    max-width: 17.8125rem;
    width: 100%;
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__description {
      max-width: 19.4375rem;
    }
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__description {
      margin: 1.5rem auto 0;
    }
  }
  @media (min-width: 960px) {
    .landing-page__featured-app__description {
      font-size: 1.125rem;
      margin: 1.5rem auto 0;
      max-width: 32.5rem;
      width: 100%;
    }
  }
  .landing-page__how-it-works {
    padding: 4rem 0;
  }
  .landing-page__how-it-works__image {
    max-height: 10.5rem;
    max-width: 10.5rem;
    width: 100%;
  }
  @media (min-width: 960px) {
    .landing-page__how-it-works__image {
      max-height: 15rem;
      max-width: 15rem;
    }
  }
  .landing-page__how-it-works__sub-title {
    font-size: 1.375rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: 800;
    line-height: 1.18;
  }
  @media (min-width: 960px) {
    .landing-page__how-it-works__sub-title {
      font-size: 1.75rem;
      line-height: 1.64;
    }
  }
  .landing-page__how-it-works .description {
    font-size: 1rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    margin: 0.1875rem auto 1.5rem;
    max-width: 14.125rem;
  }
  @media (min-width: 960px) {
    .landing-page__how-it-works .description {
      font-size: 1.125rem;
      max-width: 22.8125rem;
    }
  }
  @media (min-width: 960px) {
    .landing-page__how-it-works .description {
      font-size: 1.125rem;
    }
  }
  .navigation {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto;
  }
  .navigation__button {
    margin-top: 0.4rem;
    max-width: 80%;
    width: 320px;
  }
  .landing-page__become-supplier {
    padding: 4rem 0.4rem;
  }
  @media (min-width: 960px) {
    .landing-page__become-supplier {
      padding: 4rem 0.5rem;
    }
  }
  .landing-page__become-supplier__left-box {
    text-align: center;
  }
  @media (min-width: 960px) {
    .landing-page__become-supplier__left-box {
      text-align: center;
    }
  }
  .landing-page__become-supplier__right-box {
    margin-top: 2rem;
    text-align: center;
  }
  @media (min-width: 960px) {
    .landing-page__become-supplier__right-box {
      margin-top: 0;
      text-align: left;
    }
  }
  .landing-page__become-supplier__image {
    height: 100%;
    max-height: 17.5rem;
    max-width: 17.5rem;
    width: 100%;
  }
  .landing-page__become-supplier__description {
    font-size: 1.125rem;
    font-stretch: normal;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    margin: auto;
    padding: 1.6875rem 0;
    width: 17.8125rem;
  }
  @media (min-width: 960px) {
    .landing-page__become-supplier__description {
      width: 19.4375rem;
    }
  }
  @media (min-width: 960px) {
    .landing-page__become-supplier__description {
      margin: 0;
      padding: 0 0 1rem;
      width: 20.125rem;
    }
  }
  @media (min-width: 960px) {
    .landing-page__become-supplier__description {
      margin: 0;
      padding: 0 0 1.125rem;
      width: 39.8125rem;
    }
  }
  .landing-page__icon--sainsburys-150 {
    max-height: 4.3125rem;
  }
  .clip-circle--165 {
    clip-path: circle(120px at center);
  }
  @media (min-width: 960px) {
    .clip-circle--165 {
      clip-path: circle(165px at center);
    }
  }
`;
